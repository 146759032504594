import React, { useState, useEffect } from 'react'
import { Button } from 'react-bootstrap'
import Modal from 'react-bootstrap/Modal';
import DataTable from 'react-data-table-component';
import FetchApi from '../customhooks/functionFetchApi';
import { createDuplicate } from './service/serviceduplicagte';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import Viewduplicatedetail from './components/viewduplicatedetails';
import {
    FaSearch
} from "react-icons/fa";

function createjournalduplicate(journalId) {

    Swal.fire({
        title: "สร้างเอกสาร",
        text: "คุณแน่ใจที่สร้างเอกสาร ใช่หรือไม่ ! ",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ใช่",
        cancelButtonText: 'ยกเลิก'
    }).then((result) => {
        if (result.isConfirmed) {
            Swal.fire({
                title: "สร้างเอกสาร!",
                text: "คุณได้สร้างเอกสารเรียบแล้ว",
                icon: "success"
            });
            //   loadingdata();
           createDuplicate(journalId);

            toast.success('บันทึกเรียบร้อยแล้ว', {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                // transition: Bounce,
            });

        }
    });
}


const columns = [
    {
        name: 'MK.NO',
        grow: 1,
        sortable: true,
        selector: row => row.tdscode,
    },
    {
        name: 'Film code',
        grow: 1,
        selector: row => row.fname,
    },
    {
        name: 'filmcode',
        selector: row => row.filmcode,
    },
    {
        name: 'customername',
        grow: 1.5,
        selector: row => row.customername,
    },
    {
        name: 'Action',
        grow: 1.5,
        cell: row => <div className='row-page'>
            <Button size='sm' variant="success" onClick={() => createjournalduplicate(row.journalId)} style={{ marginRight: 10 }}>สร้างเอกสาร</Button>
            < Viewduplicatedetail jourId={row.journalId} JournalTDS={row.tdscode} />
        </div>,
    },

];

const customStyles = {
    headRow: {
        style: {
            border: 'none',
            backgroundColor: '#28B463'
        },
    },
    headCells: {
        style: {
            color: '#ffff',
            fontSize: '14px',
        },
    },
    rows: {
        highlightOnHoverStyle: {
            backgroundColor: 'rgb(230, 244, 244)',
            borderBottomColor: '#FFFFFF',
            borderRadius: '25px',
            outline: '1px solid #FFFFFF',
        },
    },
    pagination: {
        style: {
            border: 'none',
        },
    },
};

const FormAtable = ({ dataList }) => {
    var dataresult = dataList;
   
    return (
        <DataTable
            columns={columns}
            data={dataresult}
            // selectableRows
            pagination
            customStyles={customStyles}
            highlightOnHover

        />
    )
}



// list film code  to create 
function Listfilmcode() {

    const FetchApis = new FetchApi();
    const [inputText, setInputText] = useState("")
    const [listfilmcode, setListfilmcode] = useState([]);


    const getfilmcodelist = () => {
        if (inputText == "") {

            Swal.fire({
                title: "แจ้งเตือน?",
                text: "ไม่ข้อมูลที่ค้นหา ?",
                icon: "question"
            });
        }
        else {

            var datajobject={
                filmcode:inputText
            }
            FetchApis.FethcPost(`/journal/getsearchfilmcode`,datajobject).then((res) => {
                console.log(res)
                if (res.status == 200) {
                    if (res.data.length == 0) {
                        Swal.fire({
                            title: "แจ้งเตือน?",
                            text: "ไม่ข้อมูลที่ค้นหา ?",
                            icon: "question"
                        });
                    } else {
                         setListfilmcode(res.data);
                    }


                }
            })
        }
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            getfilmcodelist()
        }
    };

    return (
        <div>
            <div className='end-flex-row' style={{ backgroundColor: '#D6D9DA', padding: 20 }}>
                <div>
                    <input type='text' name='filmcode' placeholder='ป้อนข้อมูล film code '
                        onChange={(e) => setInputText(e.target.value)}
                        onKeyDown={handleKeyDown}
                    />
                </div>
                <div style={{ marginLeft: 10 }}>
                    <Button onClick={() => getfilmcodelist()} size='sm'>ค้นหา</Button>
                </div>
            </div>
            <div>
                <FormAtable dataList={listfilmcode} />
            </div>
        </div>
    )
}



const DuplicateByfilmcode = () => {

    const values = [true, 'sm-down', 'md-down', 'lg-down', 'xl-down', 'xxl-down'];
    const [fullscreen, setFullscreen] = useState(true);
    const [show, setShow] = useState(false);

    function handleShow(value) {
        // setFullscreen(breakpoint);
        setShow(value);
    }

    return (
        <div>
            <label style={{
                fontSize: 10, padding: 3, backgroundColor: '#F0BE08',
                borderRadius: 5, borderColor: '#E3B405', marginLeft: 5, color: '#ffff'
            }} onClick={() => handleShow(true)} >
                <FaSearch /> Film Code
            </label>

            <Modal show={show} fullscreen={fullscreen} onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>ข้อมูล Film Code </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Listfilmcode />
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default DuplicateByfilmcode