import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import FetchApi from '../../customhooks/functionFetchApi';
import Table from 'react-bootstrap/Table';

function Addmenudetail({ Id }) {
    const FetchApis = new FetchApi();
    const [addnewmenu, setAddnewmenu] = useState("");
    const [getmenulise, setGetmenulist] = useState([]); //เรียกเมนูมาแสดง
    const [getlistadd, setGetlistadd] = useState([]); // เรียกข้อมูลที่เพิ่มไปแล้ว
    const [getative, setGetative] = useState([]);

    useEffect(() => {
        getlistmenu();
        getlistmenuBy();

    }, [])

    //แสดงรายการ เมนูที่จะเลือกทั้งหมด
    function getlistmenu() {
        FetchApis.FethcGet(`/menuaccess/getmenulistall`).then((res) => {
            setGetmenulist(res.data.data);
            // setGetative(res.data.data);
        })
    }
    //แสงดข้อมูลที่ เพ่ิมแล้ว
    function getlistmenuBy() {
        FetchApis.FethcGet(`/groupmenusub/getgroupdetailByid/${Id}`).then((res) => {
            if (res.status == 200) {
                setGetlistadd(res.data.data);
                setGetative(res.data.data);
            }
        }).catch(err => console.log(err))
    }

    //เพิ่มข้อมูล
    function addmenubygroup() {
        let objectdata = {
            menugroupId: Id,
            menuid: addnewmenu
        }
        if (addnewmenu == "") {
            alert('คุณยังไม่ได้เลือกรายการเมนู')
        } else {
            FetchApis.FethcPost(`/groupmenusub/creategroupmenusub`, objectdata).then((res) => {
                if (res.status == 200) {
                    getlistmenuBy();
                    alert('เพิ่มเรียบร้อยแล้ว');
                }

            }).catch(err => console.log(err))
        }


    }


    // save set config user by menu
    function UpdateSetconfigall() {
        var counting = 0;
        for (let index = 0; index < getative.length; index++) {
            const getativenew = getative[index];
            // UpdateAccessusernow(getativenew);
            // console.log(getativenew);
            UpdateMenulist(getativenew);
            counting++;
        }
        if (getative.length == counting) {
            alert('update เรียบร้อยแล้ว')
        }

    }

    // update config  set 
    function UpdateMenulist(data) {

        FetchApis.FethcUpdate(`/groupmenusub/updateByid/${data.menugroupsubId}`, data).then((res) => {
            if (res.status == 200) {
                console.log('update successfully');
            } else {
                alert('update fail');
            }
        }).catch(err => alert(err))
    }



    function headleOnchange(e) {
        setAddnewmenu(e.target.value)
    }

    function deletemenu(id) {
        FetchApis.FethcDelete(`/groupmenusub/deleteByid/${id}`).then((res) => {
            if (res.status == 200) {
                alert('ลบข้อมูลเรียบร้อยแล้ว');
                getlistmenuBy();
            }
        }).catch(err => alert(err))
    }
    function handleChange(e) {

        const { name, value } = e.target;
        if (e.target.checked) {
            const item = getative.find((item) => item.menuid == name);
            item.active = value
        }
        else {
            const item = getative.find((item) => item.menuid == name);
            item.active = "0"
        }
    }

    function handleChangeAdd(e) {
        const { name, value } = e.target;
        if (e.target.checked) {
            const item = getative.find((item) => item.menuid == name);
            item.access_add = value
            // console.log(item.access_add);
        }
        else {
            const item = getative.find((item) => item.menuid == name);
            item.access_add = "0"
        }
    }

    function handleChangeDelete(e) {
        const { name, value } = e.target;
        if (e.target.checked) {
            const item = getative.find((item) => item.menuid == name);
            item.access_delete = value
            // console.log(item.access_delete);
        }
        else {
            const item = getative.find((item) => item.menuid == name);
            item.access_delete = "0"
        }
    }

    function handleChangeUpdate(e) {
        const { name, value } = e.target;
        if (e.target.checked) {
            const item = getative.find((item) => item.menuid == name);
            item.access_update = value
        }
        else {
            const item = getative.find((item) => item.menuid == name);
            item.access_update = "0"
        }
    }

    return (
        <div>
            <div>
                <label>เลือกรายการที่ต้องการเพิ่ม </label>
            </div>
            <div className='row-between-page' >
                <div className='row-page'>
                    <div>
                        <Form.Select style={{ width: 400 }} onChange={(e) => headleOnchange(e)}>
                            <option>เลือกรายการเมนู...</option>
                            {getmenulise.map((item, i) => (
                                <option value={item.menuid}>{item.name}</option>
                            ))}
                        </Form.Select>
                    </div>
                    <div style={{ marginLeft: 10 }}>
                        <Button variant="success" onClick={() => addmenubygroup()}>เพิ่มเมนู</Button>
                    </div>
                </div>

                <div>
                    <Button onClick={() => UpdateSetconfigall()}>ตั้งค่า</Button>
                </div>
            </div>
            <hr></hr>
            <div>
                <label>แสดงเมนูทั้งหมด</label>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th><center>ชื่อเมนู</center></th>
                            <th><center>active</center></th>
                            <th><center> active add</center></th>
                            <th><center>active update</center></th>
                            <th><center>active delete</center></th>
                            <th><center>action</center></th>

                        </tr>
                    </thead>
                    <tbody style={{ fontSize: 12 }}>
                        {getlistadd.map((item, i) => (
                            <tr>
                                <td>{i + 1}</td>
                                <td>{item.name}</td>
                                <td>
                                    <center>
                                        <input type='checkbox' value={'1'} name={item.menuid}
                                            defaultChecked={item.active == 1 ? true : false} onChange={e => handleChange(e)} />
                                    </center>
                                </td>
                                <td>
                                    <center>
                                        <input type='checkbox' name={item.menuid}
                                            defaultChecked={item.access_add == 1 ? true : false} value={'1'} onChange={e => handleChangeAdd(e)} />
                                    </center>
                                </td>
                                <td>
                                    <center>
                                        <input type='checkbox' name={item.menuid}
                                            defaultChecked={item.access_delete == 1 ? true : false} value={'1'} onChange={e => handleChangeDelete(e)}
                                        />
                                    </center>
                                </td>
                                <td>
                                    <center>
                                        <input type='checkbox' name={item.menuid}
                                            defaultChecked={item.access_update == 1 ? true : false}
                                            value={'1'} onChange={e => handleChangeUpdate(e)} />
                                    </center>
                                </td>
                                <td>
                                    <center>
                                        <Button size='sm' variant="danger" onClick={() => deletemenu(item.menugroupsubId)}>
                                            <label style={{ fontSize: 12 }}>ลบข้อมูล</label>
                                        </Button>
                                    </center>
                                </td>
                            </tr>
                        ))}

                    </tbody>
                </Table>
            </div>
        </div>
    )

}


const Actionadd = ({ Id, namegroup }) => {
    const values = [true, 'sm-down', 'md-down', 'lg-down', 'xl-down', 'xxl-down'];
    const [fullscreen, setFullscreen] = useState(true);
    const [show, setShow] = useState(false);

    function handleShow(value) {
        setShow(value);
    }

    return (
        <div>
            <Button size='sm' onClick={() => handleShow(true)} variant="success">ตั้งค่า</Button>

            <Modal show={show} fullscreen={fullscreen} onHide={() => handleShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>เพิ่มเมนูกลุ่ม  ( {namegroup} )</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Addmenudetail Id={Id} />
                </Modal.Body>
            </Modal>
        </div>

    )
}

export default Actionadd