import React, { useEffect, useState } from 'react'
import FetchApi from '../../../customhooks/functionFetchApi'
import { Button } from 'react-bootstrap'
import Modal from 'react-bootstrap/Modal';

function DetailNote({ journalid, dedicateid }) {
    const FetchApis = new FetchApi();
    const[detailList,setDeailList]=useState([])

    useEffect(() => {

        const datalist = {
            journalid: journalid,
            dedicatecode: dedicateid
        }
        loadingnote(datalist)
    }, [])

    function loadingnote(datalists){

        FetchApis.FethcPost(`/taskflow/getDedicateApproveByid`, datalists).then((res) => {
            if(res.status==200){
                setDeailList(res.data);
            }

        })
    }

    return (
        <div>
            {/* {JSON.stringify(detailList)} */}
            <table style={{width:'100%'}}>
                <tr>
                    <th>ลำดับ</th>
                    <th>ผู้มอบหมาย</th>
                    <th>ลำดับอนุมัติ</th>
                    <th>หมายเหตุ</th>
                    <th>เวลาที่แจ้ง</th>
                </tr>
                {detailList.map((item,i)=>(
                <tr>
                <td>{i+1}</td>
                <td>{item.referenname}</td>
                <td>{item.stateflow}</td>
                <td>{item.tasknote}</td>
                <td>{item.datetime}</td>
                </tr>
                ))}

            </table>
        </div>
    )
}

//'/tds/'
const Detaildedicatenote = ({ journalid, dedicatecode }) => {

    const values = [true, 'sm-down', 'md-down', 'lg-down', 'xl-down', 'xxl-down'];
    const [fullscreen, setFullscreen] = useState(true);
    const [show, setShow] = useState(false);

    function handleShow(value) {
        // setFullscreen(breakpoint);
        setShow(value);
    }


    return (
        <div>
            <Button size='sm' onClick={() => handleShow(true)}>รายละเอียด</Button>

            <Modal show={show}
                //  fullscreen={fullscreen} 
                size="lg"
                onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>กำหนด ผู้อนุมัติแทน </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <DetailNote journalid={journalid} dedicateid={dedicatecode} />
                </Modal.Body>
            </Modal>

        </div>



    )
}

export default Detaildedicatenote