import React, { useState, useEffect, useRef } from 'react'

import generatePDF from 'react-to-pdf';
import { Button } from 'react-bootstrap';
import { FaFileDownload } from "react-icons/fa";
import ViewNewItem from '../../components/viewNewItem';
import FetchApi from '../../../customhooks/functionFetchApi';

const PdfNewItem = ({ jourId }) => {
    const targetRef = useRef();
    const FetchApis = new FetchApi();

    function getjournalbyid() {
        FetchApis.FethcGet(`/journal/journalbyId/${jourId}`).then(res=>{
            if(res){
              let tdscodeid  =res.data.data[0].tdscode;

                generatePDF(targetRef, { filename: `${tdscodeid}_NewItem_page.pdf` })
            }
            
        })
    }

    return (
        <div>
            <Button onClick={() =>getjournalbyid()} size='sm' variant="light">
                <FaFileDownload /> Download PDF</Button>
            <div ref={targetRef}>
                <ViewNewItem jourId={jourId} />
                <img src={require('../../../images/draft-icon-27.jpg')} style={{ width: 50, height: 'auto', borderRadius: 3 }} />
            </div>
        </div>
    )
}

export default PdfNewItem