import React from 'react'
import Deleteflowsystem from './flowsystem/deleteflowsystem';
import Editflowsystem from './flowsystem/editflowsystem';
import Veiwflowsystem from './flowsystem/veiwflowsystem';
import Addflowsystemsub from './flwosystemsub/addflowsystemsub';

const Actionflowsystem = ({ Id, code }) => {

  return (
    <div className='row-between-page'>
      <div><Addflowsystemsub Id={Id} /> </div>
      <div style={{ marginRight: 3 }}> <Veiwflowsystem Id={Id} /></div>
      <div style={{ marginRight: 3 }}><Editflowsystem Id={Id} /></div>
      <div><Deleteflowsystem Id={Id} /></div>
    </div>
  )
}

export default Actionflowsystem