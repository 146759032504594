import React from "react";
import FetchApi from "../../customhooks/functionFetchApi";
import { statusflowall } from '../object-usestate/objectdefaults';

const FetchApis = new FetchApi();


export function createDuplicate(journalId) {

    // header 
    FetchApis.FethcGet(`/journal/journalbyId/${journalId}`).then((res) => {
        if (res.data.status == 200) {
           
            createjournalHeader(journalId,res.data.data[0]);

        }
    })

}

// สร้าง journal
function createjournalHeader(journalId,data) {

    const usercode = sessionStorage.getItem('EmCode');

    var journaldetail = {
        journaltype: data.journaltype,
        duedate: data.duedate,
        tdscode: data.tdscode,
        createdate: data.createdate,
        customername: data.customername,
        contact: data.contact,
        tel: data.tel,
        productname: data.productname,
        itemname: data.itemname,
        itemcode: data.itemcode,
        cansize: data.cansize,
        shape:data.shape,
        typeproduct:data.typeproduct,
        substratetype: data.substratetype,
        productgrouptype: data.productgrouptype,
        informationtype: data.informationtype,
        informationnote: data.informationnote,
        printingtype: data.printingtype,
        printingmachine:data.printingmachine,
        printingtypeother: data.printingtypeother,
        filmcode: data.filmcode,
        refFgcode:data.refFgcode,
        filmtype: data.filmtype,
        createby: usercode,
        uploadfilename:data.uploadfilename,
        uploadfilecolor:data.uploadfilecolor
    }

    FetchApis.FethcPost(`/journal/createjournal`, journaldetail).then((res) => {
        if (res.status == 200) {
            // console.log(res);
            var newJournalId=res.id;

            selectjournallist(journalId,newJournalId);

            updatestatus(newJournalId);
        }
    })


    function selectjournallist(journalId,newJournalId) {

        FetchApis.FethcGet(`/journal/journalbyId/${newJournalId}`).then((res) => {
            if (res.status == 200) {
               
                 getDatailjournal(journalId,newJournalId,res.data.data[0].tdscode)

            }
        })
    }

    function updatestatus(journalid) {

        var stateflowall = {
            statusflow: statusflowall.newjournal,
            stateflow: "0"
        }
        // update state flow  new journal 
        FetchApis.FethcUpdate(`/journal/updatestateflow/${journalid}`, stateflowall).then((res) => { console.log(res) })

    }

}


// เรียกข้อมูล ล่าสุดในการบันทึกเพื่อนำไปสร้างรายการ autonomust
async function getDatailjournal(journalId,newJournalId, new_TDScode) {
    // coating 
   
    await FetchApis.FethcGet(`/coating/coatingByJournalId/${journalId}`).then((res) => {
        if (res.data.status == 200) {

            var datacoating = res.data.data;
            for (let index = 0; index < datacoating.length; index++) {
                const element = datacoating[index];
                console.log(element)
                Addcreatecoating(element, newJournalId,new_TDScode)
            }
        }
    })
    // can & end forming
    await FetchApis.FethcGet(`/forming/formingByJournalId/${journalId}`).then((res) => {
        if (res.data.status == 200) {
            console.log("forming=> "+res.data.data[0])
             Addcreateforming(res.data.data[0],newJournalId,new_TDScode);
        }
    })
    // guideline for product pack
    await FetchApis.FethcGet(`/guideline/guidelineByJournalId/${journalId}`).then((res) => {
        if (res.data.status == 200) {
            console.log("guideline=>"+res.data.data[0])
             Addcreateguideline(res.data.data[0],newJournalId,new_TDScode);
        }
    })
    // regulation
    await FetchApis.FethcGet(`/regulation/regulationByJourId/${journalId}`).then((res) => {
        if (res.data.status == 200) {
            console.log("regulation => "+res.data.data[0])
            Addcreate_regulation(res.data.data[0],newJournalId,new_TDScode);
        }
    }).catch(err => { console.log(err) })
    // Request sample
    await FetchApis.FethcGet(`/requestsample/requestsampleByJournalId/${journalId}`).then((res) => {
        if (res.data.status == 200) {
            console.log("requestsample=>"+res.data.data[0])
             Addcreaterequest(res.data.data[0],newJournalId,new_TDScode);
        }
    }).catch(err => { console.log(err) })


    //conponent code
    await FetchApis.FethcGet(`/componentcode/componentByjournalId/${journalId}`).then((res) => {
        if (res.data.status == 200) {
            console.log("compoment code=>"+res.data.data[0])
            AddcreateComponente(res.data.data[0],newJournalId,new_TDScode);
        }
    }).catch(err => { console.log(err) })


}

// สร้าง coating
function Addcreatecoating(data,newJournalId,newtdscode) {

    const { producttype, number, groupBobyType, substrate, shape, shapedetail, dia, ring_eartype,
        ring_earcode, forsaleorder, intcoating, intwhite, intother, extcoating, extwhite, extother,
        note, statusProof, journalId } = data;

    var coatingdata = {
        producttype: producttype,
        number: number,
        groupBobyType: groupBobyType,
        substrate: substrate,
        shape: shape,
        shapedetail: shapedetail,
        dia: dia,
        ring_eartype: ring_eartype,
        ring_earcode: ring_earcode,
        forsaleorder: forsaleorder,
        intcoating: intcoating,
        intwhite: intwhite,
        intother: intother,
        extcoating: extcoating,
        extwhite: extwhite,
        extother: extother,
        note: note,
        statusProof: statusProof,
        journalId: newJournalId,
        tdscode: newtdscode
    }

    FetchApis.FethcPost(`/coating/createcoating`, coatingdata).then((res) => {
        console.log(res)
    }).catch(err => console.log(err))

}
//สร้าง can & end forming
function Addcreateforming(data,newJournalId,newtdscode) {

    const { internalside, internalother, seaming, seamingother, machineno, packaging, note, journalId } = data;
    var formingdata = {
        internalside: internalside,
        internalother: internalother,
        seaming: seaming,
        seamingother: seamingother,
        machineno: machineno,
        packaging: packaging,
        note: note,
        journalId: newJournalId,
        tdscode: newtdscode
    }
    FetchApis.FethcPost(`/forming/createforming`, formingdata).then((res) => {
        console.log(res)
    }).catch(err => { console.log(err) })

}
// สร้าง guideline
function Addcreateguideline(data,newJournalId,newtdscode) {
   
    const {
        shelfyears, 
        shelfdeg, 
        typegroup, 
        groupother, 
        groupother_note, 
        groupdryfood, 
        groupaerosol, 
        groupgeneral, 
        detailproduct,
        ph, 
        ingredients,
        ingredients_acid,
        ingredients_chioride,
        ingredientother, 
        seamercondition, 
        sterilization,
        sterilization_retort,
        sterilization_cooker,
        sterilizationother, 
        journalId
    } = data;

    var dataguidelline = {
        shelfyears: shelfyears,
        shelfdeg: shelfdeg,
        typegroup: typegroup,
        groupother: groupother,
        groupother_note: groupother_note,
        groupdryfood: groupdryfood,
        groupaerosol: groupaerosol,
        groupgeneral: groupgeneral,
        detailproduct: detailproduct,
        ph: ph,
        ingredients: ingredients,
        ingredientother: ingredientother,
        ingredients_acid:ingredients_acid,
        ingredients_chioride:ingredients_chioride,
        seamercondition: seamercondition,
        sterilization: sterilization,
        sterilization_retort:sterilization_retort,
        sterilization_cooker:sterilization_cooker,
        sterilizationother: sterilizationother,
        journalId: newJournalId,
        tdscode: newtdscode
    }

    FetchApis.FethcPost(`/guideline/createguideline`, dataguidelline).then((res) => {
        console.log(res)
    }).catch(err => { console.log(err) })

}
// สร้าง Request sample
function Addcreate_regulation(data, newJournalId,newtdscode) {

    const { regulationtype, regulationother, journalId, tdscode } = data;

    var dataregulation = {
        regulationtype: regulationtype,
        regulationother: regulationother,
        journalId: newJournalId,
        tdscode: newtdscode
    }

    FetchApis.FethcPost(`/regulation/createregulation`, dataregulation).then((res) => {
        console.log(res)
    }).catch(err => { console.log(err) });
}
//สร้าง Request sample
function Addcreaterequest(data,newJournalId,newtdscode) {
    const { proofplate, requestcan, requestend, requestnote, journalId, tdscode } = data;

    var datarequest = {
        proofplate: proofplate,
        requestcan: requestcan,
        requestend: requestend,
        requestnote: requestnote,
        journalId: newJournalId,
        tdscode: newtdscode
    }
    FetchApis.FethcPost(`/requestsample/createrequestsample`, datarequest).then((res) => {
        console.log(res)
        if (res.status == 200) {
            // navigate('/journallistparta')
            setTimeout(() => {
                window.location.href = "/journallistparta"
            }, 1700);
        }
    }).catch(err => { console.log(err) })
}


//สร้าง componnet
function AddcreateComponente(data,newJournalId,newtdscode) {
    const { ear,earwelding,ring,innerlid, outerlid, toplid,bottomlid,plasticlid, aluminumlid, journalId, tdscode } = data;
    // const { proofplate, requestcan, requestend, requestnote, journalId, tdscode } = data;

    var datarequest = {
        ear: ear,
        earwelding:earwelding,
        ring:ring,
        innerlid: innerlid,
        outerlid: outerlid,
        toplid: toplid,
        bottomlid:bottomlid,
        plasticlid: plasticlid,
        aluminumlid: aluminumlid,
        journalId:newJournalId,
        tdscode:newtdscode
    }
    FetchApis.FethcPost(`/componentcode/createcomponentcode`, datarequest).then((res) => {
        console.log(res)
        if (res.status == 200) {
            // navigate('/journallistparta')
            setTimeout(() => {
                window.location.href = "/journallistparta"
            }, 1700);
        }
    }).catch(err => { console.log(err) })
}


