import React from 'react'
// import EditformsHeader from'../../../form01/components/editformsHeader';
// import Editjournals from'../../../form01/components/editjournals';
// import Editjournalrecall from './editjournalrecall';
import { Button } from 'react-bootstrap';

function Actionrecall({id}) {

    const OnCheckEditjourn=()=>{
        window.location.href=`editjournalrecall/${id}`
    }
  return (
    <div className='row-page'>
        {/* <Editjournalrecall id={id} /> */}
        <div>
            <Button onClick={()=>OnCheckEditjourn()}>
            <label>จัดการข้อมูล    </label>
            </Button>
        </div>
     {/* <div style={{marginLeft:3}}>
    <EditformsHeader ids={id}/>
     </div>
     <div style={{marginLeft:3}}>
    <Editjournals id={id}/>
     </div> */}
    </div>
  )
}

export default Actionrecall