import React, { useState, useEffect } from 'react'
import { Button } from 'react-bootstrap'
import Tabletincoatingext from './tabletincoating'
import { useSelector, useDispatch } from 'react-redux'
import FetchApi from '../../../customhooks/functionFetchApi';
import PopupEditPrintingsystem from './componentrd/popUpEditPrintingsystem';
import { additemExt, additemInt, additemSpray, additemCopper, additemLacq, additemProductitem } from '../../../../reduxSlice/productionsystemSlice/productionSlice';
import Swal from 'sweetalert2'

function Editproductionsystem({ journalId }) {
  const dispatch = useDispatch();
  const FetchApis = new FetchApi();

  const ojectdata = {
    packId: '',
    protype: '',
    sideitemnumber: '',
    sidename: '',
    sidewfw: '',
    com1itemnumber: '',
    com1name: '',
    com1wfw: '',
    com2itemnumber: '',
    com2name: '',
    com2wfw: '',
    journalId: journalId
  }

  const jsonPacktest = ["Yes", "NoNeed"]
  const jsonPacker = ["Swan", "Customer Site"]


  const [prodcutExt, setProdcutExt] = useState({ ...ojectdata })
  const [productInt, setProductInt] = useState({ ...ojectdata })
  const [productSpray, setProductSpray] = useState({ ...ojectdata })
  const [productCopoer, setProductCopoer] = useState({ ...ojectdata })
  const [productLacq, setProductLacq] = useState({ ...ojectdata });

  const [prodataall, setProdataall] = useState({ ...ojectdata }) // sub detial
  const [packtestcheckbox, setPacktestcheckbox] = useState([])
  const [packercheckbox, setPackercheckbox] = useState([])

  const [dataTintype, setDataTintype] = useState("")

  const [prodatahead, setProdatahead] = useState({ /// header
    sidetinext: '',
    sidetinint: '',
    com1tinext: '',
    com1tinint: '',
    com2tinext: '',
    com2tinint: '',
    activebom: "",
    auditdep: "",
    packtest: [],
    packer: [],
    refproduct: "",
    journalId: journalId,
    packId: ""
  })

  useEffect(() => {
    getproductlist()
    pakctestlist()

  }, [])



  function handle_packtest(e) {
    const ischecked = e.target.checked;
    const isvalue = e.target.value;

    if (ischecked) {

      setPacktestcheckbox([...packtestcheckbox, isvalue])

    } else {
      const index = packtestcheckbox.indexOf(e.target.value);

      packtestcheckbox.splice(index, 1);

      setPacktestcheckbox(packtestcheckbox);
    }
  }

  function handle_packer(e) {

    const ischecked = e.target.checked;
    const isvalue = e.target.value;

    if (ischecked) {
      setPackercheckbox([...packercheckbox, isvalue])
    } else {
      const index = packercheckbox.indexOf(e.target.value);
      packercheckbox.splice(index, 1);
      setPackercheckbox(packercheckbox);
    }
  }


  function handle_activebom(e) {
    const ischecked = e.target.checked;
    const isvalue = e.target.value;

    if (ischecked) {
      setProdatahead({ ...prodatahead, activebom: isvalue })
    } else {
      setProdatahead({ ...prodatahead, activebom: "" })

    }
  }

  function handle_auditdep(e) {

    const ischecked = e.target.checked;
    const isvalue = e.target.value;

    if (ischecked) {
      setProdatahead({ ...prodatahead, auditdep: isvalue })
    } else {
      setProdatahead({ ...prodatahead, auditdep: "" })

    }
  }


  function onChangetypepro(e) {

    var dttype = e.target.value;

    setDataTintype(dttype)

    if (dttype == 'pro_ext') {

      setProdataall({ ...prodcutExt, protype: 'ext' })

    }
    else if (dttype == 'pro_int') {

      setProdataall({ ...productInt, protype: 'int' })

    }
    else if (dttype == 'pro_spray') {
      setProdataall({ ...productSpray, protype: 'spray' })
    }
    else if (dttype == 'pro_copper') {
      setProdataall({ ...productCopoer, protype: 'copper' })
    }
    else if (dttype == 'pro_lacq') {
      setProdataall({ ...productLacq, protype: 'lacq' })
    }

  }


  function getproductlist() {
    FetchApis.FethcGet(`/packtest/getpacktestByjournalid/${journalId}`).then(res => {
      if (res.data.data.length > 0) {
        // console.log(res.data.data)
        setProdatahead({ ...res.data.data[0] })
        var packtestData = res.data.data[0].packtest;
        var packerData = res.data.data[0].packer;

        var packsplice = packtestData.split(',')
        setPacktestcheckbox(packsplice)

        var packersplit = packerData.split(',')
        setPackercheckbox(packersplit)

        dispatch(additemProductitem(res.data.data[0]));
      }
    })
  }


  function pakctestlist() {
    FetchApis.FethcGet(`/productionsystem/getproductByjournalid/${journalId}`).then(res => {

      if (res.data.data.length > 0) {

        const prolist = res.data.data

        for (let index = 0; index < prolist.length; index++) {
          const datalist = prolist[index];

          if (datalist.protype == 'ext') {
            setProdcutExt(datalist)
            dispatch(additemExt(datalist))
          }

          if (datalist.protype == 'int') {
            setProductInt(datalist)
            dispatch(additemInt(datalist))
          }

          if (datalist.protype == 'spray') {
            setProductSpray(datalist)
            dispatch(additemSpray(datalist))
          }
          if (datalist.protype == 'copper') {
            setProductCopoer(datalist)
            dispatch(additemCopper(datalist))
          }
          if (datalist.protype == 'lacq') {
            setProductLacq(datalist)
            dispatch(additemLacq(datalist))
          }

        }

      }
    })
  }

  function submitsave(e) {
    e.preventDefault();
    Swal.fire({
      title: "บันทึกแก้ไข",
      text: "คุณแน่ใจบันทึกเอกสาร ใช่หรือไม่ ! ",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ใช่",
      cancelButtonText: 'ยกเลิก'
    }).then((result) => {
      if (result.isConfirmed) {
        updatePacktest()
      }
    })
  }

  function updatePacktest() {

    const opjectdata = {
      packId: prodatahead.packId,
      sidetinext: prodatahead.sidetinext,
      sidetinint: prodatahead.sidetinint,
      com1tinext: prodatahead.com1tinext,
      com1tinint: prodatahead.com1tinint,
      com2tinext: prodatahead.com2tinext,
      com2tinint: prodatahead.com2tinint,
      activebom: prodatahead.activebom,
      auditdep: prodatahead.auditdep,
      packtest: packtestcheckbox,
      packer: packercheckbox,
      refproduct: prodatahead.refproduct,
      journalId: journalId
    }
    if (prodatahead.packId !== "") {
      FetchApis.FethcUpdate(`/packtest/updatePacktest/${opjectdata.packId}`, opjectdata).then(res => {
        if (res.error != false) {
          console.log(res)
        }else{
          getproductlist()
        }
      })
    }
    else {
      const opjectdata = {
        // packId: prodatahead.packId,
        sidetinext: prodatahead.sidetinext,
        sidetinint: prodatahead.sidetinint,
        com1tinext: prodatahead.com1tinext,
        com1tinint: prodatahead.com1tinint,
        com2tinext: prodatahead.com2tinext,
        com2tinint: prodatahead.com2tinint,
        activebom: prodatahead.activebom,
        auditdep: prodatahead.auditdep,
        packtest: packtestcheckbox,
        packer: packercheckbox,
        refproduct: prodatahead.refproduct,
        journalId: journalId
      }
      FetchApis.FethcPost(`/packtest/createPacktest`, opjectdata).then(res => {
        if (res.error != false) {
          console.log(res)
        }
        else{
          getproductlist()
        }
      })

    }


  }


  function getpacktestcheckbox(vl) {
    // var checkitem = false
    var data = packtestcheckbox.filter((fid) => fid == vl)

    return data
  }

  function getpackercheckbox(vl) {
    // var checkitem = false
    var data = packercheckbox.filter((fid) => fid == vl)

    return data
  }



  return (
    <div>

      <label style={{ fontSize: 18, margin: 5 }}><b>Production System</b></label>
      <div className='end-flex-row' style={{ width: '100%' }} >
        <Button onClick={submitsave} size='sm' style={{ marginRight: 10 }}>บันทึก แก้ไข</Button>

      </div>
      <div>
        <div className='row-page'>

          <div style={{ width: '35%' }}>

            <div className='row-page'>

              <div style={{ width: 100 }}>
                <label><b>Tin-Conting</b></label>
              </div>
              <div>
                <label>Ext.</label>
                <input type='text' name='sidetinExt'
                  value={prodatahead.sidetinext}
                  onChange={(e) => setProdatahead({ ...prodatahead, sidetinext: e.target.value })} />
              </div>
              <div>
                <label>Int.</label>
                <input type='text' name='sidetinInt'
                  value={prodatahead.sidetinint}
                  onChange={(e) => setProdatahead({ ...prodatahead, sidetinint: e.target.value })} />
              </div>
            </div>
            <hr></hr>
          </div>

          <div style={{ width: '35%' }}>

            <div className='row-page'>
              <div style={{ width: 100 }}>
                <label><b>Tin-Conting</b></label>
              </div>
              <div>
                <label>Ext.</label>
                <input type='text' name='com1tinExt'
                  value={prodatahead.com1tinext}
                  onChange={(e) => setProdatahead({ ...prodatahead, com1tinext: e.target.value })} />
              </div>
              <div>
                <label>Int.</label>
                <input type='text' name='com1tinInt'
                  value={prodatahead.com1tinint}
                  onChange={(e) => setProdatahead({ ...prodatahead, com1tinint: e.target.value })}
                />
              </div>
            </div>
            <hr></hr>

          </div>


          <div style={{ width: '35%' }}>

            <div className='row-page'>
              <div style={{ width: 100 }}>
                <label><b>Tin-Conting</b></label>
              </div>
              <div>
                <label>Ext.</label>
                <input type='text' name='com2tinExt'
                  value={prodatahead.com2tinext}
                  onChange={(e) => setProdatahead({ ...prodatahead, com2tinext: e.target.value })} />
              </div>
              <div>
                <label>Int.</label>
                <input type='text' name='sidetinInt'
                  value={prodatahead.com2tinint}
                  onChange={(e) => setProdatahead({ ...prodatahead, com2tinint: e.target.value })} />
              </div>
            </div>
            <hr></hr>

          </div>
        </div>

        <div className='row-page' style={{ marginTop: 20, background: '#eeeded' }}>

          <div style={{ width: 100 }}>
            <label>Ext.</label>
            <div>

              <PopupEditPrintingsystem typePro={'pro_ext'} journalId={journalId} pakctestlist={pakctestlist} updatePacktest={updatePacktest} />
            </div>

          </div>

          <div style={{ width: 100 }}>
            <label>Int.</label>
            <div>

              <PopupEditPrintingsystem typePro={'pro_int'} journalId={journalId} pakctestlist={pakctestlist} updatePacktest={updatePacktest} />
            </div>

          </div>

          <div style={{ width: 100 }}>
            <label>Spray lacq.</label>
            <div>

              <PopupEditPrintingsystem typePro={'pro_spray'} journalId={journalId} pakctestlist={pakctestlist} updatePacktest={updatePacktest} />
            </div>

          </div>

          <div>
            <label>Copper Wire (kg./1,000 pcs) </label>
            <div>

              <PopupEditPrintingsystem typePro={'pro_copper'} journalId={journalId} pakctestlist={pakctestlist} updatePacktest={updatePacktest} />
            </div>

          </div>

          <div style={{ marginLeft: 20 }}>
            <label>Lacq Stemp rivet (kg./1,000 pcs)</label>
            <div>

              <PopupEditPrintingsystem typePro={'pro_lacq'} journalId={journalId} pakctestlist={pakctestlist} />
            </div>

          </div>

        </div>

      </div>

      <hr></hr>

      <div className='row-page'>
        <div style={{ marginRight: 20 }}><b>Prodcut Pack test</b></div>
        <div style={{ width: '50%' }}>

          <div className='row-page' style={{ width: '50%' }}>
            <div style={{ width: 100 }}>
              <label>Pack test :</label>
            </div>

            {jsonPacktest.map((item, i) => (
              <div>
                {getpacktestcheckbox(item) == item ?
                  <div style={{ marginRight: 10, width: 100 }}>

                    <input type='checkbox' name='packYes'
                      defaultChecked={true}
                      value={item} onClick={handle_packtest}
                    />{" "}
                    <label>{item}</label>
                  </div> :
                  <div style={{ marginRight: 10, width: 100 }}>

                    <input type='checkbox' name='packYes'

                      value={item} onClick={handle_packtest}
                    />{" "}
                    <label>{item}</label>
                  </div>
                }

              </div>
            ))}

          </div>

          <div className='row-page'>
            <div style={{ width: 100 }}>
              <label>Packer :</label>
            </div>
            {jsonPacker.map((item, i) => (
              <div>
                {getpackercheckbox(item) == item ?
                  <div style={{ marginRight: 10, width: 100 }}>
                    <input type='checkbox' name='packerSwan' value={'Swan'} onClick={handle_packer} defaultChecked={true} />{" "}
                    <label>{item}</label>
                  </div> :
                  <div style={{ marginRight: 10, width: 100 }}>
                    <input type='checkbox' name='packerSwan' value={'Customer Site'} onClick={handle_packer} />{" "}
                    <label>{item}</label>
                  </div>}

              </div>
            ))
            }

          </div>

        </div>

        <div style={{ backgroundColor: '#eeeded', padding: 10 }}>
          {prodatahead.auditdep == '1' ?
            <div style={{ width: 300 }}>
              <input type='checkbox' name='auditdep'
                value={'1'} onClick={handle_auditdep} defaultChecked={true} /> <label> Audit Dep </label>
            </div> :
            <div style={{ width: 300 }}>
              <input type='checkbox' name='auditdep'
                value={'1'} onClick={handle_auditdep} /> <label> Audit Dep </label>
            </div>
          }
          {prodatahead.activebom == '1' ?
            <div>
              <input type='checkbox' name='activeBome'
                value={'1'} onClick={handle_activebom} defaultChecked={true} /> <label> Active Bome </label>
            </div> :
            <div>
              <input type='checkbox' name='activeBome'
                value={'1'} onClick={handle_activebom} /> <label> Active Bome </label>
            </div>
          }
        </div>

      </div>
      <div>
        <div> <label> <b>Ref . Product </b></label></div>
        <textarea name='refproduct' style={{ width: 400, height: 50 }}
          value={prodatahead.refproduct}
          onChange={(e) => setProdatahead({ ...prodatahead, refproduct: e.target.value })} />
      </div>

      <Tabletincoatingext />

    </div>
  )
}

export default Editproductionsystem