import React, { useEffect, useState } from 'react'
import FetchApi from '../../customhooks/functionFetchApi'
import { Button } from 'react-bootstrap'
import Modal from 'react-bootstrap/Modal'

function Formchangepassword({id,username}) {
    const FetchApis = new FetchApi(); 

    const[dataform,setDataform]=useState({username:username,password:""})

    function onChagnePassword(){

        if(dataform.password.length <=4){

            alert('กรุณาป้อนข้อมูล password อย่างน้อย 5 ตัวอักษร ')
        }else if(dataform.username.length < 3)
        {
            alert('กรุณาป้อนข้อมูล password อย่างน้อย 3 ตัวอักษร ')
        }
        else{
                FetchApis.FethcUpdate(`/account/changepassword/${id}`,dataform).then((res)=>{
                    
                    if(res.status==200){
                        alert('คุณได้บันทึกเรียบร้อยแล้ว')
                    }
                    else{
                        alert('เกิดข้อผิดพลาดใน การบันทึก')
                    }
                }).catch(err=>console.log(err))


        }


    }


    return <div style={{fontSize:14,width:'100%'}} className='border-green-page'>
        <table style={{width:'100%'}}>
            <tr>
                <td style={{width:'20%'}}><label>User Name</label></td>
                <td><input type='text' name='username' value={username}
                onChange={(e)=>setDataform({...dataform,username:e.target.value})} required/></td>
                <td></td>
            </tr>
            <tr>
                <td><label>Password</label></td>
                <td><input type='password' name='password' 
                onChange={(e)=>setDataform({...dataform,password:e.target.value})} required/></td>
                <td>
                    <Button size='sm' variant="success" style={{marginRight:10}} onClick={()=>onChagnePassword()}>บันทึก</Button>
                    <Button size='sm' variant="warning">ยกเลิก</Button>
                    </td>
            </tr>
        </table>

    </div>
}



const Changepassword = ({id,username}) => {

    
    const values = [true, 'sm-down', 'md-down', 'lg-down', 'xl-down', 'xxl-down'];
    const [fullscreen, setFullscreen] = useState(true);
    const [show, setShow] = useState(false);

    function handleShow(value) {
        setShow(value);
    }

    return (
        <div>
            <Button size='sm' variant="warning" onClick={() => handleShow(true)}>แก้ไขรหัส</Button>

            <Modal show={show}
                // fullscreen={fullscreen} 
                size='lg'
                onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>เปลี่ยนรหัส</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* <UserEditform id={id} /> */}
                    <Formchangepassword id={id} username={username}/>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default Changepassword