import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

function Tabletincoating(props) {
    const productExt = useSelector((state) => state.product.dataext);
    const productInt = useSelector((state) => state.product.dataint);
    const productSpray = useSelector((state) => state.product.dataspray);
    const productCopper = useSelector((state) => state.product.datacopper);
    const prodcutLacq = useSelector((state) => state.product.datallacq);
    var dataExt =  productExt;
    var dataInt = productInt;
    var dataSpray = productSpray;
    var dataCopoer = productCopper;
    var dataLacq=prodcutLacq;

    return (
        <div>
            <table style={{ width: '100%' }}>
                <tr>
                    <th><center>Production System</center></th>
                    <th colspan="3"><center>Side Seams</center></th>
                    <th colspan="3"><center>Compound</center></th>
                    <th colspan="3"><center>Compound</center></th>
                </tr>
                <tr>
                    <td>{ }</td>
                    <td><b>Item Number</b></td>
                    <td><b>Name</b></td>
                    <td><b>WFW .(kg. / 1,000 pcs)</b></td>

                    <td><b>Item Number</b></td>
                    <td><b>Name</b></td>
                    <td><b>WFW .(kg. / 1,000 pcs)</b></td>

                    <td><b>Item Number</b></td>
                    <td><b>Name</b></td>
                    <td><b>WFW .(kg. / 1,000 pcs)</b></td>
                </tr>
                {/*ext*/}
                <tr>
                    <td><label><b>Ext</b></label></td>
                    <td>{dataExt.sideitemnumber}</td>
                    <td>{dataExt.sidename}</td>
                    <td>{dataExt.sidewfw}</td>

                    <td>{dataExt.com1itemnumber}</td>
                    <td>{dataExt.com1name}</td>
                    <td>{dataExt.com1wfw}</td>

                    <td>{dataExt.com2itemnumber}</td>
                    <td>{dataExt.com2name}</td>
                    <td>{dataExt.com2wfw}</td>
                </tr>

                {/*int*/}
                <tr>
                    <td><label><b>Int</b></label></td>
                    <td>{dataInt.sideitemnumber}</td>
                    <td>{dataInt.sidename}</td>
                    <td>{dataInt.sidewfw}</td>


                    <td>{dataInt.com1itemnumber}</td>
                    <td>{dataInt.com1name}</td>
                    <td>{dataInt.com1wfw}</td>


                    <td>{dataInt.com2itemnumber}</td>
                    <td>{dataInt.com2name}</td>
                    <td>{dataInt.com2wfw}</td>
                </tr>
                {/*spray lacq*/}
                <tr>
                    <td><label><b>spray lacq.</b></label></td>
                    <td>{dataSpray.sideitemnumber}</td>
                    <td>{dataSpray.sidename}</td>
                    <td>{dataSpray.sidewfw}</td>


                    <td>{dataSpray.com1itemnumber}</td>
                    <td>{dataSpray.com1name}</td>
                    <td>{dataSpray.com1wfw}</td>


                    <td>{dataSpray.com2itemnumber}</td>
                    <td>{dataSpray.com2name}</td>
                    <td>{dataSpray.com2wfw}</td>
                </tr>

                {/*dataCopoer*/}
                <tr>
                    <td>
                        <div><b>Copper wire (kg /1,000 pcs)</b></div>

                    </td>
                    <td>{dataCopoer.sideitemnumber}</td>
                    <td>{dataCopoer.sidename}</td>
                    <td>{dataCopoer.sidewfw}</td>

                    <td>{dataCopoer.com1itemnumber}</td>
                    <td>{dataCopoer.com1name}</td>
                    <td>{dataCopoer.com1wfw}</td>

                    <td>{dataCopoer.com2itemnumber}</td>
                    <td>{dataCopoer.com2name}</td>
                    <td>{dataCopoer.com2wfw}</td>
                </tr>

                <tr>
                    <td>

                        <div><b>Lacq Stemp rivet (kg / 1,000 pcs)</b></div>
                    </td>
                    <td>{dataLacq.sideitemnumber}</td>
                    <td>{dataLacq.sidename}</td>
                    <td>{dataLacq.sidewfw}</td>

                    <td>{dataLacq.com1itemnumber}</td>
                    <td>{dataLacq.com1name}</td>
                    <td>{dataLacq.com1wfw}</td>

                    <td>{dataLacq.com2itemnumber}</td>
                    <td>{dataLacq.com2name}</td>
                    <td>{dataLacq.com2wfw}</td>
                </tr>

            </table>
        </div>
    )
}

export default Tabletincoating