import React, { useState, useEffect } from 'react'
import { Button } from 'react-bootstrap'
import ShowjournalDetail from '../../../form01/components/showdetailjournal';
import Modal from 'react-bootstrap/Modal';


function ListDetailjournal({ id}) {
  return (
    <div className='form-body-page'>
      <ShowjournalDetail id={id} />
    </div>
  )
}


const JournaldetailListprepass = ({ id,jourcode }) => {

  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);

  function handleShow(value) {
    setShow(value);
  }

  return (
    <div>
      <Button size='sm' variant="warning" onClick={() => handleShow(true)} >
        รายละเอียด
      </Button>

      <Modal show={show} fullscreen={fullscreen} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>แสดงรายละเอียกเอกสาร {jourcode} </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ListDetailjournal id={id}/>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default JournaldetailListprepass