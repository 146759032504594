import DataTable from 'react-data-table-component';
import React from 'react'
import Actionflowsystemsub from './actionflowsystemsyb';


const columns = [
    {
        name: 'approve by',
        sortable: true,
        selector: row => row.approveby,
    },
    {
        name: 'employee code',
        selector: row => row.emcode,
    },
    {
        name: 'approve code',
        selector: row => <div>{row.approvecode==0?'ผู้ตรวจสอบ':'ผู้อนุมัติ'}</div>,
    },
    {
        name: 'state flow',
        //  grow: 2,
        cell: row =>row.stateflow,
    },
    {
        name: 'action',
         grow: 2,
        cell: row => <Actionflowsystemsub Id={row.flowid}/>,
    },

];

const customStyles = {
    headRow: {
        style: {
            border: 'none',
            backgroundColor: '#28B463'
        },
    },
    headCells: {
        style: {
            color: '#ffff',
            fontSize: '14px',
        },
    },
    rows: {
        highlightOnHoverStyle: {
            backgroundColor: 'rgb(230, 244, 244)',
            borderBottomColor: '#FFFFFF',
            borderRadius: '25px',
            outline: '1px solid #FFFFFF',
        },
    },
    pagination: {
        style: {
            border: 'none',
        },
    },
};



const Tableflowsystemsub = ({result}) => {
    var dataresult = result;

    return (
    
        <DataTable
            columns={columns}
            data={dataresult}
            // selectableRows
            pagination
            customStyles={customStyles}
            highlightOnHover

        />
    )
}

export default Tableflowsystemsub