import React, { useEffect, useState } from 'react'
import FetchApi from '../../customhooks/functionFetchApi'
import Journaltable from '../components/journaltable';
import Spinner from 'react-bootstrap/Spinner';
import { FaFileSignature } from "react-icons/fa";


const Journallistaddspec = () => {
  const FetchApis = new FetchApi();
  const [loading, setLoading] = useState(false);
  const [datajournal, setDatajournal] = useState([])
  const Emcode_now = sessionStorage.getItem('EmCode')
  useEffect(() => {
    loadingdata();
  }, [])


  const loadingdata = () => {
    setLoading(true)

    setTimeout(() => {
      FetchApis.FethcGet(`/journal/getjournalSendRunflowByEmcode/${Emcode_now}`).then((resultdata) => {
        if (resultdata) {
          setDatajournal(resultdata.data)
        }
        setLoading(false);
      })
    }, 1000);

  }

  return (
    <div>
      <div style={{ backgroundColor: '#E5E7E7' }}>
        <label style={{ fontSize: 20, margin: 5, padding: 5 }}>
         <FaFileSignature /> ALL TECHICAL DATA SHEET  ADD SPEC
        </label>
      </div>
      {loading == true ? <div className='body-center-page'><Spinner
        as="span"
        animation="grow"
        variant="success"
        size={200}
        role="status"
        aria-hidden="true" /> LOADIN ....</div> : <div><Journaltable datatable={datajournal.data}/></div>}
    </div>
  )
}

export default Journallistaddspec