import DataTable from 'react-data-table-component';
import React from 'react'
import Actionlist from './actionlistprepass';

const columns = [
    {
        name: 'TDS code',
        sortable: true,
        selector: row => row.tdscode,
        grow: 0.5,
    },
    {
        name: 'Journal Type',
        selector: row => row.journaltype,
        grow: 0.5,
    },
    {
        name: 'customer name',
        selector: row => row.customername,
    },
    {
        name: 'product name',
        selector: row => row.productname,
        grow: 1.5,
    },
    {
        name: 'createdate',
        selector: row => row.createdate,
        grow: 0.5,
    },
    {
        name: 'Action',
         grow: 1,
        cell: row =><Actionlist id={row.journalId} journalcode={row.tdscode} typeproduct={row.typeproduct}/>,
    },

];

const customStyles = {
    headRow: {
        style: {
            border: 'none',
            backgroundColor: '#28B463'
        },
    },
    headCells: {
        style: {
            color: '#ffff',
            fontSize: '14px',
        },
    },
    rows: {
        highlightOnHoverStyle: {
            backgroundColor: 'rgb(230, 244, 244)',
            borderBottomColor: '#FFFFFF',
            borderRadius: '25px',
            outline: '1px solid #FFFFFF',
        },
    },
    pagination: {
        style: {
            border: 'none',
        },
    },
};



const Journaltableprepass = ({datatable}) => {
    var dataresult = datatable;

    return (
    
        <DataTable
            columns={columns}
            data={dataresult}
            // selectableRows
            pagination
            customStyles={customStyles}
            highlightOnHover
        />
    )
}

export default Journaltableprepass