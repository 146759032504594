import React, { useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal';
import { FaSearch } from "react-icons/fa";
import { Button } from 'react-bootstrap';
import FetchApiCall from '../../../customhooks/functionFetchApi_erp';
import DataTable from 'react-data-table-component';




function ShowSheetSize({ handle_Adddatatype, handleShow }) {

    const FetchApiCalls = new FetchApiCall();

    const [TextSearch, setTextSearch] = useState("")
    const [searchSheet, setSearchSheet] = useState([]);
    const [SheetSize, setSheetSize] = useState([]);



    const columns = [
        {
            name: 'Item name',
            sortable: true,
            grow: 2,
            selector: row => row.Itemname,
        },
        {
            name: 'ความยาว',
            selector: row => row.DRK_LONG,
        },
        {
            name: 'ความกว้าง',
            selector: row => row.DRK_WIDTH,
        },
        {
            name: 'ความหนา',
            selector: row => row.DRK_TICK,
        },
        {
            name: 'Action',
            //  grow: 1.5,
            cell: row => <div><button onClick={() => selectSheetSize(row.Itemname)}>เลือกรายการ</button></div>,
        },

    ];



    useEffect(() => {
        getSheetsize()
    }, [])


    function getSheetsize() {
        FetchApiCalls.FethcGet(`getitemtf`).then((res) => {
            setSearchSheet(res.data.listall)
            setSheetSize(res.data.listall)
        })
    }

    function selectSheetSize(value) {
        handle_Adddatatype(value)
        handleShow(false)
    }

    const handleInputChange = (event) => {
        const query = event.target.value;

        const filtered = SheetSize.filter((item) =>
            item.Itemname.toLowerCase().includes(query.toLowerCase())
        );
        setSearchSheet(filtered);
    };

    return (
        <div>
            <div><input type='text' name='searchsheetsize' placeholder='ค้นหา sheet and size' 
            onChange={(e)=>handleInputChange(e)}
            style={{ width: 250 }} /></div>
            <div>
                <DataTable
                    columns={columns}
                    data={searchSheet}
                    pagination
                    highlightOnHover

                />
            </div>
        </div>
    )
}


const SearchSheetsize = ({ handle_Adddatatype }) => {


    const FetchApis = new FetchApiCall();
    const [fullscreen, setFullscreen] = useState(true);
    const [show, setShow] = useState(false);

    function handleShow(value) {
        setShow(value);
    }


    return (
        <div>
            <div style={{ marginLeft: 5 }}>
                <label onClick={() => handleShow(true)}><FaSearch size={20}/></label>
            </div>

            <Modal show={show}
                //   fullscreen={fullscreen}
                size="lg"
                onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title><b>Sheet Size List</b></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ShowSheetSize handle_Adddatatype={handle_Adddatatype} handleShow={handleShow} />
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default SearchSheetsize