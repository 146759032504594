import React,{useState,useEffect} from 'react'
import FetchApi from '../../../customhooks/functionFetchApi';
import { Button } from 'react-bootstrap';

const Deleteflowsystem = ({Id}) => {
  const FetchApis=new FetchApi();

  function deleteflowsystem(event){
    event.preventDefault();
    // e.preventDefault();

    FetchApis.FethcGet(`/flowsystemsub/flowsystemsubByflowcode/${Id}`).then((value)=>{
      console.log(value.data.data)
        if(value.data.data!=""){

          alert('ไม่สามารถ ลบ ได้มีการใช้งานกรุณาตรวจสอบ');
          
        }
        else{
          ondeleteflowsystem(Id);
        }

    })
   
  }

  function ondeleteflowsystem(Ids){
    // flowsystemsub/flowsystemsubDelete/3
    FetchApis.FethcDelete(`/flowsystem/flowsystemDelete/${Ids}`).then((result)=>{
      // console.log(result)
        if(result.data.status==200){
          alert('คุณได้ทำการลบรอร้อยแล้ว')
          window.location.reload();
        }
    })

  }

  return (
    <div>
      <form onSubmit={(e)=>deleteflowsystem(e)}> 
      <Button type='submit' size='sm' variant="danger">ลบ</Button>
      </form>
    </div>
  )
}

export default Deleteflowsystem