import DataTable from 'react-data-table-component';
import React from 'react'
import Actionall from './actionall';
import Statusall from './statusallByuser';
import { FaFileSignature } from "react-icons/fa";

const columns = [
    {
        name: 'TDS code',
        sortable: true,
        selector: row => row.tdscode,
    },
    {
        name: 'Journal Type',
        selector: row => row.fname,
    },
    {
        name: 'customer name',
        selector: row => row.customername,
    },
    {
        name: 'revision',
        selector: row => row.revision,
    },
    {
        name: 'status all',
        selector: row => 
        <Statusall id={row.journalId} statusflownow={row.statusflow} 
        stateflow={row.stateflow} stateSuccesfull={row.stateSuccesfull}/>,
        grow: 1.5,
    },
    {
        name: 'item sample',
        selector: row => <div>{row.itemsample=='1'&&<label style={{color:'orchid'}}><FaFileSignature/> มอบตัวอย่าง</label>}</div>,
    },
    {
        name: 'createdate',
        sortable: true,
        selector: row => row.createdate,
    },
    {
        name: 'Action',
         grow: 1.5,
        cell: row =><Actionall id={row.journalId} journalcode={row.tdscode}  
        stateflow={row.stateflow} 
        stateSuccesfull={row.stateSuccesfull}
        revision={row.revision}
        />,
    },

];

const customStyles = {
    headRow: {
        style: {
            border: 'none',
            backgroundColor: '#28B463'
        },
    },
    headCells: {
        style: {
            color: '#ffff',
            fontSize: '14px',
        },
    },
    rows: {
        highlightOnHoverStyle: {
            backgroundColor: 'rgb(230, 244, 244)',
            borderBottomColor: '#FFFFFF',
            borderRadius: '25px',
            outline: '1px solid #FFFFFF',
        },
    },
    pagination: {
        style: {
            border: 'none',
        },
    },
};



const Jornallistbyuser = (datatable) => {
    var dataresult = datatable.datatable;


    return (
    
        <DataTable
            columns={columns}
            data={dataresult}
            // selectableRows
            pagination
            customStyles={customStyles}
            highlightOnHover

        />
    )
}

export default Jornallistbyuser