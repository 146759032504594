import React, { useState, useEffect } from 'react'
import { Routes, Route, useParams } from 'react-router-dom';
import PdfPartAll from './viewdrarftcomponentpdf/pdfPartAll';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Reportjournalall from './viewdrarftcomponentpdf/reportjournalall';

const Reportpdfjournal = () => {
    const { id } = useParams()
    const [key, setKey] = useState('jouranl');
    return (
        <div className='form-body-page'>
            <form >
                <Tabs
                    id="controlled-tab-example"
                    activeKey={key}
                    onSelect={(k) => setKey(k)}
                    className="mb-3"
                >
                    <Tab eventKey="jouranl" title="รายงาน เอกสาร TDS " style={{ backgroundColor: 'aliceblue' }}>
                        <Reportjournalall jourId={id} typejour={'success'} />
                    </Tab>
                </Tabs>

            </form>
        </div>
    )
}

export default Reportpdfjournal