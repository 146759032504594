import React, { useState, useEffect } from 'react'
import { Button } from 'react-bootstrap'
import Modal from 'react-bootstrap/Modal';
import FetchApi from '../../../customhooks/functionFetchApi';
import { statusflowall } from '../../object-default/journalobject';
import { ToastContainer, toast } from 'react-toastify';
import Swal from 'sweetalert2';

function Formrejectjournal({ handleShow, newTask, newjournalUpdate }) {
    const FetchApis = new FetchApi();
    const [tesknote, setTesknote] = useState("")
    const emCode = sessionStorage.getItem('EmCode');
    const [accountlist, setAccountlist] = useState([]);
    const [selectvalue, setSelectvalue] = useState("")

    useEffect(() => {
        getAccountFlow(newTask.journalid);
    }, [])

    function recievedjournal() {


            if (tesknote == ""||selectvalue == "") {
                Swal.fire({
                    title: "แจ้งเตือน",
                    text: "กรุณาป้อนข้อมูลอธิบาย และ เลือกผู้รับผิดชอบ",
                    icon: "question"
                });
            } else {
                Swal.fire({
                    title: "บันทึกเอกสาร",
                    text: "คุณแน่ใจที่บันทึกเอกสาร ใช่หรือไม่ ! ",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "ใช่",
                    cancelButtonText: 'ยกเลิก'
                }).then((result) => {

                    if (result.isConfirmed) {

                        Swal.fire({
                            title: "บันทึกเอกสาร!",
                            text: "คุณได้ทำบันทึกเอกสารเรียบแล้ว",
                            icon: "success"
                        });
                        var dataobject = {
                            journalid: newTask.journalid,
                            emcode: newTask.emcode,
                            stateflow: newTask.stateflow,
                            approvecode: statusflowall.rejectjournal,
                            dedicatecode: "",
                            tasknote: tesknote
                        }
                        // // // create task flow
                        FetchApis.FethcPost(`/taskflow/createTaskflow`, dataobject).then((res) => {
                            if (res.status == 200) {
                                updatejournal();
                            }
                        })

                    }

                });
            }

    }

    // //update flowstatus journal
    function updatejournal() {

        var updatejournal = {
            stateflow: selectvalue
        }
        // console.log(updatejournal);
        FetchApis.FethcUpdate(`/runflow/updateJournalflow/${newTask.journalid}`, updatejournal).then((res) => {
            if (res.status == 200) {
                console.log(res);

                setTimeout(() => {
                    handleShow(false);
                    window.location.href = '/journallistbyapprove'
                }, 1000);

            }
        }).catch(error => console.log(error))

    }


    //แสดง รายชื่อที่สามารถ reject ไปหาได้
    function getAccountFlow(jouranlid) {

        FetchApis.FethcGet(`/runflow/getAccountByflow/${jouranlid}/${emCode}/${newTask.stateflow}`).then((res) => {
            if (res.status == 200) {
                setAccountlist(res.data.data)
            }
        })
    }

    function heander_change(e) {
        var datachange = e.target.value
        console.log(datachange);
        setSelectvalue(datachange)

    }

    function onSave() {
        recievedjournal()
    }

    return <div>
        <form>
            <div style={{ margin: 5 }}>ป้อนรายละเอียด ข้อมูลตีกลับ</div>

            <div className='row-page'>

                <div style={{ margin: 5 }}><label>เลือกผู้รับผิดชอบ</label></div>

                <select name="cars" id="cars" style={{ width: 250, margin: 10 }} onChange={(e) => heander_change(e)}>
                    <option value="">เลือกข้อมูล</option>
                    {accountlist.map((item, i) => (
                        <option value={item.stateflow}>{item.approveby}</option>
                    ))}
                </select>
            </div>

            <div>
                <textarea name='RejectNote' id='RejectNote' style={{ width: 400 }} onChange={(e) => setTesknote(e.target.value)} />
            </div>
            <div className='end-flex-row'>
                <div>{<Button size='sm' style={{ marginRight: 10 }} onClick={() => onSave()}>ตีกลับ</Button>}</div>
                <div><Button type='reset' size='sm' variant="warning" >ยกเลิก</Button> </div>
            </div>
        </form>
    </div>
}

const Journalrejectapprove = ({ newTask, newjournalUpdate }) => {

    const values = [true, 'sm-down', 'md-down', 'lg-down', 'xl-down', 'xxl-down'];
    const [fullscreen, setFullscreen] = useState(true);
    const [show, setShow] = useState(false);

    function handleShow(value) {
        // setFullscreen(breakpoint);
        setShow(value);
    }


    return (
        <div>

            <Button type='reset' size='sm' variant="warning" style={{ marginRight: 10 }}
                onClick={() => handleShow(true)}>ตีกลับ</Button>

            <Modal show={show}
                // fullscreen={fullscreen} 
                size="lg"
                onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>ข้อมูลรายละเอียด ตีกลับ </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Formrejectjournal handleShow={handleShow} newTask={newTask} newjournalUpdate={newjournalUpdate} />
                    {/* <FormNew handleShow={handleShow} ids={id} /> */}
                </Modal.Body>
            </Modal>

        </div>
    )
}

export default Journalrejectapprove