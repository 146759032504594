import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import FetchApi from '../../../customhooks/functionFetchApi'
import { statusflowall } from '../../object-default/journalobject';
import Modal from 'react-bootstrap/Modal';
import { ToastContainer, toast } from 'react-toastify';
import {
    FaUserCircle,
    FaBell,
    FaShareSquare,
    FaExclamationCircle,
    FaArchive,
    FaClipboardCheck
  } from "react-icons/fa";

const Recievedjournal = ({ Id, stateflow ,statusflow}) => {
    const FetchApis = new FetchApi();
    const codes = sessionStorage.getItem('EmCode');
    const [journalreject, setJournalreject] = useState("");

    const values = [true, 'sm-down', 'md-down', 'lg-down', 'xl-down', 'xxl-down'];
    const [fullscreen, setFullscreen] = useState(true);
    const [show, setShow] = useState(false);


    useEffect(() => {
        var idjournal = {
            journalid: Id
        }
        FetchApis.FethcPost(`/taskflow/getRejectByCode`, idjournal).then((res) => {
            if (res) {
                
                setJournalreject(res.data)
            }
        })
    }, [])
    

    // update  recieved 
    function recievedjournalById() {

            var dataobject = {
                journalid: Id,
                emcode: codes,
                stateflow: stateflow,
                approvecode: statusflowall.recievejournal,
                dedicatecode: "",
                tasknote: ""
            }
            // create task flow
            FetchApis.FethcPost(`/taskflow/createTaskflow`, dataobject).then((res) => {
                if (res.status == 200) {
                updatejournal();

                toast.success('บันทึกเรียบร้อยแล้ว', {
                    position: "top-right",
                    autoClose: 1200,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    // transition: Bounce,
                    });
                }
            })
    
    }

    //update flowstatus journal
    function updatejournal() {
        var updatejournal = {
            statusflow: statusflowall.recievejournal,
            stateflow: stateflow
        }
        FetchApis.FethcUpdate(`/journal/updatestateflow/${Id}`, updatejournal).then((res) => {
            if (res.status == 200) {
                setTimeout(() => {
                    window.location.reload();
                }, 1300);
               
            }
        })

    }
    function handleShow(values) {
        setShow(values)
    }


    return (
        <div>
            <Button size='sm' onClick={() => recievedjournalById()}
                style={{ fontSize: 12 }} variant="warning"><FaBell/> ตอบรับ</Button>{" "}

            {statusflow=="2001"&&<Button size='sm' style={{ fontSize: 12 }} onClick={() => handleShow(true)} >ข้อมูลตีกลับ</Button>}


            <Modal show={show}
                // fullscreen={fullscreen} 
                size="lg"
                onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>ข้อมูลรายละเอียด ตีกลับ </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <table style={{ fontSize: 12 }}>
                        <tr>
                            <th style={{ width: '10%' }}>ลำดับ</th>
                            <th style={{ width: '10%' }}>ผู้ใช้งาน</th>
                            <th style={{ width: '55%' }}>เหตุผล</th>
                            <th style={{ width: '30%' }}>เวลา</th>
                        </tr>
                        {journalreject != "" && journalreject.map((item, i) => (

                            <tr>
                                <td>{i + 1}</td>
                                <td>{item.userapprove}</td>
                                <td>{item.tasknote}</td>
                                <td>{item.datetime}</td>
                            </tr>


                        ))}
                    </table>
                </Modal.Body>
            </Modal>

        </div>
    )
}

export default Recievedjournal