import React, { useState, useEffect } from 'react'
import DataTable from 'react-data-table-component';
import Table from 'react-bootstrap/Table';
import Action from './action';


const Tablemenulist = ({ values,loadingMenulist }) => {
    var datalist = values;
    return (
        <div>
            <Table >
                <thead>
                    <tr>
                        <th>#</th>
                        <th>ชื่อกลุ่ม</th>
                        <th>Action</th>

                    </tr>
                </thead>
                <tbody>
                    {datalist.map((item, i) => (
                        <tr>
                            <td>{i+1}</td>
                            <td>{item.namegroup}</td>
                            <td><Action id={item.menugroupId } namegroup={item.namegroup} loadingMenulist={loadingMenulist}/></td>
                        </tr>
                    ))}

                </tbody>
            </Table>
        </div>
    )
}

export default Tablemenulist