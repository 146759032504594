import React, { useState, useEffect } from 'react'
import dataform from '../json-data/datatypeform.json';
import FetchApi, { host } from '../../customhooks/functionFetchApi';
import { CiImageOn } from "react-icons/ci";
import { FaFileDownload } from "react-icons/fa";

import ViewImages from './viewImages';
import {
    objectcoatingbody, objectcoatingtop, objectcoatingbottom,
    objectforming, objectguideline, objectregulation, objectrequest, statusflowall, objectcoatingring, objectcoatingear
} from '../object-usestate/objectdefaults';
const datasub = {
    spectype: "", sheetsize: "", flatscroll: "", straightscroll: "",
    tin: "", temper: "", bright: "", row: "", filmcode: "", itemno: "", journalId: "", tdscode: ""
}


const Showdetailjournal = ({ id }) => {
    const FetchApis = new FetchApi(); //fatch data
    const [key, setKey] = useState('Coating');

    const [coatingbody, setCoatingbody] = useState({ ...objectcoatingbody });
    const [coatingtop, setCoatingtop] = useState({ ...objectcoatingtop });
    const [coatingbottom, setCoatingbottom] = useState({ ...objectcoatingbottom });
    const [coatingring, setCoatingring] = useState({ ...objectcoatingring });
    const [coatingear, setCoatingear] = useState({ ...objectcoatingear });

    const [forming, setForming] = useState({ ...objectforming });
    const [guideline, setGuideline] = useState({ ...objectguideline })
    const [requestsample, setRequestsample] = useState({ ...objectrequest });

    const [regulation, setRegulation] = useState([])
    const [regulationGroup, setregulationGroup] = useState([]);

    const [regulationcheck, setRegulationcheck] = useState([]); // ค่อยปรับ
    const [regulationOter, setregulationOther] = useState([]);  // ค่อยปรับ
    const [datafetch, setDatafetch] = useState([]);

    // add substrate new 
    const [substratebody, setSubstrateBody] = useState([]);
    const [substrateTop, setSubstrateTop] = useState([]);
    const [substrateBotton, setSubstrateBotton] = useState([]);
    const [substrateTab, setSubstrateTab] = useState([]);

    const [adddatatype, setAdddatatype] = useState({ ...datasub })
    const [componentCode, setComponentCode] = useState({
        inside: "",
        ring: "",
        ear: "",
        journalId: "",
        tdscode: ""
    })




    useEffect(() => {
        FetchApis.FethcGet(`/journal/journalbyId/${id}`).then((datafetch) => {

            var codeid = "";
            if (datafetch.data.status == 200) {
                setDatafetch(datafetch.data.data)
                codeid = datafetch.data.data[0].tdscode;
                setGuideline({ ...guideline, tdscode: codeid });
                setRequestsample({ ...requestsample, tdscode: codeid });

                getSubstrateAll(id);
                getComponentCode(id);

                getCoatingbody(id);
                getforming(id);
                getguideline(id);
                getregulation(id);
                getrequestsample(id);
            }
        })
    }, [])


    async function getCoatingbody(jourId) {

        await FetchApis.FethcGet(`/coating/coatingByJournalId/${jourId}`).then((datacoating) => {
            if (datacoating.data.status == 200) {

                for (let index = 0; index < datacoating.data.data.length; index++) {

                    if (datacoating.data.data[index].producttype == 'body') {

                        var databodytypes = datacoating.data.data[index].groupBobyType.split(',')

                        setCoatingbody({ ...datacoating.data.data[index], groupBobyType: [databodytypes] });

                    }
                    else if (datacoating.data.data[index].producttype == 'top') {
                        setCoatingtop(datacoating.data.data[index])

                    }
                    else if (datacoating.data.data[index].producttype == 'bottom') {
                        setCoatingbottom(datacoating.data.data[index])

                    }
                    else if (datacoating.data.data[index].producttype == 'ring') {

                        setCoatingring(datacoating.data.data[index])
                    }
                    else if (datacoating.data.data[index].producttype == 'ear') {
                        setCoatingear(datacoating.data.data[index])
                    }
                }
            }
        })
    }

    async function getforming(jourId) {

        await FetchApis.FethcGet(`/forming/formingByJournalId/${jourId}`).then((datacoating) => {
            if (datacoating.data.status == 200) {
                setForming(datacoating.data.data[0])

            }

        })
    }

    async function getguideline(jourId) {

        await FetchApis.FethcGet(`/guideline/guidelineByJournalId/${jourId}`).then((datacoating) => {
            if (datacoating.data.status == 200) {
                setGuideline(datacoating.data.data[0])
            }

        })
    }

    async function getregulation(jourId) {

        await FetchApis.FethcGet(`/regulation/regulationByJourId/${jourId}`).then((datacoating) => {
            if (datacoating.data.status == 200) {

                setRegulation(datacoating.data.data[0])
                var datagroup = datacoating.data.data[0].regulationtype.split(",");
                setregulationGroup(datagroup);
                setRegulationcheck(datagroup);
            }

        })
    }
    // setRequestsample
    async function getrequestsample(jourId) {

        await FetchApis.FethcGet(`/requestsample/requestsampleByJournalId/${jourId}`).then((datacoating) => {
            if (datacoating.data.status == 200) {
                setRequestsample(datacoating.data.data[0]);
            }

        })
    }
    async function getSubstrateAll(jourId) {

        await FetchApis.FethcGet(`/specplanning/specplanningByJournalId/${jourId}`).then((datacoating) => {
            if (datacoating.data.status == 200) {

                for (let index = 0; index < datacoating.data.data.length; index++) {

                    if (datacoating.data.data[index].spectype == 'body') {
                        setSubstrateBody(datacoating.data.data[index]);

                    }
                    else if (datacoating.data.data[index].spectype == 'top') {
                        setSubstrateTop(datacoating.data.data[index])

                    }
                    else if (datacoating.data.data[index].spectype == 'bottom') {
                        setSubstrateBotton(datacoating.data.data[index])

                    }
                    else if (datacoating.data.data[index].spectype == 'tab') {
                        setSubstrateTab(datacoating.data.data[index])

                    }
                }
            }
        })

    }

    async function getComponentCode(jourId) {
        await FetchApis.FethcGet(`/componentcode/componentByjournalId/${jourId}`).then((res) => {
            if (res.data.status == 200) {
                if (res.data.data.length != 0) {
                    setComponentCode({ ...res.data.data[0] })

                }
            }

        })

    }


    return (
        <div >
            {datafetch.length > 0 && <div>
                <div><center><b style={{ fontSize: 15 }}>TECHNICAL DATA SHEET : {datafetch[0].typeproduct == 'A1' ? "Food can Product" : "Dry foods ,Arosol can and General can Product"}</b></center></div>
                <div>
                    <table style={{ width: '100%' }} >
                        <tr>
                            <th><center>Create product</center></th>
                            <th><center>Due Date</center></th>
                            <th><center>MK NO</center></th>
                            <th><center>Date Cretae</center></th>
                        </tr>
                        <tr>
                            <th style={{ paddingLeft: 5 }}>{datafetch[0].namejournaltype}</th>
                            <th style={{ paddingLeft: 5 }}>{datafetch[0].duedate}</th>
                            <th style={{ paddingLeft: 5 }}>{datafetch[0].tdscode}</th>
                            <th style={{ paddingLeft: 5 }}>{datafetch[0].createdate}</th>
                        </tr>
                    </table>
                </div>

                <div style={{ width: '100%' }} className='row-between-form'>
                    <div style={{ width: '50%' }} className='border_green'>
                        <table style={{ width: '100%' }} >
                            <tr>
                                <td style={{ width: '30%' }}>Product name : </td>
                                <td>{datafetch[0].productname}</td>
                            </tr>
                            <tr>
                                <td style={{ width: '30%' }}>Can Size /Dia : </td>
                                <td> {datafetch[0].cansize}</td>
                            </tr>
                            <tr>
                                <td style={{ width: '30%' }}>Shape : </td>
                                <td> {datafetch[0].shape}</td>
                            </tr>
                            <tr>
                                <td style={{ width: '30%' }}>Item name : </td>
                                <td>{datafetch[0].itemname}</td>
                            </tr>
                            <tr>
                                <td style={{ width: '30%' }}>Item NO : </td>
                                <td>{datafetch[0].itemcode}</td>
                            </tr>

                        </table>
                    </div>
                    <div style={{ width: '50%', marginLeft: 3 }} className='border_green'>
                        <table style={{ width: '100%' }} >
                            <tr>
                                <td style={{ width: '30%' }}>Customer name : </td>
                                <td>{datafetch[0].customername}</td>
                            </tr>
                            <tr>
                                <td style={{ width: '30%' }}>Contact : </td>
                                <td>{datafetch[0].contact} </td>
                            </tr>
                            <tr>
                                <td style={{ width: '30%' }}>Tel : </td>
                                <td>{datafetch[0].tel} </td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div>
                    <table style={{ width: '100%' }}>
                        <tr>
                            <th>Substrate</th>
                            <th>Product group</th>
                            <th>Information from customer</th>
                            <th>Printing system</th>
                        </tr>
                        <tr>
                            <td><input type='checkbox' checked={true} disabled /> {datafetch[0].substratetype}</td>
                            <td><input type='checkbox' checked={true} disabled /> {datafetch[0].productgrouptype}</td>
                            <td><input type='checkbox' checked={true} disabled /> {datafetch[0].informationtype == 'false' ? '! ยังไม่ได้ระบบ ข้อมูล ' : datafetch[0].informationtype}</td>
                            <td>
                                <tr style={{width:'100%'}}>
                                    <td style={{width:'20%'}}>
                                    <label style={{width:200}}><b>Printing System</b></label>
                                    </td>
                                    <td>
                                    <input type='checkbox' checked={true} disabled /> {datafetch[0].printingtype == 'false' ? '! ยังไม่ได้ระบบ ข้อมูล  ( printing system ) ' : datafetch[0].printingtype}
                                    {" "}    {datafetch[0].printingtypeother!==""&&( datafetch[0].printingtypeother )}
                                    </td>
                                </tr>
                                <tr>
                                    <td><label><b>Printing Machine</b></label></td>
                                    <td>
                                        {datafetch[0].printingmachine}
                                    </td>
                                </tr>
                                <tr >
                                    <td style={{ width: '30%' }}><b>Last film Code:</b></td>
                                    <td style={{ width: '100%' }}>{datafetch[0].filmcode}</td>
                                </tr>
                                <tr >
                                    <td style={{ width: '30%' }}><b>Ref.item FG:</b></td>
                                    <td style={{ width: '100%' }}>{datafetch[0].refFgcode}</td>
                                </tr>
                                <tr>
                                    <td>
                                        {datafetch[0].filmtype == 'Scrap' ? <div><input type='checkbox' checked={true} disabled /> Scrap</div> : <div><input type='checkbox' checked={false} disabled /> Scrap</div>}
                                    </td>
                                    <td>
                                        {datafetch[0].filmtype == 'Maintain' ? <div><input type='checkbox' checked={true} disabled /> Maintain</div> : <div><input type='checkbox' checked={false} disabled /> Maintain</div>}
                                    </td>
                                </tr>
                            </td>
                        </tr>
                    </table>
                </div>

                <div className='row-page'>
                    <div>
                        <b><label>STATUS PROOF JOB</label></b>
                        {datafetch[0].statusprove_Tds == 1 ? <div>
                            <input type='checkbox' name='noteapproveTds' checked={true} disabled /> <label> PROOF JOB</label>
                        </div> : <div>
                            <input type='checkbox' name='noteapproveTds' checked={false} disabled /> <label> PROOF JOB</label>
                        </div>}
                    </div>
                    <div style={{ marginLeft: 20 }}>
                        <div>
                            <b><label>NOTE</label></b>
                        </div>
                        <div>
                            <label>{datafetch[0].noteapproveTds}</label>
                        </div>
                    </div>
                </div>

            </div>}
            <hr></hr>
            <div>
                <div><b>Coating system</b></div>
                <div className='row-page' style={{ width: '100%' }}>
                    <div style={{ width: '10%' }}>
                        <b>Bobdy :</b>
                    </div>
                    <div style={{ width: '100%' }}>
                        <table style={{ width: '100%' }} >
                            <tr className='borderWidth-form'>
                                <th><center>Product shape</center></th>
                                <th><center>Side</center></th>
                                <th><center>Coating system</center></th>
                                <th><center>White Coat</center></th>
                                <th><center>Other</center></th>
                            </tr>
                            <tr>
                                <td style={{ paddingLeft: 5, width: '15%' }}>
                                    <center>
                                        {coatingbody.groupBobyType.map((item, i) => (
                                            <div>
                                                <label>{i + 1}. {item}</label>
                                            </div>
                                        ))}
                                    </center></td>
                                <td style={{ width: '10%' }}><center><b>Int .</b></center></td>
                                <td style={{ paddingLeft: 5, width: '20%' }}>{coatingbody.intcoating}</td>
                                <td style={{ paddingLeft: 5, width: '30%' }}>{coatingbody.intwhite}</td>
                                <td style={{ paddingLeft: 5, width: '30%' }}>{coatingbody.intother}</td>
                            </tr>
                            <tr>
                                <td style={{ paddingLeft: 5, width: '15%' }}></td>
                                <td style={{ width: '10%' }}><center><b>Ext .</b></center></td>
                                <td style={{ paddingLeft: 5, width: '20%' }}>{coatingbody.extcoating}</td>
                                <td style={{ paddingLeft: 5, width: '30%' }}>
                                   {coatingbody.exttypewhite!==""&&<label style={{marginRight:10}}>{ coatingbody.exttypewhite} </label>}
                                    {coatingbody.extwhite!==""&&<label>{coatingbody.extwhite}</label>}
                                </td>
                                <td style={{ paddingLeft: 5, width: '30%' }}>{coatingbody.extother}</td>
                            </tr>
                        </table>
                        <div><b>FG component :</b>{coatingbody.fgcomponent}</div>
                        <div><b> Note : </b>{coatingbody.note}</div>
                    </div>
                </div>

                <div className='row-between-form' style={{ width: '100%' }}>
                    <div style={{ width: '10%' }}>
                        <b>Top end :</b>
                        <div># : <b>{coatingtop.number}</b></div>
                    </div>
                    <div style={{ width: '100%' }}>
                        <table style={{ width: '100%' }} >
                            <tr className='borderWidth-form' style={{ backgroundColor: '' }}>
                                <th style={{ paddingLeft: 5, width: '10%' }}><center>Product Substrate</center></th>
                                <th style={{ paddingLeft: 5, width: '10%' }}><center>Product shape</center></th>
                                <th style={{ width: '10%' }}><center>Side</center></th>
                                <th style={{ paddingLeft: 5, width: '10%' }}><center>Coating system</center></th>
                                <th style={{ paddingLeft: 5, width: '30%' }}><center>White Coat</center></th>
                                <th style={{ paddingLeft: 5, width: '30%' }}><center>Other</center></th>
                            </tr>
                            <tr>
                                <td ><center>{coatingtop.substrate}</center></td>
                                <td ><center>{coatingtop.shape}</center></td>
                                <td ><center><b>Int .</b></center></td>
                                <td >{coatingtop.intcoating}</td>
                                <td >{coatingtop.intwhite}</td>
                                <td >{coatingtop.intother}</td>
                            </tr>
                            <tr>
                                <td ></td>
                                <td ></td>
                                <td ><center><b>Ext .</b></center></td>
                                <td >{coatingtop.extcoating}</td>
                                <td >
                                   
                                    {coatingtop.exttypewhite!==""&&<label style={{marginRight:10}}>{ coatingtop.exttypewhite} </label>}
                                    {coatingtop.extwhite!==""&&<label>{coatingtop.extwhite}</label>}
                                    </td>
                                <td >{coatingtop.extother}</td>
                            </tr>
                        </table>
                        <div><b>FG component :</b>{coatingbody.fgcomponent}</div>
                        <div><b>For sale order:</b> {coatingtop.forsaleorder}</div>
                        <div><b>Note : </b>{coatingtop.note}</div>
                    </div>
                </div>
                <div className='row-between-form' style={{ width: '100%' }}>
                    <div style={{ width: '10%' }}>
                        <b>Bottom end :</b>
                        <div># : <b>{coatingbottom.number}</b></div>
                    </div>
                    <div style={{ width: '100%' }} >
                        <table style={{ width: '100%' }} >
                            <tr className='borderWidth-form' style={{ backgroundColor: '' }}>
                                <th style={{ paddingLeft: 5, width: '10%' }}><center>Product Substrate</center></th>
                                <th style={{ paddingLeft: 5, width: '10%' }}><center>Product shape</center></th>
                                <th style={{ width: '10%' }}><center>Side</center></th>
                                <th style={{ paddingLeft: 5, width: '10%' }}><center>Coating system</center></th>
                                <th style={{ paddingLeft: 5, width: '30%' }}><center>White Coat</center></th>
                                <th style={{ paddingLeft: 5, width: '30%' }}><center>Other</center></th>
                            </tr>
                            <tr>
                                <td ><center>{coatingbottom.substrate}</center></td>
                                <td ><center>{coatingbottom.shape}</center></td>
                                <td ><center><b>Int .</b></center></td>
                                <td >{coatingbottom.intcoating}</td>
                                <td >{coatingbottom.intwhite}</td>
                                <td >{coatingbottom.intother}</td>
                            </tr>
                            <tr>
                                <td ></td>
                                <td ></td>
                                <td ><center><b>Ext .</b></center></td>
                                <td >{coatingbottom.extcoating}</td>
                                <td >
                                    {/* {coatingbottom.extwhite} */}
                                    {coatingbottom.exttypewhite!==""&&<label style={{marginRight:10}}>{ coatingbottom.exttypewhite}</label>}
                                    {coatingbottom.extwhite!==""&&<div>{coatingbottom.extwhite}</div>}
                                </td>
                                <td >{coatingbottom.extother}</td>
                            </tr>
                        </table>
                        <div><b>FG component :</b>{coatingbottom.fgcomponent}</div>
                        <div><b>For sale order: </b> {coatingbottom.forsaleorder}</div>
                        <div><b>Note : </b> {coatingbottom.note}</div>
                    </div>
                </div>

                <div className='row-between-form' style={{ width: '100%' }}>
                    <div style={{ width: '10%' }}>
                        <b>Ring :</b>

                    </div>
                    <div style={{ width: '100%' }} >
                        <table style={{ width: '100%' }} >
                            <tr className='borderWidth-form' style={{ backgroundColor: '' }}>
                                <th style={{ paddingLeft: 5, width: '10%' }}><center>Dia</center></th>
                                <th style={{ paddingLeft: 5, width: '10%' }}><center>Ring Type</center></th>
                                <th style={{ paddingLeft: 5, width: '20%' }}><center>Code</center></th>
                                <th style={{ width: '10%' }}><center>Side</center></th>
                                <th style={{ paddingLeft: 5, width: '10%' }}><center>Coating system</center></th>
                                <th style={{ paddingLeft: 5, width: '20%' }}><center>White Coat</center></th>
                                <th style={{ paddingLeft: 5, width: '20%' }}><center>Other</center></th>
                            </tr>
                            <tr>
                                <td ><center>{coatingring.dia}</center></td>
                                <td ><center>{coatingring.ring_eartype}</center></td>
                                <td><center>{coatingring.ring_earcode}</center></td>
                                <td ><center><b>Int .</b></center></td>
                                <td >{coatingring.intcoating}</td>
                                <td >{coatingring.intwhite}</td>
                                <td >{coatingring.intother}</td>
                            </tr>
                            <tr>
                                <td ></td>
                                <td ></td>
                                <td></td>
                                <td ><center><b>Ext .</b></center></td>
                                <td >{coatingring.extcoating}</td>
                                <td >
                                    {/* {coatingring.extwhite} */}
                                    {coatingring.exttypewhite!==""&&<input type='text' value={ coatingring.exttypewhite} disabled/>}
                                    {coatingring.extwhite!==""&&<input type='text' value={coatingring.extwhite} disabled/>}
                                </td>
                                <td >{coatingring.extother}</td>
                            </tr>
                        </table>
                        <div><b>For sale order : </b> {coatingring.forsaleorder}</div>

                    </div>
                </div>

                <div className='row-between-form' style={{ width: '100%' }}>
                    <div style={{ width: '10%' }}>
                        <b>Ear :</b>

                    </div>
                    <div style={{ width: '100%' }} >
                        <table style={{ width: '100%' }} >
                            <tr className='borderWidth-form' style={{ backgroundColor: '' }}>
                                <th style={{ paddingLeft: 5, width: '10%' }}><center>Code</center></th>
                                <th style={{ paddingLeft: 5, width: '10%' }}><center>Ring Type</center></th>

                                <th style={{ width: '10%' }}><center>Side</center></th>
                                <th style={{ paddingLeft: 5, width: '10%' }}><center>Coating system</center></th>
                                <th style={{ paddingLeft: 5, width: '20%' }}><center>White Coat</center></th>
                                <th style={{ paddingLeft: 5, width: '20%' }}><center>Other</center></th>
                            </tr>
                            <tr>
                                <td ><center>{coatingear.ring_earcode}</center></td>
                                <td ><center>{coatingear.ring_eartype}</center></td>

                                <td ><center><b>Int .</b></center></td>
                                <td >{coatingear.intcoating}</td>
                                <td >{coatingear.intwhite}</td>
                                <td >{coatingear.intother}</td>
                            </tr>

                        </table>

                    </div>
                </div>

                <hr></hr>
            </div>

            <div>
                <div className='row-between-form' style={{ width: '100%' }}>
                    <div style={{ width: '10%' }}><b>Can & end forming</b></div>
                    <div style={{ width: '100%' }} >
                        <table style={{ width: '100%' }} >
                            <tr className='borderWidth-form' style={{ backgroundColor: '' }}>
                                <th style={{ width: '10%' }}><center>Internal side stripe</center></th>
                                <th style={{ width: '15%' }}><center>Internal Other</center></th>
                                <th style={{ width: '10%' }}><center>Seaming </center></th>
                                <th style={{ width: '15%' }}><center>Seaming Other</center></th>
                                <th style={{ width: '20%' }}><center>Machine no </center></th>
                                <th style={{ width: '20%' }}><center>Packaging </center></th>
                            </tr>
                            <tr>
                                <td ><center>{forming.internalside}</center></td>
                                <td ><center>{forming.internalother}</center></td>
                                <td ><center>{forming.seaming}</center></td>
                                <td >{forming.seamingother}</td>
                                <td >{forming.machineno}</td>
                                <td >{forming.packaging}</td>

                            </tr>
                        </table>
                        <div><b>Note : </b> {forming.note}</div>
                    </div>
                </div>
                <hr></hr>
            </div>

            <div>
                <div className='row-between-form' style={{ width: '100%' }}>
                    <div style={{ width: '10%' }}><b>Guideline for product pack</b></div>
                    <div style={{ width: '100%' }} >
                        <div className='row-form'>
                            <div style={{ marginRight: 20 }}>
                                <b>product shelf-life</b>  : <label style={{ color: 'red' }}>( {guideline.shelfyears} ) years / at</label></div>
                            <div> <label style={{ color: 'blue' }}>( {guideline.shelfdeg} ) degC</label></div>
                        </div>
                        <table style={{ width: '100%' }} >
                            <tr className='borderWidth-form' style={{ backgroundColor: '' }}>
                                <th style={{ width: '10%' }}><center># No .</center></th>
                                <th style={{ width: '15%' }}><center>Detail</center></th>
                                <th style={{ width: '10%' }}><center>Other </center></th>
                            </tr>
                            <tr>
                                <td ><b>1. Product group</b></td>
                                <td >{guideline.groupother}</td>
                                <td >{guideline.groupother}</td>
                            </tr>
                            <tr>
                                <td ><b>2. Details of product </b></td>
                                <td >{guideline.detailproduct}</td>
                                <td ></td>
                            </tr>
                            <tr>
                                <td ><b>3. pH</b></td>
                                <td >{guideline.ph}</td>
                                <td ></td>
                            </tr>
                            <tr>
                                <td ><b>4. Ingredients </b></td>
                                <td >{guideline.ingredients}</td>
                                <td >{guideline.ingredientother}{guideline.ingredients_acid}{guideline.ingredients_chioride}</td>
                            </tr>
                            <tr>
                                <td ><b>5.Seamer condition </b></td>
                                <td >{guideline.seamercondition}</td>
                                <td ></td>
                            </tr>
                            <tr>
                                <td ><b>6.Sterilization process</b></td>
                                <td >{guideline.sterilization}</td>
                                <td >{guideline.sterilizationother}{guideline.sterilization_cooker}{guideline.sterilization_retort}</td>
                            </tr>
                        </table>
                    </div>
                </div>
                <hr></hr>
            </div>

            <div>
                <div className='row-between-form' style={{ width: '100%' }}>
                    <div style={{ width: '10%' }}><b>Regulation</b></div>
                    <div style={{ width: '100%' }} >
                        <table style={{ width: '100%' }} >
                            <tr className='borderWidth-form' style={{ backgroundColor: '' }}>
                                <th style={{ width: '10%' }}><center>Regulation type</center></th>
                                <th style={{ width: '15%' }}><center>Detail</center></th>



                            </tr>
                            <tr>
                                <td >{regulationcheck.map((item, i) => (
                                    <div>{i + 1} . {item}</div>
                                ))}</td>
                                <td >{regulationOter}</td>
                            </tr>
                        </table>
                    </div>
                </div>
                <hr></hr>
            </div>

            <div>
                <div className='row-between-form' style={{ width: '100%' }}>
                    <div style={{ width: '10%' }}><b>Request sample</b></div>
                    <div style={{ width: '100%' }} >
                        <table style={{ width: '100%' }} >
                            <tr className='borderWidth-form' style={{ backgroundColor: '' }}>
                                <th style={{ width: '10%' }}><center>Proof plate</center></th>
                                <th style={{ width: '15%' }}><center> Can (pcs)</center></th>
                                <th style={{ width: '15%' }}><center>  End (pcs)</center></th>

                            </tr>
                            <tr>
                                <td >{requestsample.proofplate}</td>
                                <td >{requestsample.requestcan}</td>
                                <td >{requestsample.requestend}</td>
                            </tr>
                        </table>
                        <div style={{ margin: 10, padding: 10, backgroundColor: '#e9e9e9' }}>
                            <b> Note : </b>{requestsample.requestnote}
                        </div>
                        {datafetch.length > 0 &&
                            <div>

                                <div className='row-page' style={{ marginLeft: 20 }}>
                                    <label><b>ตัวอย่าง Product </b></label> <ViewImages urlImages={datafetch[0].uploadfilename} />
                                </div>

                                <div style={{ margin: 0, padding: 0 }} className='row-page'>
                                    {datafetch[0].uploadfilename != "" ? <img src={host + '/images/files/' + datafetch[0].uploadfilename} style={{ width: 100, hidden: 100 }} /> : <CiImageOn size={50} />}
                                </div>

                                <div style={{ marginTop: 10 }}>
                                    {datafetch[0].uploadfilename != "" && <div>
                                        <a href={host + `/images/files/${datafetch[0].uploadfilename}`}  >
                                            <FaFileDownload size={20} /> download file
                                        </a>
                                    </div>}
                                </div>

                            </div>}
                    </div>
                </div>
                <hr></hr>
            </div>

            <div className='row-between-page'>
                <hr></hr>
                <table style={{ width: '100%' }}>
                    <tr>
                        <th style={{ width: '10%' }}><center>Substrate</center></th>
                        <th style={{ width: '10%' }}><center>Body</center></th>
                        <th style={{ width: '10%' }}><center>Top end</center></th>
                        <th style={{ width: '10%' }}><center>Botton end</center></th>
                        <th style={{ width: '10%' }}><center>Tab</center></th>
                    </tr>
                    <tr>
                        <td><b>Sheet size(T x W x L)</b></td>
                        <td>{substratebody.sheetsize}</td>
                        <td>{substrateTop.sheetsize}</td>
                        <td>{substrateBotton.sheetsize}</td>
                        <td>{substrateTab.sheetsize}</td>
                    </tr>
                    <tr>
                        <td><b>Straight / Scoll</b></td>
                        <td>{substratebody.straightscroll}</td>
                        <td>{substrateTop.straightscroll}</td>
                        <td>{substrateBotton.straightscroll}</td>
                        <td>{substrateTab.straightscroll}</td>
                    </tr>
                    <tr>
                        <td><b>Tin coating / Alloy</b></td>
                        <td>{substratebody.tin}</td>
                        <td>{substrateTop.tin}</td>
                        <td>{substrateBotton.tin}</td>
                        <td>{substrateTab.tin}</td>
                    </tr>
                    <tr>
                        <td><b>Temper / Hardness</b></td>
                        <td>{substratebody.temper}</td>
                        <td>{substrateTop.temper}</td>
                        <td>{substrateBotton.temper}</td>
                        <td>{substrateTab.temper}</td>
                    </tr>
                    <tr>
                        <td><b>Bright / Stone / Matte</b></td>
                        <td>{substratebody.bright}</td>
                        <td>{substrateTop.bright}</td>
                        <td>{substrateBotton.bright}</td>
                        <td>{substrateTab.bright}</td>
                    </tr>
                    <tr>
                        <td><b>Row x Layer</b></td>
                        <td>{substratebody.row}</td>
                        <td>{substrateTop.row}</td>
                        <td>{substrateBotton.row}</td>
                        <td>{substrateTab.row}</td>
                    </tr>
                    <tr>
                        <td><b>Film code</b></td>
                        <td>{substratebody.filmcode}</td>
                        <td>{substrateTop.filmcode}</td>
                        <td>{substrateBotton.filmcode}</td>
                        <td>{substrateTab.filmcode}</td>
                    </tr>
                    <tr>
                        <td><b>Item No.</b></td>
                        <td>{substratebody.itemno}</td>
                        <td>{substrateTop.itemno}</td>
                        <td>{substrateBotton.itemno}</td>
                        <td>{substrateTab.itemno}</td>
                    </tr>
                    <tr>
                        <td><b>Ref.Item WC.</b></td>
                        <td>{substratebody.refitemwc}</td>
                        <td>{substrateTop.refitemwc}</td>
                        <td>{substrateBotton.refitemwc}</td>
                        <td>{substrateTab.refitemwc}</td>
                    </tr>
                </table>

                <div className='border_green' style={{ width: '30%', height: 'auto', padding: 10 }}>
                    <div style={{ backgroundColor: '#989898' }}>
                        <center>Component Code </center>
                    </div>
                    <div className='row-between-page '>
                        <div><b>หูอ๊อก/ถ้วยอ๊อก :</b></div>
                        <div>{componentCode.earwelding}</div>

                    </div>
                    <div className='row-between-page '>
                        <div><b>หู :</b></div>
                        <div>{componentCode.ear}</div>

                    </div>
                    <div className='row-between-page '>
                        <div><b>ห่วง :</b></div>
                        <div>{componentCode.ring}</div>

                    </div>
                    <div className='row-between-page '>
                        <div><b>ฝาใน :</b></div>
                        <div>{componentCode.innerlid}</div>
                    </div>
                    {/* <div className='row-between-page '>
                        <div><b>ฝานอก :</b></div>
                        <div>{componentCode.outerlid}</div>
                    </div> */}
                    <div className='row-between-page '>
                        <div><b>ฝาบน :</b></div>
                        <div>{componentCode.toplid}</div>
                    </div>
                    {/* <div className='row-between-page '>
                        <div><b>ฝาล่าง :</b></div>
                        <div>{componentCode.bottomlid}</div>
                    </div> */}
                    <div className='row-between-page '>
                        <div><b>ฝาพลาสติก :</b></div>
                        <div>{componentCode.plasticlid}</div>
                    </div>
                    <div className='row-between-page '>
                        <div><b>ฝาอลูมิเนียม :</b></div>
                        <div>{componentCode.aluminumlid}</div>
                    </div>
                </div>

            </div>


        </div>
    )
}

export default Showdetailjournal