import React from 'react';
import Detailstatus from './detailstatus';

const Actionallstatus = ({id}) => {
  return (
    <div className='row-page' style={{alignItems:'center'}}>
        <div><Detailstatus id={id}/></div>
    </div>
  )
}

export default Actionallstatus