import React, { useState, useEffect,useRef } from 'react'
import Showdetailjournal from '../../../form01/components/showdetailjournal';
import generatePDF from 'react-to-pdf';
import { Button } from 'react-bootstrap';
import { FaFileDownload } from "react-icons/fa";

const PdfpartAsuccess = ({ idjour }) => {
    const targetRef = useRef();

    return (
        <div>
            <Button onClick={() => generatePDF(targetRef, { filename: `${idjour}_partA_page.pdf` })} size='sm' variant="light"><FaFileDownload /> Download PDF</Button>
            <div ref={targetRef}>
                <Showdetailjournal id={idjour} />
                <img src={require('../../../images/approve_8622624.png')} style={{ width: 50, height: 'auto', borderRadius: 3 }} />
                
            </div>
        </div>
    )
}

export default PdfpartAsuccess