import React, { useState, useEffect } from 'react'
import { Button } from 'react-bootstrap'
import Modal from 'react-bootstrap/Modal';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import FetchApi from '../../customhooks/functionFetchApi';
import Form from 'react-bootstrap/Form';

function Menulistform({ emcode }) {
    const FetchApis = new FetchApi();
    const [accesslist, setAccesslist] = useState([]);
    const [getative, setGetative] = useState([]);
    const [menulist, setMenuList] = useState([]);
    const [InputMenu, setInputMenu] = useState("");

    useEffect(() => {
        loadingListAccess();
        loadingmenuAll();
    }, [])

    // เรียกข้อมูลเมนู ตาม ผู้ใช้งาน
    function loadingListAccess() {
        FetchApis.FethcGet(`/menuaccess/menulistall/${emcode}`).then((res) => {
            if (res.status == 200) {
                setAccesslist(res.data.data);
                setGetative(res.data.data)
            }
        })
    }

    // เรียกข้อมูลเมนูทั้งหมด
    function loadingmenuAll() {
        FetchApis.FethcGet(`/menuaccess/getmenulistall`).then((res) => {
            if (res.status == 200) {
                setMenuList(res.data.data);
            }
            else {
                alert('เกิกข้อผิดพลาดในการแสดงข้อมูล')
            }
        }).catch(err => alert(err))
    }


    function handleChange(e) {

        const { name, value } = e.target;
        if (e.target.checked) {
            const item = getative.find((item) => item.menuid == name);
            item.active = value
        }
        else {
            const item = getative.find((item) => item.menuid == name);
            item.active = "0"
        }
    }

    function handleChangeAdd(e) {
        const { name, value } = e.target;
        if (e.target.checked) {
            const item = getative.find((item) => item.menuid == name);
            item.access_add = value
            // console.log(item.access_add);
        }
        else {
            const item = getative.find((item) => item.menuid == name);
            item.access_add = "0"
        }
    }


    function handleChangeDelete(e) {
        const { name, value } = e.target;
        if (e.target.checked) {
            const item = getative.find((item) => item.menuid == name);
            item.access_delete = value
            // console.log(item.access_delete);
        }
        else {
            const item = getative.find((item) => item.menuid == name);
            item.access_delete = "0"
        }
    }



    function handleChangeUpdate(e) {
        const { name, value } = e.target;
        if (e.target.checked) {
            const item = getative.find((item) => item.menuid == name);
            item.access_update = value
        }
        else {
            const item = getative.find((item) => item.menuid == name);
            item.access_update = "0"
        }
    }

    function handleChangeMenu(e) {
        var valueMenu = e.target.value;
        setInputMenu(valueMenu);
    }



    //  update status sting account
    function submit() {
        for (let index = 0; index < getative.length; index++) {
            const getativenew = getative[index];
            UpdateAccessusernow(getativenew);
        }

        alert('คุณได้ทำการตั้งค่าเรียบร้อยแล้ว');
    }


    function UpdateAccessusernow(results) {
        FetchApis.FethcUpdate(`/menuaccess/updateMenuAccess/${results.accessid}`, results).then((res) => {
        }).catch((err) => (alert(err)));
    }

    //เพิ่มข้อมูบเมนู
    function addnewmenulsit() {
        var objectdata = {
            menuid: InputMenu,
            usercode: emcode,
            access_add: "1",
            access_delete: "1",
            access_update: "1",
            active: "1"
        }

        if (InputMenu == "") {
            alert("กรุณาเลือกรายการเมนู ?");
        } else {
            FetchApis.FethcPost(`/menuaccess/createMenuAccess`, objectdata).then((res) => {
                if (res.status == 200) {
                    alert('ทำการบันทึกเรียบร้อยแล้ว');
                    loadingListAccess();
                }
            }).catch(err => alert(err));
        }
    }

    //ลบข้อมูล
    function deleteMenu(id) {
        if(window.confirm()==true){
            FetchApis.FethcDelete(`/menuaccess/muneDeleteById/${id}`).then((res)=>{
                    if(res.status==200){
                        alert('ลบข้อมูลเรียบร้อยแล้ว');
                        loadingListAccess();

                    }
            }).catch(err=>alert(err))
        }
       
    }

    return (
        <div>
            <div>

            </div>
            <div className='row-between-page'>
                <div className='row-page'>
                    <div>
                        <Form.Select style={{ width: 250 }} onChange={(e) => handleChangeMenu(e)}>
                            <option>เลือกเมนู ...</option>
                            {menulist.map((item, i) => (
                                <option value={item.menuid}>{item.name}</option>
                            ))}
                        </Form.Select>
                    </div>
                    <div style={{ marginLeft: 5 }}>
                        <Button variant="success" onClick={() => addnewmenulsit()}>เพิ่มเมนู</Button>
                    </div>
                </div>
                <div>
                    <Button onClick={() => submit()}>ตั้งค่า</Button>
                </div>
            </div>
            <table style={{ width: '100%' }}>
                <tr>
                    <th style={{ width: '5%' }}><center>ลำดับ</center></th>
                    <th style={{ width: '30%' }}><center>ชื่อเมนู</center></th>
                    <th style={{ width: '10%' }}><center>เปิดใช้</center></th>
                    <th style={{ width: '10%' }}><center>เพิ่ม</center></th>
                    <th style={{ width: '10%' }}><center>ลบ</center></th>
                    <th style={{ width: '10%' }}><center>แก้ไข</center></th>
                    <th style={{ width: '10%' }}><center>action</center></th>
                </tr>
                {
                    accesslist.map((item, i) => (
                        <tr>
                            <td style={{ width: '5%' }}><center>{i + 1}</center></td>
                            <td style={{ width: '30%' }}>{item.name}</td>
                            <td style={{ width: '10%' }}>
                                <center>
                                    <input type='checkbox' value={'1'} name={item.menuid}
                                        defaultChecked={item.active == 1 ? true : false} onChange={e => handleChange(e)} />
                                </center>
                            </td>
                            <td style={{ width: '10%' }}>
                                {/* {item.access_add} */}
                                <center>
                                    <input type='checkbox' name={item.menuid}
                                        defaultChecked={item.access_add == 1 ? true : false} value={'1'} onChange={e => handleChangeAdd(e)} />
                                </center>
                            </td>
                            <td style={{ width: '10%' }}>
                                {/* {item.access_delete} */}
                                <center>
                                    <input type='checkbox' name={item.menuid}
                                        defaultChecked={item.access_delete == 1 ? true : false} value={'1'} onChange={e => handleChangeDelete(e)}
                                    />
                                </center>
                            </td>
                            <td style={{ width: '10%' }}>
                                {/* {item.access_update} */}
                                <center>
                                    <input type='checkbox' name={item.menuid}
                                        defaultChecked={item.access_update == 1 ? true : false}
                                        value={'1'} onChange={e => handleChangeUpdate(e)} />
                                </center>
                            </td>
                            <td>
                                <Button size='sm' variant="danger" onClick={()=>deleteMenu(item.accessid)}><label style={{ fontSize: 12 }}></label>ลบข้อมูล</Button>
                            </td>
                        </tr>
                    ))
                }
            </table>
        </div>
    )
}


const Accessuser = ({ emcode }) => {

    const values = [true, 'sm-down', 'md-down', 'lg-down', 'xl-down', 'xxl-down'];
    const [fullscreen, setFullscreen] = useState(true);
    const [show, setShow] = useState(false);

    function handleShow(value) {
        setShow(value);
    }

    return (
        <div>
            <Button size='sm' variant="primary" onClick={() => handleShow(true)} >ตั้งค่า</Button>

            <Modal show={show} fullscreen={fullscreen} onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>ตั้งค่าเมนู</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Menulistform emcode={emcode} />
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default Accessuser