import DataTable from 'react-data-table-component';

import Actionforms from './actionforms';


import React from 'react'


const columns = [
    {
        name: 'MK.NO',
        grow: 0.5,
        sortable: true,
        selector: row => row.tdscode,
    },
    {
        name: 'Revision',
         grow: 0.5,
        selector: row => row.revision,
    },
    {
        name: 'Journal type',
        grow: 1.5,
        selector: row => row.journaltype,
    },
    {
        name: 'Customer name',
        selector: row => row.customername,
    },
    {
        name: 'Createdate',
        grow: 1,
        selector: row => row.createdate,
    },
    {
        name: 'Action',
        grow: 1.5,
        cell: row => <Actionforms id={row.journalId}/>,
    },

];

const customStyles = {
	headRow: {
		style: {
			border: 'none',
            backgroundColor:'#28B463'
		},
	},
	headCells: {
		style: {
			color: '#ffff',
			fontSize: '14px',
		},
	},
	rows: {
		highlightOnHoverStyle: {
			backgroundColor: 'rgb(230, 244, 244)',
			borderBottomColor: '#FFFFFF',
			borderRadius: '25px',
			outline: '1px solid #FFFFFF',
		},
	},
	pagination: {
		style: {
			border: 'none',
		},
	},
};



const FormAtable = ({datatable}) => {
   var dataresult=datatable;
 
  return (
    <DataTable
    columns={columns}
    data={dataresult}
    // selectableRows
    pagination
    customStyles={customStyles}
    highlightOnHover
    
/>
  )
}

export default FormAtable