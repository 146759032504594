import React, { useState, useEffect } from 'react'
import Tablerecallstatus from './components/componentrecall/tablerecallstatus';
import FetchApi from '../customhooks/functionFetchApi';
import Spinner from 'react-bootstrap/Spinner';
import { FaFileSignature } from "react-icons/fa";


const Journalrecallstatus = () => {
    const FetchApis = new FetchApi();
    const [loading, setLoading] = useState(false);
    const [datajournal, setDatajournal] = useState([]);
    const[journalList,setJournalList]=useState([]);
    const emcode = sessionStorage.getItem('EmCode');
    useEffect(() => {
        loadingdata();
    }, [])


    const loadingdata = () => {
        setLoading(true)

        setTimeout(() => {
            FetchApis.FethcGet(`/journal/getjournalRecallByEmcode/${emcode}`).then((resultdata) => {
                if (resultdata) {
                    setDatajournal(resultdata.data.data);
                    setJournalList(resultdata.data.data);
                }
                setLoading(false);
            })
        }, 1000);

    }

    //ค้นหาข้อมูลทั้งหมด
    function heandleSearch(e) {
        const { value, name } = e.target;
    
        // setSearchInput(searchValue)
        const filteredData = datajournal.filter((item) => {
          return Object.values(item).join('').toLowerCase().includes(value.toLowerCase())
        })
        setJournalList(filteredData)
      }

    return (
        <div>
            <div className='row-between-page' style={{ backgroundColor: '#E5E7E7' }}>
                <div >
                    <label style={{ fontSize: 20, margin: 5, padding: 5 }}>
                        <FaFileSignature /> หน้ารวมสถานะแจ้งแก้ไข ( {datajournal.length} )</label>
                </div>
                <div>
                    <input type='text' name='TextSearch' placeholder='ค้นหาข้อมูล' onChange={(e)=>heandleSearch(e)}/>
                </div>
            </div>

            <div>
                {loading == true ? <div className='body-center-page'><Spinner
                    as="span"
                    animation="grow"
                    variant="success"
                    size={200}
                    role="status"
                    aria-hidden="true" /> LOADIN ....</div> : <div>
                    <Tablerecallstatus datatable={journalList} />
                </div>}
            </div>
        </div>
    )
}

export default Journalrecallstatus