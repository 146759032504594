import React, { useState, useEffect } from 'react'
import FetchApi from '../customhooks/functionFetchApi';
import Tablelistdedicate from './components/componentlistdedicate/tablelistdedicate';
import { FaFileSignature } from "react-icons/fa";
import Spinner from 'react-bootstrap/Spinner';

const Journallistdedicate = () => {
  const FetchApis = new FetchApi();
  const codes = sessionStorage.getItem('EmCode');
  const [loading, setLoading] = useState(false);

  const [dedicatelist, steDedecatelist] = useState([]);

  useEffect(() => {
    apiloading(codes);
  }, []);

  const apiloading = (codeid) => {
    setLoading(true)
    setTimeout(() => {
      FetchApis.FethcGet(`/journal/getjournaldedicateRunflowByEmcode/${codeid}`).then((res) => {

        if (res.status == 200) {
          steDedecatelist(res.data.data);
        }
        setLoading(false)
      })
    }, 1000);
  }


  return (
    <div>
      <div style={{ backgroundColor: '#E5E7E7' }}>
        <label style={{ fontSize: 20, margin: 5, padding: 5 }}>
          <FaFileSignature /> รายการ มอบให้อนุมัติแทน
        </label>
      </div>
      {loading == true ? <div className='body-center-page'><Spinner
        as="span"
        animation="grow"
        variant="success"
        size={200}
        role="status"
        aria-hidden="true" /> LOADIN ....</div> : <div>
        <Tablelistdedicate values={dedicatelist} /></div>}
    </div>
  )
}

export default Journallistdedicate