import React, { useState, useEffect } from 'react'
import { Button } from 'react-bootstrap'
import FetchApi from '../customhooks/functionFetchApi'
import Tableflowsystem from './component/tableflowsystem'

const Flowsystem = () => {
    const FetchApis = new FetchApi();

    const [formlist, setFormlist] = useState([])
    const [flowdata, setFlowdata] = useState({ nameflow: "", flowcode: "", formcode: "" })
    const [dataflow, setDataflow] = useState([])


    useEffect(() => {
        FetchApis.FethcGet('/formtype/formtypeAll').then((result) => {
            // console.log(result.data.data)
            if (result.data.status == 200) {
                setFormlist(result.data.data)
            }
            else {
                setFormlist([])
            }

        });
        loadeflowsystem();

    }, [])


    const loadeflowsystem = () => {
        FetchApis.FethcGet('/flowsystem/flowsystemAll').then((value) => {
            setDataflow(value.data.data);
        })
    }


    const onchaenghandle = (e) => {
        const { name, value } = e.target
        setFlowdata({ ...flowdata, [name]: value })

    }

    const handleSubmit = (event) => {
        event.preventDefault()
        if (flowdata.nameflow == "" || flowdata.flowcode == "" || flowdata.formcode == "") {
            alert('กรุณา ป้อนข้อมูลให้ครบถ้วน');
        } else {
            // console.log(flowdata)
            FetchApis.FethcPost('/flowsystem/createflowsystem', flowdata).then((results) => {
                if (results.status) {
                    alert('คุณได้ทำการบันทึกเรียบร้อยแล้ว');
                    loadeflowsystem()
                }
            })
        }
    }

    return (
        <div>
            <div style={{ backgroundColor: '#F2EFE7', padding: 10 }}>
                <div>เพิ่ม flow เอกสารตามประเทภเอกสาร </div>
                <center>
                    <div className='border-green-page' style={{ width: '80%' }}>
                        <form onSubmit={(e) => handleSubmit(e)}>
                            <div className='row-between-page ' >

                                <div>
                                    <div>
                                        ชื่อ flow
                                    </div>
                                    <div>
                                        <input type='text' name='nameflow' placeholder='input flow name' onChange={(e) => onchaenghandle(e)} />
                                    </div>
                                </div>
                                <div>
                                    <div>รหัส flow</div>
                                    <div>
                                        <input type='text' name='flowcode' placeholder='input code flow' onChange={(e) => onchaenghandle(e)} />
                                    </div>
                                </div>
                                <div>
                                    <div>ประเภทเอกสาร </div>
                                    <div>
                                        <select name="formcode" id="formcode" onChange={(e) => onchaenghandle(e)}>
                                            <option selected>เลือกข้อมูล...</option>
                                            {
                                                formlist.map((item, i) => (
                                                    <option value={item.formcode} >{item.fname}</option>
                                                ))
                                            }

                                        </select>
                                    </div>
                                </div>
                                <div>
                                    <Button type='submit' size='sm' variant="success">เพิ่มข้อมูล</Button> {" "}
                                    <Button type='reset' size='sm' variant="warning">ยกเลิก</Button>
                                </div>

                            </div>
                        </form>
                    </div>
                </center>

            </div>
            <center>
                <div style={{ width: '100%',marginTop:10 }}>
                    <Tableflowsystem data={dataflow} />
                </div>
            </center>
        </div>
    )
}

export default Flowsystem