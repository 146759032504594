import React from 'react'
import Table from 'react-bootstrap/Table';
const TableINewitem = ({ datatable }) => {
    return (
        <Table striped bordered hover>
            <thead>
                <tr>
                    <th>#</th>
                    <th>Product Type</th>
                    <th>Item Group</th>
                    <th>Type </th>
                    <th>Item</th>
                    <th>Item Name</th>
                    <th>Dimension Group</th>
                    <th>Packing Group</th>
                    <th>Qty / Tag</th>
                    <th>RAF Jouranl</th>
                    <th>Inventory Unit</th>
                    <th>Purchase Unit</th>
                    <th>Sale Unit</th>

                </tr>
            </thead>

            <tbody>
                <tr>
                    <td>1</td>
                    <td>{datatable.DraftFG.bodytype}</td>
                    <td>{datatable.DraftFG.itemegroup}</td>
                    <td>{datatable.DraftFG.typeItem}</td>
                    <td>{datatable.DraftFG.itemId}</td>
                    <td>{datatable.DraftFG.itemname}</td>
                    <td>{datatable.DraftFG.dimensiongroup}</td>
                    <td>{datatable.DraftFG.packinggroup}</td>
                    <td>{datatable.DraftFG.qtypertag}</td>
                    <td>{datatable.DraftFG.raf_journalname}</td>
                    <td>{datatable.DraftFG.inventtoryUnit}</td>
                    <td>{datatable.DraftFG.purchaseUnit}</td>
                    <td>{datatable.DraftFG.saleUnit}</td>
                </tr>
                {datatable.data_objectW &&
                    <tr>
                        <td>2</td>
                        <td>{datatable.data_objectW.bodytype}</td>
                        <td>{datatable.data_objectW.itemegroup}</td>
                        <td>{datatable.data_objectW.typeItem}</td>
                        <td>{datatable.data_objectW.itemId}</td>
                        <td>{datatable.data_objectW.itemname}</td>
                        <td>{datatable.data_objectW.dimensiongroup}</td>
                        <td>{datatable.data_objectW.packinggroup}</td>
                        <td>{datatable.data_objectW.qtypertag}</td>
                        <td>{datatable.data_objectW.raf_journalname}</td>
                        <td>{datatable.data_objectW.inventtoryUnit}</td>
                        <td>{datatable.data_objectW.purchaseUnit}</td>
                        <td>{datatable.data_objectW.saleUnit}</td>
                    </tr>
                }
                {datatable.data_objectCE &&
                    <tr>
                        <td>3</td>
                        <td>{datatable.data_objectCE.bodytype}</td>
                        <td>{datatable.data_objectCE.itemegroup}</td>
                        <td>{datatable.data_objectCE.typeItem}</td>
                        <td>{datatable.data_objectCE.itemId}</td>
                        <td>{datatable.data_objectCE.itemname}</td>
                        <td>{datatable.data_objectCE.dimensiongroup}</td>
                        <td>{datatable.data_objectCE.packinggroup}</td>
                        <td>{datatable.data_objectCE.qtypertag}</td>
                        <td>{datatable.data_objectCE.raf_journalname}</td>
                        <td>{datatable.data_objectCE.inventtoryUnit}</td>
                        <td>{datatable.data_objectCE.purchaseUnit}</td>
                        <td>{datatable.data_objectCE.saleUnit}</td>
                    </tr>}
            </tbody>
        </Table>
    )
}

export default TableINewitem