import React, { useState, useEffect } from 'react'
import { Button } from 'react-bootstrap';
// import Modal from 'react-bootstrap/Modal';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import FetchApi from '../../../customhooks/functionFetchApi';
import { Routes, Route, useParams } from 'react-router-dom';
import { statusflowall } from '../../object-default/journalobject';
import Journalrejectapprove from './journalrejectapprove';
import Journaldedicated from './journaldedicated';
import Journalrecalltapprove from './journalrecalltapprove';
import ViewAllpartAB from '../viewAllpartAB';


import Showdetailjournal from '../../../form01/components/showdetailjournal';
import AllDetailpartb from '../allDetailpartb';
import ViewJournaldetail from '../componentallistallpprovebyuser/viewJournaldetail';

import Spinner from 'react-bootstrap/Spinner';
import { ToastContainer, toast } from 'react-toastify';
import Swal from 'sweetalert2';

const datasub = {
  spectype: "", sheetsize: "", flatscroll: "", straightscroll: "", tin: "",
   temper: "", bright: "", row: "", filmcode: "", itemno: "", journalId: "",tdscode: "",
}

function FormEditJournal() {

  const { id } = useParams()

  const FetchApis = new FetchApi();

  const emCode = sessionStorage.getItem('EmCode');

  const [key, setKey] = useState('jouranl');

  const [datafetch, setDatafetch] = useState([]);


  // data update crate task
  const [newTask, setNewTask] = useState([]);

  //data update journal
  const [newjournalUpdate, setNewjournalUpdate] = useState([]);
  const [laodingdata, setLaodingdata] = useState(true);
  const[idcode,setIdcode]=useState(null)


  useEffect(() => {
    setLaodingdata(true);
  
    var intdataconvert = 0;
    FetchApis.FethcGet(`/journal/journalbyId/${id}`).then((datafetch) => {
      if (datafetch.data.status == 200) {
        setDatafetch(datafetch.data.data)
     let   codeid = datafetch.data.data[0].tdscode;
        setIdcode(codeid)
        intdataconvert = datafetch.data.data[0].stateflow - 1;

        var dataobject = {
          journalid: id,
          emcode: emCode,
          stateflow: intdataconvert,
          approvecode: statusflowall.sendjournal,
          dedicatecode: ""
        }

        setNewTask(dataobject)

        var updatejournal = {
          statusflow: statusflowall.sendjournal,
          stateflow: intdataconvert
        }

        setNewjournalUpdate(updatejournal);
        setTimeout(() => {
          setLaodingdata(false)
        }, 800);

      }
    })



  }, [])

  
  // บันทึกข้อมูล
  const submitform = (e) => {
    e.preventDefault();

    Swal.fire({
      title: "บันทึกเอกสาร",
      text: "คุณแน่ใจที่บันทึกเอกสาร ใช่หรือไม่ ! ",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ใช่",
      cancelButtonText: 'ยกเลิก'
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "บันทึกเอกสาร!",
          text: "คุณได้ทำบันทึกเอกสารเรียบแล้ว",
          icon: "success"
        });
        // loadingdata();
        recievedjournal();
      }

    });


  }

  // update  recieved 
  function recievedjournal() {

    var dataint = parseInt(datafetch[0].stateflow) + 1; //อัพเดท journal

    var statejournal = parseInt(datafetch[0].stateflow) //อัพเดท taskflow
    var dataobject = {
      journalid: id,
      emcode: emCode,
      stateflow: statejournal,
      approvecode: statusflowall.approvejournal,
      dedicatecode: "",
      tasknote: ""
    }
    setNewTask(dataobject)

    // // create task flow
    FetchApis.FethcPost(`/taskflow/createTaskflow`, dataobject).then((res) => {
      if (res.status == 200) {
        updatejournal(dataint);
      }
    })
  }

  // //update flowstatus journal
  function updatejournal(stateflows) {

    var updatejournal = {
      statusflow: statusflowall.sendjournal,
      stateflow: stateflows
    }

    setNewjournalUpdate(updatejournal);
    FetchApis.FethcUpdate(`/journal/updatestateflow/${id}`, updatejournal).then((res) => {
      if (res.status == 200) {

        toast.success('บันทึกเรียบร้อยแล้ว', {
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          // transition: Bounce,
        });

        setTimeout(() => {
          window.location.href = '/journallistbyapprove'
        }, 1700);

      }
    })

  }

  return (
    <div>
      {laodingdata ? <div className='body-center-page'><Spinner
        as="span"
        animation="grow"
        variant="success"
        size={200}
        role="status"
        aria-hidden="true" /> LOADIN ....
      </div> :
        <div className='form-body-page'>
          <form onSubmit={(e) => submitform(e)} >
            <div className='end-flex-row'>
              <Button type='submit' size='sm' variant="success" style={{ marginRight: 10 }}>อนุมัติ</Button>
              <Journaldedicated newTask={newTask} newjournalUpdate={newjournalUpdate} />
              <Journalrejectapprove newTask={newTask} newjournalUpdate={newjournalUpdate} />
              <Journalrecalltapprove newTask={newTask} newjournalUpdate={newjournalUpdate} />
            </div>
        <div>
          <ViewAllpartAB jourId={id} codeId={idcode}/>
        </div>
          </form>
        </div>}

    </div>
  )
}


const Journalapprove = () => {

  return (
    <div>
      <FormEditJournal />
    </div>
  )
}

export default Journalapprove