import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    dataext: [],
    dataint: [],
    dataspray: [],
    datacopper: [],
    datallacq:[],
    productitem:[]

}

export const productionSlice = createSlice({
    name: 'productionSlice',
    initialState,
    reducers: {
        additemExt: (state, action) => {
            state.dataext = action.payload;
        },
        additemInt: (state, action) => {
            state.dataint = action.payload;
        },
        additemSpray: (state, action) => {
            state.dataspray = action.payload;
        },
        additemCopper: (state, action) => {
            state.datacopper = action.payload;
        },
        additemLacq:(state, action)=>{
            state.datallacq=action.payload;
        },
        additemProductitem: (state, action) => {
            state.productitem = action.payload;
        },
    },
})

// Action creators are generated for each case reducer function
export const {additemExt,additemInt,additemSpray,additemCopper,additemProductitem,additemLacq} = productionSlice.actions

export default productionSlice.reducer