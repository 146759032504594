import React,{useState,useEffect} from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { MdPageview } from "react-icons/md";
import {host}from'../../customhooks/functionFetchApi';
import { CiImageOn } from "react-icons/ci";

function VeiwShowModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
          ตัวอย่าง PRODUCT
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {props.urlImages!=""?<img src={host+'/images/files/'+props.urlImages}/>:<CiImageOn size={50}/>}
          
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }


function ViewImages({urlImages}) {
   const [modalShow, setModalShow] = React.useState(false);

  return (
    <>
      {/* <Button variant="primary" onClick={() => setModalShow(true)} size='small'>
        ดูตัวอย่าง
      </Button> */}
      <div style={{marginLeft:10}}>
        <MdPageview onClick={() => setModalShow(true)} size={30}/>
      </div>

      <VeiwShowModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        
        urlImages={urlImages}
      />
    </>
  )
}

export default ViewImages