import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    flowsublist: [],
}

export const flowsystemsubSlice = createSlice({
    name: 'flowsystemsubSlice',
    initialState,
    reducers: {
        addflowsub: (state, action) => {
            state.flowsublist = action.payload;
            console.log(state.flowsublist);
        },
        updateflowsub:(state,action)=>{
                 console.log(action.payload)
            const item = state.flowsublist.find((item) => item.flowid == action.payload.flowid)
            if(item){
                item.approveby=action.payload.approveby;
                item.approvecode=action.payload.approvecode;
                item.emcode=action.payload.emcode;
                item.stateflow=action.payload.stateflow;
            }
        },
        deleteflowsub:(state,action)=>{
            // console.log(action.payload)
            state.flowsublist = state.flowsublist.filter((arrow) => arrow.flowid !== action.payload);
        }


    },
})

// Action creators are generated for each case reducer function
export const { addflowsub,updateflowsub,deleteflowsub } = flowsystemsubSlice.actions

export default flowsystemsubSlice.reducer