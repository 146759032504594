import React, { useCallback, useEffect, useState } from 'react';
import dataform from '../../../form01/json-data/datatypeform.json';
import { FaFileSignature } from "react-icons/fa";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { useForm } from "react-hook-form";
import FetchApi from '../../../customhooks/functionFetchApi';
import { opjectHeader } from '../../../form01/object-usestate/objectdefaults';
import { ToastContainer, toast } from 'react-toastify';



function FormEdit({ handleShow, ids }) {

  const FetchApis = new FetchApi();
  const [datafetch, setDatafetch] = useState({ ...opjectHeader });

  const [infortype, setInfortype] = useState([]); // check Information from customer
  const [infortypeError, setInfortypeError] = useState(false);//check  Information length over one item 

  const [printtype, setPrinttype] = useState([]); // check printting system type 
  const [printtypeError, setPrinttypeError] = useState(false); // check printting length over one item

  const [filmtypeall, setfilmtypeall] = useState([]);
  const [filmtypeError, setfilmtypeError] = useState(false);


  const [formlist, setFormlist] = useState([]);

  useEffect(() => {
    FetchApis.FethcGet(`/journal/getupdatejournalbyId/${ids.ids}`).then((res) => {
      if (res.status == 200) {

        setDatafetch(res.data.data[0]);
        if (res.data.data[0].informationtype != "") {
          setInfortype([...infortype, res.data.data[0].informationtype]);
        }

        if (res.data.data[0].printingtype != "") {
          setPrinttype([...printtype, res.data.data[0].printingtype]);
        }

        if (res.data.data[0].filmtype != "") {
          setfilmtypeall([...filmtypeall, res.data.data[0].filmtype]);
        }

      }
    })
    loadingformtype()
  }, [])

  const onSubmitHandler = (e) => {
    var id = datafetch.journalId;
    // e.preventDefault();

    if (infortype.length > 1) {
      setInfortypeError(true)
    }
    else {
      setInfortypeError(false)
    }


    if (printtype.length > 1) {
      setPrinttypeError(true)
    }
    else {
      setPrinttypeError(false)
    }


    if (filmtypeall.length > 1) {
      setfilmtypeError(true)
    } else {
      setfilmtypeError(false)
    }

    if (infortype.length <= 1 && printtype.length <= 1 && filmtypeall.length <= 1) {

      FetchApis.FethcUpdate(`/journal/journalupdate/${id}`, datafetch).then((result) => {
        if (result.status==200) {


          toast.success('บันทึกเรียบร้อยแล้ว', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            // transition: Bounce,
            });
          handerOnclick();

            setTimeout(() => {
              window.location.reload();
            }, 5000);

        

        }
      }).catch((err) => {
        console.log(err)
      });

    }

  }

  // load ข้อมูล หัวข้อฟอร์ม
  const loadingformtype = () => {

    FetchApis.FethcGet(`/formtype/formlistByCreateflow`).then((value) => {
      setFormlist(value.data.data)
      var defaulttype = datafetch.fcode;

    })

  }


  function handerOnclick() {
     handleShow(false)
  }

  // check information from customer 
  function handleChange(e) {

    const ischecked = e.target.checked;
    const istext = e.target.value;


    if (ischecked == true) {

      if (e.target.name == "informationtype") {
        setInfortype([...infortype, e.target.value]);
        setDatafetch({ ...datafetch, informationtype: e.target.value })
      }
      if (e.target.name == "printingtype") {
        setPrinttype([...printtype, e.target.value]);
        setDatafetch({ ...datafetch, printingtype: e.target.value })
      }
      if (e.target.name == "namefilmtype") {
        setfilmtypeall([...filmtypeall, e.target.value]);
        setDatafetch({ ...datafetch, filmtype: e.target.value });
      }

    }
    else if (ischecked == false) {

      if (e.target.name == "informationtype") {

        const index = infortype.indexOf(e.target.value);
        infortype.splice(index, 1);
        if (infortype[0] == undefined) {
          setDatafetch({ ...datafetch, informationtype: [] })
        }
        else {
          setDatafetch({ ...datafetch, informationtype: infortype[0] })
        }

      }

      if (e.target.name == "printingtype") {
        const index = printtype.indexOf(e.target.value);
        printtype.splice(index, 1);

        if (printtype[0] == undefined) {
          setDatafetch({ ...datafetch, printingtype: [] })
        } else {
          setDatafetch({ ...datafetch, printingtype: printtype[0] })
        }


      }

      if (e.target.name == "namefilmtype") {

        const index = filmtypeall.indexOf(e.target.value);
        filmtypeall.splice(index, 1);

        if (filmtypeall[0] == undefined) {
          setDatafetch({ ...datafetch, filmtype: [] });
        }
        else {
          setDatafetch({ ...datafetch, filmtype: filmtypeall[0] });
        }

      }

    }
  };


  return (
    <div className='form-body'>
      <form 
      // onSubmit={onSubmitHandler}
      >
        <div className='row-between-form'>
          <div style={{ width: '50%', padding: 5 }} className='row-between-form'>

            <div style={{ backgroundColor: '#F2EFE7', alignItems: 'center', textAlign: 'center', justifyContent: 'center', padding: 20 }}>
              <b>Marketing " A "</b>
            </div>

            <select name="journaltype" id="journaltype" onChange={(e) => setDatafetch({ ...datafetch, journaltype: e.target.value })} disabled>
              <option value={""}>เลือกประเภท product ...</option>
              {formlist.map((item, i) => (
                datafetch.fcode == item.formcode ? <option value={item.formcode} selected >{item.fname}</option> :
                  <option value={item.formcode}>{item.fname}</option>
              ))}
            </select>

          </div>

          <div style={{ width: '25%', padding: 5, flexDirection: 'column', alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
            <div style={{ marginBottom: 5 }}><b>Due Date:</b></div>
            <div>
              <input type='date' id='duedate' value={datafetch.duedate} onChange={(e) => setDatafetch({ ...datafetch, duedate: e.target.value })} required disabled/>
            </div>

          </div>
          <div style={{ width: '25%', padding: 5 }}>

            <div className='row-between-form'>
              <label>MK NO :</label> <input type='text' id='tdscode' value={datafetch.tdscode} disabled />
            </div>
            <div className='row-between-form'>
              <label>Date :</label><input type='date' id='createdate' value={datafetch.createdate} onChange={(e) => setDatafetch({ ...datafetch, createdate: e.target.value })} disabled required />
            </div>
          </div>

        </div>
        <hr></hr>
        <div className='row-between-form' style={{ padding: 10 }}>
          <div style={{ width: '50%' }}>
            <div>
              Customer name : <input type='text' id='customername' value={datafetch.customername}
                onChange={(e) => setDatafetch({ ...datafetch, customername: e.target.value })} style={{ width: '50%', margin: 1 }} required />
            </div>
            <div>
              Product name : <input type='text' id='productname' value={datafetch.productname} onChange={(e) => setDatafetch({ ...datafetch, productname: e.target.value })} style={{ width: '50%', margin: 1 }} required />
            </div>
            <div>Item name : <input type='text' id='itemname' value={datafetch.itemname} onChange={(e) => setDatafetch({ ...datafetch, itemname: e.target.value })} style={{ width: '50%', margin: 1 }} required /></div>
          </div>

          <div style={{ width: '50%' }}>
            <div>Contact : <input type='text' id='contact' value={datafetch.contact} onChange={(e) => setDatafetch({ ...datafetch, contact: e.target.value })} style={{ width: '50%', margin: 1 }} required />
              Tel : <input type='text' id='tel' value={datafetch.tel} onChange={(e) => setDatafetch({ ...datafetch, tel: e.target.value })} style={{ width: '30%', margin: 1 }} required />
            </div>
            <div>Can Size / Dia  : <input type='text' id='cansize' value={datafetch.cansize} onChange={(e) => setDatafetch({ ...datafetch, cansize: e.target.value })} style={{ width: '50%', margin: 1 }} required /></div>
            <div>Item no : <input type='text' id='itemcode' value={datafetch.itemcode} onChange={(e) => setDatafetch({ ...datafetch, itemcode: e.target.value })} style={{ width: '50%', margin: 1 }} required /></div>
          </div>
        </div>
        <hr></hr>

        <div className='row-between-form'>
          <div>
            <b>substrate</b>
            <div>

              {dataform.substrate.data.map((item, i) => (
                <div>
                  <input type='radio' id='substratetype'
                    onChange={(e) => setDatafetch({ ...datafetch, substratetype: e.target.value })} value={item}
                    checked={datafetch.substratetype == item ? true : false} disabled/> {item}
                </div>
              ))}
            </div>
          </div>

          <div>
            <b>Product group</b>
            <div>
              {dataform.productgroup.data.map((item, i) => (
                <div>
                  <input type='radio' id='productgrouptype'
                    onChange={(e) => setDatafetch({ ...datafetch, productgrouptype: item })} value={item}
                    checked={datafetch.productgrouptype == item ? true : false} disabled/> {item}
                </div>
              ))}
            </div>
          </div>
          <div>
            <b>Information from customer</b>
            <div>{dataform.information.data.map((item, i) => (
              <div>
                {datafetch.informationtype == item ? <div>
                  <input type='checkbox' id='informationtype' name='informationtype'
                    onClick={(e) => handleChange(e)} defaultChecked={true} disabled/> {item}
                </div> : <div>
                  <input type='checkbox' id='informationtype' name='informationtype'
                    onClick={(e) => handleChange(e)} value={item} disabled/> {item}
                </div>}

              </div>
            ))}
            </div>
            <div>
              <input type='text' id='informationnote' value={datafetch.informationnote}
                onChange={(e) => setDatafetch({ ...datafetch, datafetch: e.target.value })} style={{ width: 350 }} disabled/>
            </div>
            <div>{infortypeError == true ? <label style={{ color: 'red' }}> 'กรุณาเลือก 1 รายการเท่านั้น'</label> : ""}</div>
          </div>

          <div>
            <b>Printing system</b>
            <div>
              {dataform.printing.data.map((item, i) => (
                <div>
                  {
                    datafetch.printingtype == item ?
                      <div>
                        <input type='checkbox' id='printingtype' name='printingtype'
                          onClick={(e) => handleChange(e)} defaultChecked={true} value={item} disabled/> {item}
                      </div> :
                      <div>
                        <input type='checkbox' name='printingtype' onClick={(e) => handleChange(e)} value={item} disabled/> {item}
                      </div>
                  }
                </div>
              ))}
              {printtypeError == true ? <label style={{ color: 'red' }}>'กรุณาเลือก 1 รายการเท่านั้น printingtype'</label> : ""}
              {printtypeError}
            </div>
            <div>
              <b>last film code :</b><input type='text' id='filmcode'
                value={datafetch.filmcode} onChange={(e) => setDatafetch({ ...datafetch, filmcode: e.target.value })} disabled/>
            </div>

            <div>

              {datafetch.filmtype == "Scrap" ? <div>
                <input type='checkbox' id="namefilmtype" name='namefilmtype' value={"Scrap"}
                  onClick={(e) => handleChange(e)}
                  defaultChecked={true}
                  disabled
                /> Scrap </div> :
                <div>
                  <input type='checkbox' id="filmtype" name='namefilmtype' value={"Scrap"}
                    onClick={(e) => handleChange(e)}
                    disabled
                  /> Scrap </div>}

              {
                datafetch.filmtype == "Maintion" ? <div>
                  <input type='checkbox' id="namefilmtype" name='namefilmtype' value={"Maintion"}
                    onClick={(e) => handleChange(e)}

                    defaultChecked={true}
                    disabled
                  /> Maintion
                </div> :
                  <div>
                    <input type='checkbox' id="namefilmtype" name='namefilmtype' value={"Maintion"}
                      onClick={(e) => handleChange(e)}
                      disabled
                    /> Maintion
                  </div>
              }
              {filmtypeError == true ? <label style={{ color: 'red' }}>'กรุณาเลือก 1 รายการเท่านั้น '</label> : ""}
            </div>
          </div>
        </div>
        <hr></hr>

        <div style={{ width: '100%', justifyContent: 'flex-end', flexDirection: 'row', alignItems: 'flex-end', display: 'flex' }}>
          <div style={{ marginRight: 10 }}>
            <Button onClick={()=>onSubmitHandler()} size='sm' variant="primary" style={{ marginRight: 10 }} ><FaFileSignature /> แก้ไข</Button>
            <Button type='reset' size='sm' style={{ backgroundColor: '#F7B528', borderColor: '#F7B528', marginRight: 10 }} onClick={() => handerOnclick(false)}>ยกเลิก</Button>
          </div>

        </div>
      </form>
    </div>
  )
}

const EditformsHeaderjournal = (ids) => {

  const values = [true, 'sm-down', 'md-down', 'lg-down', 'xl-down', 'xxl-down'];
  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);


  function handleShow(value) {
    setShow(value);
  }

  return (
    <div>
      <FormEdit handleShow={handleShow} ids={ids} />
    </div>
  );

}

export default EditformsHeaderjournal