import React, { useState, useEffect, useRef } from 'react'
import PdfpartAsuccess from '../viewSuccessfull/pdfpartAsuccess'
import Showdetailjournal from '../../../form01/components/showdetailjournal';
import AllDetailpartb from '../../components/allDetailpartb';
import FetchApi from '../../../customhooks/functionFetchApi';
import Colordetaillist from '../../components/colordetaillist';
import ViewColorlist from '../../components/viewColorlist';
import ViewNewItem from '../../components/viewNewItem';
import generatePDF from 'react-to-pdf';
import { Button } from 'react-bootstrap';
import { FaFileDownload } from "react-icons/fa";

const objectPartB = {
    tecbid: "", sequence: "", bodyside: "", bodycolor: "", bodyfm: "", bodydfw: "",
    bodywfw: "", bodytemp: "", bodyspeed: "", bodyrubber: "", topside: "", topcolor: "",
    topfm: "", topdfw: "", topwfw: "", toptemp: "", topspeed: "", toprubber: "",
    bottomside: "", bottomcolor: "", bottomfm: "", bottomdfw: "", bottomwfw: "", bottomtemp: "", bottomspeed: "", bottomrubber: "",
    otherdetail: "",
    tdscode: ""
}

const PdfPartAll = ({ jourId, typejour }) => {
    const FetchApis = new FetchApi()
    const targetRef = useRef();

    const [coanting1st, setCoanting1st] = useState(objectPartB);
    const [coanting2nd, setCoanting2nd] = useState(objectPartB);
    const [coanting3rd, setCoanting3rd] = useState(objectPartB);
    const [coanting4th, setCoanting4th] = useState(objectPartB);
    const [coanting5th, setCoanting5th] = useState(objectPartB);
    const [coanting6th, setCoanting6th] = useState(objectPartB);
    const [coanting7th, setCoanting7th] = useState(objectPartB);
    const [checkdata, setCheckdata] = useState(false);
    const [checkProductcolor, setCheckProdcutcolor] = useState(false)
    const [checkItemNew, setCheckItemNew] = useState(false)

    useEffect(() => {
        checkTechnical()
        getcheckProductcolor()
        getcheckItem()
    }, [])

    function checkTechnical() {
        FetchApis.FethcGet(`/technical/techniaclByJournalId/${jourId}`).then((res) => {
            console.log(res.data.data)
            if (res.status == 200) {
                if (res.data.data.length != 0) {

                    Coatinglistbytype(res.data.data)
                    setCheckdata(true)
                }

            }
        })
    }

    function getcheckProductcolor() {
        FetchApis.FethcGet(`/productcolor/getproductByjournal/${jourId}`).then((res) => {
            if (res) {
                let colorallss = res.data.data;
                if (colorallss.length >= 1) {
                    setCheckProdcutcolor(true)
                }
            }
        })
    }

    function getcheckItem() {
        FetchApis.FethcGet(`/newitem/getnewitemByJournalId/${jourId}`).then((res) => {

            if (res.status == 200) {
                if (res.data.data.length > 0) {

                    setCheckItemNew(true)

                }
            }
        })
    }


    function Coatinglistbytype(datalist) {
        const coating1st = datalist.find((item) => item.sequence == '1st');
        setCoanting1st(coating1st);

        const coating2nd = datalist.find((item) => item.sequence == '2nd');
        setCoanting2nd(coating2nd);

        const coating3rd = datalist.find((item) => item.sequence == '3rd');
        setCoanting3rd(coating3rd);

        const coating4th = datalist.find((item) => item.sequence == '4th');
        setCoanting4th(coating4th);

        const coating5th = datalist.find((item) => item.sequence == '5th');
        setCoanting5th(coating5th);

        const coating6th = datalist.find((item) => item.sequence == '6th');
        setCoanting6th(coating6th);

        const coating7th = datalist.find((item) => item.sequence == '7th');
        setCoanting7th(coating7th);
    }

    return (
        <div style={{ width: '100%' }}>
            <Button onClick={() => generatePDF(targetRef, { filename: `${coanting1st.tdscode}_partA_page.pdf` })} size='sm' variant="light"><FaFileDownload /> Download PDF</Button>

            <div ref={targetRef}>
                <div style={{ height: 1800, padding: 10 }}>
                    <hr></hr>
                    <div className='end-flex-row'>
                        {typejour != 'draft' ? <img src={require('../../../images/approve_8622624.png')} style={{ width: 50, height: 'auto', borderRadius: 3 }} /> :
                            <img src={require('../../../images/draft-icon-27.jpg')} style={{ width: 50, height: 'auto', borderRadius: 3 }} />}
                    </div>
                    <Showdetailjournal id={jourId} />
                </div>
                {checkdata == true && <div style={{ height: 1800, padding: 10 }} >
                    <hr></hr>
                    <AllDetailpartb journalId={jourId} />
                </div>}
                {checkdata == true &&
                    <div style={{ height: 1800, padding: 10 }}>
                        <hr></hr>
                        <b style={{ fontSize: 16 }}>System Color </b>
                        <Colordetaillist tecbid={coanting1st.tecbid} typetecb={'1st'} />
                        <Colordetaillist tecbid={coanting2nd.tecbid} typetecb={'2nd'} />
                        <Colordetaillist tecbid={coanting3rd.tecbid} typetecb={'3rd'} />
                        <Colordetaillist tecbid={coanting4th.tecbid} typetecb={'4th'} />
                        <Colordetaillist tecbid={coanting5th.tecbid} typetecb={'5th'} />
                        <Colordetaillist tecbid={coanting6th.tecbid} typetecb={'6th'} />
                        <Colordetaillist tecbid={coanting7th.tecbid} typetecb={'7th'} />
                    </div>}
                {checkProductcolor == true && <div style={{ height: 1800, padding: 10 }}>
                    <hr></hr>
                    <ViewColorlist journalId={jourId} />
                </div >}

                {checkItemNew == true && <div style={{ height: 1800, padding: 10 }}>
                    <hr></hr>
                    <ViewNewItem jourId={jourId} />
                </div>}

            </div>

        </div>
    )
}

export default PdfPartAll