import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { FaArchive } from "react-icons/fa";

import FetchApi from '../../../customhooks/functionFetchApi';
import Modal from 'react-bootstrap/Modal';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { ToastContainer, toast } from 'react-toastify';
import Showdetailjournal from'../../../form01/components/showdetailjournal';
import { statusflowall
} from '../../../form01/object-usestate/objectdefaults';

const datasub = {
  spectype: "", sheetsize: "", flatscroll: "", straightscroll: "", 
  tin: "", temper: "", bright: "", row: "", filmcode: "", itemno: "", tdscode: ""
}



function LisetDetailJorunal({ handleShow, id }) {

  const FetchApis = new FetchApi(); //fatch data

  const emCode = sessionStorage.getItem('EmCode');

  const [key, setKey] = useState('jouranl');

  const [datafetch, setDatafetch] = useState([]);

  
  const[statusitemsample,setStatusitemsample]=useState("");


  // data update crate task
  const [newTask, setNewTask] = useState([]);

  //data update journal
  const [newjournalUpdate, setNewjournalUpdate] = useState([]);


  useEffect(() => {
    var codeid = "";
    var intdataconvert = 0;
    FetchApis.FethcGet(`/journal/journalbyId/${id}`).then((datafetch) => {
      if (datafetch.data.status == 200) {
        setDatafetch(datafetch.data.data)
        codeid = datafetch.data.data[0].tdscode;

        intdataconvert = datafetch.data.data[0].stateflow - 1;

        var dataobject = {
          journalid: id,
          emcode: emCode,
          stateflow: intdataconvert,
          approvecode: statusflowall.sendjournal,
          dedicatecode: ""
        }

        setNewTask(dataobject)

        var updatejournal = {
          statusflow: statusflowall.sendjournal,
          stateflow: intdataconvert
        }

        setNewjournalUpdate(updatejournal);

      }
    })



  }, [])


  // checked status sample item
  function CheckUpdatesampleItem(e){
          if(e.target.checked==true){
            setStatusitemsample(e.target.value);
          }
          else{
            setStatusitemsample("0");
          }

  }
  
  function submitUpdate(){
      const objecitemsample={
        "itemsample":statusitemsample
      }
    FetchApis.FethcUpdate(`/journal/updateitemsample/${id}`,objecitemsample).then((res)=>{
      if(res.status==200){
        
        toast.success('บันทึกเรียบร้อยแล้ว', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          // transition: Bounce,
          });

          costModel()

          setTimeout(() => {
            window.location.reload();
          },5000 );
         
      }
    })
  }

  function costModel(){
    handleShow(false);
  }


  return (<div>
    <div className='form-body-page'>
      <form >
        <div className='end-flex-row'>
          <div>
          <div>
              <Button  size='sm' variant="warning" style={{ marginRight: 10 }} onClick={()=>submitUpdate()}><FaArchive /> ส่งมอบตัวอย่าง</Button>
            </div>
            <div style={{marginTop:10}}>
              {datafetch.length > 0 &&<div>
                {
                  datafetch[0].itemsample=="1"?<div>
                    <input type='checkbox' name='itemsample' value={'1'} onChange={(e)=>CheckUpdatesampleItem(e)} defaultChecked={true}/> <label>ยืนยันส่งมอบตัวอย่าง เรียบร้อยแล้ว</label>
                    </div> :
                  <div>
                    <input type='checkbox' name='itemsample' value={'1'} onChange={(e)=>CheckUpdatesampleItem(e)}/> <label>ยืนยันส่งมอบตัวอย่าง เรียบร้อยแล้ว</label>
                    </div>
                }
                </div>}

            </div>
          </div>
          
        </div>
        <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k) => setKey(k)}
          className="mb-3"
        >
          <Tab eventKey="jouranl" title="Form ( F-MK-009A1-1 ) ตรวจสอบข้อมูลก่อนบันทึก" style={{ backgroundColor: 'aliceblue' }}>
                <Showdetailjournal id={id}/>
          </Tab>

        </Tabs>
      </form>
    </div>
  </div>)
}



const Actionexampleofwork = ({ id , actionState }) => {

  const [journallist, setJournallist] = useState([])

  const values = [true, 'sm-down', 'md-down', 'lg-down', 'xl-down', 'xxl-down'];
  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);

  function handleShow(values) {
    // setFullscreen(breakpoint);
    setShow(values);
  }

  return (
    <div>
      <Button size='sm' variant="warning" style={{ fontSize: 12 }} 
      onClick={() => handleShow(true)} disabled={actionState}><FaArchive /> ส่งมอบตัวอย่าง</Button>

      <Modal
        show={show}
        onHide={() => setShow(false)}
        // size="lg"
        fullscreen={fullscreen}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            รายละเอียดเอกสาร
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <ListStateByJournal id={id} /> */}
          <LisetDetailJorunal handleShow={handleShow} id={id} />
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default Actionexampleofwork