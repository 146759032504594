import DataTable from 'react-data-table-component';
import React from 'react'
import { FaList,FaFileSignature,FaArchive,FaExclamationTriangle,FaCheck,FaCheckCircle } from "react-icons/fa";
import Addprinting from './addprinting';

const columns = [
    {
        name: 'TDS code',
        sortable: true,
        selector: row => row.tdscode,
    },
    {
        name: 'Journal Type',
        selector: row => row.journaltype,
    },
    {
        name: 'customer name',
        selector: row => row.customername,
    },
    {
        name: 'job status',
        selector: row =><div>{row.statusprove_Tds==1&&<label style={{color:'#0e9a07'}}><FaCheckCircle /> proof job</label>}</div> ,
    },
    // {
    //     name: 'itemname',
    //     selector: row => row.itemname,
    // },
    // {
    //     name: 'item code',
    //     sortable: true,
    //     selector: row => row.itemcode,
    // },
    // {
    //     name: 'cansize',
    //     selector: row => row.cansize,
    // },
    // {
    //     name: 'duedate',x
    //     sortable: true,
    //     selector: row => row.duedate,
    // },
    {
        name: 'createdate',
        selector: row => row.createdate,
    },
    {
        name: 'Action',
         grow: 1.5,
        cell: row =><Addprinting codeId={row.tdscode} journalId={row.journalId} stateflow={row.stateflow}/>,
    },

];

const customStyles = {
    headRow: {
        style: {
            border: 'none',
            backgroundColor: '#28B463'
        },
    },
    headCells: {
        style: {
            color: '#ffff',
            fontSize: '14px',
        },
    },
    rows: {
        highlightOnHoverStyle: {
            backgroundColor: 'rgb(230, 244, 244)',
            borderBottomColor: '#FFFFFF',
            borderRadius: '25px',
            outline: '1px solid #FFFFFF',
        },
    },
    pagination: {
        style: {
            border: 'none',
        },
    },
};



const Journalprintingtable = (datatable) => {
    var dataresult = datatable.datatable;


    return (
    
        <DataTable
            columns={columns}
            data={dataresult}
            // selectableRows
            pagination
            customStyles={customStyles}
            highlightOnHover

        />
    )
}

export default Journalprintingtable