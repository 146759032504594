import React from 'react'
import { Container, Form, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
const Index = () => {
  return (
    <div style={{ backgroundColor: '#4A76C4', height: 750 }}>
      <div className='row-page'>

        <div style={{ width: '50%', height: 500 }} className='grad-login-form'>

          <div>
            <div style={{ fontSize: 25, color: '#ffff' }}>E-TDS</div>
            <div style={{ fontSize: 25, color: '#ffff' }}>Swan Business</div>
            <div style={{ fontSize: 18, color: '#D2D4D8' }}>Welcome to System E-TDS We are team of swan Industry thailand </div>
            <div style={{ fontSize: 18, color: '#D2D4D8' }}>Version .01 </div>

            <div style={{ marginTop: 30 }}>
              <a href='/login'><label style={{ backgroundColor: '#2F99C6', borderRadius: 20, padding: 10, color: '#ffff' }}>Get Started By Login </label></a>
            </div>
          </div>
        </div>

        <div style={{ width: '50%', marginTop: 20 }}>
          <img src={require('../pages/images/hero-img.png')} style={{ width: '100%', height: '100%' }} />
        </div>
      </div>
    </div>
  )
}

export default Index