import React, { useEffect, useState } from 'react'
import FetchApi from '../customhooks/functionFetchApi';
import Teblelistaccessuser from './component/teblelistaccessuser';

const Listaccessuser = () => {

    const FetchApis = new FetchApi();
    const [userAll, setUserAll] = useState([])

    useEffect(() => {
        loadingUser()
    }, [])

    function loadingUser() {
        FetchApis.FethcGet(`/account/accountall`).then((res) => {
            if (res.status == 200) {
                setUserAll(res.data.data)
            }

        })
    }

    return (
        <div>
            <Teblelistaccessuser  userAll={userAll}/>
        </div>
    )
}

export default Listaccessuser;