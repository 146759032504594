import DataTable from 'react-data-table-component';
import React from 'react'
import Actionflowsystem from './actionflowsystem';
import FetchApi from '../../customhooks/functionFetchApi';


const columns = [
    {
        name: 'flow name',
        sortable: true,
        selector: row => row.nameflow,
        grow: 2,
    },
    {
        name: 'flow code',
        selector: row => row.flowcode,
        grow: 1,
    },
    {
        name: 'form code',
        selector: row => row.formcode,
        grow: 0.5,
    },
    {
        name: 'form Name',
        //  grow: 2,
        cell: row =>row.fname,
        grow: 1.5,
    },
    {
        name: 'Action',
         grow: 2,
        cell: row =><Actionflowsystem Id={row.flowid} code={row.flowcode}/>,
    },

];

const customStyles = {
    headRow: {
        style: {
            border: 'none',
            backgroundColor: '#28B463'
        },
    },
    headCells: {
        style: {
            color: '#ffff',
            fontSize: '14px',
        },
    },
    rows: {
        highlightOnHoverStyle: {
            backgroundColor: 'rgb(230, 244, 244)',
            borderBottomColor: '#FFFFFF',
            borderRadius: '25px',
            outline: '1px solid #FFFFFF',
        },
    },
    pagination: {
        style: {
            border: 'none',
        },
    },
};



const Tableflowsystem = ({data}) => {
    var dataresult = data;
   
    return (
    
        <DataTable
            columns={columns}
            data={dataresult}
            // selectableRows
            pagination
            customStyles={customStyles}
            highlightOnHover

        />
    )
}

export default Tableflowsystem