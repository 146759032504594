import{configureStore}from'@reduxjs/toolkit';
import counterReducer from'../reduxSlice/counterSlice';
import coating1StSlice from '../reduxSlice/coatingpartbSlice/coating1StSlice';
import coating1NdSlice from '../reduxSlice/coatingpartbSlice/coating2NdSlice';
import coating3RdSlice from '../reduxSlice/coatingpartbSlice/coating3RdSlice';
import coating4ThSlice from '../reduxSlice/coatingpartbSlice/coating4ThSlice';
import coating5ThSlice from '../reduxSlice/coatingpartbSlice/coating5ThSlice';
import coating6ThSlice from '../reduxSlice/coatingpartbSlice/coating6ThSlice';
import coating7ThSlice from '../reduxSlice/coatingpartbSlice/coating7ThSlice';
import coatingDetailSlice from '../reduxSlice/coatingpartbSlice/coatingDetailSlice';
import flowsystemsubSlice from '../reduxSlice/flowsystemsubSlice/flowsystemsubSlice';
import newitemSlice from'../reduxSlice/newItemSlice/newitemSlice';
import productionSlice from '../reduxSlice/productionsystemSlice/productionSlice';
import itemcomponentSlice from '../reduxSlice/itemcomponentSlice/itemcomponentSlice';


export const store=configureStore({
    reducer:{
        counter:counterReducer,
        coating1st:coating1StSlice,
        coating2nd:coating1NdSlice,
        coating3rd:coating3RdSlice,
        coating4th:coating4ThSlice,
        coating5th:coating5ThSlice,
        coating6th:coating6ThSlice,
        coating7th:coating7ThSlice,
        coatingDetail:coatingDetailSlice,
        flowsysub:flowsystemsubSlice,
        newitems:newitemSlice,
        product:productionSlice,
        itemcomponent:itemcomponentSlice
    }
})
