import React, { useState, useEffect, useRef } from 'react'
// import Showdetailjournal from '../../../form01/components/showdetailjournal';
import AllDetailpartb from '../../components/allDetailpartb';
import generatePDF from 'react-to-pdf';
import { Button } from 'react-bootstrap';
import FetchApi from '../../../customhooks/functionFetchApi';
import { FaFileDownload } from "react-icons/fa";

const PdfpartB = ({ idjour }) => {
    const targetRef = useRef();
    const FetchApis = new FetchApi();

    function getjournalbyid() {
        FetchApis.FethcGet(`/journal/journalbyId/${idjour}`).then(res=>{
            if(res){
              let tdscodeid  =res.data.data[0].tdscode;

                generatePDF(targetRef, { filename: `${tdscodeid}_partB_page.pdf` })
            }
            
        })
    }

    return (
        <div>
            <Button onClick={() =>getjournalbyid()} size='sm' variant="light"><FaFileDownload /> Download PDF</Button>
            <div ref={targetRef}>
                <AllDetailpartb journalId={idjour} />
                <img src={require('../../../images/draft-icon-27.jpg')} style={{ width: 5, height: 'auto', borderRadius: 3 }} />
            </div>
        </div>
    )
}

export default PdfpartB