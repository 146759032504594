import React, { useState, useEffect } from 'react'
import { Button } from 'react-bootstrap'
import FetchApi from './customhooks/functionFetchApi'
import { FaUserLock } from "react-icons/fa6";
const Login = () => {
    const FetchApis = new FetchApi();
    const [isUser, setIsUser] = useState({ username: '', password: '' })

    const [data, setData] = useState("")

    useEffect(() => {
        sessionStorage.setItem("Islogin", false);
        sessionStorage.setItem("UserName", null);
        sessionStorage.setItem("Token", null);
        sessionStorage.setItem("EmCode", null)
        // window.location.reload();
        sessionStorage.clear();
    }, [])

    const loginguser = (e) => {
        e.preventDefault();
        FetchApis.FethcPost(`/account/login`, isUser).then((results) => {
            if (results == undefined) {
                alert('เกิดความผิดพลาด user and password invalid')
            }
            else if (results.status == 200) {

                if (results.data[0].isActive == "1") {
                    sessionStorage.setItem("UserName", results.data[0].name);
                    sessionStorage.setItem("Token", results.token);
                    sessionStorage.setItem("EmCode", results.data[0].employeeId)
                    sessionStorage.setItem("Islogin", true);
                    window.location.href = "/journalallstatus";
                }
                else {
                    alert('รหัสนี้ถูกปิดการใช้งานอยู่ กรุณาติดต่อเจ้าหน้าที่')
                }

            }
        })

    }

    return (
        <div className='form-body-login-page'>

            <form onSubmit={(e) => loginguser(e)}>
                <div >
                    <label style={{backgroundColor:'#2d57f5',padding:10,color:'#ffff'}}>E-TDS SYSTEM</label>
                </div>
                <div className='grad-login-form' style={{
                    width: 450, height: 300,
                    borderRadius: 10, backgroundColor: '#9AE3BD', padding: 20
                }}>
                    <div>
                        <div style={{ margin: 20,alignItems:'center',fontSize:18 }}><center> <FaUserLock size={30}/> <b style={{color:'#fa8b22'}}>L</b>OGIN  </center></div>
                        <div style={{ margin: 5 }} className='row-page'>
                            <div style={{ width: 100 }}>
                                <label>User Name : </label>
                            </div>
                            <div>
                                <input type='input' name='usernam' style={{ width: 200 }}
                                    onChange={(e) => setIsUser({ ...isUser, username: e.target.value })} />
                            </div>
                        </div>
                        <div style={{ margin: 5 }} className='row-page'>
                            <div style={{ width: 100 }}>
                                <label>PassWord :</label>
                            </div>
                            <div>
                                <input type='password' name='password' style={{ width: 200 }}
                                    onChange={(e) => setIsUser({ ...isUser, password: e.target.value })}
                                />
                            </div>
                        </div>
                    </div>

                    <center>
                        <div className='row-page' style={{ width: '100%', marginTop: 20 }}>
                            <div style={{ margin: 10 }}>
                                <Button type='submit' size='sm' variant="success">เข้าระบบ</Button>
                            </div>
                            <div style={{ margin: 10 }}>
                                <Button type='reset' size='sm' variant="warning">ยกเลิก</Button>
                            </div>
                        </div>
                    </center>
                </div>
            </form>

        </div>
    )
}

export default Login