import DataTable from 'react-data-table-component';
import React from 'react'
import UserEdit from './userEdit';
import Changepassword from './changepassword';


function IsActiveNow(isdata){
    if(isdata==1){
    return 'เปิดใช้งาน'
    }
    else{
        return 'ปิด'
    }
 
}

const columns = [
    {
        name: 'username',
        sortable: true,
        selector: row => row.username,
    },
    {
        name: 'employeeId',
        selector: row => row.employeeId,
    },
    {
        name: 'departmentId',
        selector: row => row.departmentId,
    },
    {
        name: 'roles',
        //  grow: 2,
        cell: row =>row.roles,
    },
    {
        name: 'isActive',
        //  grow: 2,
        cell: row =>IsActiveNow(row.isActive),
    },
    {
        name: 'Action',
        //  grow: 2,
        cell: row =><div className='row-page'>
        <div style={{marginRight:5}}><UserEdit id={row.aId}/></div>
        <div><Changepassword id={row.aId} username={row.username}/> </div>
        </div>,
    },

];

const customStyles = {
    headRow: {
        style: {
            border: 'none',
            backgroundColor: '#28B463'
        },
    },
    headCells: {
        style: {
            color: '#ffff',
            fontSize: '14px',
        },
    },
    rows: {
        highlightOnHoverStyle: {
            backgroundColor: 'rgb(230, 244, 244)',
            borderBottomColor: '#FFFFFF',
            borderRadius: '25px',
            outline: '1px solid #FFFFFF',
        },
    },
    pagination: {
        style: {
            border: 'none',
        },
    },
};



const Tablelistuserall = ({userAll}) => {
    var dataresult = userAll;
    return (
    
        <DataTable
            columns={columns}
            data={dataresult}
            // selectableRows
            pagination
            customStyles={customStyles}
            highlightOnHover

        />
    )
}

export default Tablelistuserall