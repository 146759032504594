import React, { useState, useEffect } from 'react'
import { useParams } from "react-router-dom";
import FetchApi from '../../../customhooks/functionFetchApi';

import EditformsHeader from '../../../form01/components/editformsHeader';
import Editjournals from '../../../form01/components/editjournals';
import { Button } from 'react-bootstrap';
import { statusflowall } from '../../../form01/object-usestate/objectdefaults'
import Swal from 'sweetalert2';

const Editjournalrecall = () => {
    let { id } = useParams();
    const FetchApis = new FetchApi();
    const [dataValuse, setDataValues] = useState("")
    const [taskdata, setTaskdata] = useState([])
    const Emcode = sessionStorage.getItem('EmCode');

    useEffect(() => {
        getdetailRecall()

    }, [])

    function getdetailRecall() {
        FetchApis.FethcGet(`/taskflow/getRecalljournalByid/${id}`).then((res) => {

            if (res.status == 200) {
                setTaskdata(res.data.data)
            }

        })
    }




    async function sendjournal() {

        if (dataValuse == "") {
            Swal.fire({
                icon: "warning",
                title: "แจ้งเตือน",
                text: "กรุณาป้อนรายละเอียดแก้ไข",
                // footer: '<a href="#">Why do I have this issue?</a>'
            });
        }
        else {

            Swal.fire({
                title: "แจ้งเตือน",
                text: "คุณแน่ใจที่ส่งข้อมูลเอกสาร ใช่หรือไม่ ! ",
                icon: "question",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "ใช่",
                cancelButtonText: 'ยกเลิก'
            }).then((result) => {

                if (result.isConfirmed) {
                    var stateflowall = {
                        statusflow: statusflowall.sendjournal,
                        stateflow: "1"
                    }

                    var taskeflows = {
                        journalid: id,
                        emcode: Emcode,
                        stateflow: "1",
                        approvecode: statusflowall.sendjournal,
                        dedicatecode: "",
                        tasknote: dataValuse
                    }

                    FetchApis.FethcPost(`/taskflow/createTaskflow`, taskeflows).then((res) => {
                        if (res) {
                            // update state flow  new journal 
                            FetchApis.FethcUpdate(`/journal/updatestateflow/${id}`, stateflowall).then((res) => {
                                if (res) {
                                    window.location.href='/journalrecallstatus'
                                }
                            })
                        }
                    })

                }

            });
        }

    }

    return (
        <div className='row-page' style={{ width: '100%', padding: 10 }}>

            <div style={{ width: '50%', marginRight: 10 }} className='border-green-page'>
                <label>รายละเอียดการแจ้ง</label>
                <hr></hr>
                <div>
                    {/* {JSON.stringify(taskdata)} */}
                    {taskdata.map((item, i) => (
                        <div>
                            <label>ลำดับที่ ( {i + 1} )</label>
                            <div>
                                <label>ผู้แจ้ง: {item.userapprove}</label>
                            </div>

                            <div style={{ margin: 10 }}>
                                <label>รายละเอียด</label>
                                <div style={{ width: '100%', backgroundColor: '#eeeded', padding: 5, borderRadius: 10, fontSize: 14 }}>
                                    {item.tasknote}
                                </div>
                            </div>
                            <div>
                                <label style={{ fontSize: 12 }}>เวลาที่แจ้ง : {item.datetime}</label>
                            </div>

                        </div>
                    ))}
                </div>
            </div>

            <div style={{ width: '50%' }}>
                <div>
                    <label>ระบบรายละเอียดแก้ไขงาน</label>
                    <textarea value={dataValuse} onChange={(e) => setDataValues(e.target.value)} style={{ width: '100%', height: 150 }} />
                </div>

                <div className='row-page'>
                    <div>
                        <Button size="sm" onClick={() => sendjournal()}>
                            บันทึกและส่งข้อมูล
                        </Button>
                    </div>

                    <div style={{ marginLeft: 3 }}>
                        <EditformsHeader ids={id} />
                    </div>
                    <div style={{ marginLeft: 3 }}>
                        <Editjournals id={id} />
                    </div>

                </div>
            </div>


        </div>
    )
}

export default Editjournalrecall