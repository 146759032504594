import React, { useState, useEffect } from 'react'
import { Button } from 'react-bootstrap'
import FetchApi from '../../../customhooks/functionFetchApi';
import PopUpitemgroup from './popupitemgroup';
import PopupPackinggroup from './popupPackinggroup';
import Table from 'react-bootstrap/Table';
import Swal from 'sweetalert2';
import { UpdateItem } from './service/servicecreateItem';

const EditNewitemToerp = ({ idjournal }) => {

    const draftFG_Object = {
        newItemId: "",
        typeItem: "FG",
        itemegroup: "",
        itemId: "",
        itemname: "",
        dimensiongroup: "SWLBS2",
        packinggroup: "",
        inventtoryUnit: "PCS",
        saleUnit: "PCS",
        purchaseUnit: "PCS",
        raf_journalname: "",
        yymm: "1",
        swanjob: "0",
        qtypertag: "",
        jobstatus: "1",
        tdscode: "",
        journalId: ""
    }

    const draftW_Object = {
        newItemId: "",
        typeItem: "",
        itemegroup: "",
        itemId: "",
        itemname: "",
        dimensiongroup: "SWBS2",
        packinggroup: "",
        inventtoryUnit: "Sheet",
        saleUnit: "Sheet",
        purchaseUnit: "Sheet",
        raf_journalname: "PD-RF1",
        yymm: "0",
        swanjob: "1",
        qtypertag: "1500",
        jobstatus: "",
        tdscode: "",
        journalId: ""
    }

    const draftCE_Object = {
        newItemId: "",
        typeItem: "",
        itemegroup: "",
        itemId: "",
        itemname: "",
        dimensiongroup: "SWLBS2",
        packinggroup: "",
        inventtoryUnit: "PCS",
        saleUnit: "PCS",
        purchaseUnit: "PCS",
        raf_journalname: "",
        yymm: "0",
        swanjob: "1",
        qtypertag: "1500",
        jobstatus: "",
        tdscode: "",
        journalId: ""
    }

    const FetchApis = new FetchApi();

    const [DraftFG, setDraftFG] = useState({ ...draftFG_Object });
    const [DraftW, setDraftW] = useState(null);
    const [DraftCE, setDraftCE] = useState(null);
    const [itemproductall, setItemproductall] = useState([])



    const [groupitem, setGroupitem] = useState([]); //group item 
    const [packinggrouplist, setPackinggrouplist] = useState([]); //group item 
    const [typeitem, setTypeitem] = useState('WP');
    const [itemCreateStatus, setItemCreateStatus] = useState(false);
    const [producttype, setProducttype] = useState("body");
    const [newitemall, setNewitemall] = useState({ proBody: [], proTop: [], proBottom: [], proTab: [] });


    useEffect(() => {

        CheckItemCreate()

    }, [])


    function CheckItemCreate() {

        FetchApis.FethcGet(`/newitem/getnewitemByJournalId/${idjournal}`).then((res) => {
                console.log(res.data.data)
            if (res.status == 200) {
                if (res.data.data.length > 0) {
                    var itemlist = res.data.data;
                    console.log(itemlist);

                    setItemproductall(itemlist);
                } else {
                    setItemCreateStatus(false)
                }
            }
        })
    }

    function getgroupitem(values) {
        setGroupitem(values);

        setDraftFG({ ...DraftFG, itemegroup: values })
        setDraftW({ ...DraftW, itemegroup: values })
    }

    function getPackinggroup(values) {
        setPackinggrouplist(values);
        setDraftFG({ ...DraftFG, packinggroup: values })
        setDraftW({ ...DraftW, packinggroup: values })
    }


    //เอาไว้กำหนด type item create
    const onhendleTypeItem = (e) => {
        var values = e.target.value;
        setTypeitem(values)

        setDraftW({ ...DraftW, typeItem: values })

    }

    const onChangInventUnit = (e) => {
        var values = e.target.value;
        setDraftFG({ ...DraftFG, inventtoryUnit: values })
        setDraftW({ ...DraftW, inventtoryUnit: values })

    }

    const onChangSaleUnit = (e) => {
        var values = e.target.value;
        setDraftFG({ ...DraftFG, saleUnit: values })
        setDraftW({ ...DraftW, saleUnit: values })

    }

    const onChangPurchaseUnit = (e) => {
        var values = e.target.value;
        setDraftFG({ ...DraftFG, purchaseUnit: values })

    }

    const CheckItemConfirm = (e) => {
        var values = e.target.checked;

        if (values == true) {
            setDraftFG({ ...DraftFG, jobstatus: e.target.value })
            setDraftW({ ...DraftW, jobstatus: e.target.value })

        } else {
            setDraftFG({ ...DraftFG, jobstatus: '' })
            setDraftW({ ...DraftW, jobstatus: '' })
        }
    }


    const savedraftNewitem = () => {

        if (DraftFG.itemegroup == "" || DraftFG.itemId == "" || DraftFG.itemname == ""
            || DraftFG.packinggroup == "" || DraftFG.jobstatus == "", DraftFG.raf_journalname == "") {

            Swal.fire({
                title: "แจ้งเตือน",
                text: "ไม่สามารถบันทึกได้กรุณาตรวจสอบข้อมูลก่อนบันทึก",
                icon: "question"
            });
        } else {

            Swal.fire({
                title: "บันทึกเอกสาร",
                text: "คุณแน่ใจที่บันทึกเอกสาร ใช่หรือไม่ ! ",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "ใช่",
                cancelButtonText: 'ยกเลิก'
            }).then((result) => {
                if (result.isConfirmed) {

                    //  UpdateItem(DraftFG, DraftW);
                    // console.log(DraftFG)
                    UpdateItem(DraftFG)
                    if (DraftW != null) {
                        //  console.log(DraftW)
                        let data_objectW = {
                            bodytype: DraftW.bodytype,
                            typeItem: DraftW.typeItem,
                            itemegroup: DraftFG.itemegroup,
                            itemId: DraftW.typeItem + "" + DraftFG.itemId.substring(2),
                            itemname: DraftFG.itemname,
                            dimensiongroup: "SWBS2",
                            packinggroup: DraftFG.packinggroup,
                            inventtoryUnit: 'Sheet',
                            saleUnit: "Sheet",
                            purchaseUnit: "Sheet",
                            raf_journalname: "PD-RF1",
                            yymm: "0",
                            swanjob: "1",
                            qtypertag: "1500",
                            jobstatus: DraftFG.jobstatus,
                            tdscode: DraftW.tdscode,
                            journalId: DraftW.journalId,
                            newItemId: DraftW.newItemId,
                        }
                        UpdateItem(data_objectW)
                        //  console.log(data_objectW)

                    }

                    if (DraftCE != null) {

                        let data_objectCE = {
                            bodytype: DraftCE.bodytype,
                            typeItem: DraftCE.typeItem,
                            itemegroup: DraftFG.itemegroup,
                            itemId: DraftCE.typeItem + DraftFG.itemId.substring(2),
                            itemname: DraftFG.itemname,
                            dimensiongroup: "SWBS2",
                            packinggroup: DraftFG.packinggroup,
                            inventtoryUnit: 'PCS',
                            saleUnit: "PCS",
                            purchaseUnit: "PCS",
                            raf_journalname: "PD-RF1",
                            yymm: "0",
                            swanjob: "1",
                            qtypertag: "1500",
                            jobstatus: DraftFG.jobstatus,
                            tdscode: DraftCE.tdscode,
                            journalId: DraftCE.journalId,
                            newItemId: DraftCE.newItemId,
                        }
                        // console.log(data_objectCE)
                        UpdateItem(data_objectCE)
                    }

                    setTimeout(() => {
                        Swal.fire({
                            title: "เสร็จเรียบแล้ว",
                            text: "คุณได้ทำการ Update ข้อมูลเรียบร้อยแล้ว",
                            icon: "success"
                        });

                        CheckItemCreate();

                    }, 1700);



                }
            })
        }
    }


    const onhendleProductType = (e) => {
        var values = e.target.checked;
        if (values) {
            // setProducttype(e.target.value)
            // setDraftFG({ ...DraftFG, bodytype: e.target.value })
            var dataitemFG = itemproductall.find((item) => item.bodytype == e.target.value && item.typeItem == 'FG')
            var dataitemWC = itemproductall.find((item) => item.bodytype == e.target.value && item.typeItem == 'WC')
            var dataitemWP = itemproductall.find((item) => item.bodytype == e.target.value && item.typeItem == 'WP')
            var dataitemCE = itemproductall.find((item) => item.bodytype == e.target.value && item.typeItem == 'CE')

            if (dataitemFG != undefined) {
                setDraftFG(dataitemFG)
            }
            else {
                setDraftFG(draftFG_Object)
            }

            if (dataitemWC != undefined) {
                setDraftW(dataitemWP)
            } else {
                setDraftW(undefined)
            }

            if (dataitemWP != undefined) {
                setDraftW(dataitemWP)
            } else {
                setDraftW(undefined)
            }

            if (dataitemCE != undefined) {

                setDraftCE(dataitemCE)
            } else {
                setDraftCE(undefined)
            }

        }
        else {
            console.log(e.target.checked)
        }
    }


    // function cencel ข้อมูล
    const canceldraftItem = () => {
        setDraftW({ ...DraftW, jobstatus: '0' })
        setDraftW({ ...DraftFG, jobstatus: '0' })
    }

    




    return (
        <div>
            <div>
                <form>
                    <div style={{ backgroundColor: '#E1DDDC', padding: 10 }}>
                        <center>
                            <label style={{ fontSize: 20 }}><b>
                                Draft New Item To ERP
                            </b>
                            </label>
                        </center>
                    </div>
                    <div className='row-page' style={{ width: '100%', marginTop: 20, padding: 10 }}>
                        <div style={{ width: '50%' }}>
                            <div className='row-page'>
                                <div style={{ marginRight: 20, width: 200 }}>
                                    <label style={{ fontSize: 15 }}>Item group </label>
                                </div>
                                <div className='row-page'>
                                    <input type='text' name='itemgroup'
                                        value={DraftFG.itemegroup}
                                        style={{ marginRight: 10, height: 25, width: 200 }}
                                        disabled /> <PopUpitemgroup getgroupitem={getgroupitem} />
                                </div>
                            </div>

                            <div className='row-page' style={{ marginTop: 5 }}>
                                <div style={{ marginRight: 20, width: 200 }}>
                                    <label style={{ fontSize: 15 }}>Item ID </label>
                                </div>
                                <div>
                                    <input type='text' name='itemId' value={DraftFG.itemId}
                                        onChange={(e) => setDraftFG({ ...DraftFG, itemId: e.target.value })}
                                        style={{ width: 250, height: 25 }} required />
                                </div>
                            </div>
                            <div className='row-page' style={{ marginTop: 5 }}>
                                <div style={{ marginRight: 20, width: 200 }}>
                                    <label style={{ fontSize: 15 }}>Item Name </label>
                                </div>
                                <div>
                                    <input type='text' name='itemName' value={DraftFG.itemname} onChange={(e)=>setDraftFG({...DraftFG,itemname:e.target.value})}
                                        style={{ width: 250, height: 25 }} required />
                                </div>
                            </div>
                            <div className='row-page' style={{ marginTop: 5 }}>
                                <div style={{ marginRight: 20, width: 200 }}>
                                    <label style={{ fontSize: 15 }}>Packing Group </label>
                                </div>
                                <div className='row-page'>
                                    <input type='text' name='PackingGroup'
                                        style={{ marginRight: 10, height: 25, width: 200 }}
                                        value={DraftFG.packinggroup}
                                        disabled /> <PopupPackinggroup getPackinggroup={getPackinggroup} />
                                </div>
                            </div>

                            <div className='row-page' style={{ marginTop: 5 }}>
                                <div style={{ marginRight: 20, width: 200 }}>
                                    <label style={{ fontSize: 15 }}>RAF Journal Name</label>
                                </div>
                                <div>
                                    <select name="itemgroup" id="raf_journalname"
                                        onChange={(e) => setDraftFG({ ...DraftFG, raf_journalname: e.target.value })}
                                        style={{ width: 200, height: 25 }} required>
                                        <option value={DraftFG.raf_journalname} selected>{DraftFG.raf_journalname}</option>
                                        <option value="PD-RF2">PD-RF2</option>
                                        <option value="PD-RF3">PD-RF3</option>
                                        <option value="PD-RF4">PD-RF4</option>
                                        <option value="PD-RF5">PD-RF5</option>
                                        <option value="PD-RF6">PD-RF6</option>
                                    </select>
                                </div>
                            </div>

                            <div className='row-page' style={{ marginTop: 5 }}>
                                <div style={{ marginRight: 20, width: 200 }}>
                                    <label style={{ fontSize: 15 }}>Serail Number Qty / Tag </label>
                                </div>
                                <div>
                                    <input type='number' name='itemName' value={DraftFG.qtypertag}
                                        onChange={(e) => setDraftFG({ ...DraftFG, qtypertag: e.target.value })}
                                        style={{ width: 250, height: 25 }} 
                                        required />
                                </div>
                            </div>

                            <div className='row-page' style={{ marginTop: 5 }}>
                                <div style={{ marginRight: 20, width: 200 }}>
                                    <label style={{ fontSize: 15 }}>Type WP / WC </label>
                                </div>
                                <div className='row-page' >
                                    <div style={{ marginLeft: 5, marginRight: 15 }}>
                                        <input type='radio' name='TypeItem' value={'WP'} onChange={(e) => onhendleTypeItem(e)} defaultValue={'WP'} defaultChecked /> <label>WP</label>
                                    </div>
                                    <div style={{ marginLeft: 5, marginRight: 10 }}>
                                        <input type='radio' name='TypeItem' value={'WC'} onChange={(e) => onhendleTypeItem(e)} /> <label>WC</label>
                                    </div>
                                </div>
                            </div>

                            <div className='row-page' style={{ marginTop: 5 }}>
                                <div style={{ marginRight: 20, width: 200 }}>
                                    <label style={{ fontSize: 15 }}>Product Type</label>
                                </div>
                                <div className='row-page' >
                                    <div style={{ marginLeft: 5, marginRight: 15 }}>
                                        <input type='radio' name='ProTypeItem' value={'body'} onChange={(e) => onhendleProductType(e)} /> <label>Body</label>
                                    </div>
                                    <div style={{ marginLeft: 5, marginRight: 10 }}>
                                        <input type='radio' name='ProTypeItem' value={'top'} onChange={(e) => onhendleProductType(e)} /> <label>Top</label>
                                    </div>
                                    <div style={{ marginLeft: 5, marginRight: 10 }}>
                                        <input type='radio' name='ProTypeItem' value={'bottom'} onChange={(e) => onhendleProductType(e)} /> <label>Bottom</label>
                                    </div>
                                    <div style={{ marginLeft: 5, marginRight: 10 }}>
                                        <input type='radio' name='ProTypeItem' value={'tab'} onChange={(e) => onhendleProductType(e)} /> <label>Tab</label>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div style={{ width: '50%' }}>

                            <div className='row-page' style={{ marginTop: 5 }}>
                                <div style={{ marginRight: 20, width: 200 }}>
                                    <label style={{ fontSize: 15 }}>Inventory Unit </label>
                                </div>
                                <div className='row-page' >
                                    <div style={{ marginLeft: 5, marginRight: 15 }}>
                                        <input type='checkbox' name='InventoryUnit' value={'PCS'}
                                            onChange={(e) => onChangInventUnit(e)} defaultValue={'PCS'}
                                            style={{ width: 18, height: 18 }}
                                            defaultChecked disabled /> <label>PCS</label>
                                    </div>
                                </div>
                            </div>
                            <div className='row-page' style={{ marginTop: 5 }}>
                                <div style={{ marginRight: 20, width: 200 }}>
                                    <label style={{ fontSize: 15 }}>Purchase Unit </label>
                                </div>
                                <div className='row-page' >
                                    <div style={{ marginLeft: 5, marginRight: 15 }}>
                                        <input type='checkbox' name='PurchaseUnit'
                                            style={{ width: 18, height: 18 }}
                                            value={'PCS'} onChange={(e) => onChangPurchaseUnit(e)}
                                            defaultValue={'PCS'} defaultChecked disabled /> <label>PCS</label>
                                    </div>

                                </div>
                            </div>
                            <div className='row-page' style={{ marginTop: 5 }}>
                                <div style={{ marginRight: 20, width: 200 }}>
                                    <label style={{ fontSize: 15 }}>Sale Unit </label>
                                </div>
                                <div className='row-page' >
                                    <div style={{ marginLeft: 5, marginRight: 15 }}>
                                        <input type='checkbox' name='SaleUnit' value={'PCS'}
                                            onChange={(e) => onChangSaleUnit(e)}
                                            style={{ width: 18, height: 18 }}
                                            defaultValue={'PCS'} defaultChecked disabled /> <label>PCS</label>
                                    </div>

                                </div>
                            </div>

                            <div style={{ marginRight: 20, width: 200, marginTop: 10 }}>
                                <label style={{ fontSize: 15, marginRight: 10 }}>ยืนยันการสร้าง </label>
                                <input type='checkbox' name='SaleUnit' value={'1'} onChange={(e) => CheckItemConfirm(e)} defaultValue={'1'} defaultChecked />
                            </div>

                        </div>
                    </div>
                    <hr></hr>


                    <div className='row-page' style={{ alignContent: 'center', flexDirection: 'row', justifyContent: 'center' }}>
                        <div>
                            <Button onClick={() => savedraftNewitem()} style={{ marginRight: 10 }} size='sm'> แก้ไข เอกสาร</Button>
                        </div>
                        <div>
                            <Button type='reset' variant="warning" size='sm' onClick={() => canceldraftItem()}>ยกเลิก</Button>
                        </div>

                    </div>

                </form>
                <div style={{ marginTop: 5 }}>

                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>bodytype</th>
                                <th>typeItem</th>
                                <th>Item Group</th>
                                <th>Item</th>
                                <th>Item Name</th>
                                <th>Dimension Group</th>
                                <th>Packing Group</th>
                                <th>RAF Jouranl</th>
                                <th>Serail Number Qty / Tag</th>
                                <th>Inventory Unit</th>
                                <th>Purchase Unit</th>
                                <th>Sale Unit</th>

                            </tr>
                        </thead>
                        <tbody>
                            {itemproductall.map((item, i) => (
                                <tr>
                                    <td>{i + 1}</td>
                                    <td>{item.bodytype}</td>
                                    <td>{item.typeItem}</td>
                                    <td>{item.itemegroup}</td>
                                    <td>{item.itemId}</td>
                                    <td>{item.itemname}</td>
                                    <td>{item.dimensiongroup}</td>
                                    <td>{item.packinggroup}</td>
                                    <td>{item.raf_journalname}</td>
                                    <td>{item.qtypertag}</td>
                                    <td>{item.inventtoryUnit}</td>
                                    <td>{item.purchaseUnit}</td>
                                    <td>{item.saleUnit}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>

            </div>


            <div>

            </div>
        </div>
    )
}

export default EditNewitemToerp