import React, { useState, useEffect } from 'react'
import { Button } from 'react-bootstrap'
import Modal from 'react-bootstrap/Modal';
import FetchApi from '../../customhooks/functionFetchApi';

function UserEditform({ id }) {
    const FetchApis = new FetchApi();


    const [departmentlist, setDepartmentlist] = useState([]);// department
    const [positionall, setPositionall] = useState([]);//position
    const [listmenuAll, setLIstmenuAll] = useState([]); // get menu list all

    const [formData, setFormData] = useState([]);

    useEffect(() => {
        departmentgroup();
        positiongroup();
        getUserDetail();
        getmenulistall();

    }, [])


    const departmentgroup = () => {
        FetchApis.FethcGet(`/department/getdepartmentall`).then((res) => {
            if (res.status == 200) {
                setDepartmentlist(res.data.data)
            }
        })
    }

    const positiongroup = () => {

        FetchApis.FethcGet(`/emposition/getempositionall`).then((res) => {

            if (res.status == 200) {
                setPositionall(res.data.data)
            }
        })
    }

    const getUserDetail = () => {
        FetchApis.FethcGet(`/account/accountById/${id}`).then((res) => {
            if (res.status == 200) {
               setFormData(res.data.data[0])
            }
        })
    }

    const getmenulistall = () => {

        FetchApis.FethcGet(`/groupmenu/getgroupmenulistall`).then((res) => {

            if (res.status == 200) {
                setLIstmenuAll(res.data.data)
            }
        })
    }


    function handleChange(e) {

        const { name, value } = e.target;
        if (e.target.checked) {
            // const item = formData.find((item) => item.isActive == name);
            // item.active = value
            setFormData({...formData,isActive:'1'})
        }
        else {
            setFormData({...formData,isActive:'0'})
            // const item = formData.find((item) => item.isActive == name);
            // item.active = "0"
        }
    }

    


    const OnSubmitform = (e) => {

        e.preventDefault();
        // console.log(formData)
        FetchApis.FethcUpdate(`/account/updateaccount/${id}`, formData).then((res) => {
            if (res.status == 200) {
                alert('ทำการสร้างเรียบแล้ว')
            }
        })
    }


    return (
        <div>
            <div className='form-body'>
                <form onSubmit={(e) => OnSubmitform(e)}>
                    <div>
                        <center><label style={{ fontSize: 18 }}>แก้ไขผู้ใช้งานระบบ</label></center>
                    </div>

                    <div style={{ width: '95%', padding: 10, margin: 5 }}>
                        <div className='row-between-page'>
                            <div className='row-page'>
                                <div>
                                    <label style={{ marginRight: 10 }}>Username :</label>
                                    <input type='text' name='username'
                                    value={formData.username}
                                    style={{ marginRight: 10 }} onChange={(e) => setFormData({...formData,username:e.target.value})} disabled />
                                </div>
                                <div>
                                    <label style={{ marginRight: 10 }}>Password :</label>
                                    <input type='text' name='password'
                                    value={'xxxx'}
                                    style={{ marginRight: 10 }} onChange={(e) => setFormData({...formData,password:e.target.value})}  disabled />
                                </div>
                                <div>
                                    <label style={{ marginRight: 10 }}>Rolse :</label>
                                    <select style={{ marginRight: 10, width: 200 }} name='roles'  
                                    onChange={(e) => setFormData({...formData,roles:e.target.value})}   
                                    required>
                                        <option value={formData.roles}>{formData.roles=='user'?'ผู้ใช้ทั่วไป':'ผู้ดูแลระบบ'}</option>
                                        <option value={'user'}>ผู้ใช้ทั่วไป</option>
                                        <option value={'admin'}>ผู้ดูแลระบบ</option>
                                    </select>
                                </div>
                                <div style={{ marginLeft: 10 }}>
                                        <label style={{ marginRight: 10 }}>กลุ่มผุ้ใช้งาน : </label>

                                        <select style={{ marginRight: 10, width: 200 }} name='menugroupId' 
                                        onChange={(e) => setFormData({...formData,menugroupId:e.target.value})}
                                        >
                                            <option value={formData.menugroupId}>{formData.namegroup}</option>
                                            {listmenuAll.map((item, i) => (
                                                <option value={item.menugroupId}>{item.namegroup}</option>
                                            ))}

                                        </select>
                                    </div>
                            </div>
                            <div>
                                <Button type='submit' size='sm' variant="success">แก้ไขข้อมูล</Button>{" "}
                                <Button size='sm' variant="warning">ยกเลิก</Button>
                            </div>
                        </div>

                        <hr />

                        <div style={{ margin: 10 }}>
                            <label>ชื่อผู้ใช้งาน : </label>
                            <div style={{ marginLeft: 10 }}><input type='text' name='name' style={{ width: 200 }}
                            value={formData.name}
                             onChange={(e) => setFormData({...formData,name:e.target.value})}    required /></div>
                        </div>

                        <div style={{ margin: 10 }}>
                            <label>รหัสพันักงาน : </label>
                            <div style={{ marginLeft: 10 }}><input type='text' name='employeeId'
                            value={formData.employeeId}
                            onChange={(e) => setFormData({...formData,employeeId:e.target.value})} 
                            style={{ width: 200 }}  required /></div>
                        </div>

                        <div style={{ margin: 10 }}>
                            <label>ตำแหน่งงาน : </label>
                            <div style={{ marginLeft: 10 }}>

                                <select style={{ width: 150 }} name='positioncode' onChange={(e) => setFormData({...formData,positioncode:e.target.value})}  required>
                                    <option value={formData.positioncode}>{formData.nameposition}</option>
                                    {positionall.map((item, i) => (
                                        <option value={item.postioncode}>{item.nameposition}</option>
                                    ))}
                                </select>
                            </div>
                        </div>

                        <div style={{ margin: 10 }}>
                            <label> แผนก :</label>
                            <div style={{ marginLeft: 10 }}>
                                <select style={{ width: 150 }} name='departmentId'  onChange={(e) => setFormData({...formData,departmentId:e.target.value})}  required>
                                    <option  value={formData.departmentId}>{formData.departmentname}</option>
                                    {departmentlist.map((item, i) => (
                                        <option value={item.departmentcode}>{item.departmentname}</option>
                                    ))}
                                </select>
                            </div>
                        </div>

                        <div style={{ margin: 10 }}>
                            <label>email : </label>
                            <div style={{ marginLeft: 10 }}>
                                <input type='text' name='email'
                                value={formData.email}
                                style={{ width: 300 }}  onChange={(e) => setFormData({...formData,email:e.target.value})}  />
                            </div>
                        </div>

                        <div style={{ margin: 10 }} className='row-page'>
                            <label>เปิดใช้ : </label>
                            <div style={{ marginLeft: 10 }}>
                                
                                <input 
                                type='radio' 
                                name='isActive' value={'1'}
                                onChange={(e)=>setFormData({...formData,isActive:e.target.value})}
                                    checked={formData.isActive==1&&true}
                                 />

                                <label style={{marginLeft:20}}> ปิด : </label>
                                <input 
                                type='radio' 
                                name='isActive' value={'0'}
                                onChange={(e)=>setFormData({...formData,isActive:e.target.value})}
                                checked={formData.isActive==0&&true}
                                style={{ marginLeft: 10 }}
                                 />
                            </div>
                        </div>
                    </div>
                </form>

            </div>
        </div>
    )
}


const UserEdit = ({ id }) => {
  
    const values = [true, 'sm-down', 'md-down', 'lg-down', 'xl-down', 'xxl-down'];
    const [fullscreen, setFullscreen] = useState(true);
    const [show, setShow] = useState(false);

    function handleShow(value) {
        // setFullscreen(breakpoint);
        setShow(value);
    }

    return (
        <div>
            <Button size='sm' variant="primary" onClick={() => handleShow(true)}>แก้ไขข้อมูล</Button>

            <Modal show={show} fullscreen={fullscreen} onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>แก้ไขข้อมูล</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <UserEditform id={id} />
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default UserEdit