import React, { useEffect, useState } from 'react'
import FetchApi, { host } from '../../customhooks/functionFetchApi';
import Tablecolordetail from './tablecolordetail';
import { CiImageOn } from "react-icons/ci";
import { FaFileDownload } from "react-icons/fa";
import ViewImages from '../../form01/components/viewImages';
const colorheader = {
    number: 0,
    colorheader: "",
    detailcolor: [],
    remark: ""
}

const ViewColorlist = ({ journalId }) => {

    const FetchApis = new FetchApi();

    const [jouranldata, setJournaldata] = useState([]);
    // detail ink
    const [colorlist1, setColorlist1] = useState(colorheader);
    const [colorlist2, setColorlist2] = useState(colorheader);
    const [colorlist3, setColorlist3] = useState(colorheader);
    const [colorlist4, setColorlist4] = useState(colorheader);
    //header ink
    const [colorall, setColorall] = useState({ colorheader: "", remark: "", typecolor: "", number: '' });
    const [colordetail, setColordetail] = useState({ inkname: "", formulate: "", kg1: "", kg2: "", colordetailId: "" })
    useEffect(() => {
        getproductcolor(journalId)
        datalistjournal(journalId)
    }, [])


    function datalistjournal(jourId) {
        FetchApis.FethcGet(`/journal/journalbyId/${jourId}`).then((res) => {
            if (res.status == 200) {
                if (res.data.data.length > 0) {
                    setJournaldata(res.data.data[0]);
                }

            }
        })
    }

    function getproductcolor(jourId) {

        FetchApis.FethcGet(`/productcolor/getproductByjournal/${jourId}`).then((res) => {
            if (res) {
                // console.log(res.data)
                let colorallss = res.data.data;

                for (let index = 0; index < colorallss.length; index++) {
                    let resultcolor = colorallss[index];

                    if (resultcolor.number == 1) {
                        let tdcolor = colorallss[index];

                        getproductcolordetail(resultcolor.colorId, resultcolor.number, tdcolor)

                    }

                    if (resultcolor.number == 2) {
                        let tdcolor = colorallss[index];

                        getproductcolordetail(resultcolor.colorId, resultcolor.number, tdcolor)
                    }

                    if (resultcolor.number == 3) {
                        let tdcolor = colorallss[index];

                        getproductcolordetail(resultcolor.colorId, resultcolor.number, tdcolor)
                    }

                    if (resultcolor.number == 4) {
                        let tdcolor = colorallss[index];

                        getproductcolordetail(resultcolor.colorId, resultcolor.number, tdcolor)
                    }

                }
                // setJournaldata(res.data.data[0]);
            }
        })
    }

    function getproductcolordetail(colorid, typenumber, resultcolor) {

        if (typenumber == 1) {
            FetchApis.FethcGet(`/productcolordetail/getproductByColorId/${colorid}`).then((res) => {
                const { colorheader, detailcolor, remark } = colorlist1;

                if (res.data.data.length > 0) {
                    setColorlist1({
                        ...colorlist1,
                        colorheader: resultcolor.colorname,
                        detailcolor: res.data.data,
                        remark: resultcolor.remark,
                        number: 1
                    })
                } else {
                    setColorlist1({
                        ...colorlist1,
                        colorheader: resultcolor.colorname,
                        // detailcolor: res.data.data,
                        remark: resultcolor.remark,
                        number: 1
                    })
                }

            })
        }

        if (typenumber == 2) {
            FetchApis.FethcGet(`/productcolordetail/getproductByColorId/${colorid}`).then((res) => {
                // console.log(res.data.data)
                const { colorheader, detailcolor, remark } = colorlist2;

                if (res.data.data.length > 0) {
                    setColorlist2({
                        ...colorlist2,
                        colorheader: resultcolor.colorname,
                        detailcolor: res.data.data,
                        remark: resultcolor.remark,
                        number: 2
                    })
                } else {
                    setColorlist2({
                        ...colorlist2,
                        colorheader: resultcolor.colorname,
                        // detailcolor: res.data.data,
                        remark: resultcolor.remark,
                        number: 2
                    })
                }
            })
        }

        if (typenumber == 3) {
            FetchApis.FethcGet(`/productcolordetail/getproductByColorId/${colorid}`).then((res) => {

                const { colorheader, detailcolor, remark } = colorlist3;

                if (res.data.data.length > 0) {
                    setColorlist3({
                        ...colorlist3,
                        colorheader: resultcolor.colorname,
                        detailcolor: res.data.data,
                        remark: resultcolor.remark,
                        number: 3
                    })
                } else {
                    setColorlist3({
                        ...colorlist3,
                        colorheader: resultcolor.colorname,
                        // detailcolor: res.data.data,
                        remark: resultcolor.remark,
                        number: 3
                    })
                }
            })
        }

        if (typenumber == 4) {

            FetchApis.FethcGet(`/productcolordetail/getproductByColorId/${colorid}`).then((res) => {

                const { colorheader, detailcolor, remark } = colorlist4;
                if (res.data.data.length > 0) {
                    setColorlist4({
                        ...colorlist4,
                        colorheader: resultcolor.colorname,
                        detailcolor: res.data.data,
                        remark: resultcolor.remark,
                        number: 4
                    })
                } else {
                    setColorlist4({
                        ...colorlist4,
                        colorheader: resultcolor.colorname,
                        // detailcolor: res.data.data,
                        remark: resultcolor.remark,
                        number: 4
                    })
                }
            })
        }


    }


    return (
        <div>
            <div>
                {colorlist1.colorheader != "" && <Tablecolordetail data={colorlist1} />}
                {colorlist2.colorheader != "" && <Tablecolordetail data={colorlist2} />}
                {colorlist3.colorheader != "" && <Tablecolordetail data={colorlist3} />}
                {colorlist4.colorheader != "" && <Tablecolordetail data={colorlist4} />}
            </div>
            <hr></hr>
            {jouranldata.uploadfilecolor != "" &&
                <div>
                    <div className='row-page'>
                        <label style={{ fontSize: 16 }}><b>รูปประกอบ</b></label>
                        <ViewImages urlImages={jouranldata.uploadfilecolor} />
                    </div>

                    <div>
                        {jouranldata.uploadfilecolor != "" ?
                            <img src={host + '/images/files/' + jouranldata.uploadfilecolor}
                                style={{ width: 100, hidden: 100 }} /> : <CiImageOn size={50} />}
                    </div>
                    <div>
                        {jouranldata.uploadfilecolor != "" && <div>
                            <a href={host + `/images/files/${jouranldata.uploadfilecolor}`} ><FaFileDownload size={20} /> download</a>
                        </div>}
                    </div>
                </div>}
        </div>
    )
}

export default ViewColorlist