import React, { useState, useEffect } from 'react'
import { Button } from 'react-bootstrap'
import Modal from 'react-bootstrap/Modal';
import FetchApi from '../../customhooks/functionFetchApi';

const Actionedit = ({ Id,loadingMenulist }) => {
  const  FetchApis=new FetchApi();
    const values = [true, 'sm-down', 'md-down', 'lg-down', 'xl-down', 'xxl-down'];
    const [fullscreen, setFullscreen] = useState(true);
    const [show, setShow] = useState(false);
    const[inputText,setInputText]=useState("");

    function handleShow(value) {
        setShow(value);
    }
    
    function headleChange(e){
        setInputText(e.target.value);
    }

    function editNameNew(){
        var object={
            namegroup:inputText
        }
        FetchApis.FethcUpdate(`/groupmenu/updateNameMenuList/${Id}`,object).then(res=>{
            if(res.status==200){
                alert('แก้ไขข้อมูลเรียบร้อยแล้ว');
                loadingMenulist()
                handleShow(false);
            }
        }).catch(err=>alert(err));

    }
    return (
        <div>
            <Button size='sm' variant="warning" onClick={() => handleShow(true)}>แก้ไขข้อมูล</Button>

            <Modal show={show} size="lg" onHide={() => handleShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>แก้ไขข้อมูล ชื่อกลุ่ม</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='row-page'>
                        <div><label>ป้อนข้อมูล : </label></div>
                        <div>
                            <input type='text' name='TextInputName' onChange={(e)=>headleChange(e)}/>
                        </div>
                        <div style={{marginLeft:20}}>
                            <Button size='sm' variant="success" style={{marginLeft:5}} onClick={()=>editNameNew()}>แก้ไขข้อมูล</Button>
                            <Button size='sm' variant="warning" style={{marginLeft:5}}>ยกเลิก</Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default Actionedit