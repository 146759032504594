import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import FetchApi from '../../../customhooks/functionFetchApi_erp';
import { FaSearch } from "react-icons/fa";
import DataTable from 'react-data-table-component';
import {useSelector, useDispatch}from 'react-redux';

import { addEarwelding,addEar,addRing,addInnerlid,addOuterlid,addToplid,
    addBottomlid,addPlasticlid,addAluminumlid}from'../../../../reduxSlice/itemcomponentSlice/itemcomponentSlice';


function TableItemList({ data, hendleModel,typecom}) {
        const dispatch =useDispatch();
        const compoData = useSelector((state) => state.itemcomponent.datacomponent)

    const selectItem = (itemnames) => {

       if(typecom=='Earwelding'){
        dispatch(addEarwelding(itemnames))
       }
       else if(typecom=='Ear'){
        dispatch(addEar(itemnames))
       
       }
       else if(typecom=='Ring'){
        dispatch(addRing(itemnames))
       
       }
       else if(typecom=='Innerlid'){
        dispatch(addInnerlid(itemnames))
       
       }
       else if(typecom=='Outerlid'){
        dispatch(addOuterlid(itemnames))
       
       }
       else if(typecom=='Toplid'){
        dispatch(addToplid(itemnames))
       
       }
       else if(typecom=='Bottomlid'){
        dispatch(addBottomlid(itemnames))
       
       }
       else if(typecom=='Plasticlid'){
        dispatch(addPlasticlid(itemnames))
        
       }
       else if(typecom=='Aluminumlid'){
        dispatch(addAluminumlid(itemnames))
        
       }
       hendleModel(false)
     
    }

    const columns = [
        {
            name: 'Item ID',
            sortable: true,
            selector: row => row.itemid,
        },
        {
            name: 'Item Name',
            grow: 2,
            selector: row => row.Itemname,
        },
        {
            name: 'เลือกกุล่ม',
            selector: row => <div>
                <Button variant="primary" size='sm' onClick={() => selectItem(row.itemid, row.Itemname)}>เลือกกลุ่ม</Button>

            </div>,
        },
    ];

    const customStyles = {
        headRow: {
            style: {
                border: 'none',
                backgroundColor: '#28B463'
            },
        },
        headCells: {
            style: {
                color: '#ffff',
                fontSize: '14px',
            },
        }
    };

    return (
        <DataTable
            columns={columns}
            data={data}
            // selectableRows
            pagination
            customStyles={customStyles}
            highlightOnHover

        />
    )

}



function PopupFGAll(props) {

   

    const FetchApis = new FetchApi();
    const [show, setShow] = useState(false);
   
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [itemList, setItemList] = useState([]);
    const [textSearch, setTextSearch] = useState([]);

    useEffect(() => {

        getapilist()

    }, [])

    const hendleModel = (value) => {
        setShow(false)
    }

    const getapilist = () => {
        
        FetchApis.FethcGet(`getItemFG`).then((res) => {
            if (res.status == 200) {
               
                setItemList(res.data.listall)
                setTextSearch(res.data.listall);
            }
        })
    }

    const onSearchText = (datavalue) => {

        const filteredData = textSearch.filter((item) => {
            return Object.values(item).join('').toLowerCase().includes(datavalue.toLowerCase())
          })

          setItemList(filteredData)
    }

    return (
        <div>
            
            <FaSearch variant="primary" onClick={handleShow} size={20}>
                Launch demo modal
            </FaSearch>

            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Item list</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Control type="text" placeholder="ค้นหา ป้อนข้อมูล" size='sm' onChange={(e) => onSearchText(e.target.value)} />
                        </Form.Group>
                    </Form>

                    {
                        itemList.length > 0 && <TableItemList data={itemList} hendleModel={hendleModel} typecom={props.typecom}/>
                    }

                </Modal.Body>
            </Modal>
        </div>
    )
}

export default PopupFGAll