import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import Tablelistuserall from './component/tablelistuserall';
import FetchApi from '../customhooks/functionFetchApi';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Createmenulist from './createmenulist';

const Createuser = () => {
    const FetchApis = new FetchApi();

    const [departmentlist, setDepartmentlist] = useState([]);// department
    const [positionall, setPositionall] = useState([]);//position
    const [listUserAll, setListUserAll] = useState([])//get user all list
    const [listmenuAll, setLIstmenuAll] = useState([]); // get menu list all
    const[textsearch,setTextsearch]=useState([]);

    const [formData, setFormData] = useState({
        name: "",
        username: "",
        password: "",
        employeeId: "",
        departmentId: "",
        positioncode: "",
        email: "kungchai@.com",
        roles: "",
        menugroupId: 0,
        isActive: ""
    });

    useEffect(() => {
        departmentgroup();
        positiongroup();
        getUserAll();
        getmenulistall();

    }, [])

    // get department  show overall
    const departmentgroup = () => {
        FetchApis.FethcGet(`/department/getdepartmentall`).then((res) => {
            if (res.status == 200) {
                setDepartmentlist(res.data.data)
            }
        })
    }
    // get position overall
    const positiongroup = () => {

        FetchApis.FethcGet(`/emposition/getempositionall`).then((res) => {

            if (res.status == 200) {
                setPositionall(res.data.data)
            }
        })
    }

    const getmenulistall = () => {

        FetchApis.FethcGet(`/groupmenu/getgroupmenulistall`).then((res) => {

            if (res.status == 200) {
                setLIstmenuAll(res.data.data)
            }
        })
    }

    // get list user overall 
    const getUserAll = () => {
        FetchApis.FethcGet(`/account/accountall`).then((res) => {
            if (res.status == 200) {
                // setDepartmentlist(res.data.data)
                setListUserAll(res.data.data)
                setTextsearch(res.data.data)
            }
        })
    }

    const handleinput = (e) => {
        // const{name,value}=e.target;
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));

    }
    // save data to insert table
    const OnSubmitform = (e) => {
        e.preventDefault();

        FetchApis.FethcPost(`/account/addaccount`, formData).then((res) => {
            if (res.status == 200) {
                alert('ทำการสร้างเรียบแล้ว')
            }
        })
    }

    //searhc account

    const searchAccountall=(e)=>{
        const { value, name } = e.target;
                // setSearchInput(searchValue)
        const filteredData = listUserAll.filter((item) => {
          return Object.values(item).join('').toLowerCase().includes(value.toLowerCase())
        })
        
        setTextsearch(filteredData)
    }


    //   //ค้นหาข้อมูลทั้งหมด
    //   function heandleSearch(e) {
    //     const { value, name } = e.target;
    
    //     // setSearchInput(searchValue)
    //     const filteredData = datajournal.filter((item) => {
    //       return Object.values(item).join('').toLowerCase().includes(value.toLowerCase())
    //     })
        
    //     setJournalList(filteredData)
    //   }

    return (
        <div className='form-body'>
            <Tabs
                defaultActiveKey="createuser"
                id="uncontrolled-tab-example"
                className="mb-3"
            >

                <Tab eventKey="createuser" title="สร้างผู้ใช้งาน">
                    <form onSubmit={(e) => OnSubmitform(e)}>
                        <div>
                            <center><label style={{ fontSize: 18 }}>สร้างผู้ใช้งานระบบ</label></center>
                        </div>

                        <div style={{ width: '95%', padding: 10, margin: 5 }}>
                            <div className='row-between-page'>
                                <div className='row-page'>
                                    <div>
                                        <label style={{ marginRight: 10 }}>Username :</label>
                                        <input type='text' name='username' style={{ marginRight: 10 }} onChange={(e) => handleinput(e)} required />
                                    </div>
                                    <div>
                                        <label style={{ marginRight: 10 }}>Password :</label>
                                        <input type='password' name='password' style={{ marginRight: 10 }} onChange={(e) => handleinput(e)} required />
                                    </div>
                                    <div>
                                        <label style={{ marginRight: 10 }}>Rolse :</label>
                                        <select style={{ marginRight: 10, width: 200 }} name='roles' onChange={(e) => handleinput(e)} required>
                                            <option value={''}>เลือก...</option>
                                            <option value={'user'}>ผู้ใช้ทั่วไป</option>
                                            <option value={'admin'}>ผู้ดูแลระบบ</option>
                                        </select>
                                    </div>
                                    <div style={{ marginLeft: 10 }}>
                                        <label style={{ marginRight: 10 }}>กลุ่มผุ้ใช้งาน : </label>

                                        <select style={{ marginRight: 10, width: 200 }} name='menugroupId' onChange={(e)=>handleinput(e)} >
                                            <option value={''}>เลือก...</option>
                                            {listmenuAll.map((item, i) => (
                                                <option value={item.menugroupId}>{item.namegroup}</option>
                                            ))}

                                        </select>
                                    </div>
                                </div>
                                <div>
                                    <Button type='submit' size='sm' variant="success">เพิ่มข้อมูล</Button>{" "}
                                    <Button size='sm' variant="warning">ยกเลิก</Button>
                                </div>
                            </div>

                            <hr />
                            <div className='row-page'>
                                <div>

                                    <div style={{ margin: 10 }}>
                                        <label>ชื่อผู้ใช้งาน : </label>
                                        <div style={{ marginLeft: 10 }}><input type='text' name='name' style={{ width: 200 }} onChange={(e) => handleinput(e)} required /></div>
                                    </div>

                                    <div style={{ margin: 10 }}>
                                        <label>รหัสพันักงาน : </label>
                                        <div style={{ marginLeft: 10 }}><input type='text' name='employeeId' style={{ width: 200 }} onChange={(e) => handleinput(e)} required /></div>
                                    </div>

                                    <div style={{ margin: 10 }}>
                                        <label>ตำแหน่งงาน : </label>
                                        <div style={{ marginLeft: 10 }}>

                                            <select style={{ width: 150 }} name='positioncode' onChange={(e) => handleinput(e)} required>
                                                <option value={''}>เลือกตำแหน่ง...</option>
                                                {positionall.map((item, i) => (
                                                    <option value={item.postioncode}>{item.nameposition}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>

                                </div>

                                <div style={{ marginLeft: 50 }}>
                                    <div style={{ margin: 10 }}>
                                        <label> แผนก :</label>
                                        <div style={{ marginLeft: 10 }}>
                                            <select style={{ width: 150 }} name='departmentId' onChange={(e) => handleinput(e)} required>
                                                <option>เลือกแผนก...</option>
                                                {departmentlist.map((item, i) => (
                                                    <option value={item.departmentcode}>{item.departmentname}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>

                                    <div style={{ margin: 10 }}>
                                        <label>email : </label>
                                        <div style={{ marginLeft: 10 }}>
                                            <input type='text' name='email' style={{ width: 300 }} onChange={(e) => handleinput(e)} />
                                        </div>
                                    </div>

                                    <div style={{ margin: 10 }} className='row-page'>
                                        <label>เปิดใช้ : </label>
                                        <div style={{ marginLeft: 10 }}>
                                            <input type='checkbox' name='isActive' value={'1'} onChange={(e) => handleinput(e)} />
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </form>
                    <div>
                        <div className='end-flex-row'>
                        <input type='text' name='searchuser' placeholder='ค้นหาข้อมูล' onChange={(e)=>searchAccountall(e)} style={{marginRight:20,padding:5}}/>
                        </div>
                        <Tablelistuserall userAll={textsearch} />
                    </div>

                </Tab>
                <Tab eventKey="createmenulist" title="สร้างกลุ่มผู้ใช้งาน">
                    <Createmenulist />
                </Tab>
            </Tabs>



        </div>
    )
}

export default Createuser