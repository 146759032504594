import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    datacomponent:{
        earwelding: "",
        ear: "",
        ring: "",
        innerlid: "",
        outerlid:"",
        toplid:"",
        bottomlid:"",
        plasticlid:"",
        aluminumlid:"",
        componentbody:"",
        componenttop:"",
        componentbottom:""
    }


}

export const itemcomponentSlice = createSlice({
    name: 'itemcomponent',
    initialState,
    reducers: {
        addEarwelding: (state, action) => {
            state.datacomponent.earwelding = action.payload;
        },
        addEar: (state, action) => {
            state.datacomponent.ear = action.payload;
        },
        addRing: (state, action) => {
            state.datacomponent.ring = action.payload;
        },
        addInnerlid: (state, action) => {
            state.datacomponent.innerlid = action.payload;
        },
        addOuterlid: (state, action) => {
            state.datacomponent.outerlid = action.payload;
        },
        addToplid: (state, action) => {
            state.datacomponent.toplid = action.payload;
        },
        addBottomlid: (state, action) => {
            state.datacomponent.bottomlid = action.payload;
        },
        addPlasticlid: (state, action) => {
            state.datacomponent.plasticlid = action.payload;
        },
        addAluminumlid: (state, action) => {
            state.datacomponent.aluminumlid = action.payload;
        },
        addcomponentbody:(state, action)=>{
            state.datacomponent.componentbody=action.payload;
        },
        addcomponenttop:(state,action)=>{
            state.datacomponent.componenttop=action.payload;
        },
        addcomponentbottom:(state,action)=>{
            state.datacomponent.componentbottom=action.payload;
        }   

    },
})

// Action creators are generated for each case reducer function
export const { addEarwelding,addEar,addRing,addInnerlid,addOuterlid,addToplid,
    addBottomlid,addPlasticlid,addAluminumlid,addcomponentbody,addcomponenttop,addcomponentbottom
} = itemcomponentSlice.actions

export default itemcomponentSlice.reducer