import React, { useState, useEffect } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';

import formA1 from '../json-data/datatypeformA1.json';
import formA2 from '../json-data/datatypeformA2.json';

import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import FetchApi from '../../customhooks/functionFetchApi';
import EditComponentCode from './editComponentCode';
import ViewImages from './viewImages';
import { host } from '../../customhooks/functionFetchApi';
import { useSelector, useDispatch } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import Swal from 'sweetalert2'
import { ToastContainer, toast } from 'react-toastify';
import PopupFGcomponent from './popupComponent/popupFGcomponent';

import {
    objectcoatingbody, objectcoatingtop, objectcoatingbottom, objectforming,
    objectguideline, objectrequest, objectcoatingring, objectcoatingear, objectcomponentcode
} from '../object-usestate/objectdefaults';

import {
    addEarwelding, addEar, addRing, addInnerlid, addOuterlid, addToplid,
    addBottomlid, addPlasticlid, addAluminumlid
} from '../../../reduxSlice/itemcomponentSlice/itemcomponentSlice';


function FormNew({ handleShow, ids }) {

    const dispatch = useDispatch()
    const compoData = useSelector((state) => state.itemcomponent.datacomponent)

    const FetchApis = new FetchApi(); //fatch data
    const [key, setKey] = useState('jouranl');
    const jourId = ids.id;

    const [coatingbody, setCoatingbody] = useState({ ...objectcoatingbody });
    const [coatingtop, setCoatingtop] = useState({ ...objectcoatingtop });
    const [coatingbottom, setCoatingbottom] = useState({ ...objectcoatingbottom });
    const [coatingring, setCoatingring] = useState({ ...objectcoatingring });
    const [coatingear, setCoatingear] = useState({ ...objectcoatingear });

    const [forming, setForming] = useState({ ...objectforming });
    const [guideline, setGuideline] = useState({ ...objectguideline })
    const [requestsample, setRequestsample] = useState({ ...objectrequest });
    const [componentCodeall, setComponentCode] = useState({ ...objectcomponentcode });

    const [regulation, setRegulation] = useState([])
    const [regulationGroup, setregulationGroup] = useState([]);

    const [regulationcheck, setRegulationcheck] = useState([]) // ค่อยปรับ
    const [regulationOter, setregulationOther] = useState([])  // ค่อยปรับ
    const [datafetch, setDatafetch] = useState([])

    // create error length select over field  coating system
    const [BodyError, setBodyError] = useState(false); // body
    const [TopError, setTopError] = useState(false); // top
    const [BottomError, setBottomError] = useState(false); //botton
    const [RingError, setRingError] = useState(false);
    const [EarError, setEarError] = useState(false);

    // create error length select over field can end & forming
    const [formingError, setFormingError] = useState(false);
    //create error length select over field Guideline
    const [ProductgorupError, setProductgorupError] = useState(false);

    //กำหนดประเภทเอกสาร . . .
    const [dataDocument, setDataDocument] = useState(null);
    const [typedocument, setTypedocument] = useState(null);


    useEffect(() => {
        FetchApis.FethcGet(`/journal/journalbyId/${jourId}`).then((res) => {

            var codeid = "";
            if (res.data.status == 200) {

                setTypedocument(res.data.data[0].typeproduct)

                if (res.data.data[0].typeproduct == 'A1') {
                    setDataDocument(formA1)
                } else if (res.data.data[0].typeproduct == 'A2') {
                    setDataDocument(formA2)
                }


                setDatafetch(res.data.data)

                codeid = res.data.data[0].tdscode;

                setGuideline({
                    ...guideline,
                    tdscode: codeid,
                    journalId: jourId
                });
                setRequestsample({
                    ...requestsample,
                    tdscode: codeid,
                    journalId: jourId
                });

                setComponentCode({
                    ...componentCodeall,
                    tdscode: codeid,
                    journalId: jourId
                })


                getCoatingbody(jourId);
                getforming(jourId);
                getguideline(jourId);
                getregulation(jourId);
                getrequestsample(jourId);
                getcomponentcodelist(jourId);
            }
        })

    }, [])


    async function getCoatingbody(code) {

        await FetchApis.FethcGet(`/coating/coatingByJournalId/${code}`).then((res) => {
            if (res.data.status == 200) {
                const { groupBobyType, intcoating, extcoating } = coatingbody;

                for (let index = 0; index < res.data.data.length; index++) {

                    if (res.data.data[index].producttype == 'body') {
                        var databodytypes = res.data.data[index].groupBobyType.split(',')

                        setCoatingbody({
                            ...res.data.data[index],
                            groupBobyType: databodytypes,
                            intcoating: [...intcoating, res.data.data[index].intcoating],
                            extcoating: [...extcoating, res.data.data[index].extcoating],
                        })
                    }

                }
            }
        })

        await FetchApis.FethcGet(`/coating/coatingByJournalId/${code}`).then((res) => {
            if (res.data.status == 200) {
                const { substrate, shape, intcoating, extcoating } = coatingtop;

                for (let index = 0; index < res.data.data.length; index++) {

                    if (res.data.data[index].producttype == 'top') {
                        setCoatingtop({
                            ...res.data.data[index],
                            substrate: [...substrate, res.data.data[index].substrate],
                            shape: [...shape, res.data.data[index].shape],
                            intcoating: [...intcoating, res.data.data[index].intcoating],
                            extcoating: [...extcoating, res.data.data[index].extcoating]
                        })
                    }
                }
            }
        })


        await FetchApis.FethcGet(`/coating/coatingByJournalId/${code}`).then((res) => {
            if (res.data.status == 200) {
                const { substrate, shape, intcoating, extcoating } = coatingbottom;


                for (let index = 0; index < res.data.data.length; index++) {

                    if (res.data.data[index].producttype == 'bottom') {
                        setCoatingbottom({
                            ...res.data.data[index],
                            substrate: [...substrate, res.data.data[index].substrate],
                            shape: [...shape, res.data.data[index].shape],
                            intcoating: [...intcoating, res.data.data[index].intcoating],
                            extcoating: [...extcoating, res.data.data[index].extcoating]
                        })
                    }
                }
            }
        })

        await FetchApis.FethcGet(`/coating/coatingByJournalId/${code}`).then((res) => {
            if (res.data.status == 200) {
                const { ring_eartype, groupBobyType, intcoating, extcoating } = coatingring;


                for (let index = 0; index < res.data.data.length; index++) {

                    if (res.data.data[index].producttype == 'ring') {

                        setCoatingring({
                            ...res.data.data[index],
                            groupBobyType: [...groupBobyType, res.data.data[index].groupBobyType],
                            ring_eartype: [...ring_eartype, res.data.data[index].ring_eartype],
                            intcoating: [...intcoating, res.data.data[index].intcoating],
                            extcoating: [...extcoating, res.data.data[index].extcoating]
                        })
                    }
                }
            }
        })
        await FetchApis.FethcGet(`/coating/coatingByJournalId/${code}`).then((res) => {
            if (res.data.status == 200) {
                const { ring_eartype, groupBobyType, intcoating, extcoating } = coatingbottom;


                for (let index = 0; index < res.data.data.length; index++) {

                    if (res.data.data[index].producttype == 'ear') {
                        setCoatingear({
                            ...res.data.data[index],
                            groupBobyType: [...groupBobyType, res.data.data[index].groupBobyType],
                            ring_eartype: [...ring_eartype, res.data.data[index].ring_eartype],
                            intcoating: [...intcoating, res.data.data[index].intcoating],
                            extcoating: [...extcoating, res.data.data[index].extcoating]
                        })
                    }
                }
            }
        })
    }





    async function getforming(code) {

        await FetchApis.FethcGet(`/forming/formingByJournalId/${code}`).then((res) => {
            const { internalside, seaming } = forming;
            if (res.data.status == 200) {
                setForming({
                    ...res.data.data[0],
                    internalside: [...internalside, res.data.data[0].internalside],
                    seaming: [...seaming, res.data.data[0].seaming]
                })
            }

        })
    }


    async function getguideline(code) {

        await FetchApis.FethcGet(`/guideline/guidelineByJournalId/${code}`).then((res) => {
            const { groupother, groupdryfood, ph, ingredients, seamercondition, sterilization, typegroup } = guideline;

            if (res.data.status == 200) {
                setGuideline({
                    ...res.data.data[0],
                    groupother: [...groupother, res.data.data[0].groupother],
                    groupdryfood: [...groupdryfood, res.data.data[0].groupdryfood],
                    ph: [...ph, res.data.data[0].ph],
                    ingredients: [...ingredients, res.data.data[0].ingredients],
                    seamercondition: [...seamercondition, res.data.data[0].seamercondition],
                    sterilization: [...sterilization, res.data.data[0].sterilization],
                    groupother_note: res.data.data[0].groupother_note,
                    typegroup: [...typegroup, res.data.data[0].typegroup]
                })
            }

        })
    }

    async function getregulation(code) {

        await FetchApis.FethcGet(`/regulation/regulationByJourId/${code}`).then((res) => {

            if (res.data.status == 200) {

                setRegulation(res.data.data[0])
                var datagroup = res.data.data[0].regulationtype.split(",");
                setregulationGroup(datagroup);
                setRegulationcheck(datagroup);
            }

        })
    }
    // setRequestsample
    async function getrequestsample(code) {
        await FetchApis.FethcGet(`/requestsample/requestsampleByJournalId/${code}`).then((res) => {
            if (res.data.status == 200) {
                setRequestsample(res.data.data[0]);
            }

        })
    }
    //component code
    async function getcomponentcodelist(idjounr) {
        FetchApis.FethcGet(`/componentcode/componentByjournalId/${idjounr}`).then((res) => {

            if (res.data.status == 200) {
                // console.log(res.data.data[0])
                console.log(res.data);
                setComponentCode(res.data.data[0])

                dispatch(addEarwelding(res.data.data[0].earwelding))
                dispatch(addEar(res.data.data[0].ear))
                dispatch(addRing(res.data.data[0].ring))
                dispatch(addInnerlid(res.data.data[0].innerlid))
                dispatch(addOuterlid(res.data.data[0].outerlid))
                dispatch(addToplid(res.data.data[0].toplid))
                dispatch(addBottomlid(res.data.data[0].bottomlid))
                dispatch(addPlasticlid(res.data.data[0].plasticlid))
                dispatch(addAluminumlid(res.data.data[0].aluminumlid))

            }
        })
    }




    function handerOnclick() {
        handleShow(false)
    }

    async function submitSave(e) {

        e.preventDefault();
        if (coatingbody.intcoating.length > 1 || coatingbody.extcoating.length > 1) {
            setBodyError(true);
        }
        else {
            setBodyError(false);
        }

        if (coatingtop.substrate.length > 1 || coatingtop.shape.length > 1 || coatingtop.intcoating.length > 1 || coatingbottom.extcoating.length > 1) {
            setTopError(true);
        }
        else {
            setTopError(false);
        }
        if (coatingbottom.substrate.length > 1 || coatingbottom.shape.length > 1 || coatingbottom.intcoating.length > 1 || coatingbottom.extcoating.length > 1) {
            setBottomError(true);
        }
        else {
            setBottomError(false);
        }


        if (forming.internalside.length > 1 || forming.seaming.length > 1) {
            setFormingError(true)
        }
        else {
            setFormingError(false);
        }


        if (guideline.groupother.length > 1 || guideline.groupdryfood.length > 1 || guideline.ph.length > 1 || guideline.ingredients.length > 1
            || guideline.seamercondition.length > 1 || guideline.sterilization.length > 1) {
            setProductgorupError(true)
        }
        else {
            setProductgorupError(false);
        }

        if (coatingear.ring_eartype.length > 1 || coatingear.intcoating.length > 1) {
            setEarError(true)
        } else {
            setEarError(false)
        }

        if (guideline.groupother.length == 1 && guideline.typegroup.length == 0) {
            Swal.fire({
                icon: "error",
                title: "แจ้งเตือน",
                text: "กรุณาเลือกประเทศกลุ่ม typeProduct 1 รายการ !",
            });
            return
        }

        if (coatingbody.intcoating.length > 1 || coatingbody.extcoating.length > 1 ||
            coatingtop.substrate.length > 1 || coatingtop.shape.length > 1 || coatingtop.intcoating.length > 1 || coatingbottom.extcoating.length > 1 ||
            coatingbottom.substrate.length > 1 || coatingbottom.shape.length > 1 || coatingbottom.intcoating.length > 1 || coatingbottom.extcoating.length > 1 ||
            coatingear.ring_eartype.length > 1 || coatingear.intcoating.length > 1 ||
            forming.internalside.length > 1 || forming.seaming.length > 1 ||
            guideline.groupother.length > 1 || guideline.groupdryfood.length > 1 || guideline.ph.length > 1 || guideline.ingredients.length > 1 ||
            guideline.typegroup.length > 1
            || guideline.seamercondition.length > 1 || guideline.sterilization.length > 1
        ) {
            // alert('มีรายการมากกว่า 1 รายการ กรุณาตรวจสอบข้อมูล')
            Swal.fire({
                icon: "error",
                title: "แจ้งเตือน",
                text: "มีรายการมากกว่า 1 รายการ กรุณาตรวจสอบข้อมูล!",
            });
        }
        else {

            const dataregu = {
                regulationtype: regulationcheck,
                regulationother: regulationOter,
                tdscode: datafetch[0].tdscode
            }

            let datacomponentCodeall = {
                ear: compoData.ear,
                earwelding: compoData.earwelding,
                ring: compoData.ring,
                innerlid: compoData.innerlid,
                outerlid: compoData.outerlid,
                toplid: compoData.toplid,
                bottomlid: compoData.bottomlid,
                plasticlid: compoData.plasticlid,
                aluminumlid: compoData.aluminumlid,
                journalId: datafetch[0].journalId,
                tdscode: datafetch[0].tdscode
            }


            Swal.fire({
                title: "บันทึกแก้ไข",
                text: "คุณแน่ใจบันทึกเอกสาร ใช่หรือไม่ ! ",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "ใช่",
                cancelButtonText: 'ยกเลิก'
            }).then((result) => {

                if (result.isConfirmed) {
                    Swal.fire({
                        title: "บันทึกแก้ไข!",
                        text: "บันทึกแก้ไขเรียบแล้ว",
                        icon: "success"
                    });

                    // Coating system tab update
                    FetchApis.FethcUpdate(`/coating/coatingUpdateById/${coatingbody.coatingId}`, coatingbody).then((res) => {

                        if (res.status != 200) {
                            alert(res.message)
                        }
                    }).catch((err) => { console.log(err) })

                    //update coating type top
                    FetchApis.FethcUpdate(`/coating/coatingUpdateById/${coatingtop.coatingId}`, coatingtop).then((res) => {

                        if (res.status != 200) {
                            alert(res.message)
                        }
                    }).catch((err) => { console.log(err) })

                    //update coating type bottom
                    FetchApis.FethcUpdate(`/coating/coatingUpdateById/${coatingbottom.coatingId}`, coatingbottom).then((res) => {

                        if (res.status != 200) {
                            alert(res.message + "bottom")
                        }
                    }).catch((err) => { console.log(err) })

                    //update coating type ring
                    FetchApis.FethcUpdate(`/coating/coatingUpdateById/${coatingring.coatingId}`, coatingring).then((res) => {

                        if (res.status != 200) {
                            alert(res.message)
                        }
                    }).catch((err) => { console.log(err) })

                    //update coating type ear
                    FetchApis.FethcUpdate(`/coating/coatingUpdateById/${coatingear.coatingId}`, coatingear).then((res) => {

                        if (res.status != 200) {
                            alert(res.message)
                        }
                    }).catch((err) => { console.log(err) })


                    // Can & End forming tab
                    FetchApis.FethcUpdate(`/forming/updateforming/${forming.formingId}`, forming).then((res) => {

                        if (res.status != 200) {
                            alert(res.message)
                        }
                    }).catch((err) => { console.log(err) })

                    // //Guideline tab
                    FetchApis.FethcUpdate(`/guideline/updateguideline/${guideline.guidelineId}`, guideline).then((res) => {

                        if (res.status != 200) {
                            alert(res.message)
                        }
                    }).catch((err) => { console.log(err) })

                    // // regulation tab
                    FetchApis.FethcUpdate(`/regulation/updateregulation/${regulation.regulationId}`, dataregu).then((res) => {

                        if (res.status != 200) {
                            alert(res.message)
                        }
                    }).catch((err) => { console.log(err) })


                    // // request sample tab
                    FetchApis.FethcUpdate(`/requestsample/updaterequestsample/${requestsample.requestId}`, requestsample).then((res) => {

                        if (res.status != 200) {
                            alert(res.message)
                        }
                    }).catch((err) => { console.log(err) })

                    // component code update
                    FetchApis.FethcUpdate(`/componentcode/updatecomponentcode/${componentCodeall.comId}`, datacomponentCodeall).then((res) => {

                        if (res.status != 200) {
                            alert(res.message)
                        }
                    }).catch((err) => { console.log(err) })


                    handleShow(false);
                    toast.success('บันทึกเรียบร้อยแล้ว', {
                        position: "top-right",
                        autoClose: 1500,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        // transition: Bounce,
                    });

                    setTimeout(() => {
                        handleShow(false);
                        window.location.reload();
                    }, 1700);

                }
            });

        }

    }


    function handleChange(e) {

        const ischecked = e.target.checked;
        const istext = e.target.value;

        if (ischecked) {
            setRegulationcheck([...regulationcheck, e.target.value])

        }
        else {

            const index = regulationcheck.indexOf(e.target.value);
            regulationcheck.splice(index, 1);
            setRegulationcheck(regulationcheck);

        }

        if (e.target.type == 'text') {
            setregulationOther(e.target.value)
        }
    };

    function getGroup(vl) {

        var checkitem = false
        var data = regulationGroup.filter((fid) => fid == vl)

        return data
    }

    // check body ... 
    const handle_bodychecked = (e) => {

        const { value, checked } = e.target;
        const { groupBobyType, intcoating, extcoating } = coatingbody;

        if (checked) {
            if (e.target.name == "groupBobyType") {

                if (groupBobyType[0] == '') {

                    var cusvalue = groupBobyType[0] = value;
                    setCoatingbody({
                        ...coatingbody,
                        groupBobyType: [cusvalue],
                    });
                }
                else {
                    setCoatingbody({
                        ...coatingbody,
                        groupBobyType: [...groupBobyType, value],
                    });
                }

            }

            else if (e.target.name == "intcoatingbody") {
                if (intcoating[0] == '') {
                    var invalue = intcoating[0] = value;
                    setCoatingbody({
                        ...coatingbody,
                        intcoating: [invalue],
                    });
                }
                else {
                    setCoatingbody({
                        ...coatingbody,
                        intcoating: [...intcoating, value],
                    });
                }

            }
            else if (e.target.name == "extcoatingbody") {

                if (extcoating[0] == '') {

                    var exvalue = extcoating[0] = value;
                    setCoatingbody({
                        ...coatingbody,
                        extcoating: [exvalue],
                    });
                } else {
                    setCoatingbody({
                        ...coatingbody,
                        extcoating: [...extcoating, value],
                    });
                }


            }
            else if (e.target.name == 'bodyProof') {
                setCoatingbody({
                    ...coatingbody,
                    statusProof: value,
                });
            }
        }
        // Case 2 : The user unchecks the box
        else {
            if (e.target.name == 'groupBobyType') {
                setCoatingbody({
                    ...coatingbody,
                    groupBobyType: groupBobyType.filter((e) => e !== value),
                });
            }
            else if (e.target.name == "intcoatingbody") {
                setCoatingbody({
                    ...coatingbody,
                    intcoating: intcoating.filter((e) => e !== value),
                });
            }
            else if (e.target.name == "extcoatingbody") {
                setCoatingbody({
                    ...coatingbody,
                    extcoating: extcoating.filter((e) => e !== value),
                });
            }
            else if (e.target.name == 'bodyProof') {
                setCoatingbody({
                    ...coatingbody,
                    statusProof: "",
                });
            }
        }

    }

    //check top  ...
    const handle_topchecked = (e) => {
        const { value, checked } = e.target;
        const { substrate, shape, intcoating, extcoating } = coatingtop;

        if (checked) {
            if (e.target.name == "substratetop") {
                if (substrate[0] == '') {
                    var subvalue = substrate[0] = value;
                    setCoatingtop({
                        ...coatingtop,
                        substrate: [subvalue],
                    });
                } else {
                    setCoatingtop({
                        ...coatingtop,
                        substrate: [...substrate, value],
                    });
                }

            }
            else if (e.target.name == "Shapetop") {

                if (shape[0] == '') {
                    var shapevalue = shape[0] = value;
                    setCoatingtop({
                        ...coatingtop,
                        shape: [shapevalue],
                    });
                } else {
                    setCoatingtop({
                        ...coatingtop,
                        shape: [...shape, value],
                    });
                }

            }
            else if (e.target.name == "intcoatingtop") {

                if (intcoating[0] == '') {
                    var intcoatingvalue = intcoating[0] = value;
                    setCoatingtop({
                        ...coatingtop,
                        intcoating: [intcoatingvalue],
                    });
                } else {
                    setCoatingtop({
                        ...coatingtop,
                        intcoating: [...intcoating, value],
                    });
                }

            }
            else if (e.target.name == "extcoatingtop") {
                if (extcoating[0] == '') {
                    var extcoatingvalue = extcoating[0] = value;
                    setCoatingtop({
                        ...coatingtop,
                        extcoating: [extcoatingvalue],
                    });
                } else {
                    setCoatingtop({
                        ...coatingtop,
                        extcoating: [...extcoating, value],
                    });
                }


            }
            else if (e.target.name == 'topProof') {
                setCoatingtop({
                    ...coatingtop,
                    statusProof: value,
                });
            }
        }
        else {
            if (e.target.name == 'substratetop') {
                setCoatingtop({
                    ...coatingtop,
                    substrate: substrate.filter((e) => e !== value),
                });
            }
            else if (e.target.name == "Shapetop") {

                setCoatingtop({
                    ...coatingtop,
                    shape: shape.filter((e) => e !== value),
                });
            }
            else if (e.target.name == "intcoatingtop") {
                setCoatingtop({
                    ...coatingtop,
                    intcoating: intcoating.filter((e) => e !== value),
                });
            }
            else if (e.target.name == "extcoatingtop") {
                setCoatingtop({
                    ...coatingtop,
                    extcoating: extcoating.filter((e) => e !== value),
                });
            }
            else if (e.target.name == 'topProof') {
                setCoatingtop({
                    ...coatingtop,
                    statusProof: "",
                });
            }
        }
    }

    //  check bottom
    const handle_bottomchecked = (e) => {

        const { value, checked } = e.target;
        const { substrate, shape, intcoating, extcoating } = coatingbottom;
        if (checked) {
            if (e.target.name == "substratebottom") {

                if (substrate[0] == '') {
                    var subvalue = substrate[0] = value;
                    setCoatingbottom({
                        ...coatingbottom,
                        substrate: [subvalue],
                    });
                } else {
                    setCoatingbottom({
                        ...coatingbottom,
                        substrate: [...substrate, value],
                    });
                }
            }
            else if (e.target.name == "Shapebottom") {

                if (shape[0] == '') {
                    var shapevalue = shape[0] = value;
                    setCoatingbottom({
                        ...coatingbottom,
                        shape: [shapevalue],
                    });
                } else {
                    setCoatingbottom({
                        ...coatingbottom,
                        shape: [...shape, value],
                    });
                }
            }
            else if (e.target.name == "intcoatingbottom") {

                if (intcoating[0] == '') {
                    var intcoatingvalue = intcoating[0] = value;
                    setCoatingbottom({
                        ...coatingbottom,
                        intcoating: [intcoatingvalue],
                    });
                } else {
                    setCoatingbottom({
                        ...coatingbottom,
                        intcoating: [...intcoating, value],
                    });
                }
            }
            else if (e.target.name == "extcoatingbottom") {

                if (extcoating[0] == '') {
                    var extcoatingvalue = extcoating[0] = value;
                    setCoatingbottom({
                        ...coatingbottom,
                        extcoating: [extcoatingvalue],
                    });
                } else {
                    setCoatingbottom({
                        ...coatingbottom,
                        extcoating: [...extcoating, value],
                    });
                }
            }
            else if (e.target.name == 'bottomProof') {
                setCoatingbottom({
                    ...coatingbottom,
                    statusProof: value,
                });
            }
        }
        else {
            if (e.target.name == 'substratebottom') {
                setCoatingbottom({
                    ...coatingbottom,
                    substrate: substrate.filter((e) => e !== value),
                });
            }
            else if (e.target.name == "Shapebottom") {
                setCoatingbottom({
                    ...coatingbottom,
                    shape: shape.filter((e) => e !== value),
                });
            }
            else if (e.target.name == "intcoatingbottom") {
                setCoatingbottom({
                    ...coatingbottom,
                    intcoating: intcoating.filter((e) => e !== value),
                });
            }
            else if (e.target.name == "extcoatingbottom") {
                setCoatingbottom({
                    ...coatingbottom,
                    extcoating: extcoating.filter((e) => e !== value),
                });
            }
            else if (e.target.name == "bottomProof") {
                setCoatingbottom({
                    ...coatingbottom,
                    statusProof: "",
                });
            }

        }
    }
    // checkbox coating  ring 
    const handle_Ringchecked = (e) => {
        const { value, checked } = e.target;
        const { ring_eartype, intcoating, extcoating } = coatingring;

        if (checked) {
            if (e.target.name == 'coatingDia') {
                setCoatingring({
                    ...coatingring,
                    ring_eartype: [...ring_eartype, value]
                })
            } else if (e.target.name == 'intcoatingring') {
                setCoatingring({
                    ...coatingring,
                    intcoating: [...intcoating, value]
                })
            }
            else if (e.target.name == "extcoatingring") {
                setCoatingring({
                    ...coatingring,
                    extcoating: [...extcoating, value]
                })
            } else if (e.target.name == 'ringProof') {
                setCoatingring({
                    ...coatingring,
                    statusProof: value
                })
            }

        }
        else {
            if (e.target.name == 'coatingDia') {
                setCoatingring({
                    ...coatingring,
                    ring_eartype: ring_eartype.filter((e) => e !== value)
                })

            } else if (e.target.name == 'intcoatingring') {
                setCoatingring({
                    ...coatingring,
                    intcoating: intcoating.filter((e) => e !== value)
                })
            }
            else if (e.target.name == "extcoatingring") {
                setCoatingring({
                    ...coatingring,
                    extcoating: extcoating.filter((e) => e !== value)
                })
            } else if (e.target.name == 'ringProof') {
                setCoatingring({
                    ...coatingring,
                    statusProof: ""
                })
            }
        }
    }
    // check box coating ear
    const handle_earCheck = (e) => {
        const { value, checked } = e.target;
        const { ring_eartype, intcoating } = coatingear;
        if (checked) {
            if (e.target.name == 'eartype') {
                setCoatingear({
                    ...coatingear,
                    ring_eartype: [...ring_eartype, value]
                })
            }
            else if (e.target.name == 'intcoatingear') {
                setCoatingear({
                    ...coatingear,
                    intcoating: [...intcoating, value]
                })
            }
            else if (e.target.name == 'earProof') {
                setCoatingear({
                    ...coatingear,
                    statusProof: value
                })
            }

        }
        else {

            if (e.target.name == 'eartype') {
                setCoatingear({
                    ...coatingear,
                    ring_eartype: ring_eartype.filter((e) => e !== value)
                })
            } else if (e.target.name == 'intcoatingear') {
                setCoatingear({
                    ...coatingear,
                    intcoating: intcoating.filter((e) => e !== value)
                })
            }
            else if (e.target.name == 'earProof') {
                setCoatingear({
                    ...coatingear, statusProof: ""
                })
            }
        }
    }

    // check forming 
    const handle_formingchecked = (e) => {
        const { value, checked } = e.target;
        const { internalside, seaming } = forming;

        if (checked) {
            if (e.target.name == "internalside") {
                if (internalside[0] == '') {
                    var intervalue = internalside[0] = value;
                    setForming({
                        ...forming,
                        internalside: [intervalue],
                    });
                }
                else {
                    setForming({
                        ...forming,
                        internalside: [...internalside, value],
                    });
                }
            }
            else if (e.target.name == "seaming") {
                if (seaming[0] == '') {
                    var seamingvalue = seaming[0] = value;
                    setForming({
                        ...forming,
                        seaming: [seamingvalue],
                    });
                }
                else {
                    setForming({
                        ...forming,
                        seaming: [...seaming, value],
                    });
                }

            }

        } else {
            if (e.target.name == "internalside") {
                setForming({
                    ...forming,
                    internalside: internalside.filter((e) => e !== value),
                });
            }
            else if (e.target.name == "seaming") {

                setForming({
                    ...forming,
                    seaming: seaming.filter((e) => e !== value),
                });
            }
        }
    }



    const handle_guidelinechecked = (e) => {
        const { value, checked } = e.target;
        const { groupother, groupdryfood, ph, ingredients, seamercondition, sterilization } = guideline;

        if (checked) {

            if (e.target.name == "productgroupother") {
                if (groupother[0] == '') {

                    var guidelinevalue = groupother[0] = value;

                    setGuideline({
                        ...guideline,
                        groupother: [guidelinevalue],
                    });
                } else {

                    setGuideline({
                        ...guideline,
                        groupother: [...groupother, value],
                    });
                }
            }
            else if (e.target.name == "ph") {

                if (ph[0] == '') {
                    var phvalue = ph[0] = value;

                    setGuideline({
                        ...guideline,
                        ph: [phvalue],
                    });

                } else {
                    setGuideline({
                        ...guideline,
                        ph: [...ph, value],
                    });
                }
            }
            else if (e.target.name == "Ingredientstype") {

                if (ingredients[0] == '') {
                    var ingredientsvalue = ingredients[0] = value;
                    setGuideline({
                        ...guideline,
                        ingredients: [ingredientsvalue],
                    });
                } else {
                    setGuideline({
                        ...guideline,
                        ingredients: [...ingredients, value],
                    });
                }

            }
            else if (e.target.name == "seamercondition") {

                if (seamercondition[0] == '') {
                    var seamerconditionvalue = seamercondition[0] = value;

                    setGuideline({
                        ...guideline,
                        seamercondition: [seamerconditionvalue],
                    });
                } else {
                    setGuideline({
                        ...guideline,
                        seamercondition: [...seamercondition, value],
                    });
                }

            }
            else if (e.target.name == "sterilization") {

                if (sterilization[0] == '') {
                    var sterilizationvalue = sterilization[0] = value;
                    setGuideline({
                        ...guideline,
                        sterilization: [sterilizationvalue],
                    });
                } else {
                    setGuideline({
                        ...guideline,
                        sterilization: [...sterilization, value],
                    });
                }

            }

        }
        else {
            if (e.target.name == "productgroupother") {

                setGuideline({
                    ...guideline,
                    groupother: groupother.filter((e) => e !== value),
                });
            }
            else if (e.target.name == "ph") {
                setGuideline({
                    ...guideline,
                    ph: ph.filter((e) => e !== value),
                });
            }
            else if (e.target.name == "Ingredientstype") {
                setGuideline({
                    ...guideline,
                    ingredients: ingredients.filter((e) => e !== value),
                });
            }
            else if (e.target.name == "seamercondition") {
                setGuideline({
                    ...guideline,
                    seamercondition: seamercondition.filter((e) => e !== value),
                });
            }
            else if (e.target.name == "sterilization") {
                setGuideline({
                    ...guideline,
                    sterilization: sterilization.filter((e) => e !== value),
                });
            }

        }
    }

    const showProductType = (e) => {

        const { typegroup } = guideline
        const ischecked = e.target.checked;
        const value = e.target.value;

        if (ischecked) {
            if (e.target.name == 'grouptypeOther') {


                setGuideline({
                    ...guideline,
                    typegroup: [...typegroup, value],
                });


            } else if (e.target.name == 'grouptypeDryfood') {


                setGuideline({
                    ...guideline,
                    typegroup: [...typegroup, value],
                });

            }
            else if (e.target.name == 'grouptypeAerosol') {


                setGuideline({
                    ...guideline,
                    typegroup: [...typegroup, value],
                });

            } else if (e.target.name == 'grouptypeGeneral') {


                setGuideline({
                    ...guideline,
                    typegroup: [...typegroup, value],
                });
            }

        } else {

            if (e.target.name == 'grouptypeOther') {


                setGuideline({
                    ...guideline,
                    typegroup: typegroup.filter((e) => e !== value),
                });

            } else if (e.target.name == 'grouptypeDryfood') {


                setGuideline({
                    ...guideline,
                    typegroup: typegroup.filter((e) => e !== value),
                });
            } else if (e.target.name == 'grouptypeAerosol') {

                setGuideline({
                    ...guideline,
                    typegroup: typegroup.filter((e) => e !== value),
                });
            } else if (e.target.name == 'grouptypeGeneral') {

                setGuideline({
                    ...guideline,
                    typegroup: typegroup.filter((e) => e !== value),
                });
            }
        }
    }

    const onchangeComponentbody = (values) => {
        setCoatingbody({ ...coatingbody, fgcomponent: values })
    }

    const onchangeComponenttop = (values) => {
        setCoatingtop({ ...coatingtop, fgcomponent: values })
    }
    const onchangeComponentbottom = (values) => {
        setCoatingbottom({ ...coatingbottom, fgcomponent: values })
    }


    return (

        <div className='form-body' >
            {dataDocument == null ?
                <div className='body-center-page'><Spinner
                    as="span"
                    animation="grow"
                    variant="success"
                    size={200}
                    role="status"
                    aria-hidden="true" /> LOADIN ....</div>
                :
                <form onSubmit={(e) => submitSave(e)}>
                    <div style={{ width: '100%', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'flex-end', display: 'flex' }}>
                        <div style={{ marginRight: 10 }}>
                            <Button type='submit' size='sm' style={{ background: '#0DB48B', borderColor: '#0DB48B' }}>บันทึก แก้ไข</Button>
                        </div>
                        <div>
                            <Button type='reset' size='sm' value={'ยกเลิก'} style={{ backgroundColor: '#F7B528', borderColor: '#F7B528', marginRight: 10 }} >ยกเลิก </Button>
                        </div>
                    </div>
                    <Tabs
                        id="controlled-tab-example"
                        activeKey={key}
                        onSelect={(k) => setKey(k)}
                        className="mb-3"
                    >
                        <Tab eventKey="jouranl" title="Form ( F-MK-009A1-1 ) ตรวจสอบข้อมูลก่อนบันทึก" style={{ backgroundColor: 'aliceblue' }}>

                            <div>
                                {datafetch.length > 0 && <div>
                                    <div><center><b>TECHNICAL DATA SHEET : Food can Product</b></center></div>
                                    <div>

                                        <table style={{ width: '100%' }} >
                                            <tr>
                                                <th><center>Create product</center></th>
                                                <th><center>Due Date</center></th>
                                                <th><center>MK NO</center></th>
                                                <th><center>Date Cretae</center></th>
                                            </tr>
                                            <tr>
                                                <th style={{ paddingLeft: 5 }}>{datafetch[0].journaltype}</th>
                                                <th style={{ paddingLeft: 5 }}>{datafetch[0].duedate}</th>
                                                <th style={{ paddingLeft: 5 }}>{datafetch[0].tdscode}</th>
                                                <th style={{ paddingLeft: 5 }}>{datafetch[0].createdate}</th>
                                            </tr>

                                        </table>
                                    </div>

                                    <div style={{ width: '100%' }} className='row-between-form'>
                                        <div style={{ width: '50%' }} className='border_green'>
                                            <table style={{ width: '100%' }} >
                                                <tr>
                                                    <td style={{ width: '30%' }}>Product name : </td>
                                                    <td>{datafetch[0].productname}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ width: '30%' }}>Can Size /Dia : </td>
                                                    <td> {datafetch[0].cansize}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ width: '30%' }}>Item name : </td>
                                                    <td>{datafetch[0].itemname}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ width: '30%' }}>Item NO : </td>
                                                    <td>{datafetch[0].itemcode}</td>
                                                </tr>

                                            </table>
                                        </div>
                                        <div style={{ width: '50%', marginLeft: 3 }} className='border_green'>
                                            <table style={{ width: '100%' }} >
                                                <tr>
                                                    <td style={{ width: '30%' }}>Customer name : </td>
                                                    <td>{datafetch[0].customername}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ width: '30%' }}>Contact : </td>
                                                    <td>{datafetch[0].contact} </td>
                                                </tr>
                                                <tr>
                                                    <td style={{ width: '30%' }}>Tel : </td>
                                                    <td>{datafetch[0].tel} </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                    <div>
                                        <table style={{ width: '100%' }}>
                                            <tr>
                                                <th>Substrate</th>
                                                <th>Product group</th>
                                                <th>Information from customer</th>
                                                <th>Printing system</th>
                                            </tr>
                                            <tr>
                                                <td><input type='checkbox' checked={true} disabled /> {datafetch[0].substratetype}</td>
                                                <td><input type='checkbox' checked={true} disabled /> {datafetch[0].productgrouptype}</td>
                                                <td><input type='checkbox' checked={true} disabled /> {datafetch[0].informationtype == 'false' ? '! ยังไม่ได้ระบบ ข้อมูล' : datafetch[0].informationtype}</td>
                                                <td >
                                                    <input type='checkbox' checked={true} disabled /> {datafetch[0].printingtype == 'false' ? '! ยังไม่ได้ระบบ ข้อมูล  ( printing system ) ' : datafetch[0].printingtype}

                                                    {" "}<input type='text' value={datafetch[0].printingtypeother} disabled />

                                                    <tr >
                                                        <td style={{ width: '30%' }}><b>Last film Code :</b></td>
                                                        <td style={{ width: '100%' }}>{datafetch[0].filmcode}</td>
                                                    </tr>
                                                    <tr >
                                                        <td style={{ width: '30%' }}><b>Ref FG Last film Code :</b></td>
                                                        <td style={{ width: '100%' }}>{datafetch[0].refFgcode}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            {datafetch[0].filmtype == 'Scrap' ? <div><input type='checkbox' checked={true} disabled /> Scrap</div> : <div><input type='checkbox' checked={false} disabled /> Scrap</div>}
                                                        </td>
                                                        <td>
                                                            {datafetch[0].filmtype == 'Maintain' ? <div><input type='checkbox' checked={true} disabled /> Maintain</div> : <div><input type='checkbox' checked={false} disabled /> Maintain</div>}
                                                        </td>
                                                    </tr>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>}
                                <hr></hr>
                                <div>
                                    <div><b>Coating system</b></div>
                                    <div className='row-page' style={{ width: '100%' }}>
                                        <div style={{ width: '10%' }}>
                                            <b>Bobdy :</b>
                                        </div>
                                        <div style={{ width: '100%' }}>
                                            <table style={{ width: '100%' }} >
                                                <tr className='borderWidth-form'>
                                                    <th><center>Product shape</center></th>
                                                    <th><center>Side</center></th>
                                                    <th><center>Coating system</center></th>
                                                    <th><center>White Coat</center></th>
                                                    <th><center>Other</center></th>
                                                </tr>
                                                <tr>
                                                    <td style={{ paddingLeft: 5, width: '15%' }}>

                                                        {coatingbody.groupBobyType.map((item, i) => (
                                                            <div style={{ marginLeft: 20 }}>
                                                                <label>{i + 1}. {item}</label>
                                                            </div>
                                                        ))}
                                                    </td>
                                                    <td style={{ width: '10%' }}><center><b>Int .</b></center></td>
                                                    <td style={{ paddingLeft: 5, width: '20%' }}>{coatingbody.intcoating}</td>
                                                    <td style={{ paddingLeft: 5, width: '30%' }}>{coatingbody.intwhite}</td>
                                                    <td style={{ paddingLeft: 5, width: '30%' }}>{coatingbody.intother}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ paddingLeft: 5, width: '15%' }}></td>
                                                    <td style={{ width: '10%' }}><center><b>Ext .</b></center></td>
                                                    <td style={{ paddingLeft: 5, width: '20%' }}>{coatingbody.extcoating}</td>
                                                    <td style={{ paddingLeft: 5, width: '30%' }}>{coatingbody.extwhite}</td>
                                                    <td style={{ paddingLeft: 5, width: '30%' }}>{coatingbody.extother}</td>
                                                </tr>
                                            </table>
                                            <div><b>FG component : </b> {coatingbody.fgcomponent}</div>
                                            <div><b> Note : </b>{coatingbody.note}</div>
                                        </div>
                                    </div>

                                    <div className='row-between-form' style={{ width: '100%' }}>
                                        <div style={{ width: '10%' }}>
                                            <b>Top end :</b>
                                            <div># : <b>{coatingtop.number}</b></div>
                                        </div>
                                        <div style={{ width: '100%' }}>
                                            <table style={{ width: '100%' }} >
                                                <tr className='borderWidth-form' style={{ backgroundColor: '' }}>
                                                    <th style={{ paddingLeft: 5, width: '10%' }}><center>Product Substrate</center></th>
                                                    <th style={{ paddingLeft: 5, width: '10%' }}><center>Product shape</center></th>
                                                    <th style={{ width: '10%' }}><center>Side</center></th>
                                                    <th style={{ paddingLeft: 5, width: '10%' }}><center>Coating system</center></th>
                                                    <th style={{ paddingLeft: 5, width: '30%' }}><center>White Coat</center></th>
                                                    <th style={{ paddingLeft: 5, width: '30%' }}><center>Other</center></th>
                                                </tr>
                                                <tr>
                                                    <td ><center>{coatingtop.substrate}</center></td>
                                                    <td ><center>{coatingtop.shape}</center></td>
                                                    <td ><center><b>Int .</b></center></td>
                                                    <td >{coatingtop.intcoating}</td>
                                                    <td >{coatingtop.intwhite}</td>
                                                    <td >{coatingtop.intother}</td>
                                                </tr>
                                                <tr>
                                                    <td ></td>
                                                    <td ></td>
                                                    <td ><center><b>Ext .</b></center></td>
                                                    <td >{coatingtop.extcoating}</td>
                                                    <td >{coatingtop.extwhite}</td>
                                                    <td >{coatingtop.extother}</td>
                                                </tr>
                                            </table>
                                            <div><b>For sale order:</b> {coatingtop.forsaleorder}</div>
                                            <div><b>FG component : </b> {coatingtop.fgcomponent}</div>
                                            <div><b>Note : </b>{coatingtop.note}</div>
                                        </div>
                                    </div>
                                    <div className='row-between-form' style={{ width: '100%' }}>
                                        <div style={{ width: '10%' }}>
                                            <b>Bottom end :</b>
                                            <div># : <b>{coatingbottom.number}</b></div>
                                        </div>
                                        <div style={{ width: '100%' }} >
                                            <table style={{ width: '100%' }} >
                                                <tr className='borderWidth-form' style={{ backgroundColor: '' }}>
                                                    <th style={{ paddingLeft: 5, width: '10%' }}><center>Product Substrate</center></th>
                                                    <th style={{ paddingLeft: 5, width: '10%' }}><center>Product shape</center></th>
                                                    <th style={{ width: '10%' }}><center>Side</center></th>
                                                    <th style={{ paddingLeft: 5, width: '10%' }}><center>Coating system</center></th>
                                                    <th style={{ paddingLeft: 5, width: '30%' }}><center>White Coat</center></th>
                                                    <th style={{ paddingLeft: 5, width: '30%' }}><center>Other</center></th>
                                                </tr>
                                                <tr>
                                                    <td ><center>{coatingbottom.substrate}</center></td>
                                                    <td ><center>{coatingbottom.shape}</center></td>
                                                    <td ><center><b>Int .</b></center></td>
                                                    <td >{coatingbottom.intcoating}</td>
                                                    <td >{coatingbottom.intwhite}</td>
                                                    <td >{coatingbottom.intother}</td>
                                                </tr>
                                                <tr>
                                                    <td ></td>
                                                    <td ></td>
                                                    <td ><center><b>Ext .</b></center></td>
                                                    <td >{coatingbottom.extcoating}</td>
                                                    <td >{coatingbottom.extwhite}</td>
                                                    <td >{coatingbottom.extother}</td>
                                                </tr>
                                            </table>
                                            <div><b>For sale order: </b> {coatingbottom.forsaleorder}</div>
                                            <div><b>FG component : </b> {coatingbottom.fgcomponent}</div>
                                            <div><b>Note : </b> {coatingbottom.note}</div>
                                        </div>
                                    </div>

                                    <div className='row-between-form' style={{ width: '100%' }}>
                                        <div style={{ width: '10%' }}>
                                            <b>Ring :</b>
                                        </div>
                                        <div style={{ width: '100%' }} >
                                            <table style={{ width: '100%' }} >
                                                <tr className='borderWidth-form' style={{ backgroundColor: '' }}>
                                                    <th style={{ paddingLeft: 5, width: '10%' }}><center>Dia</center></th>
                                                    <th style={{ paddingLeft: 5, width: '10%' }}><center>Ring Type</center></th>
                                                    <th style={{ paddingLeft: 5, width: '20%' }}><center>Code</center></th>
                                                    <th style={{ width: '10%' }}><center>Side</center></th>
                                                    <th style={{ paddingLeft: 5, width: '10%' }}><center>Coating system</center></th>
                                                    <th style={{ paddingLeft: 5, width: '20%' }}><center>White Coat</center></th>
                                                    <th style={{ paddingLeft: 5, width: '20%' }}><center>Other</center></th>
                                                </tr>
                                                <tr>
                                                    <td ><center>{coatingring.dia}</center></td>
                                                    <td ><center>{coatingring.ring_eartype}</center></td>
                                                    <td><center>{coatingring.ring_earcode}</center></td>
                                                    <td ><center><b>Int .</b></center></td>
                                                    <td >{coatingring.intcoating}</td>
                                                    <td >{coatingring.intwhite}</td>
                                                    <td >{coatingring.intother}</td>
                                                </tr>
                                                <tr>
                                                    <td ></td>
                                                    <td ></td>
                                                    <td></td>
                                                    <td ><center><b>Ext .</b></center></td>
                                                    <td >{coatingring.extcoating}</td>
                                                    <td >{coatingring.extwhite}</td>
                                                    <td >{coatingring.extother}</td>
                                                </tr>
                                            </table>
                                            <div><b>For sale order : </b> {coatingring.forsaleorder}</div>

                                        </div>
                                    </div>

                                    <div className='row-between-form' style={{ width: '100%' }}>
                                        <div style={{ width: '10%' }}>
                                            <b>Ear :</b>

                                        </div>
                                        <div style={{ width: '100%' }} >
                                            <table style={{ width: '100%' }} >
                                                <tr className='borderWidth-form' style={{ backgroundColor: '' }}>
                                                    <th style={{ paddingLeft: 5, width: '10%' }}><center>Code</center></th>
                                                    <th style={{ paddingLeft: 5, width: '10%' }}><center>Ring Type</center></th>

                                                    <th style={{ width: '10%' }}><center>Side</center></th>
                                                    <th style={{ paddingLeft: 5, width: '10%' }}><center>Coating system</center></th>
                                                    <th style={{ paddingLeft: 5, width: '20%' }}><center>White Coat</center></th>
                                                    <th style={{ paddingLeft: 5, width: '20%' }}><center>Other</center></th>
                                                </tr>
                                                <tr>
                                                    <td ><center>{coatingear.ring_earcode}</center></td>
                                                    <td ><center>{coatingear.ring_eartype}</center></td>
                                                    <td ><center><b>Int .</b></center></td>
                                                    <td >{coatingear.intcoating}</td>
                                                    <td >{coatingear.intwhite}</td>
                                                    <td >{coatingear.intother}</td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>

                                    <hr></hr>
                                </div>

                                <div>
                                    <div className='row-between-form' style={{ width: '100%' }}>
                                        <div style={{ width: '10%' }}><b>Can & end forming</b></div>
                                        <div style={{ width: '100%' }} >
                                            <table style={{ width: '100%' }} >
                                                <tr className='borderWidth-form' style={{ backgroundColor: '' }}>
                                                    <th style={{ width: '10%' }}><center>Internal side stripe</center></th>
                                                    <th style={{ width: '15%' }}><center>Internal Other</center></th>
                                                    <th style={{ width: '10%' }}><center>Seaming </center></th>
                                                    <th style={{ width: '15%' }}><center>Seaming Other</center></th>
                                                    <th style={{ width: '20%' }}><center>Machine no </center></th>
                                                    <th style={{ width: '20%' }}><center>Packaging </center></th>


                                                </tr>
                                                <tr>
                                                    <td ><center>{forming.internalside}</center></td>
                                                    <td ><center>{forming.internalother}</center></td>
                                                    <td ><center>{forming.seaming}</center></td>
                                                    <td >{forming.seamingother}</td>
                                                    <td >{forming.machineno}</td>
                                                    <td >{forming.packaging}</td>

                                                </tr>
                                            </table>
                                            <div>Note : {forming.note}</div>
                                        </div>
                                    </div>
                                    <hr></hr>
                                </div>

                                <div>
                                    <div className='row-between-form' style={{ width: '100%' }}>
                                        <div style={{ width: '10%' }}><b>Guideline for product pack</b></div>
                                        <div style={{ width: '100%' }} >
                                            <div className='row-form'>
                                                <div style={{ marginRight: 20 }}>
                                                    <b>product shelf-life</b>  : <label style={{ color: 'red' }}>( {guideline.shelfyears} ) years / at</label></div>
                                                <div> <label style={{ color: 'blue' }}>( {guideline.shelfdeg} ) degC</label></div>
                                            </div>
                                            <table style={{ width: '100%' }} >
                                                <tr className='borderWidth-form' style={{ backgroundColor: '' }}>
                                                    <th style={{ width: '10%' }}><center># No .</center></th>
                                                    <th style={{ width: '15%' }}><center>Detail</center></th>
                                                    <th style={{ width: '10%' }}><center>Other </center></th>



                                                </tr>
                                                <tr>
                                                    <td ><b>1. Product group</b></td>
                                                    <td >{guideline.groupother}</td>
                                                    <td >{guideline.groupother}</td>

                                                </tr>
                                                <tr>
                                                    <td ><b>2. Details of product </b></td>
                                                    <td >{guideline.detailproduct}</td>
                                                    <td ></td>

                                                </tr>
                                                <tr>
                                                    <td ><b>3. pH</b></td>
                                                    <td >{guideline.ph}</td>
                                                    <td ></td>

                                                </tr>
                                                <tr>
                                                    <td ><b>4. Ingredients </b></td>
                                                    <td >{guideline.ingredients}</td>
                                                    <td >{guideline.ingredientother}</td>

                                                </tr>
                                                <tr>
                                                    <td ><b>5.Seamer condition </b></td>
                                                    <td >{guideline.seamercondition}</td>
                                                    <td ></td>

                                                </tr>
                                                <tr>
                                                    <td ><b>6.Sterilization process</b></td>
                                                    <td >{guideline.sterilization}</td>
                                                    <td >{guideline.sterilizationother}</td>

                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                    <hr></hr>
                                </div>

                                <div>
                                    <div className='row-between-form' style={{ width: '100%' }}>
                                        <div style={{ width: '10%' }}><b>Regulation</b></div>
                                        <div style={{ width: '100%' }} >
                                            <table style={{ width: '100%' }} >
                                                <tr className='borderWidth-form' style={{ backgroundColor: '' }}>
                                                    <th style={{ width: '10%' }}><center>Regulation type</center></th>
                                                    <th style={{ width: '15%' }}><center>Detail</center></th>



                                                </tr>
                                                <tr>
                                                    <td >{regulationGroup.map((item, i) => (
                                                        <div>{i + 1} . {item}</div>
                                                    ))}</td>
                                                    <td >{regulation.regulationother}</td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                    <hr></hr>
                                </div>

                                <div style={{ marginBottom: 40 }}>
                                    <div className='row-between-form' style={{ width: '100%' }}>
                                        <div style={{ width: '10%' }}><b>Request sample</b></div>
                                        <div style={{ width: '100%' }} >
                                            <table style={{ width: '100%' }} >
                                                <tr className='borderWidth-form' style={{ backgroundColor: '' }}>
                                                    <th style={{ width: '10%' }}><center>Proof plate</center></th>
                                                    <th style={{ width: '15%' }}><center> Can (pcs)</center></th>
                                                    <th style={{ width: '15%' }}><center>  End (pcs)</center></th>

                                                </tr>
                                                <tr>
                                                    <td >{requestsample.proofplate}</td>
                                                    <td >{requestsample.requestcan}</td>
                                                    <td >{requestsample.requestend}</td>
                                                </tr>
                                            </table>
                                            <div style={{ margin: 10, padding: 10, backgroundColor: '#e9e9e9' }} > <label style={{ fontSize: 16 }}>
                                                <b> Note : </b></label>{requestsample.requestnote}
                                            </div>

                                        </div>
                                    </div>
                                    <hr></hr>
                                </div>

                                <div className='row-page'>
                                    <div className='border_green' style={{ width: '30%', height: 'auto', padding: 10 }}>
                                        <div style={{ backgroundColor: '#989898' }}>
                                            <center>Component Code </center>
                                        </div>
                                        <div className='row-between-page '>
                                            <div><b>หูออ๊ก / ถ้วยอ๊อก:</b></div>
                                            <div>{componentCodeall.earwelding}</div>

                                        </div>
                                        <div className='row-between-page '>
                                            <div><b>หู :</b></div>
                                            <div>{componentCodeall.ear}</div>

                                        </div>
                                        <div className='row-between-page '>
                                            <div><b>ห่วง :</b></div>
                                            <div>{componentCodeall.ring}</div>

                                        </div>
                                        <div className='row-between-page '>
                                            <div><b>ฝาใน :</b></div>
                                            <div>{componentCodeall.innerlid}</div>
                                        </div>
                                        {/* <div className='row-between-page '>
                                            <div><b>ฝานอก :</b></div>
                                            <div>{componentCodeall.outerlid}</div>
                                        </div> */}
                                        <div className='row-between-page '>
                                            <div><b>ฝาบน :</b></div>
                                            <div>{componentCodeall.toplid}</div>
                                        </div>
                                        {/* <div className='row-between-page '>
                                            <div><b>ฝาล่าง :</b></div>
                                            <div>{componentCodeall.bottomlid}</div>
                                        </div> */}
                                        <div className='row-between-page '>
                                            <div><b>ฝานพลาสติก :</b></div>
                                            <div>{componentCodeall.plasticlid}</div>
                                        </div>
                                        <div className='row-between-page '>
                                            <div><b>ฝาอลูมิเนียม :</b></div>
                                            <div>{componentCodeall.aluminumlid}</div>
                                        </div>
                                    </div>



                                    <div>
                                        <div className='row-page' style={{ marginLeft: 20 }}>
                                            <label><b>ตัวอย่าง Product </b></label> <ViewImages urlImages={datafetch[0].uploadfilename} />
                                        </div>

                                        <div style={{ margin: 10, padding: 5 }} className='row-page'>
                                            {/* <div> */}
                                            {datafetch[0].uploadfilename != "" ? <img src={host + '/images/files/' + datafetch[0].uploadfilename} style={{ width: 100, hidden: 100 }} /> : "ไม่มีข้อมูล รูป ประกอบ"}
                                            {/* </div> */}

                                        </div>

                                    </div>

                                </div>


                            </div>

                        </Tab>

                        <Tab eventKey="Coating" title="Coating System" style={{ backgroundColor: 'aliceblue' }}>

                            <div>
                                <div style={{ marginLeft: 10 }}><b>Coating system</b></div>

                                <div>
                                    <div className='row-page' w style={{ padding: 10 }}>

                                        <div style={{ width: 100 }}><b>Body</b></div>

                                        <div style={{ width: "50%" }}>

                                            <div className='row-between-form'>
                                                {dataDocument.coating.body.data.map((item, i) => (

                                                    <div>{coatingbody.groupBobyType[i] != item ?
                                                        <div><input type='checkbox' name='groupBobyType' value={item}
                                                            onClick={(e) => handle_bodychecked(e)}
                                                        /> {item}
                                                        </div> :
                                                        <div>
                                                            <input type='checkbox' name='groupBobyType' value={item}
                                                                defaultChecked={true}
                                                                onClick={(e) => handle_bodychecked(e)}
                                                            /> {item}
                                                        </div>
                                                    }
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>

                                    <div style={{ width: "70%", marginLeft: 110, backgroundColor: '#F1F0ED', padding: 5 }}>
                                        <div className='row-between-form'>
                                            <b>Int {" : "}</b>
                                            {dataDocument.coating.int.data.map((item, i) => (
                                                <div>
                                                    {item == 'white-coat' ? <div>
                                                        <input type='text' name='intwhitebody' value={coatingbody.intwhite} placeholder={item}
                                                            onChange={(e) => setCoatingbody({ ...coatingbody, intwhite: e.target.value })} />
                                                    </div> :
                                                        <div>
                                                            {coatingbody.intcoating == item ?
                                                                <div>
                                                                    <input type='checkbox' name='intcoatingbody' value={item}
                                                                        onClick={(e) => handle_bodychecked(e)}
                                                                        defaultChecked={true}
                                                                    /> {item}
                                                                </div> :
                                                                <div>
                                                                    <input type='checkbox' name='intcoatingbody' value={item}
                                                                        onClick={(e) => handle_bodychecked(e)}
                                                                    /> {item}
                                                                </div>}

                                                        </div>

                                                            && item == 'Other' ? <div>
                                                            <input type='text' name='intotherbody' value={coatingbody.intother} placeholder={item}
                                                                onChange={(e) => setCoatingbody({ ...coatingbody, intother: e.target.value })} />
                                                        </div> :
                                                            <div>
                                                                {coatingbody.intcoating == item ?
                                                                    <div>
                                                                        <input type='checkbox' name='intcoatingbody' value={item}
                                                                            onClick={(e) => handle_bodychecked(e)}
                                                                            defaultChecked={true}
                                                                        /> {item}
                                                                    </div> :
                                                                    <div>
                                                                        <input type='checkbox' name='intcoatingbody' value={item}
                                                                            onClick={(e) => handle_bodychecked(e)}
                                                                        /> {item}
                                                                    </div>}
                                                            </div>
                                                    }</div>
                                            ))}
                                        </div>

                                        <div className='row-between-form'>
                                            <b>Ext {" : "}</b>
                                            {dataDocument.coating.ext.data.map((item, i) => (
                                                <div>{item == 'white-coat' ? <div>
                                                    <input type='text' name='extwhitebody' placeholder={item} value={coatingbody.extwhite}
                                                        onChange={(e) => setCoatingbody({ ...coatingbody, extwhite: e.target.value })} />
                                                </div> :
                                                    <div>
                                                        {coatingbody.extcoating == item ?
                                                            <div>
                                                                <input type='checkbox' name='extcoatingbody' value={item}
                                                                    onClick={(e) => handle_bodychecked(e)}
                                                                    defaultChecked={true}
                                                                /> {item}
                                                            </div> :
                                                            <div>
                                                                <input type='checkbox' name='extcoatingbody' value={item}
                                                                    onClick={(e) => handle_bodychecked(e)}
                                                                /> {item}
                                                            </div>}
                                                    </div>
                                                        && item == 'Other' ? <div>
                                                        <input type='text' name='extotherbody' placeholder={item} value={coatingbody.extother}
                                                            onChange={(e) => setCoatingbody({ ...coatingbody, extother: e.target.value })} />
                                                    </div> :
                                                        <div>
                                                            {coatingbody.extcoating == item ?
                                                                <div>
                                                                    <input type='checkbox' name='extcoatingbody' value={item}
                                                                        onClick={(e) => handle_bodychecked(e)}
                                                                        defaultChecked={true}
                                                                    /> {item}
                                                                </div> :
                                                                <div>
                                                                    <input type='checkbox' name='extcoatingbody' value={item}
                                                                        onClick={(e) => handle_bodychecked(e)}
                                                                    /> {item}
                                                                </div>}

                                                        </div>}
                                                </div>
                                            ))}
                                        </div>
                                        <div className='row-page'>
                                            <label><b>FG Component :</b></label>
                                            <input type='text' name='bodyfgcomponent'
                                                value={coatingbody.fgcomponent}
                                                onChange={(e) => setCoatingbody({ ...coatingbody, fgcomponent: e.target.value })} style={{ width: 300 }} />
                                            <PopupFGcomponent typecom={'componentbody'} onchangeComponentbody={onchangeComponentbody} />
                                        </div>

                                        <div>
                                            <div>
                                                <label>Note : </label>
                                            </div>
                                            <textarea type='text' name='textnodebody' style={{ width: 450, height: 50 }}
                                                onChange={(e) => setCoatingbody({ ...coatingbody, note: e.target.value })}
                                                value={coatingbody.note}
                                                maxLength={50}
                                            />

                                        </div>

                                    </div>

                                    <div> {BodyError == true ? <label style={{ color: 'red' }}>* มีรายการเลือกเกิน 1 รายการ *</label> : ""} </div>
                                </div>

                                <hr></hr>

                                <div>
                                    <div className='row-page' style={{ padding: 10 }}>

                                        <div style={{ width: 100 }}>
                                            <b>Top end</b>
                                            <div>
                                                <input type='number' name='numbertopendtop' value={coatingtop.number} style={{ width: 70 }}
                                                    onChange={(e) => setCoatingtop({ ...coatingtop, number: e.target.value })} />
                                            </div>
                                        </div>
                                        <div style={{ width: '50%' }}>

                                            <div className='row-between-form'>
                                                <b>Substrate :</b>
                                                {dataDocument.coating.topend.substrate.map((item, i) => (
                                                    <div>
                                                        {coatingtop.substrate == item ? <div>
                                                            <input type='checkbox' name='substratetop' value={item}
                                                                onClick={(e) => handle_topchecked(e)}
                                                                defaultChecked={true} /> {item}
                                                        </div> :
                                                            <div>
                                                                <input type='checkbox' name='substratetop' value={item}
                                                                    onClick={(e) => handle_topchecked(e)}
                                                                />
                                                                {item}
                                                            </div>}
                                                    </div>
                                                ))}
                                            </div>
                                            <div className='row-between-form'>
                                                <b>Shape :</b>
                                                {dataDocument.coating.topend.shape.map((item, i) => (
                                                    <div>{coatingtop.shape == item ?
                                                        <div>
                                                            <input type='checkbox' name='Shapetop' value={item}
                                                                onClick={(e) => handle_topchecked(e)}
                                                                defaultChecked={coatingtop.shape == item ? true : false} /> {item}
                                                        </div>
                                                        : <div>
                                                            <input type='checkbox' name='Shapetop' value={item}
                                                                onClick={(e) => handle_topchecked(e)}
                                                            /> {item}

                                                        </div>}
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>

                                    <div style={{ width: "70%", marginLeft: 110, backgroundColor: '#F1F0ED', padding: 5 }}>
                                        <div className='row-between-form'>
                                            <b>Int {" : "}</b>
                                            {dataDocument.coating.int.data.map((item, i) => (
                                                <div>{item == 'white-coat' ? <div>
                                                    <input type='text' name='intwhitetop' value={coatingtop.intwhite} placeholder={item}
                                                        onChange={(e) => setCoatingtop({ ...coatingtop, intwhite: e.target.value })} />
                                                </div> :
                                                    <div>
                                                        {coatingtop.intcoating == item ?
                                                            <div>
                                                                <input type='checkbox' name='intcoatingtop' value={item}
                                                                    onClick={(e) => handle_topchecked(e)}
                                                                    defaultChecked={coatingtop.intcoating == item && true}
                                                                /> {item}
                                                            </div>
                                                            : <div>
                                                                <input type='checkbox' name='intcoatingtop' value={item}
                                                                    onClick={(e) => handle_topchecked(e)}
                                                                /> {item}
                                                            </div>
                                                        }

                                                    </div>
                                                        && item == 'Other' ? <div>
                                                        <input type='text' name='Othertop' value={coatingtop.intother} placeholder={item}
                                                            onChange={(e) => setCoatingtop({ ...coatingtop, intother: e.target.value })} />
                                                    </div> :
                                                        <div>

                                                            {coatingtop.intcoating == item ?
                                                                <div>
                                                                    <input type='checkbox' name='intcoatingtop' value={item}
                                                                        onClick={(e) => handle_topchecked(e)}
                                                                        defaultChecked={coatingtop.intcoating == item && true}
                                                                    /> {item}
                                                                </div>
                                                                : <div>
                                                                    <input type='checkbox' name='intcoatingtop' value={item}
                                                                        onClick={(e) => handle_topchecked(e)}
                                                                    /> {item}
                                                                </div>
                                                            }
                                                        </div>}
                                                </div>
                                            ))}
                                        </div>

                                        <div className='row-between-form'>
                                            <b>Ext {" : "}</b>
                                            {dataDocument.coating.ext.data.map((item, i) => (
                                                <div>{item == 'white-coat' ? <div>
                                                    <input type='text' name='extwhitetop' value={coatingtop.extwhite} placeholder={item}
                                                        onChange={(e) => setCoatingtop({ ...coatingtop, extwhite: e.target.value })}
                                                    />
                                                </div> :
                                                    <div>
                                                        {coatingtop.extcoating[0] == item && true ?
                                                            <div>
                                                                <input type='checkbox' name='extcoatingtop' value={item}
                                                                    onClick={(e) => handle_topchecked(e)}
                                                                    defaultChecked={coatingtop.extcoating[0] == item && true}
                                                                /> {item}
                                                            </div> :
                                                            <div>
                                                                <input type='checkbox' name='extcoatingtop' value={item}
                                                                    onClick={(e) => handle_topchecked(e)}
                                                                /> {item}
                                                            </div>}

                                                    </div>
                                                        && item == 'Other' ? <div>
                                                        <input type='text' name='extothertop' value={coatingtop.extother} placeholder={item}
                                                            onChange={(e) => setCoatingtop({ ...coatingtop, extother: e.target.value })} />
                                                    </div> :
                                                        <div>

                                                            {coatingtop.extcoating[0] == item && true ?
                                                                <div>
                                                                    <input type='checkbox' name='extcoatingtop' value={item}
                                                                        onClick={(e) => handle_topchecked(e)}
                                                                        defaultChecked={coatingtop.extcoating[0] == item && true}
                                                                    /> {item}
                                                                </div> :
                                                                <div>
                                                                    <input type='checkbox' name='extcoatingtop' value={item}
                                                                        onClick={(e) => handle_topchecked(e)}
                                                                    /> {item}
                                                                </div>}
                                                        </div>

                                                }
                                                </div>
                                            ))}
                                        </div>
                                        <div className='row-page'>
                                            <label><b>FG Component :</b></label>
                                            <input type='text' name='topfgcomponent'
                                                value={coatingtop.fgcomponent}
                                                onChange={(e) => setCoatingtop({ ...coatingtop, fgcomponent: e.target.value })} style={{ width: 300 }} />
                                            <PopupFGcomponent typecom={'componenttop'} onchangeComponenttop={onchangeComponenttop} />
                                        </div>
                                        <div className='row-between-form'>
                                            <div >
                                                <div><label><b>for sale order : </b></label></div>

                                                <textarea type='text' name='forsaleorder'
                                                    onChange={(e) => setCoatingtop({ ...coatingtop, forsaleorder: e.target.value })} style={{ width: 450 }}
                                                    value={coatingtop.forsaleorder}
                                                    maxLength={250}
                                                />
                                            </div>

                                            <div>
                                                <div> <label>Note : </label></div>
                                                <textarea type='text' name='textnodebody' style={{ width: 450, height: 50 }}
                                                    onChange={(e) => setCoatingtop({ ...coatingtop, note: e.target.value })}
                                                    value={coatingtop.note}
                                                    maxLength={350}
                                                />

                                            </div>
                                        </div>
                                    </div>

                                    <div> {TopError == true ? <label style={{ color: 'red' }}>* มีรายการเลือกเกิน 1 รายการ *</label> : ""} </div>
                                </div>

                                <hr></hr>

                                <div>
                                    <div className='row-page' style={{ padding: 10 }}>

                                        <div style={{ width: 100 }}>
                                            <b>Bottom end</b>
                                            <div><input type='number' value={coatingbottom.number} name='numberbottomend' style={{ width: 70 }}
                                                onChange={(e) => setCoatingbottom({ ...coatingbottom, number: e.target.value })} /></div>
                                        </div>
                                        <div style={{ width: "50%" }}>
                                            <div className='row-between-form'>
                                                <b>Substrate :</b>
                                                {dataDocument.coating.bottomend.substrate.map((item, i) => (
                                                    <div>
                                                        {coatingbottom.substrate == item ?
                                                            <div>
                                                                <input type='checkbox' name='substratebottom' value={item}
                                                                    onClick={(e) => handle_bottomchecked(e)}
                                                                    defaultChecked={true} /> {item}
                                                            </div> :
                                                            <div>
                                                                <input type='checkbox' name='substratebottom' value={item}
                                                                    onClick={(e) => handle_bottomchecked(e)}
                                                                /> {item}
                                                            </div>
                                                        }
                                                    </div>
                                                ))}
                                            </div>
                                            <div className='row-between-form'>
                                                <b>Shape :</b>
                                                {dataDocument.coating.bottomend.shape.map((item, i) => (
                                                    <div>
                                                        {coatingbottom.shape == item ?
                                                            <div>
                                                                <input type='checkbox' name='Shapebottom' value={item}
                                                                    onClick={(e) => handle_bottomchecked(e)}
                                                                    defaultChecked={true} /> {item}
                                                            </div> :
                                                            <div>
                                                                <input type='checkbox' name='Shapebottom' value={item}
                                                                    onClick={(e) => handle_bottomchecked(e)}
                                                                /> {item}
                                                            </div>
                                                        }
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>

                                    <div style={{ width: "70%", marginLeft: 110, backgroundColor: '#F1F0ED', padding: 5 }}>
                                        <div className='row-between-form'>
                                            <b>Int {" : "}</b>
                                            {dataDocument.coating.int.data.map((item, i) => (
                                                <div>{item == 'white-coat' ? <div>
                                                    <input type='text' name='intwhitebottom' value={coatingbottom.intwhite} placeholder={item}
                                                        onChange={(e) => setCoatingbottom({ ...coatingbottom, intwhite: e.target.value })} />
                                                </div> :
                                                    <div>
                                                        {coatingbottom.intcoating == item ?
                                                            <div>
                                                                <input type='checkbox' name='intcoatingbottom' value={item}
                                                                    onClick={(e) => handle_bottomchecked(e)}
                                                                    defaultChecked={true}
                                                                /> {item}
                                                            </div> :
                                                            <div>
                                                                <input type='checkbox' name='intcoatingbottom' value={item}
                                                                    onClick={(e) => handle_bottomchecked(e)}
                                                                /> {item}

                                                            </div>}
                                                    </div>
                                                        && item == 'Other' ? <div>
                                                        <input type='text' name='intotherbottom' value={coatingbottom.intother} placeholder={item}
                                                            onChange={(e) => setCoatingbottom({ ...coatingbottom, intother: e.target.value })} />
                                                    </div> :
                                                        <div>
                                                            {coatingbottom.intcoating == item ?
                                                                <div>
                                                                    <input type='checkbox' name='intcoatingbottom' value={item}
                                                                        onClick={(e) => handle_bottomchecked(e)}
                                                                        defaultChecked={true}
                                                                    /> {item}
                                                                </div> :
                                                                <div>
                                                                    <input type='checkbox' name='intcoatingbottom' value={item}
                                                                        onClick={(e) => handle_bottomchecked(e)}
                                                                    /> {item}

                                                                </div>}

                                                        </div>}
                                                </div>
                                            ))}
                                        </div>
                                        <div className='row-between-form'>
                                            <b>Ext {" : "}</b>
                                            {dataDocument.coating.ext.data.map((item, i) => (
                                                <div>{item == 'white-coat' ? <div>
                                                    <input type='text' name='extwhitebottom' value={coatingbottom.extwhite} placeholder={item}
                                                        onChange={(e) => setCoatingbottom({ ...coatingbottom, extwhite: e.target.value })} />
                                                </div> :
                                                    <div>
                                                        {coatingbottom.extcoating == item ?
                                                            <div>
                                                                <input type='checkbox' name='extcoatingbottom' value={item}
                                                                    onClick={(e) => handle_bottomchecked(e)}
                                                                    defaultChecked={true}
                                                                /> {item}
                                                            </div> :
                                                            <div>
                                                                <input type='checkbox' name='extcoatingbottom' value={item}
                                                                    onClick={(e) => handle_bottomchecked(e)}
                                                                /> {item}
                                                            </div>}
                                                    </div>
                                                        && item == 'Other' ? <div>
                                                        <input type='text' name='extotherbottom' value={coatingbottom.extother} placeholder={item}
                                                            onChange={(e) => setCoatingbottom({ ...coatingbottom, extother: e.target.value })} />
                                                    </div> :
                                                        <div>
                                                            {coatingbottom.extcoating == item ?
                                                                <div>
                                                                    <input type='checkbox' name='extcoatingbottom' value={item}
                                                                        onClick={(e) => handle_bottomchecked(e)}
                                                                        defaultChecked={true}
                                                                    /> {item}
                                                                </div> :
                                                                <div>
                                                                    <input type='checkbox' name='extcoatingbottom' value={item}
                                                                        onClick={(e) => handle_bottomchecked(e)}
                                                                    /> {item}
                                                                </div>}
                                                        </div>}
                                                </div>
                                            ))}
                                        </div>
                                        <div className='row-page'>
                                            <label><b>FG Component :</b></label>
                                            <input type='text' name='bottomfgcomponent'
                                                value={coatingbottom.fgcomponent}
                                                onChange={(e) => setCoatingbottom({ ...coatingbottom, fgcomponent: e.target.value })} style={{ width: 300 }} />
                                            <PopupFGcomponent typecom={'componentbottom'} onchangeComponentbottom={onchangeComponentbottom} />
                                        </div>
                                        <div className='row-between-form'>
                                            <div>
                                                <label><b>for sale order : </b></label>
                                                <input type='text' name='forsaleorder'
                                                    onChange={(e) => setCoatingbottom({ ...coatingbottom, forsaleorder: e.target.value })} style={{ width: 450, height: 50 }}
                                                    value={coatingbottom.forsaleorder}
                                                    maxLength={250}
                                                />
                                            </div>
                                            <div>
                                                <div><label>Note : </label></div>
                                                <textarea type='text' name='textnodeBottom' value={coatingbottom.note} style={{ width: 450, height: 50 }}
                                                    onChange={(e) => setCoatingbottom({ ...coatingbottom, note: e.target.value })} maxLength={350} />

                                            </div>
                                        </div>
                                    </div>

                                    <div> {BottomError == true ? <label style={{ color: 'red' }}>* มีรายการเลือกเกิน 1 รายการ *</label> : ""} </div>
                                </div>

                                <hr></hr>

                                {typedocument == 'A2' && <div>
                                    <div>
                                        <div className='row-page' style={{ padding: 10 }}>

                                            <div style={{ width: 100 }}>
                                                <b>Ring</b>
                                            </div>
                                            <div style={{ width: "40%" }}>
                                                <div className='row-between-form'>
                                                    <b>Dia : <input type='text' name='dia'
                                                        value={coatingring.dia}
                                                        onChange={(e) => setCoatingring({ ...coatingring, dia: e.target.value })} /></b>

                                                    {dataDocument.coating.ring.dai.map((item, i) => (
                                                        <div>
                                                            {item == coatingring.ring_eartype ?
                                                                <div>
                                                                    <input type='checkbox' name='coatingDia' value={item}
                                                                        onClick={(e) => handle_Ringchecked(e)}
                                                                        defaultChecked={true}
                                                                    /> {item}
                                                                </div> :
                                                                <div>
                                                                    <input type='checkbox' name='coatingDia' value={item}
                                                                        onClick={(e) => handle_Ringchecked(e)}
                                                                    /> {item}
                                                                </div>}
                                                        </div>
                                                    ))}
                                                </div>

                                            </div>
                                        </div>
                                        <div style={{ marginLeft: 110 }} className='row-page'>
                                            <div style={{ marginRight: 20 }}>
                                                <label>Code :</label>
                                                <input type='text' name='ringcode'
                                                    onChange={(e) => setCoatingring({ ...coatingring, ring_earcode: e.target.value })}
                                                    value={coatingring.ring_earcode}
                                                />
                                            </div>
                                        </div>
                                        <div style={{ width: "70%", marginLeft: 110, backgroundColor: '#F1F0ED', padding: 5 }}>
                                            <div className='row-between-form'>
                                                <b>Int {" : "}</b>
                                                {dataDocument.coating.int.data.map((item, i) => (
                                                    <div>{item == 'white-coat' ? <div>
                                                        <input type='text' name='intwhitebottom' value={coatingring.intwhite} placeholder={item}
                                                            onChange={(e) => setCoatingring({ ...coatingring, intwhite: e.target.value })} />
                                                    </div> :
                                                        <div>
                                                            {coatingring.intcoating == item ?
                                                                <div>
                                                                    <input type='checkbox' name='intcoatingring' value={item}
                                                                        onClick={(e) => handle_Ringchecked(e)}
                                                                        defaultChecked={true}
                                                                    /> {item}
                                                                </div> :
                                                                <div>
                                                                    <input type='checkbox' name='intcoatingring' value={item}
                                                                        onClick={(e) => handle_Ringchecked(e)}
                                                                    /> {item}

                                                                </div>}
                                                        </div>
                                                            && item == 'Other' ? <div>
                                                            <input type='text' name='intotherring' value={coatingring.intother} placeholder={item}
                                                                onChange={(e) => setCoatingring({ ...coatingring, intother: e.target.value })} />
                                                        </div> :
                                                            <div>
                                                                {coatingring.intcoating == item ?
                                                                    <div>
                                                                        <input type='checkbox' name='intcoatingring' value={item}
                                                                            onClick={(e) => handle_Ringchecked(e)}
                                                                            defaultChecked={true}
                                                                        /> {item}
                                                                    </div> :
                                                                    <div>
                                                                        <input type='checkbox' name='intcoatingring' value={item}
                                                                            onClick={(e) => handle_Ringchecked(e)}
                                                                        /> {item}

                                                                    </div>}

                                                            </div>}
                                                    </div>
                                                ))}
                                            </div>
                                            <div className='row-between-form'>
                                                <b>Ext {" : "}</b>
                                                {dataDocument.coating.ext.data.map((item, i) => (
                                                    <div>{item == 'white-coat' ? <div>
                                                        <input type='text' name='extwhitebottom' value={coatingring.extwhite} placeholder={item}
                                                            onChange={(e) => setCoatingring({ ...coatingring, extwhite: e.target.value })} />
                                                    </div> :
                                                        <div>
                                                            {coatingring.extcoating == item ?
                                                                <div>
                                                                    <input type='checkbox' name='extcoatingring' value={item}
                                                                        onClick={(e) => handle_Ringchecked(e)}
                                                                        defaultChecked={true}
                                                                    /> {item}
                                                                </div> :
                                                                <div>
                                                                    <input type='checkbox' name='extcoatingring' value={item}
                                                                        onClick={(e) => handle_Ringchecked(e)}
                                                                    /> {item}
                                                                </div>}
                                                        </div>
                                                            && item == 'Other' ? <div>
                                                            <input type='text' name='extcoatingotherring' value={coatingring.extother} placeholder={item}
                                                                onChange={(e) => setCoatingring({ ...coatingring, extother: e.target.value })} />
                                                        </div> :
                                                            <div>
                                                                {coatingring.extcoating == item ?
                                                                    <div>
                                                                        <input type='checkbox' name='extcoatingring' value={item}
                                                                            onClick={(e) => handle_Ringchecked(e)}
                                                                            defaultChecked={true}
                                                                        /> {item}
                                                                    </div> :
                                                                    <div>
                                                                        <input type='checkbox' name='extcoatingring' value={item}
                                                                            onClick={(e) => handle_Ringchecked(e)}
                                                                        /> {item}
                                                                    </div>}
                                                            </div>}
                                                    </div>
                                                ))}

                                            </div>
                                            <div className='row-page'>
                                                <div>
                                                    <div><label><b>for sale order : </b></label></div>

                                                    <textarea type='text' name='forsaleorder' style={{ width: 450, height: 50 }}
                                                        onChange={(e) => setCoatingring({ ...coatingring, forsaleorder: e.target.value })}
                                                        value={coatingring.forsaleorder}
                                                        maxLength={250}
                                                    />
                                                </div>

                                            </div>


                                            <div>{RingError == true ? <label style={{ color: 'red' }}>* มีรายการเลือกเกิน 1 รายการ *</label> : ""}</div>
                                        </div>
                                    </div>

                                    <hr></hr>

                                    <div>
                                        <div className='row-page' style={{ padding: 10 }}>

                                            <div style={{ width: 100 }}>
                                                <b>Ear</b>
                                            </div>
                                        </div>
                                        <div style={{ marginLeft: 110, width: '40%' }} className='row-page'>

                                            <div style={{ marginRight: 20 }}>
                                                <label>Code :</label>
                                                <input type='text' name='earcode'
                                                    onChange={(e) => setCoatingear({ ...coatingear, ring_earcode: e.target.value })}
                                                    value={coatingear.ring_earcode}
                                                />
                                            </div>
                                            <div className='row-between-form' style={{ width: '30%' }}>
                                                {dataDocument.coating.ear.eartype.map((item, i) => (
                                                    <div>
                                                        {item == coatingear.ring_eartype ?
                                                            <div>
                                                                <input type='checkbox' name='eartype' value={item} onClick={(e) => handle_earCheck(e)}
                                                                    defaultChecked={true}
                                                                /> {item}
                                                            </div> : <div>
                                                                <input type='checkbox' name='eartype' value={item} onClick={(e) => handle_earCheck(e)}
                                                                /> {item}
                                                            </div>
                                                        }
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                        <div style={{ width: "70%", marginLeft: 110, backgroundColor: '#F1F0ED', padding: 5 }}>
                                            <div className='row-between-form'>
                                                <b>Int {" : "}</b>
                                                {dataDocument.coating.int.data.map((item, i) => (
                                                    <div>{item == 'white-coat' ? <div>
                                                        <input type='text' name='intwhiteear' value={coatingring.intwhite} placeholder={item}
                                                            onChange={(e) => setCoatingear({ ...coatingring, intwhite: e.target.value })} />
                                                    </div> :
                                                        <div>
                                                            {coatingear.intcoating == item ?
                                                                <div>
                                                                    <input type='checkbox' name='intcoatingear' value={item}
                                                                        onClick={(e) => handle_earCheck(e)}
                                                                        defaultChecked={true}
                                                                    /> {item}
                                                                </div> :
                                                                <div>
                                                                    <input type='checkbox' name='intcoatingear' value={item}
                                                                        onClick={(e) => handle_earCheck(e)}
                                                                    /> {item}

                                                                </div>}
                                                        </div>
                                                            && item == 'Other' ? <div>
                                                            <input type='text' name='intotherear' value={coatingring.intother} placeholder={item}
                                                                onChange={(e) => setCoatingear({ ...coatingring, intother: e.target.value })} />
                                                        </div> :
                                                            <div>
                                                                {coatingear.intcoating == item ?
                                                                    <div>
                                                                        <input type='checkbox' name='intcoatingear' value={item}
                                                                            onClick={(e) => handle_earCheck(e)}
                                                                            defaultChecked={true}
                                                                        /> {item}
                                                                    </div> :
                                                                    <div>
                                                                        <input type='checkbox' name='intcoatingear' value={item}
                                                                            onClick={(e) => handle_earCheck(e)}
                                                                        /> {item}

                                                                    </div>}

                                                            </div>}
                                                    </div>
                                                ))}

                                            </div>

                                            <div>{EarError == true ? <label style={{ color: 'red' }}>* มีรายการเลือกเกิน 1 รายการ *</label> : ""}</div>
                                        </div>
                                    </div>
                                </div>}

                            </div>

                        </Tab>

                        <Tab eventKey="forming" title="Can & end forming" style={{ backgroundColor: 'aliceblue' }}>
                            <div style={{ marginLeft: 10 }}>
                                <div>
                                    <b>Can & End forming</b>
                                </div>

                                <div >
                                    <div>

                                        <div className='row-page' style={{ width: '100%' }}>
                                            <div style={{ width: 250 }}>
                                                <b>Internal side stripe: </b>
                                            </div>
                                            <div className='row-page' style={{ width: 1000 }}>
                                                {dataDocument.forming.internalside.map((item, i) => (
                                                    <div> {item == 'Other' ? <div style={{ marginRight: 100, width: 150 }}>
                                                        <input type='text' name='internalside' value={forming.internalother}
                                                            onChange={(e) => setForming({ ...forming, internalother: e.target.value })} placeholder='other' />
                                                    </div> :
                                                        <div >{forming.internalside == item ?
                                                            <div>
                                                                <input type='checkbox' name='internalside' value={item}
                                                                    onClick={(e) => handle_formingchecked(e)}
                                                                    defaultChecked={true} /> {item}
                                                            </div> :
                                                            <div style={{ marginRight: 100, width: 150 }}>
                                                                <input type='checkbox' name='internalside' value={item}
                                                                    onClick={(e) => handle_formingchecked(e)}
                                                                /> {item}
                                                            </div>}
                                                        </div>}
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div >
                                    <div>
                                        <div className='row-page' style={{ width: '100%' }}>
                                            <div style={{ width: 250 }}>
                                                <b>Seaming : </b>
                                            </div>
                                            <div className='row-page' style={{ width: 1000 }}>
                                                {dataDocument.forming.seaming.map((item, i) => (
                                                    <div>{item == 'Other' ? <div style={{ marginRight: 100, width: 150 }}>
                                                        <input type='text' value={forming.seamingother} name='seamingother' placeholder='other'
                                                            onChange={(e) => setForming({ ...forming, seamingother: e.target.value })} />
                                                    </div> :
                                                        <div>{forming.seaming == item ?
                                                            <div style={{ marginRight: 100, width: 150 }}>
                                                                <input type='checkbox' name='seaming' value={item}
                                                                    onClick={(e) => handle_formingchecked(e)}
                                                                    defaultChecked={true} /> {item}
                                                            </div> :
                                                            <div style={{ marginRight: 100, width: 150 }}>
                                                                <input type='checkbox' name='seaming' value={item}
                                                                    onClick={(e) => handle_formingchecked(e)}
                                                                /> {item}
                                                            </div>}
                                                        </div>}
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='row-page' style={{ width: '100%' }}>
                                    <div style={{ width: 250 }}>
                                        <b>Packaging :</b>
                                    </div>
                                    <div>
                                        <div>Packaging : </div>
                                        <textarea type='text' value={forming.packaging} name='packaging' style={{ width: 250, height: 50, marginRight: 10 }}
                                            onChange={(e) => setForming({ ...forming, packaging: e.target.value })} maxLength={250} />
                                    </div>
                                    <div>
                                        <div
                                        >Note :
                                        </div><textarea type='text' value={forming.note} name='packaging'
                                            style={{ width: 450, height: 50 }} onChange={(e) => setForming({ ...forming, note: e.target.value })} maxLength={350} />
                                    </div>
                                </div>
                                <div> {formingError == true ? <label style={{ color: 'red' }}>* มีรายการเลือกเกิน 1 รายการ *</label> : ""} </div>
                            </div>
                        </Tab>

                        <Tab eventKey="Guideline" title="Guideline for product pack" style={{ backgroundColor: 'aliceblue' }}>
                            <div style={{ marginLeft: 10 }}>
                                <div><b>Guideline for product pack</b></div>
                                <br></br>

                                <div>
                                    <div className='row-between-form' style={{ width: 550 }}>
                                        <div style={{ width: 150 }}><b>Product shelf-life</b> :</div>
                                        <div>
                                            <input type='text' name='productshelt' value={guideline.shelfyears}
                                                onChange={(e) => setGuideline({ ...guideline, shelfyears: e.target.value })} /> years / at
                                            {" "}<input type='text' name='degC' value={guideline.shelfdeg}
                                                onChange={(e) => setGuideline({ ...guideline, shelfdeg: e.target.value })} /> degC
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <div style={{ width: 1100, marginBottom: 5 }}>
                                        <div style={{ width: 300 }}>
                                            <label><b>1.Product gorup :</b></label>
                                        </div>
                                        <div className='row-between-form'>
                                            {typedocument == 'A1' ?
                                                <div>
                                                    <div style={{ marginLeft: 150 }}>

                                                        <center>
                                                            {guideline.typegroup == 'typefoods' ?
                                                                <div>
                                                                    <input type='checkbox' name='grouptypeOther'
                                                                        value={'typefoods'} onClick={(e) => showProductType(e)}
                                                                        defaultChecked={true} /> <label>Group foods </label>
                                                                </div> :
                                                                <div>
                                                                    <input type='checkbox' name='grouptypeOther'
                                                                        value={'typefoods'} onClick={(e) => showProductType(e)}
                                                                    /> <label>Group foods </label>
                                                                </div>
                                                            }
                                                        </center>
                                                    </div>




                                                    <div style={{ marginLeft: 150 }}>
                                                        {dataDocument.guideline.productgroup.groupother.map((item, i) => (
                                                            <div>{item == 'Other' ? <div>
                                                                <input type='text' name='groupother_note' placeholder='other' style={{ width: 250 }}
                                                                    onChange={(e) => setGuideline({ ...guideline, groupother_note: e.target.value })}
                                                                />
                                                            </div> :
                                                                <div>
                                                                    {item == guideline.groupother ?
                                                                        <div>
                                                                            <input type='checkbox' name='productgroupother' value={item}
                                                                                onClick={(e) => handle_guidelinechecked(e)}
                                                                                defaultChecked={true}
                                                                            /> {item}
                                                                        </div> : <div>
                                                                            <input type='checkbox' name='productgroupother' value={item}
                                                                                onClick={(e) => handle_guidelinechecked(e)}

                                                                            /> {item}
                                                                        </div>}
                                                                </div>}</div>
                                                        ))}

                                                    </div>
                                                </div> :
                                                <div className='row-between-form'>
                                                    <div style={{ marginLeft: 50 }}>
                                                        <center>
                                                            {guideline.typegroup == 'dryfood' ?
                                                                <div>
                                                                    <input type='checkbox' name='grouptypeDryfood' value={'dryfood'}
                                                                        onClick={(e) => showProductType(e)}
                                                                        defaultChecked={true}
                                                                    /> <label>Dry foods</label>
                                                                </div> :
                                                                <div>
                                                                    <input type='checkbox' name='grouptypeDryfood' value={'dryfood'}
                                                                        onClick={(e) => showProductType(e)}
                                                                    /> <label>Dry foods</label>
                                                                </div>
                                                            }

                                                        </center>
                                                        {dataDocument.guideline.productgroup.groupDryfoods.map((item, i) => (
                                                            <div>
                                                                {item == 'Other' ? <div>
                                                                    <input type='text' name='groupother_note' placeholder='other' style={{ width: 250 }}
                                                                        onChange={(e) => setGuideline({ ...guideline, groupother_note: e.target.value })}

                                                                    />
                                                                </div> :
                                                                    <div>
                                                                        {item == guideline.groupother ?
                                                                            <div>
                                                                                <input type='checkbox' name='productgroupother' value={item}
                                                                                    onClick={(e) => handle_guidelinechecked(e)}
                                                                                    defaultChecked={true}
                                                                                /> {item}
                                                                            </div> :
                                                                            <div>
                                                                                <input type='checkbox' name='productgroupother' value={item}
                                                                                    onClick={(e) => handle_guidelinechecked(e)}

                                                                                /> {item}
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                }</div>
                                                        ))}
                                                    </div>

                                                    <div style={{ marginLeft: 50 }}>
                                                        <center>
                                                            <input type='checkbox' name='grouptypeAerosol' value={'aerosol'}
                                                                onClick={(e) => showProductType(e)}
                                                                defaultChecked={guideline.typegroup == 'aerosol' && true}
                                                            /> <label>Aerosol can</label>
                                                        </center>
                                                        {dataDocument.guideline.productgroup.groupAerosolcan.map((item, i) => (
                                                            <div>
                                                                {item == 'Other' ? <div>
                                                                    <input type='text' name='groupother_note' placeholder='other' style={{ width: 250 }}
                                                                        onChange={(e) => setGuideline({ ...guideline, groupother_note: e.target.value })}

                                                                    />
                                                                </div> :
                                                                    <div>
                                                                        {guideline.groupother == item ?
                                                                            <div>
                                                                                <input type='checkbox' name='productgroupother' value={item}
                                                                                    onClick={(e) => handle_guidelinechecked(e)}
                                                                                    defaultChecked={true}
                                                                                /> {item}
                                                                            </div> :
                                                                            <div>
                                                                                <input type='checkbox' name='productgroupother' value={item}
                                                                                    onClick={(e) => handle_guidelinechecked(e)}

                                                                                /> {item}
                                                                            </div>
                                                                        }
                                                                    </div>}
                                                            </div>
                                                        ))}
                                                    </div>
                                                    <div style={{ marginLeft: 50 }}>
                                                        <center>
                                                            {guideline.typegroup == 'general' ?
                                                                <div>
                                                                    <input type='checkbox' name='grouptypeGeneral' value={'general'}
                                                                        onClick={(e) => showProductType(e)} /> <label>General can</label>
                                                                </div> :
                                                                <div>
                                                                    <input type='checkbox' name='grouptypeGeneral' value={'general'}
                                                                        onClick={(e) => showProductType(e)} /> <label>General can</label>
                                                                </div>
                                                            }
                                                        </center>
                                                        {dataDocument.guideline.productgroup.groupGeneralcan.map((item, i) => (
                                                            <div>
                                                                {item == 'Other' ? <div>
                                                                    <input type='text' name='groupother_note' placeholder='other' style={{ width: 250 }}
                                                                        onChange={(e) => setGuideline({ ...guideline, groupother_note: e.target.value })}

                                                                    />
                                                                </div> :
                                                                    <div>
                                                                        {guideline.groupother == item ?
                                                                            <div>
                                                                                <input type='checkbox' name='productgroupother' value={item}
                                                                                    onClick={(e) => handle_guidelinechecked(e)}
                                                                                    defaultChecked={true}
                                                                                /> {item}
                                                                            </div> :
                                                                            <div>
                                                                                <input type='checkbox' name='productgroupother' value={item}
                                                                                    onClick={(e) => handle_guidelinechecked(e)}

                                                                                /> {item}
                                                                            </div>
                                                                        }
                                                                    </div>}</div>
                                                        ))}
                                                    </div>
                                                </div>}
                                        </div>
                                    </div>

                                    <hr></hr>

                                    <div style={{ width: 670, marginBottom: 5 }} className='row-page'>
                                        <div style={{ width: 150 }}>
                                            <b>2.Details of product :</b>
                                        </div>
                                        <div>
                                            <textarea type='text' name='Detailsproduct' value={guideline.detailproduct} style={{ width: 500, height: 50 }}
                                                onChange={(e) => setGuideline({ ...guideline, detailproduct: e.target.value })} maxLength={350} />
                                        </div>
                                    </div>

                                    <div className='row-page' style={{ width: 1100, marginBottom: 5 }}>
                                        <div style={{ width: 150 }}>
                                            <b>3. PH:</b>
                                        </div>
                                        <div className='row-between-form' style={{ width: '50%' }}>
                                            {dataDocument.guideline.ph.data.map((item, i) => (
                                                <div>{guideline.ph == item ?
                                                    <div>
                                                        <input type='checkbox' name='ph' value={item}
                                                            onClick={(e) => handle_guidelinechecked(e)}
                                                            defaultChecked={true} /> {item}
                                                    </div> :
                                                    <div>
                                                        <input type='checkbox' name='ph' value={item}
                                                            onClick={(e) => handle_guidelinechecked(e)}
                                                        /> {item}
                                                    </div>
                                                }
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>

                                <div className='row-page' style={{ width: '100%', marginBottom: 5 }}>
                                    <div style={{ width: 150 }}>
                                        <b>4.Ingredients :</b>
                                    </div>
                                    <div className='row-between-form' style={{ width: 1200 }} >
                                        {dataDocument.guideline.ingredients.data.map((item, i) => (
                                            <div>
                                                {item == 'Other' ?
                                                    <div>
                                                        {item == guideline.ingredients ?
                                                            <div>
                                                                <input type='checkbox' name='Ingredientstype' value={item}
                                                                    onClick={(e) => handle_guidelinechecked(e)}
                                                                    defaultChecked={true}
                                                                /> {item}
                                                            </div> : <div>
                                                                <input type='checkbox' name='Ingredientstype' value={item}
                                                                    onClick={(e) => handle_guidelinechecked(e)}
                                                                    defaultChecked={false}
                                                                /> {item}
                                                            </div>
                                                        }

                                                        <input type='text' name='ingredientsOther' placeholder='input detail'
                                                            onChange={(e) => setGuideline({ ...guideline, ingredientother: e.target.value })} style={{ marginLeft: 20 }}

                                                        />
                                                    </div> :
                                                    <div>
                                                        <input type='checkbox' name='Ingredientstype' value={item}
                                                            onClick={(e) => handle_guidelinechecked(e)}
                                                        /> {item}
                                                    </div>
                                                        && item == 'Acid added' ?
                                                        <div>
                                                            {guideline.ingredients == item ?
                                                                <div>
                                                                    <input type='checkbox' name='Ingredientstype' value={item}
                                                                        onClick={(e) => handle_guidelinechecked(e)}
                                                                        defaultChecked={true}
                                                                    /> {item}
                                                                </div> : <div>
                                                                    <input type='checkbox' name='Ingredientstype' value={item}
                                                                        onClick={(e) => handle_guidelinechecked(e)}
                                                                    /> {item}
                                                                </div>
                                                            }
                                                            <input type='text' name='Acid_added'
                                                                value={guideline.ingredients_acid}
                                                                onChange={(e) => setGuideline({ ...guideline, ingredients_acid: e.target.value })}

                                                            />

                                                        </div> :
                                                        <div>
                                                            <input type='checkbox' name='Ingredientstype' value={item}
                                                                onClick={(e) => handle_guidelinechecked(e)}
                                                            /> {item}
                                                        </div>
                                                            && item == 'Chloride(as NaCI)' ?
                                                            <div>
                                                                {guideline.ingredients == item ? <div>
                                                                    <input type='checkbox' name='Ingredientstype' value={item}
                                                                        onClick={(e) => handle_guidelinechecked(e)}
                                                                        defaultChecked={true}
                                                                    /> {item}
                                                                </div> : <div>
                                                                    <input type='checkbox' name='Ingredientstype' value={item}
                                                                        onClick={(e) => handle_guidelinechecked(e)}
                                                                    // defaultChecked={}
                                                                    /> {item}
                                                                </div>}

                                                                <input type='text' name='Chloride'
                                                                    value={guideline.ingredients_chioride}
                                                                    onChange={(e) => setGuideline({ ...guideline, ingredients_chioride: e.target.value })} />

                                                            </div> :
                                                            <div>
                                                                {guideline.ingredients == item ? <div>
                                                                    <input type='checkbox' name='Ingredientstype' value={item}
                                                                        onClick={(e) => handle_guidelinechecked(e)}
                                                                        defaultChecked={true}
                                                                    /> {item}
                                                                </div> : <div>
                                                                    <input type='checkbox' name='Ingredientstype' value={item}
                                                                        onClick={(e) => handle_guidelinechecked(e)}

                                                                    /> {item}
                                                                </div>}
                                                            </div>
                                                }
                                            </div>
                                        ))}
                                    </div>
                                </div>

                                <div className='row-page' style={{ width: 1100, marginBottom: 5 }}>
                                    <div style={{ width: 150 }}>
                                        <b>5.Seamer condition :</b>
                                    </div>
                                    <div className='row-between-form' style={{ width: '50%' }}>
                                        {dataDocument.guideline.seamer.data.map((item, i) => (
                                            <div>{guideline.seamercondition == item ?
                                                <div>
                                                    <input type='checkbox' name='seamercondition' value={item}
                                                        onClick={(e) => handle_guidelinechecked(e)}
                                                        defaultChecked={true} /> {item}
                                                </div> :
                                                <div>
                                                    <input type='checkbox' name='seamercondition' value={item}
                                                        onClick={(e) => handle_guidelinechecked(e)}
                                                    /> {item}
                                                </div>}

                                            </div>
                                        ))}
                                    </div>
                                </div>


                                {typedocument == 'A1' && <div className='row-page' style={{ width: '100%', marginBottom: 5 }}>
                                    <div style={{ width: 150 }}>
                                        <b>6.Sterilization process :</b>
                                    </div>
                                    <div className='row-between-form' style={{ width: 1000 }}>
                                        {dataDocument.guideline.sterilization.data.map((item, i) => (
                                            <div style={{ width: "100%" }}>
                                                {item == 'Retort Temp/Time' ?
                                                    <div>
                                                        {guideline.sterilization == item ? <div>
                                                            <input type='checkbox' name='sterilization' value={item}
                                                                onClick={(e) => handle_guidelinechecked(e)}
                                                                defaultChecked={true}
                                                            /> {item}
                                                        </div> : <div>
                                                            <input type='checkbox' name='sterilization' value={item}
                                                                onClick={(e) => handle_guidelinechecked(e)}
                                                            /> {item}
                                                        </div>}

                                                        <input type='retorttemp' name='retorttemp' value={guideline.sterilization_retort}
                                                            onChange={(e) => setGuideline({ ...guideline, sterilization_retort: e.target.value })} />

                                                    </div> :
                                                    <div>
                                                        <input type='checkbox' name='sterilization' value={item}
                                                            onClick={(e) => setGuideline({ ...guideline, sterilization: e.target.value })}

                                                        /> {item}</div> &&
                                                        item == 'Cooker Temp/time' ?
                                                        <div>
                                                            {guideline.sterilization == item ? <div>
                                                                <input type='checkbox' name='sterilization' value={item}
                                                                    onClick={(e) => handle_guidelinechecked(e)}
                                                                    defaultChecked={true}
                                                                /> {item}
                                                            </div> : <div>
                                                                <input type='checkbox' name='sterilization' value={item}
                                                                    onClick={(e) => handle_guidelinechecked(e)}
                                                                /> {item}
                                                            </div>}
                                                            <input type='text' name='cooker_temp'
                                                                value={guideline.sterilization_cooker}
                                                                onChange={(e) => setGuideline({ ...guideline, sterilization_cooker: e.target.value })} />
                                                        </div> :
                                                        <div>
                                                            <input type='checkbox' name='sterilization'
                                                                onClick={(e) => handle_guidelinechecked(e)}
                                                            /> {item}</div> &&
                                                            item == 'Other' ?
                                                            <div>
                                                                {guideline.sterilization == item ? <div>
                                                                    <input type='checkbox' name='sterilization' value={item}
                                                                        onClick={(e) => handle_guidelinechecked(e)}
                                                                        defaultChecked={true}
                                                                    /> {item}
                                                                </div> : <div>
                                                                    <input type='checkbox' name='sterilization' value={item}
                                                                        onClick={(e) => handle_guidelinechecked(e)}
                                                                    /> {item}
                                                                </div>}

                                                                <input type='text' name='sterilization' placeholder='input detail'
                                                                    value={guideline.sterilizationother}
                                                                    onChange={(e) => setGuideline({ ...guideline, sterilizationother: e.target.value })}
                                                                />
                                                            </div> :
                                                            <div>
                                                                <input type='checkbox' name='sterilization' value={item}
                                                                    onClick={(e) => handle_guidelinechecked(e)}
                                                                /> {item}
                                                            </div> &&
                                                                item == guideline.sterilization ?
                                                                <div>

                                                                    <input type='checkbox' name='sterilization' value={item}
                                                                        onClick={(e) => handle_guidelinechecked(e)}
                                                                        defaultChecked={true}
                                                                    /> {item}
                                                                </div> : <div>
                                                                    <input type='checkbox' name='sterilization' value={item}
                                                                        onClick={(e) => handle_guidelinechecked(e)}
                                                                    // defaultChecked={true}
                                                                    /> {item}
                                                                </div>
                                                }
                                            </div>
                                        ))}
                                    </div>

                                </div>}

                                <div> {ProductgorupError == true ? <label style={{ color: 'red' }}>* มีรายการเลือกเกิน 1 รายการ *</label> : ""} </div>
                            </div>
                        </Tab>

                        <Tab eventKey="Regulation" title="Regulation" style={{ backgroundColor: 'aliceblue' }}>
                            <div style={{ marginLeft: 10, width: 1000 }} >

                                <div style={{ width: 150 }}>
                                    <b>Regulation :</b>
                                </div>
                                <div className='row-between-form'>
                                    {dataDocument.regulation.data.map((item, i) => (
                                        <div>
                                            {getGroup(item) == item ?
                                                <div style={{ color: 'red' }}>
                                                    <input type='checkbox' name={`Regulationtype`} value={item} onClick={(e) => handleChange(e)} defaultChecked={true} /> {item}
                                                </div> :
                                                <div>
                                                    <input type='checkbox' name={`Regulationtype`} value={item} onClick={(e) => handleChange(e)} /> {item}
                                                </div>
                                            }
                                            <div>

                                            </div>
                                        </div>
                                    ))}
                                    <div>
                                        {/* regulationtype */}
                                        <input type='checkbox' name={`Regulationtype`} value={"Other"} onChange={(e) => handleChange(e)} /> <label>Other</label>
                                        <input type='text' name='regulationother' value={regulationGroup.regulationother} onChange={(e) => setregulationOther(e.target.value)} />
                                    </div>
                                </div>

                                <hr></hr>
                                <div>
                                    <div>

                                        <label style={{ fontSize: 16 }}><b>Request Sample : </b></label>

                                        <div className='row-page'>
                                            <div style={{ width: 100 }}>
                                                <label>Proof plate:</label>
                                            </div>
                                            <input type='text' name='proofplate' value={requestsample.proofplate}
                                                onChange={(e) => setRequestsample({ ...requestsample, proofplate: e.target.value })} style={{ width: 200 }} />
                                        </div>
                                        <div className='row-page'>
                                            <div style={{ width: 100 }}>
                                                <label>Can :</label>
                                            </div>
                                            <input type='text' name='sample_can' value={requestsample.requestcan}
                                                onChange={(e) => setRequestsample({ ...requestsample, requestcan: e.target.value })} style={{ width: 200 }} />
                                        </div>
                                        <div className='row-page'>
                                            <div style={{ width: 100 }}>
                                                <label>End :</label>
                                            </div>
                                            <input type='text' name='proofplate' value={requestsample.requestend}
                                                onChange={(e) => setRequestsample({ ...requestsample, requestend: e.target.value })} style={{ width: 200 }} />
                                        </div>
                                    </div>

                                    <div style={{ marginTop: 10 }}>
                                        <div><b>Note Detail :  </b>
                                        </div>
                                        <textarea name='regulationnote'
                                            maxlength="350"
                                            value={requestsample.requestnote}
                                            onChange={(e) => setRequestsample({ ...requestsample, requestnote: e.target.value })}
                                            style={{ width: 500, height: 100 }} />
                                    </div>
                                </div>
                            </div>

                        </Tab>

                        <Tab eventKey="componetcode" title="Componet Code" style={{ backgroundColor: 'aliceblue' }}>
                            <div style={{ marginLeft: 10, width: '100%' }} >
                                {componentCodeall != "" &&
                                    <EditComponentCode componentdata={componentCodeall} />
                                }
                            </div>
                        </Tab>

                    </Tabs>
                </form >
            }

        </div >

    )
}



const Editjournals = (id) => {


    const values = [true, 'sm-down', 'md-down', 'lg-down', 'xl-down', 'xxl-down'];
    const [fullscreen, setFullscreen] = useState(true);
    const [show, setShow] = useState(false);

    function handleShow(value) {
        setShow(value);
    }

    return (
        <div>
            <Button variant="warning" size="sm" style={{ fontSize: 10 }} onClick={() => handleShow(true)} >แก้ไข รายละเอียด</Button>

            <Modal show={show} fullscreen={fullscreen} onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>แก้ไขข้อมูล TECHBICAL DATA SHEET PART A </Modal.Title>
                </Modal.Header>
                <Modal.Body><FormNew handleShow={handleShow} ids={id} /></Modal.Body>
            </Modal>
        </div>
    )
}

export default Editjournals