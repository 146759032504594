import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import PopupFGAll from './popupComponent/popupFGAll'
import {
    addEarwelding, addEar, addRing, addInnerlid, addOuterlid, addToplid,
    addBottomlid, addPlasticlid, addAluminumlid
} from '../../../reduxSlice/itemcomponentSlice/itemcomponentSlice';

function EditComponentCode({ ...props }) {

    const dispatch = useDispatch();
    const compoData = useSelector((state) => state.itemcomponent.datacomponent)

    const [dataCompo, setDataCompo] = useState({
        ear: compoData.ear,
        earwelding: compoData.earwelding,
        ring: compoData.ring,
        innerlid: compoData.innerlid,
        outerlid: compoData.outerlid,
        toplid: compoData.toplid,
        bottomlid: compoData.bottomlid,
        plasticlid: compoData.plasticlid,
        aluminumlid: compoData.aluminumlid,
        journalId: props.journalId,
        tdscode: "",
    })

    useEffect(() => { }, [compoData])



    function onChangetext(e) {

        if (e.target.name == 'Earwelding') {
            setDataCompo({ ...dataCompo, earwelding: e.target.value })
            dispatch(addEarwelding(e.target.value))
        }
        else if (e.target.name == 'Ear') {
            setDataCompo({ ...dataCompo, ear: e.target.value })
            dispatch(addEar(e.target.value))
        }
        else if (e.target.name == 'Ring') {
            setDataCompo({ ...dataCompo, ring: e.target.value })
            dispatch(addRing(e.target.value))
        }
        else if (e.target.name == 'Innerlid') {
            setDataCompo({ ...dataCompo, innerlid: e.target.value })
            dispatch(addInnerlid(e.target.value))
        }
        else if (e.target.name == 'Outerlid') {
            setDataCompo({ ...dataCompo, outerlid: e.target.value })
            dispatch(addOuterlid(e.target.value))

        }
        else if (e.target.name == 'Toplid') {
            setDataCompo({ ...dataCompo, toplid: e.target.value })
            dispatch(addToplid(e.target.value))
        }
        else if (e.target.name == 'Bottomlid') {
            setDataCompo({ ...dataCompo, bottomlid: e.target.value })
            dispatch(addBottomlid(e.target.value))
        }
        else if (e.target.name == 'Plasticlid') {
            setDataCompo({ ...dataCompo, plasticlid: e.target.value })
            dispatch(addPlasticlid(e.target.value))
        }
        else if (e.target.name == 'Aluminumlid') {
            setDataCompo({ ...dataCompo, aluminumlid: e.target.value })
            dispatch(addAluminumlid(e.target.value))
        }
    }

    return (
        <div>

            <div style={{ marginLeft: '10%', backgroundColor: '#F0F0F0', padding: 10, borderRadius: 5 }}>
                <center><div style={{ backgroundColor: '#989898' }}>เพิ่ม Component Code</div></center>
                <div className='row-page' style={{ marginTop: 10 }}>
                    <div style={{ width: 100 }}>หูออ๊ก/ถ้วยอ๊อก</div>
                    <div className='row-page' >
                        <input type='text' name='Earwelding'
                            defaultValue={compoData.earwelding}
                            onChange={onChangetext}
                            style={{ width: 300 }} />
                        <PopupFGAll typecom={'Earwelding'} />
                    </div>
                </div>
                <div className='row-page' style={{ marginTop: 10 }}>
                    <div style={{ width: 100 }}>หู</div>
                    <div className='row-page' >
                        <input type='text' name='Ear'
                            defaultValue={compoData.ear}
                            onChange={onChangetext}
                            style={{ width: 300 }} />
                        <PopupFGAll typecom={'Ear'} />
                    </div>
                </div>
                <div className='row-page' style={{ marginTop: 10 }}>
                    <div style={{ width: 100 }}>ห่วง</div>
                    <div className='row-page' >
                        <input type='text' name='Ring'
                            defaultValue={compoData.ring}
                            onChange={onChangetext}
                            style={{ width: 300 }} />
                        <PopupFGAll typecom={'Ring'} />
                    </div>
                </div>
                <div className='row-page' style={{ marginTop: 10 }}>
                    <div style={{ width: 100 }}>ฝาใน</div>
                    <div className='row-page' >
                        <input type='text' name='Innerlid'
                        defaultValue={compoData.innerlid}
                        onChange={onChangetext}
                        style={{ width: 300 }} />
                        <PopupFGAll typecom={'Innerlid'} />
                    </div>
                </div>
                {/* <div className='row-page' style={{ marginTop: 10 }}>
                    <div style={{ width: 100 }}>ฝานอก</div>
                    <div className='row-page' >
                        <input type='text' name='Outerlid'
                            defaultValue={compoData.outerlid}
                            onChange={onChangetext}
                            style={{ width: 300 }} />
                        <PopupFGAll typecom={'Outerlid'} />
                    </div>
                </div> */}
                <div className='row-page' style={{ marginTop: 10 }}>
                    <div style={{ width: 100 }}>ฝาบน</div>
                    <div className='row-page' >
                        <input type='text' name='Toplid'
                        defaultValue={compoData.toplid}
                        onChange={onChangetext}
                        style={{ width: 300 }} />
                        <PopupFGAll typecom={'Toplid'} />
                    </div>
                </div>
                {/* <div className='row-page' style={{ marginTop: 10 }}>
                    <div style={{ width: 100 }}>ฝาล่าง</div>
                    <div className='row-page' >
                        <input type='text' name='Bottomlid'
                            defaultValue={compoData.bottomlid}
                            onChange={onChangetext}
                            style={{ width: 300 }} />
                        <PopupFGAll typecom={'Bottomlid'} />
                    </div>
                </div> */}
                <div className='row-page' style={{ marginTop: 10 }}>
                    <div style={{ width: 100 }}>ฝาพลาสติก</div>
                    <div className='row-page' >
                        <input type='text' name='Plasticlid'
                        defaultValue={compoData.plasticlid}
                        onChange={onChangetext}
                        style={{ width: 300 }} />
                        <PopupFGAll typecom={'Plasticlid'} />
                    </div>
                </div>
                <div className='row-page' style={{ marginTop: 10 }}>
                    <div style={{ width: 100 }}>ฝาอลูมิเนียม</div>
                    <div className='row-page' >
                        <input type='text' name='Aluminumlid'
                        defaultValue={compoData.aluminumlid}
                        onChange={onChangetext}
                        style={{ width: 300 }} />
                        <PopupFGAll typecom={'Aluminumlid'} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditComponentCode