import DataTable from 'react-data-table-component';
import React from 'react'

// import Editjournals from './editjournals';
import Actionjourparta from './actionjourparta';


const columns = [
    {
        name: 'TDS code',
        sortable: true,
        selector: row => row.tdscode,
    },
    {
        name: 'Journal Type',
        selector: row => row.journaltype,
    },
    {
        name: 'customer name',
        selector: row => row.customername,
    },
    {
        name: 'itemname',
        selector: row => row.itemname,
    },
    {
        name: 'revision',
        selector: row => row.revision,
    },
    // {
    //     name: 'duedate',
    //     sortable: true,
    //     selector: row => row.duedate,
    // },
    {
        name: 'createdate',
        selector: row => row.createdate,
    },
    {
        name: 'Action',
         grow: 3,
        cell: row =><Actionjourparta id={row.journalId}/>,
    },

];

const customStyles = {
    headRow: {
        style: {
            border: 'none',
            backgroundColor: '#28B463'
        },
    },
    headCells: {
        style: {
            color: '#ffff',
            fontSize: '14px',
        },
    },
    rows: {
        highlightOnHoverStyle: {
            backgroundColor: 'rgb(230, 244, 244)',
            borderBottomColor: '#FFFFFF',
            borderRadius: '25px',
            outline: '1px solid #FFFFFF',
        },
    },
    pagination: {
        style: {
            border: 'none',
        },
    },
};



const Journaltable = (datatable) => {
    var dataresult = datatable.datatable;
  

    return (
    
        <DataTable
            columns={columns}
            data={dataresult}
            // selectableRows
            pagination
            customStyles={customStyles}
            highlightOnHover

        />
    )
}

export default Journaltable