import React,{useState,useEffect} from 'react';
import { Button } from 'react-bootstrap';
import{statusflowall}from'../../form01/object-usestate/objectdefaults';
import FetchApi from '../../customhooks/functionFetchApi';
import Swal from 'sweetalert2'

const Fg1approveState = ({idjournal,stateflow}) => {
    const FetchApis=new FetchApi();
    const emCode = sessionStorage.getItem('EmCode');
  function recievedjournal() {

    var dataint = parseInt(stateflow) + 1; //อัพเดท journal

    var statejournal = parseInt(stateflow) //อัพเดท taskflow
    var dataobject = {
      journalid: idjournal,
      emcode: emCode,
      stateflow: statejournal,
      approvecode: statusflowall.approvejournal,
      dedicatecode: "",
      tasknote: ""
    }
    // // create task flow
    FetchApis.FethcPost(`/taskflow/createTaskflow`, dataobject).then((res) => {
      if (res.status == 200) {
        updatejournal(dataint);
      }
    })
  }

  // //update flowstatus journal
  function updatejournal(stateflows) {

    var updatejournal = {
      statusflow: statusflowall.sendjournal,
      stateflow: stateflows
    }

    // setNewjournalUpdate(updatejournal);
    FetchApis.FethcUpdate(`/journal/updatestateflow/${idjournal}`, updatejournal).then((res) => {
      if (res.status == 200) {

        Swal.fire({
          title: "อนุมัติเอกสาร!",
          text: "อนุมัติเอกสารเรียบแล้ว",
          icon: "success"
        });

        setTimeout(() => {
          window.location.reload();
        }, 500);
      }
    })

  }

function approvestate(){

  Swal.fire({
    title: "บันทึกเอกสาร",
    text: "คุณแน่ใจที่จะอนุมัติเอกสาร ใช่หรือไม่ ! ",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "ใช่",
    cancelButtonText: 'ยกเลิก'
  }).then((result) => {

    if (result.isConfirmed) {
      recievedjournal()
    }
  });

}

  return (
    <div>
        <Button variant="success"   style={{ fontSize: 12 ,marginRight:5}} onClick={()=>approvestate()}>อนุมัติเอกสาร</Button>
    </div>
  )
}

export default Fg1approveState