import React from 'react'
import EditformsHeader from './editformsHeader'
import Newjournals from './newjournals'
const Actionforms = ({id}) => {
     
  return (
    <div style={{flexDirection:'row',display:'flex',justifyContent:'space-between'}}>
        <div style={{marginRight:5}}><Newjournals id={id}/></div>
        <div><EditformsHeader ids={id}/> </div>
    </div>
  )
}

export default Actionforms