import React,{useEffect,useState} from 'react';
import Approvebyuser from './approvebyprepass';
import JournaldetailList from './journaldetailListprepass';
const actionlistprepass = ({id,journalcode,typeproduct}) => {
  return (
    <div className='row-page'>
        <div style={{marginRight:10}}> <Approvebyuser id={id} journalcode={journalcode} typeproduct={typeproduct}/> </div>
    <div> <JournaldetailList id={id} jourcode={journalcode}/> </div>
    </div>
  )
}

export default actionlistprepass