import React, { useState, useEffect } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import dataform from '../json-data/datatypeform.json';
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import FetchApi, { host } from '../../customhooks/functionFetchApi';
import ViewImages from './viewImages';
import { ToastContainer, toast } from 'react-toastify';
import Swal from 'sweetalert2';
import {
    objectcoatingbody, objectcoatingtop, objectcoatingbottom,
    objectforming, objectguideline, objectrequest, statusflowall,
    objectcoatingring, objectcoatingear, objectcomponentcode
} from '../object-usestate/objectdefaults';



function FormNew({ handleShow, ids }) {

    const jourId = ids.id;

    const Emcode = sessionStorage.getItem('EmCode');
    const FetchApis = new FetchApi(); //fatch data
    const [key, setKey] = useState('jouranl');

    const [coatingbody, setCoatingbody] = useState({ ...objectcoatingbody });
    const [coatingtop, setCoatingtop] = useState({ ...objectcoatingtop });
    const [coatingbottom, setCoatingbottom] = useState({ ...objectcoatingbottom });
    const [coatingring, setCoatingring] = useState({ ...objectcoatingring });
    const [coatingear, setCoatingear] = useState({ ...objectcoatingear });
    const [componentCodeall, setComponentCode] = useState({ ...objectcomponentcode });

    const [forming, setForming] = useState({ ...objectforming });
    const [guideline, setGuideline] = useState({ ...objectguideline })
    const [requestsample, setRequestsample] = useState({ ...objectrequest });

    const [regulation, setRegulation] = useState([])
    const [regulationGroup, setregulationGroup] = useState([]);

    const [regulationcheck, setRegulationcheck] = useState([]); // ค่อยปรับ
    const [regulationOter, setregulationOther] = useState([]);  // ค่อยปรับ
    const [datafetch, setDatafetch] = useState([]);

    useEffect(() => {
        FetchApis.FethcGet(`/journal/journalbyId/${jourId}`).then((datafetch) => {

            var codeid = "";
            if (datafetch.data.status == 200) {
                setDatafetch(datafetch.data.data)
                codeid = datafetch.data.data[0].tdscode;

                setGuideline({
                    ...guideline,
                    tdscode: codeid,
                    journalId: jourId
                });
                setRequestsample({
                    ...requestsample,
                    tdscode: codeid,
                    journalId: jourId
                });

                setComponentCode({
                    ...componentCodeall,
                    tdscode: codeid,
                    journalId: jourId
                })

                getCoatingbody(jourId);
                getforming(jourId);
                getguideline(jourId);
                getregulation(jourId);
                getrequestsample(jourId);
                getcomponentcodelist(jourId);
            }
        })
    }, [])


    async function getCoatingbody(jourid) {

        await FetchApis.FethcGet(`/coating/coatingByJournalId/${jourid}`).then((datacoating) => {
            if (datacoating.data.status == 200) {
                
                for (let index = 0; index < datacoating.data.data.length; index++) {

                    if (datacoating.data.data[index].producttype == 'body') {
                        var databodytypes=datacoating.data.data[index].groupBobyType.split(',')
                       
                        setCoatingbody({...datacoating.data.data[index],groupBobyType:databodytypes});

                    }
                    else if (datacoating.data.data[index].producttype == 'top') {
                        setCoatingtop(datacoating.data.data[index])

                    }
                    else if (datacoating.data.data[index].producttype == 'bottom') {
                        setCoatingbottom(datacoating.data.data[index])

                    }
                    else if (datacoating.data.data[index].producttype == 'ring') {
                        console.log(datacoating.data.data[index].producttype);
                        setCoatingring(datacoating.data.data[index])
                    }
                    else if (datacoating.data.data[index].producttype == 'ear') {
                        setCoatingear(datacoating.data.data[index])
                    }
                }
            }
        })
    }

    async function getforming(jourid) {

        await FetchApis.FethcGet(`/forming/formingByJournalId/${jourid}`).then((datacoating) => {
            if (datacoating.data.status == 200) {
                setForming(datacoating.data.data[0])

            }

        })
    }

    async function getguideline(jourid) {

        await FetchApis.FethcGet(`/guideline/guidelineByJournalId/${jourid}`).then((datacoating) => {
            if (datacoating.data.status == 200) {
                setGuideline(datacoating.data.data[0])
            }

        })
    }

    async function getregulation(jourid) {

        await FetchApis.FethcGet(`/regulation/regulationByJourId/${jourid}`).then((datacoating) => {
            if (datacoating.data.status == 200) {

                setRegulation(datacoating.data.data[0])
                var datagroup = datacoating.data.data[0].regulationtype.split(",");
                setregulationGroup(datagroup);
                setRegulationcheck(datagroup);
            }

        })
    }
    // setRequestsample
    async function getrequestsample(jourid) {

        await FetchApis.FethcGet(`/requestsample/requestsampleByJournalId/${jourid}`).then((datacoating) => {
            if (datacoating.data.status == 200) {
                setRequestsample(datacoating.data.data[0]);
            }

        })
    }

    //component code
    async function getcomponentcodelist(jourid) {
        FetchApis.FethcGet(`/componentcode/componentByjournalId/${jourid}`).then((res) => {

            if (res.data.status == 200) {
                // console.log(res.data.data[0])
                console.log(res.data);
                setComponentCode(res.data.data[0])
            }
        })
    }


    async function submitSave(e) {
        e.preventDefault();

        Swal.fire({
            title: "แจ้งเตือน",
            text: "คุณแน่ใจที่ส่งข้อมูลเอกสาร ใช่หรือไม่ ! ",
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "ใช่",
            cancelButtonText:'ยกเลิก'
          }).then((result) => {
      
            if (result.isConfirmed) {
            var stateflowall = {
                statusflow: statusflowall.sendjournal,
                stateflow: "1"
            }


        var stateflowall = {
            statusflow: statusflowall.sendjournal,
            stateflow: "1",
            tasknote: ""
        }
        // update state flow  new journal 
         FetchApis.FethcUpdate(`/journal/updatestateflow/${datafetch[0].journalId}`, stateflowall).then((res) => { console.log(res) })

        var taskeflows = {
            journalid: datafetch[0].journalId,
            emcode: Emcode,
            stateflow: "1",
            approvecode: statusflowall.sendjournal,
            dedicatecode: ""
        }

        //create task send journal
         FetchApis.FethcPost(`/taskflow/createTaskflow`, taskeflows).then((res) => {
            // console.log(res);

            if (res.status == 200) {

                toast.success('บันทึกเรียบร้อยแล้ว', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    // transition: Bounce,
                });

                setTimeout(() => {
                    handleShow(false);
                    window.location.reload();
                }, 4000);


            }

        })
    }
})


    }


    return (

        <div className='form-body' >
            <form onSubmit={(e) => submitSave(e)}>
                <div style={{ width: '100%', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'flex-end', display: 'flex' }}>
                    <div style={{ marginRight: 10 }}>
                        <Button type='submit' size='sm' style={{ background: '#0DB48B', borderColor: '#0DB48B' }}>ส่งข้อมูล</Button>
                    </div>
                </div>
                <Tabs
                    id="controlled-tab-example"
                    activeKey={key}
                    onSelect={(k) => setKey(k)}
                    className="mb-3"
                >
                    <Tab eventKey="jouranl" title="Form ( F-MK-009A1-1 ) ตรวจสอบข้อมูลก่อนบันทึก" style={{ backgroundColor: 'aliceblue' }}>
                        <div>
                            {datafetch.length > 0 && <div>
                                <div><center><b>TECHNICAL DATA SHEET : {datafetch[0].typeproduct == 'A1' ? "Food can Product" : "Dry foods ,Arosol can and General can Product"}</b></center></div>
                                <div>

                                    <table style={{ width: '100%' }} >
                                        <tr>
                                            <th><center>Create product</center></th>
                                            <th><center>Due Date</center></th>
                                            <th><center>MK NO</center></th>
                                            <th><center>Date Cretae</center></th>
                                        </tr>
                                        <tr>
                                            <th style={{ paddingLeft: 5 }}>{datafetch[0].journaltype}</th>
                                            <th style={{ paddingLeft: 5 }}>{datafetch[0].duedate}</th>
                                            <th style={{ paddingLeft: 5 }}>{datafetch[0].tdscode}</th>
                                            <th style={{ paddingLeft: 5 }}>{datafetch[0].createdate}</th>
                                        </tr>
                                    </table>
                                </div>

                                <div style={{ width: '100%' }} className='row-between-form'>
                                    <div style={{ width: '50%' }} className='border_green'>
                                        <table style={{ width: '100%' }} >
                                            <tr>
                                                <td style={{ width: '30%' }}>Product name : </td>
                                                <td>{datafetch[0].productname}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ width: '30%' }}>Can Size /Dia : </td>
                                                <td> {datafetch[0].cansize}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ width: '30%' }}>Shape : </td>
                                                <td> {datafetch[0].shape}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ width: '30%' }}>Item name : </td>
                                                <td>{datafetch[0].itemname}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ width: '30%' }}>Item NO : </td>
                                                <td>{datafetch[0].itemcode}</td>
                                            </tr>

                                        </table>
                                    </div>
                                    <div style={{ width: '50%', marginLeft: 3 }} className='border_green'>
                                        <table style={{ width: '100%' }} >
                                            <tr>
                                                <td style={{ width: '30%' }}>Customer name : </td>
                                                <td>{datafetch[0].customername}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ width: '30%' }}>Contact : </td>
                                                <td>{datafetch[0].contact} </td>
                                            </tr>
                                            <tr>
                                                <td style={{ width: '30%' }}>Tel : </td>
                                                <td>{datafetch[0].tel} </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                                <div>
                                    <table style={{ width: '100%' }}>
                                        <tr>
                                            <th>Substrate</th>
                                            <th>Product group</th>
                                            <th>Information from customer</th>
                                            <th>Printing system</th>
                                        </tr>
                                        <tr>
                                            <td><input type='checkbox' checked={true} disabled /> {datafetch[0].substratetype}</td>
                                            <td><input type='checkbox' checked={true} disabled /> {datafetch[0].productgrouptype}</td>
                                            <td><input type='checkbox' checked={true} disabled /> {datafetch[0].informationtype=='false'?'! ยังไม่ได้ระบบ ข้อมูล':datafetch[0].informationtype}</td>
                                            <td >
                                               
                                                <input type='checkbox' checked={true} disabled /> {datafetch[0].printingtype == 'false' ? '! ยังไม่ได้ระบบ ข้อมูล  ( printing system ) ' : datafetch[0].printingtype}
                                                {" "}{datafetch[0].printingtypeother!==""&& ( datafetch[0].printingtypeother)}
                                                <tr >
                                                    <td style={{ width: '30%' }}><b>Last film Code:</b></td>
                                                    <td style={{ width: '100%' }}>{datafetch[0].filmcode}</td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        {datafetch[0].filmtype == 'Scrap' ? <div><input type='checkbox' checked={true} disabled /> Scrap</div> : <div><input type='checkbox' checked={false} disabled /> Scrap</div>}
                                                    </td>
                                                    <td>
                                                        {datafetch[0].filmtype == 'Maintain' ? <div><input type='checkbox' checked={true} disabled /> Maintain</div> : <div><input type='checkbox' checked={false} disabled /> Maintain</div>}
                                                    </td>
                                                </tr>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>}
                            <hr></hr>
                            <div>

                                <div><b>Coating system</b></div>
                                <div className='row-page' style={{ width: '100%' }}>
                                    <div style={{ width: '10%' }}>
                                        <b>Bobdy :</b>
                                    </div>
                                    <div style={{ width: '100%' }}>
                                        <table style={{ width: '100%' }} >
                                            <tr className='borderWidth-form'>
                                                <th><center>Product shape</center></th>
                                                <th><center>Side</center></th>
                                                <th><center>Coating system</center></th>
                                                <th><center>White Coat</center></th>
                                                <th><center>Other</center></th>
                                            </tr>
                                            <tr>
                                                <td style={{ paddingLeft: 5, width: '15%' }}>
                                                    {coatingbody.groupBobyType.map((item,i)=>(
                                                        <div>{i+1}. {item}</div>
                                                    ))}</td>
                                                <td style={{ width: '10%' }}><center><b>Int .</b></center></td>
                                                <td style={{ paddingLeft: 5, width: '20%' }}>{coatingbody.intcoating}</td>
                                                <td style={{ paddingLeft: 5, width: '30%' }}>{coatingbody.intwhite}</td>
                                                <td style={{ paddingLeft: 5, width: '30%' }}>{coatingbody.intother}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ paddingLeft: 5, width: '15%' }}></td>
                                                <td style={{ width: '10%' }}><center><b>Ext .</b></center></td>
                                                <td style={{ paddingLeft: 5, width: '20%' }}>{coatingbody.extcoating}</td>
                                                <td style={{ paddingLeft: 5, width: '30%' }}>{coatingbody.extwhite}</td>
                                                <td style={{ paddingLeft: 5, width: '30%' }}>{coatingbody.extother}</td>
                                            </tr>
                                        </table>
                                        <div><b>FG component : </b>{coatingbody.fgcomponent}</div>
                                        <div><b> Note : </b>{coatingbody.note}</div>
                                    </div>
                                </div>

                                <div className='row-between-form' style={{ width: '100%' }}>
                                    <div style={{ width: '10%' }}>
                                        <b>Top end :</b>
                                        <div># : <b>{coatingtop.number}</b></div>
                                    </div>
                                    <div style={{ width: '100%' }}>
                                        <table style={{ width: '100%' }} >
                                            <tr className='borderWidth-form' style={{ backgroundColor: '' }}>
                                                <th style={{ paddingLeft: 5, width: '10%' }}><center>Product Substrate</center></th>
                                                <th style={{ paddingLeft: 5, width: '10%' }}><center>Product shape</center></th>
                                                <th style={{ width: '10%' }}><center>Side</center></th>
                                                <th style={{ paddingLeft: 5, width: '10%' }}><center>Coating system</center></th>
                                                <th style={{ paddingLeft: 5, width: '30%' }}><center>White Coat</center></th>
                                                <th style={{ paddingLeft: 5, width: '30%' }}><center>Other</center></th>
                                            </tr>
                                            <tr>
                                                <td ><center>{coatingtop.substrate}</center></td>
                                                <td ><center>{coatingtop.shape}</center></td>
                                                <td ><center><b>Int .</b></center></td>
                                                <td >{coatingtop.intcoating}</td>
                                                <td >{coatingtop.intwhite}</td>
                                                <td >{coatingtop.intother}</td>
                                            </tr>
                                            <tr>
                                                <td ></td>
                                                <td ></td>
                                                <td ><center><b>Ext .</b></center></td>
                                                <td >{coatingtop.extcoating}</td>
                                                <td >{coatingtop.extwhite}</td>
                                                <td >{coatingtop.extother}</td>
                                            </tr>
                                        </table>
                                        <div><b>For sale order:</b> {coatingtop.forsaleorder}</div>
                                        <div><b>FG component : </b>{coatingtop.fgcomponent}</div>
                                        <div><b>Note : </b>{coatingtop.note}</div>
                                    </div>
                                </div>
                                <div className='row-between-form' style={{ width: '100%' }}>
                                    <div style={{ width: '10%' }}>
                                        <b>Bottom end :</b>
                                        <div># : <b>{coatingbottom.number}</b></div>
                                    </div>
                                    <div style={{ width: '100%' }} >
                                        <table style={{ width: '100%' }} >
                                            <tr className='borderWidth-form' style={{ backgroundColor: '' }}>
                                                <th style={{ paddingLeft: 5, width: '10%' }}><center>Product Substrate</center></th>
                                                <th style={{ paddingLeft: 5, width: '10%' }}><center>Product shape</center></th>
                                                <th style={{ width: '10%' }}><center>Side</center></th>
                                                <th style={{ paddingLeft: 5, width: '10%' }}><center>Coating system</center></th>
                                                <th style={{ paddingLeft: 5, width: '30%' }}><center>White Coat</center></th>
                                                <th style={{ paddingLeft: 5, width: '30%' }}><center>Other</center></th>
                                            </tr>
                                            <tr>
                                                <td ><center>{coatingbottom.substrate}</center></td>
                                                <td ><center>{coatingbottom.shape}</center></td>
                                                <td ><center><b>Int .</b></center></td>
                                                <td >{coatingbottom.intcoating}</td>
                                                <td >{coatingbottom.intwhite}</td>
                                                <td >{coatingbottom.intother}</td>
                                            </tr>
                                            <tr>
                                                <td ></td>
                                                <td ></td>
                                                <td ><center><b>Ext .</b></center></td>
                                                <td >{coatingbottom.extcoating}</td>
                                                <td >{coatingbottom.extwhite}</td>
                                                <td >{coatingbottom.extother}</td>
                                            </tr>
                                        </table>
                                        <div><b>For sale order: </b> {coatingbottom.forsaleorder}</div>
                                        <div><b>FG component :</b> {coatingbottom.fgcomponent}</div>
                                        <div><b>Note : </b> {coatingbottom.note}</div>
                                    </div>
                                </div>

                                <div className='row-between-form' style={{ width: '100%' }}>
                                    <div style={{ width: '10%' }}>
                                        <b>Ring :</b>
                                    </div>
                                    <div style={{ width: '100%' }} >
                                        <table style={{ width: '100%' }} >
                                            <tr className='borderWidth-form' style={{ backgroundColor: '' }}>
                                                <th style={{ paddingLeft: 5, width: '10%' }}><center>Dia</center></th>
                                                <th style={{ paddingLeft: 5, width: '10%' }}><center>Ring Type</center></th>
                                                <th style={{ paddingLeft: 5, width: '20%' }}><center>Code</center></th>
                                                <th style={{ width: '10%' }}><center>Side</center></th>
                                                <th style={{ paddingLeft: 5, width: '10%' }}><center>Coating system</center></th>
                                                <th style={{ paddingLeft: 5, width: '20%' }}><center>White Coat</center></th>
                                                <th style={{ paddingLeft: 5, width: '20%' }}><center>Other</center></th>
                                            </tr>
                                            <tr>
                                                <td ><center>{coatingring.dia}</center></td>
                                                <td ><center>{coatingring.ring_eartype}</center></td>
                                                <td><center>{coatingring.ring_earcode}</center></td>
                                                <td ><center><b>Int .</b></center></td>
                                                <td >{coatingring.intcoating}</td>
                                                <td >{coatingring.intwhite}</td>
                                                <td >{coatingring.intother}</td>
                                            </tr>
                                            <tr>
                                                <td ></td>
                                                <td ></td>
                                                <td></td>
                                                <td ><center><b>Ext .</b></center></td>
                                                <td >{coatingring.extcoating}</td>
                                                <td >{coatingring.extwhite}</td>
                                                <td >{coatingring.extother}</td>
                                            </tr>
                                        </table>
                                        <div><b>For sale order : </b> {coatingring.forsaleorder}</div>
                                        
                                    </div>
                                </div>

                                <div className='row-between-form' style={{ width: '100%' }}>
                                    <div style={{ width: '10%' }}>
                                        <b>Ear :</b>
                                    </div>
                                    <div style={{ width: '100%' }} >
                                        <table style={{ width: '100%' }} >
                                            <tr className='borderWidth-form' style={{ backgroundColor: '' }}>
                                                <th style={{ paddingLeft: 5, width: '10%' }}><center>Code</center></th>
                                                <th style={{ paddingLeft: 5, width: '10%' }}><center>Ring Type</center></th>
                                                <th style={{ width: '10%' }}><center>Side</center></th>
                                                <th style={{ paddingLeft: 5, width: '10%' }}><center>Coating system</center></th>
                                                <th style={{ paddingLeft: 5, width: '20%' }}><center>White Coat</center></th>
                                                <th style={{ paddingLeft: 5, width: '20%' }}><center>Other</center></th>
                                            </tr>
                                            <tr>
                                                <td ><center>{coatingear.ring_earcode}</center></td>
                                                <td ><center>{coatingear.ring_eartype}</center></td>
                                                <td ><center><b>Int .</b></center></td>
                                                <td >{coatingear.intcoating}</td>
                                                <td >{coatingear.intwhite}</td>
                                                <td >{coatingear.intother}</td>
                                            </tr>
                                        </table>
                                     
                                    </div>
                                </div>

                                <hr></hr>
                            </div>

                            <div>
                                <div className='row-between-form' style={{ width: '100%' }}>
                                    <div style={{ width: '10%' }}><b>Can & end forming</b></div>
                                    <div style={{ width: '100%' }} >
                                        <table style={{ width: '100%' }} >
                                            <tr className='borderWidth-form' style={{ backgroundColor: '' }}>
                                                <th style={{ width: '10%' }}><center>Internal side stripe</center></th>
                                                <th style={{ width: '15%' }}><center>Internal Other</center></th>
                                                <th style={{ width: '10%' }}><center>Seaming </center></th>
                                                <th style={{ width: '15%' }}><center>Seaming Other</center></th>
                                                <th style={{ width: '20%' }}><center>Machine no </center></th>
                                                <th style={{ width: '20%' }}><center>Packaging </center></th>
                                            </tr>
                                            <tr>
                                                <td ><center>{forming.internalside}</center></td>
                                                <td ><center>{forming.internalother}</center></td>
                                                <td ><center>{forming.seaming}</center></td>
                                                <td >{forming.seamingother}</td>
                                                <td >{forming.machineno}</td>
                                                <td >{forming.packaging}</td>

                                            </tr>
                                        </table>
                                        <div><b>Note : </b> {forming.note}</div>
                                    </div>
                                </div>
                                <hr></hr>
                            </div>

                            <div>
                                <div className='row-between-form' style={{ width: '100%' }}>
                                    <div style={{ width: '10%' }}><b>Guideline for product pack</b></div>
                                    <div style={{ width: '100%' }} >
                                        <div className='row-form'>
                                            <div style={{ marginRight: 20 }}>
                                                <b>product shelf-life</b>  : <label style={{ color: 'red' }}>( {guideline.shelfyears} ) years / at</label></div>
                                            <div> <label style={{ color: 'blue' }}>( {guideline.shelfdeg} ) degC</label></div>
                                        </div>
                                        <table style={{ width: '100%' }} >
                                            <tr className='borderWidth-form' style={{ backgroundColor: '' }}>
                                                <th style={{ width: '10%' }}><center># No .</center></th>
                                                <th style={{ width: '15%' }}><center>Detail</center></th>
                                                <th style={{ width: '10%' }}><center>Other </center></th>
                                            </tr>
                                            <tr>
                                                <td ><b>1. Product group</b></td>
                                                <td >{guideline.groupother}</td>
                                                <td >{guideline.groupother}</td>

                                            </tr>
                                            <tr>
                                                <td ><b>2. Details of product </b></td>
                                                <td >{guideline.detailproduct}</td>
                                                <td ></td>

                                            </tr>
                                            <tr>
                                                <td ><b>3. pH</b></td>
                                                <td >{guideline.ph}</td>
                                                <td ></td>

                                            </tr>
                                            <tr>
                                                <td ><b>4. Ingredients </b></td>
                                                <td >{guideline.ingredients}</td>
                                                <td >{guideline.ingredientother}</td>

                                            </tr>
                                            <tr>
                                                <td ><b>5.Seamer condition </b></td>
                                                <td >{guideline.seamercondition}</td>
                                                <td ></td>

                                            </tr>
                                            <tr>
                                                <td ><b>6.Sterilization process</b></td>
                                                <td >{guideline.sterilization}</td>
                                                <td >{guideline.sterilizationother}</td>

                                            </tr>
                                        </table>
                                    </div>
                                </div>
                                <hr></hr>
                            </div>

                            <div>
                                <div className='row-between-form' style={{ width: '100%' }}>
                                    <div style={{ width: '10%' }}><b>Regulation</b></div>
                                    <div style={{ width: '100%' }} >
                                        <table style={{ width: '100%' }} >
                                            <tr className='borderWidth-form' style={{ backgroundColor: '' }}>
                                                <th style={{ width: '10%' }}><center>Regulation type</center></th>
                                                <th style={{ width: '15%' }}><center>Detail</center></th>
                                            </tr>
                                            <tr>
                                                <td >{regulationcheck.map((item, i) => (
                                                    <div>{i + 1} . {item}</div>
                                                ))}</td>
                                                <td >{regulationOter}</td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                                <hr></hr>
                            </div>

                            <div>
                                <div className='row-between-form' style={{ width: '100%' }}>
                                    <div style={{ width: '10%' }}><b>Request sample</b></div>
                                    <div style={{ width: '100%' }} >
                                        <table style={{ width: '100%' }} >
                                            <tr className='borderWidth-form' style={{ backgroundColor: '' }}>
                                                <th style={{ width: '10%' }}><center>Proof plate</center></th>
                                                <th style={{ width: '15%' }}><center> Can (pcs)</center></th>
                                                <th style={{ width: '15%' }}><center>  End (pcs)</center></th>

                                            </tr>
                                            <tr>
                                                <td >{requestsample.proofplate}</td>
                                                <td >{requestsample.requestcan}</td>
                                                <td >{requestsample.requestend}</td>
                                            </tr>
                                        </table>
                                        {/* <div><b> Note : </b>{requestsample.requestnote}</div> */}
                                        <div style={{ margin: 10, padding: 10, backgroundColor: '#e9e9e9' }} > <label style={{ fontSize: 16 }}>
                                            <b> Note : </b></label>{requestsample.requestnote}
                                        </div>
                                    </div>
                                </div>
                                <div className='row-page'>
                                    <div className='border_green' style={{ width: '30%', height: 'auto', padding: 10 }}>
                                        <div style={{ backgroundColor: '#989898' }}>
                                            <center>Component Code </center>
                                        </div>
                                        <div className='row-between-page '>
                                            <div><b>หูอ๊อก/ถ้วยอ๊อก :</b></div>
                                            <div>{componentCodeall.earwelding}</div>

                                        </div>
                                        <div className='row-between-page '>
                                            <div><b>หู :</b></div>
                                            <div>{componentCodeall.ear}</div>

                                        </div>
                                        <div className='row-between-page '>
                                            <div><b>ห่วง :</b></div>
                                            <div>{componentCodeall.ring}</div>

                                        </div>
                                        <div className='row-between-page '>
                                            <div><b>ฝาใน :</b></div>
                                            <div>{componentCodeall.innerlid}</div>
                                        </div>
                                        {/* <div className='row-between-page '>
                                            <div><b>ฝานอก :</b></div>
                                            <div>{componentCodeall.outerlid}</div>
                                        </div> */}
                                        <div className='row-between-page '>
                                            <div><b>ฝาบน :</b></div>
                                            <div>{componentCodeall.toplid}</div>
                                        </div>
                                        {/* <div className='row-between-page '>
                                            <div><b>ฝาล่าง :</b></div>
                                            <div>{componentCodeall.bottomlid}</div>
                                        </div> */}
                                        <div className='row-between-page '>
                                            <div><b>ฝานพลาสติก :</b></div>
                                            <div>{componentCodeall.plasticlid}</div>
                                        </div>
                                        <div className='row-between-page '>
                                            <div><b>ฝาอลูมิเนียม :</b></div>
                                            <div>{componentCodeall.aluminumlid}</div>
                                        </div>
                                    </div>


                                    {/* {datafetch[0].length>0} */}
                                    <div>
                                        <div className='row-page' style={{ marginLeft: 20 }}>
                                            <label><b>ตัวอย่าง Product </b></label> {datafetch.length > 0 && <ViewImages urlImages={datafetch[0].uploadfilename} />}
                                        </div>

                                        {datafetch.length > 0 && <div style={{ margin: 10, padding: 5 }} className='row-page'>

                                            {datafetch.uploadfilename != "" ? <img src={host + '/images/files/' + datafetch[0].uploadfilename} style={{ width: 100, hidden: 100 }} /> : "ไม่มีข้อมูล รูป ประกอบ"}


                                        </div>}

                                    </div>

                                </div>
                                <hr></hr>
                            </div>


                        </div>
                    </Tab>
                </Tabs>

            </form>

        </div>

    )
}



const Detailjournal = (id) => {


    const values = [true, 'sm-down', 'md-down', 'lg-down', 'xl-down', 'xxl-down'];
    const [fullscreen, setFullscreen] = useState(true);
    const [show, setShow] = useState(false);

    function handleShow(value) {

        setShow(value);
    }

    return (
        <div>
            <Button variant="primary" size="sm" style={{ fontSize: 10 }} onClick={() => handleShow(true)} >รายละเอียด</Button>

            <Modal show={show} fullscreen={fullscreen} onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>แก้ไขข้อมูล TECHBICAL DATA SHEET PART A </Modal.Title>
                </Modal.Header>
                <Modal.Body><FormNew handleShow={handleShow} ids={id} /></Modal.Body>
            </Modal>
        </div>
    )
}

export default Detailjournal