import React, { useState, useEffect, useRef } from 'react'
// import dataform from '../json-data/datatypeform.json';
import FetchApi from '../../customhooks/functionFetchApi';
import { usePDF } from 'react-to-pdf';
import jsPDF from 'jspdf';

import { FaFileDownload } from "react-icons/fa";
import { useParams } from "react-router-dom";
import Journaldetailpartbpdf from './journaldetailpartbpdf';
import Showdetailjournal from '../../form01/components/showdetailjournal';
import AllDetailpartb from '../components/allDetailpartb';
import Colordetaillist from '../components/colordetaillist';
import { Button } from 'react-bootstrap';
const datasub = {
    spectype: "", sheetsize: "", flatscroll: "", straightscroll: "",
    tin: "", temper: "", bright: "", row: "", filmcode: "", itemno: "", journalId: "", tdscode: ""
}


const objectPartB = {
    tecbid: "",
    sequence: "",
    bodyside: "",
    bodycolor: "",
    bodyfm: "",
    bodydfw: "",
    bodywfw: "",
    bodytemp: "",
    bodyspeed: "",
    bodyrubber: "",
    topside: "",
    topcolor: "",
    topfm: "",
    topdfw: "",
    topwfw: "",
    toptemp: "",
    topspeed: "",
    toprubber: "",
    bottomside: "",
    bottomcolor: "",
    bottomfm: "",
    bottomdfw: "",
    bottomwfw: "",
    bottomtemp: "",
    bottomspeed: "",
    bottomrubber: "",
    otherdetail: "",
    tdscode: ""
}



const Showdetailjournalpdf = () => {
    let { id } = useParams();

    const FetchApis = new FetchApi(); //fatch data
    // const [key, setKey] = useState('Coating');
    const { toPDF, targetRef } = usePDF({ filename: `${id}_page.pdf` });

    const [jouranldata, setJournaldata] = useState([]);

    const [coanting1st, setCoanting1st] = useState(objectPartB);
    const [coanting2nd, setCoanting2nd] = useState(objectPartB);
    const [coanting3rd, setCoanting3rd] = useState(objectPartB);
    const [coanting4th, setCoanting4th] = useState(objectPartB);
    const [coanting5th, setCoanting5th] = useState(objectPartB);
    const [coanting6th, setCoanting6th] = useState(objectPartB);
    const [coanting7th, setCoanting7th] = useState(objectPartB);

    const [checkdata, setCheckdata] = useState(false);

    useEffect(() => {

        FetchApis.FethcGet(`/technical/techniaclByJournalId/${id}`).then((res) => {

            if (res.status == 200) {
                if (res.data.data.length != 0) {
                    setCheckdata(true)
                    Coatinglistbytype(res.data.data)
                }
                datalistjournal()
            }
        })
    }, [])


    function datalistjournal() {
        FetchApis.FethcGet(`/journal/journalbyId/${id}`).then((res) => {
            if (res) {
                setJournaldata(res.data.data[0]);
            }
        })
    }

    function Coatinglistbytype(datalist) {

        const coating1st = datalist.find((item) => item.sequence == '1st');
        setCoanting1st(coating1st);

        const coating2nd = datalist.find((item) => item.sequence == '2nd');
        setCoanting2nd(coating2nd);

        const coating3rd = datalist.find((item) => item.sequence == '3rd');
        setCoanting3rd(coating3rd);

        const coating4th = datalist.find((item) => item.sequence == '4th');
        setCoanting4th(coating4th);

        const coating5th = datalist.find((item) => item.sequence == '5th');
        setCoanting5th(coating5th);

        const coating6th = datalist.find((item) => item.sequence == '6th');
        setCoanting6th(coating6th);

        const coating7th = datalist.find((item) => item.sequence == '7th');
        setCoanting7th(coating7th);
    }


    return (
        <div>
            <div className='end-flex-row'>
                <Button onClick={() => toPDF()} size='sm' variant="light"><FaFileDownload /> Download PDF</Button>
            </div>
            <div className='form-body-page-pdf' ref={targetRef}>
                <div>
                    <Showdetailjournal id={id} />
                </div>
                <hr></hr>
                <div style={{ width: 'auto', height: '100%' }}>
                    <AllDetailpartb journalId={id} />
                </div>
                <hr></hr>
                <div>
                    {checkdata == true ? <div>
                        <Colordetaillist tecbid={coanting1st.tecbid} typetecb={'1st'} />
                        <Colordetaillist tecbid={coanting2nd.tecbid} typetecb={'2nd'} />
                        <Colordetaillist tecbid={coanting3rd.tecbid} typetecb={'3rd'} />
                        <Colordetaillist tecbid={coanting4th.tecbid} typetecb={'4th'} />
                        <Colordetaillist tecbid={coanting5th.tecbid} typetecb={'5th'} />
                        <Colordetaillist tecbid={coanting6th.tecbid} typetecb={'6th'} />
                        <Colordetaillist tecbid={coanting7th.tecbid} typetecb={'7th'} />

                    </div>
                        : <div></div>}
                </div>
            </div>
        </div>
    )
}

export default Showdetailjournalpdf