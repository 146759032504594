import React, { useState, useEffect } from 'react'
import { Button } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import Popupsolidlist from './popupsolid/popupsolidlist';
import { addbody, removebody, addtop, removetop, addbottom, addnotedail, removebottom } from '../../../../reduxSlice/coatingpartbSlice/coating7ThSlice';
import PopupTemp from './popupsolid/popupTemp';
const datalist = {
    sequence: "7th",
    type: "",
    side: "",
    color: "",
    fm: "",
    solid: "",
    dfw_min: "",
    dfw_max: "",
    wfw: "",
    temp: "",
    speed: "",
    rubber: "",
    other: "",
    rfFgbody: "",
    rfFgtop: "",
    rfFgbottom: ""
}

const Rdaddnew7thcoating = () => {
    // get redux kit values add boy
    const dispatch = useDispatch();

    const [coatingall, setCoatingall] = useState({ ...datalist })
    const [coatingbody, setCoatingbody] = useState({ ...datalist })
    const [coatingtop, setCoatingtop] = useState({ ...datalist })
    const [coatingbotton, setCoatingbotton] = useState({ ...datalist })

    const [notedetail, setNotedetail] = useState("");

    useEffect(() => {
        dispatch(addbody(datalist))
        dispatch(addtop(datalist))
        dispatch(addbottom(datalist))
    }, [])

    const addcoating = () => {
        // alert(coatingtype)
        dispatch(addnotedail(notedetail))
        if (coatingall.type == 'body') {
            setCoatingbody(coatingall)
            dispatch(addbody(coatingall))
        }
        else if (coatingall.type == 'top') {
            setCoatingtop(coatingall)
            dispatch(addtop(coatingall))
        }
        else if (coatingall.type == 'bottom') {
            setCoatingbotton(coatingall)
            dispatch(addbottom(coatingall))
        }
        else {
            alert("คุณยังไม่ได้เลือก ประเภทการบันทึก")
        }
    }

    const removecoating = () => {

        dispatch(addnotedail(notedetail))

        if (coatingall.type == 'body') {
            setCoatingbody({ ...datalist })

            dispatch(removebody(datalist))

        }
        else if (coatingall.type == 'top') {
            setCoatingtop({ ...datalist })

            dispatch(removetop(datalist))
        }
        else if (coatingall.type == 'bottom') {
            setCoatingbotton({ ...datalist })

            dispatch(removebottom(datalist))
        }
    }

    const SeelctPropertyList = (fmcode, solid) => {
        setCoatingall({
            ...coatingall,
            fm: fmcode,
            solid: solid
        })
    }

    function selectTemp(valueTemp){

        setCoatingall({ ...coatingall, temp: valueTemp })
    }


    return (
        <div>
            <div style={{ width: 700 }} >
                <div className='row-between-page'>
                    <div className='row-between-page' style={{ width: 200, backgroundColor: '#E9E7E2', padding: 5, borderRadius: 4 }}>
                        <div> <input type='radio' name='typecoating' value={'body'} onClick={(e) => setCoatingall({ ...coatingall, type: e.target.value })} /> Body</div>
                        <div><input type='radio' name='typecoating' value={'top'} onClick={(e) => setCoatingall({ ...coatingall, type: e.target.value })} /> Top </div>
                        <div><input type='radio' name='typecoating' value={'bottom'} onClick={(e) => setCoatingall({ ...coatingall, type: e.target.value })} /> Bottom </div>
                    </div>
                    <div className='row-between-page' >
                        <div style={{ marginRight: 10 }}>
                            <Button size='sm' variant="primary" onClick={() => addcoating()}>เพิ่มข้อมูล</Button>
                        </div>
                        <div>
                            <Button size='sm' variant="warning" onClick={() => removecoating()}>ยกเลิก</Button>
                        </div>
                    </div>
                </div>
            </div>

            <div style={{ width: 700 }} className='border-green-page'>
                <div className='row-between-page' style={{ width: '100%' }}>
                    <div><b><label>Varnish top coat</label></b></div>
                    <div className='row-between-page' style={{ width: '25%' }}>
                        <div><input type='radio' name='radioside' value={'W/W'} onClick={(e) => setCoatingall({ ...coatingall, side: e.target.value })} /> W / W</div>
                        <div><input type='radio' name='radioside' value={'W/D'} onClick={(e) => setCoatingall({ ...coatingall, side: e.target.value })} /> W / D</div>
                    </div>
                </div>
                <div className='row-between-page' style={{ width: '100%' }}>
                    <div><b><label>Color shade</label></b></div>
                    <div>
                        <input type='text' name='ColorShade' style={{ width: 200 }} onChange={(e) => setCoatingall({ ...coatingall, color: e.target.value })} />
                    </div>
                </div>
                <div className='row-between-page' style={{ width: '100%' }}>
                    <div><b><label>FM Code</label></b></div>
                    <div className='row-page'>
                        <Popupsolidlist SeelctPropertyList={SeelctPropertyList} />
                        <input type='text' name='FMCode'
                            value={coatingall.fm}
                            style={{ width: 200, marginLeft: 5 }} onChange={(e) => setCoatingall({ ...coatingall, fm: e.target.value })} />
                    </div>
                </div>
                <div className='row-between-page' style={{ width: '100%' }}>
                    <div><b><label>Solid</label></b></div>
                    <div>
                        <input type='number' name='Solid' style={{ width: 150 }}
                        value={coatingall.solid}
                        onChange={(e) => setCoatingall({ ...coatingall, solid: e.target.value })} />
                    </div>
                </div>
                <div className='row-between-page' style={{ width: '100%', marginTop: 10, marginBottom: 10 }}>
                    <div><b><label>DFW (gsm) / wfw (Kg /1000 Sht)</label></b></div>
                    <div style={{ width: 300 }}>
                        <div className='row-between-page'>
                            <label>DFW MIN : </label>
                            <input type='number' name='DFW' style={{ width: 150 }}
                                onChange={(e) => setCoatingall({ ...coatingall, dfw_min: e.target.value })} />
                        </div>
                        <div className='row-between-page'>
                            <label>DFW MAX : </label>
                            <input type='number' name='DFW' style={{ width: 150 }}
                                onChange={(e) => setCoatingall({ ...coatingall, dfw_max: e.target.value })} />
                        </div>
                        <div className='row-between-page'>
                            <label>wfw ( kg /1,000 Sht ) : </label>
                            <input type='number' name='wfw' style={{ width: 150 }} step='0.01' onChange={(e) => setCoatingall({ ...coatingall, wfw: e.target.value })} />
                        </div>
                    </div>
                </div>
                <div className='row-between-page' style={{ width: '100%' }}>
                    <div><b><label>Temp ( C )</label></b></div>
                    <div className='row-page'>
                        <PopupTemp selectTemp={selectTemp}/>
                        <input type='text' name='Temp' style={{ width: 250,marginLeft:5 }} 
                        value={coatingall.temp}
                        onChange={(e) => setCoatingall({ ...coatingall, temp: e.target.value })} />
                    </div>
                </div>
                <div className='row-between-page' style={{ width: '100%', marginTop: 10, marginBottom: 10 }}>
                    <div><b><label>Speed (sph)/ No Ruble</label></b></div>
                    <div style={{ width: 300 }}>
                        <div className='row-between-page'>
                            <label>Speed (sph) : </label>
                            <input type='number' name='Speed' style={{ width: 150 }}
                                onChange={(e) => setCoatingall({ ...coatingall, speed: e.target.value })}
                                disabled
                            />
                        </div>
                        <div className='row-between-page'>
                            <label>No Rubber : </label>
                            <input type='text' name='Rubber' style={{ width: 150 }} onChange={(e) => setCoatingall({ ...coatingall, rubber: e.target.value })}
                                disabled
                            />
                        </div>
                    </div>
                </div>
                <div className='row-between-page' style={{ width: '100%' }}>
                    <div><b><label>Other </label></b></div>
                    <div>
                        <textarea style={{ width: 250, height: 50 }} name='Other' onChange={(e) => setNotedetail(e.target.value)}>

                        </textarea>
                    </div>
                </div>
            </div>

            <hr></hr>
            <table style={{ width: '100%' }}>
                <tr>
                    <th style={{ width: '10%' }}><center>sequence</center></th>
                    <th style={{ width: '30%' }}><center>Coating system</center></th>
                    <th style={{ width: '20%' }}><center>Body</center></th>
                    <th style={{ width: '20%' }}><center>Top end</center></th>
                    <th style={{ width: '20%' }}><center>Bottom end</center></th>
                </tr>
                <tr>
                    <td style={{ width: '5%' }}><center><b>7 th </b></center></td>
                    <td style={{ width: '30%' }}>Varnish top coat</td>
                    <td style={{ width: '20%' }}>{coatingbody.side}</td>
                    <td style={{ width: '20%' }}>{coatingtop.side}</td>
                    <td style={{ width: '20%' }}>{coatingbotton.side}</td>
                </tr>
                <tr>
                    <td style={{ width: '5%' }}></td>
                    <td style={{ width: '30%' }}>Color shade</td>
                    <td style={{ width: '20%' }}>{coatingbody.color}</td>
                    <td style={{ width: '20%' }}>{coatingtop.color}</td>
                    <td style={{ width: '20%' }}>{coatingbotton.color}</td>
                </tr>
                <tr>
                    <td style={{ width: '5%' }}></td>
                    <td style={{ width: '30%' }}>FM.code</td>
                    <td style={{ width: '20%' }}>{coatingbody.fm}</td>
                    <td style={{ width: '20%' }}>{coatingtop.fm}</td>
                    <td style={{ width: '20%' }}>{coatingbotton.fm}</td>
                </tr>
                <tr>
                    <td style={{ width: '5%' }}></td>
                    <td style={{ width: '30%' }}>DFW ( gsm ) / wfw (Kg / 1000 Sht)</td>
                    <td style={{ width: '20%' }}> {coatingbody.dfw_min.length > 0 && <div>{coatingbody.dfw_min}-{coatingbody.dfw_max} / {coatingbody.wfw}</div>}</td>
                    <td style={{ width: '20%' }}> {coatingtop.dfw_min > 0 && <div>{coatingtop.dfw_min}-{coatingtop.dfw_max} / {coatingtop.wfw}</div>}</td>
                    <td style={{ width: '20%' }}> {coatingbotton.dfw_min.length > 0 && <div>{coatingbotton.dfw_min}-{coatingbotton.dfw_max} / {coatingbotton.wfw}</div>}</td>
                </tr>
                <tr>
                    <td style={{ width: '5%' }}></td>
                    <td style={{ width: '30%' }}>Temp ( C )</td>
                    <td style={{ width: '20%' }}>{coatingbody.temp}</td>
                    <td style={{ width: '20%' }}>{coatingtop.temp}</td>
                    <td style={{ width: '20%' }}>{coatingbotton.temp}</td>
                </tr>
                <tr>
                    <td style={{ width: '5%' }}></td>
                    <td style={{ width: '30%' }}>Speed ( sph) / No .Rubber</td>
                    <td style={{ width: '20%' }}> {coatingbody.speed.length > 0 && <div>{coatingbody.speed} / {coatingbody.rubber}</div>}</td>
                    <td style={{ width: '20%' }}> {coatingtop.speed.length > 0 && <div>{coatingtop.speed} / {coatingtop.rubber}</div>}</td>
                    <td style={{ width: '20%' }}> {coatingbotton.speed.length > 0 && <div>{coatingbotton.speed} / {coatingbotton.rubber}</div>}</td>
                </tr>
            </table>

            <div>
                <label><b>OTHER DETAIL</b></label>
                <div style={{ marginLeft: '5%', marginTop: 10 }}>
                    {notedetail}
                </div>
            </div>
        </div>
    )
}

export default Rdaddnew7thcoating