import { createSlice } from '@reduxjs/toolkit'

const datalist = {
    sequence: "2nd",
    type: "",
    side: "",
    color: "",
    fm: "",
    dfw: "",
    wfw:"",
    temp: "",
    speed: "",
    rubber:"",
    other: ""
}

const initialState = {
    body: [datalist],
    top: [datalist],
    bottom: [datalist],
    notedail:[]
}

export const coating2NdSlice = createSlice({
    name: 'coating2nd',
    initialState,
    reducers: {
        addbody: (state, action) => {
            state.body = action.payload;
        },
        removebody: (state, action) => {
            state.body = action.payload;
        },
        addtop: (state, action) => {
            state.top = action.payload;
        },
        removetop: (state, action) => {
            state.top = action.payload;
        },
        addbottom: (state, action) => {
            state.bottom = action.payload
        },
        addnotedail:(state, action)=>{
            state.notedail = action.payload 
        },
        removebottom: (state, action) => {
            state.bottom = action.payload;
        },
        removeall: (state) => {
            state.body = [];
            state.top = [];
            state.bottom = [];
            state.notedail=[];

        }

    },
})

// Action creators are generated for each case reducer function
export const { addbody, removebody, addtop, removetop, addbottom,addnotedail, removebottom } = coating2NdSlice.actions

export default coating2NdSlice.reducer