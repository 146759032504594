import React,{useState,useEffect} from 'react'
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import FetchApi from '../../customhooks/functionFetchApi';
import Showdetailjournal from '../../form01/components/showdetailjournal';
import AllDetailpartb from './allDetailpartb';
import Colordetaillist from './colordetaillist';
import ViewColorlist from './viewColorlist';
import ViewNewItem from './viewNewItem';

const objectPartB = {
    tecbid: "",
    sequence: "",
    bodyside: "",
    bodycolor: "",
    bodyfm: "",
    bodydfw: "",
    bodywfw: "",
    bodytemp: "",
    bodyspeed: "",
    bodyrubber: "",
    topside: "",
    topcolor: "",
    topfm: "",
    topdfw: "",
    topwfw: "",
    toptemp: "",
    topspeed: "",
    toprubber: "",
    bottomside: "",
    bottomcolor: "",
    bottomfm: "",
    bottomdfw: "",
    bottomwfw: "",
    bottomtemp: "",
    bottomspeed: "",
    bottomrubber: "",
    otherdetail: "",
    tdscode: ""
  }
  
  
  function FormViewJournal({ jourId, codeId }) {
  
    const FetchApis = new FetchApi()
    const [key, setKey] = useState('jouranl');
  
  
    const [coanting1st, setCoanting1st] = useState(objectPartB);
    const [coanting2nd, setCoanting2nd] = useState(objectPartB);
    const [coanting3rd, setCoanting3rd] = useState(objectPartB);
    const [coanting4th, setCoanting4th] = useState(objectPartB);
    const [coanting5th, setCoanting5th] = useState(objectPartB);
    const [coanting6th, setCoanting6th] = useState(objectPartB);
    const [coanting7th, setCoanting7th] = useState(objectPartB);
  
    const [checkdata, setCheckdata] = useState(false);
  
    useEffect(() => {
  
      FetchApis.FethcGet(`/technical/techniaclByJournalId/${jourId}`).then((res) => {
        console.log(res)
        if (res.status == 200) {
          if (res.data.data.length != 0) {
            
            Coatinglistbytype(res.data.data)
            setCheckdata(true)
          }
  
        }
      })
  
    }, [])
  
    function Coatinglistbytype(datalist) {
      const coating1st = datalist.find((item) => item.sequence == '1st');
      setCoanting1st(coating1st);
  
      const coating2nd = datalist.find((item) => item.sequence == '2nd');
      setCoanting2nd(coating2nd);
  
      const coating3rd = datalist.find((item) => item.sequence == '3rd');
      setCoanting3rd(coating3rd);
  
      const coating4th = datalist.find((item) => item.sequence == '4th');
      setCoanting4th(coating4th);
  
      const coating5th = datalist.find((item) => item.sequence == '5th');
      setCoanting5th(coating5th);
  
      const coating6th = datalist.find((item) => item.sequence == '6th');
      setCoanting6th(coating6th);
  
      const coating7th = datalist.find((item) => item.sequence == '7th');
      setCoanting7th(coating7th);
    }
  
  
    return (
      <div className='form-body-page'>
        <form >
      
  
          <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => setKey(k)}
            className="mb-3"
          >
            <Tab eventKey="jouranl" title="TECHNICAL DATA SHEET A" style={{ backgroundColor: 'aliceblue' }}>
              
              <Showdetailjournal id={jourId} />
            </Tab>
            <Tab eventKey="journaldetailpartb" title="TECHNICAL DATA SHEET B" style={{ backgroundColor: 'aliceblue' }}>
  
              <AllDetailpartb  codeId={codeId} journalId={jourId}/>
            </Tab>
            <Tab eventKey="printingcolor" title="COLOR PRINTING SYSTEM" style={{ backgroundColor: 'aliceblue' }}>
              
              {checkdata==true?<div>
              <Colordetaillist tecbid={coanting1st.tecbid} typetecb={'1st'}/>
              <Colordetaillist tecbid={coanting2nd.tecbid} typetecb={'2nd'}/>
              <Colordetaillist tecbid={coanting3rd.tecbid} typetecb={'3rd'}/>
              <Colordetaillist tecbid={coanting4th.tecbid} typetecb={'4th'}/>
              <Colordetaillist tecbid={coanting5th.tecbid} typetecb={'5th'}/>
              <Colordetaillist tecbid={coanting6th.tecbid} typetecb={'6th'}/>
              <Colordetaillist tecbid={coanting7th.tecbid} typetecb={'7th'}/>
              
              </div>
              :<div></div>}
            </Tab>
            <Tab eventKey='colorlist'title='เอกสารหมีกผสม แนบท้าย TDS By printing ' >
                <ViewColorlist journalId={jourId}/>
            </Tab>
            <Tab eventKey='NewItem'title='Create New Item' >
                <ViewNewItem jourId={jourId}/>
            </Tab>
  
          </Tabs>
        </form>
      </div>
    )
  }


const ViewAllpartAB = ({jourId,codeId}) => {
  return (
    <div><FormViewJournal jourId={jourId} codeId={codeId}/></div>
  )
}

export default ViewAllpartAB