import React,{useState,useEffect} from 'react'
import { Button } from 'react-bootstrap'
import { FaFileSignature } from "react-icons/fa";
import EditformsHeaderjournal from './editformsHeaderjournal';
import Showdetailjournal from'../../../form01/components/showdetailjournal';
import FetchApi from '../../../customhooks/functionFetchApi';
import Modal from 'react-bootstrap/Modal';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import {statusflowall} from '../../../form01/object-usestate/objectdefaults';


const datasub = {
  spectype: "", sheetsize: "", flatscroll: "", straightscroll: "", tin: "", temper: "", bright: "", row: "", filmcode: "", itemno: "", tdscode: ""
}


function LisetDetailJorunal({ handleShow, id }) {

  const Emcode = sessionStorage.getItem('EmCode');
  const FetchApis = new FetchApi(); //fatch data

  const emCode = sessionStorage.getItem('EmCode');

  const [key, setKey] = useState('jouranl');


  useEffect(() => {
    var codeid = "";
    var intdataconvert = 0;
    FetchApis.FethcGet(`/journal/journalbyId/${id}`).then((datafetch) => {
      if (datafetch.data.status == 200) {
       
        codeid = datafetch.data.data[0].tdscode;

        intdataconvert = datafetch.data.data[0].stateflow - 1;
        var dataobject = {
          journalid: id,
          emcode: emCode,
          stateflow: intdataconvert,
          approvecode: statusflowall.sendjournal,
          dedicatecode: ""
        }

      }
    })



  }, [])


  return (<div>
    <div className='form-body-page'>
      <form  >
        <div className='end-flex-row'>          
        </div>
        <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k) => setKey(k)}
          className="mb-3"
        >
          <Tab eventKey="jouranl" title="Form ( F-MK-009A1-1 ) ตรวจสอบข้อมูลก่อนบันทึก" style={{ backgroundColor: 'aliceblue' }}>
            <Showdetailjournal id={id}/>
          </Tab>
          <Tab eventKey="editjournal" title="แก้ไขข้อมูลเอกสาร" style={{ backgroundColor: 'aliceblue' }}>
                          <EditformsHeaderjournal ids={id}/>
          </Tab>

        </Tabs>
      </form>
    </div>
  </div>)
}



const Actionrename = ({id,actionState}) => {


  const [journallist, setJournallist] = useState([])

  const values = [true, 'sm-down', 'md-down', 'lg-down', 'xl-down', 'xxl-down'];
  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);

  function handleShow(values) {
    setShow(values);
  }

  return (
    <div>
      <Button size='sm' style={{fontSize:12}} onClick={()=>handleShow(true)} variant="primary" disabled={actionState}><FaFileSignature /> เปลี่ยนชื่อสินค้า</Button>

      <Modal
        show={show}
        onHide={() => setShow(false)}
        // size="lg"
        fullscreen={fullscreen}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            รายละเอียดเอกสาร
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <ListStateByJournal id={id} /> */}
          <LisetDetailJorunal handleShow={handleShow} id={id} />
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default Actionrename