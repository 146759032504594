import React from 'react'
import DataTable from 'react-data-table-component';
import Updatepartb from './updatepartb';
function ActionList({journalid,codeid,stateflow}){
    return(
        <div>
        <Updatepartb journalid={journalid} codeid={codeid} stateflow={stateflow}/>
        </div>
    )
}

const columns = [
    {
        name: 'TDS code',
        sortable: true,
        selector: row => row.tdscode,
    },
    {
        name: 'Journal Type',
        selector: row => row.journaltype,
    },
    {
        name: 'customer name',
        selector: row => row.customername,
    },
    {
        name: 'itemname',
        selector: row => row.itemname,
    },
    {
        name: 'createdate',
        selector: row => row.createdate,
    },
    {
        name: 'Action',
         grow: 1.5,
        cell: row =><ActionList journalid={row.journalId} codeid={row.tdscode} stateflow={row.stateflow}/>,
    },

];

const customStyles = {
    headRow: {
        style: {
            border: 'none',
            backgroundColor: '#28B463'
        },
    },
    headCells: {
        style: {
            color: '#ffff',
            fontSize: '14px',
        },
    },
    rows: {
        highlightOnHoverStyle: {
            backgroundColor: 'rgb(230, 244, 244)',
            borderBottomColor: '#FFFFFF',
            borderRadius: '25px',
            outline: '1px solid #FFFFFF',
        },
    },
    pagination: {
        style: {
            border: 'none',
        },
    },
};

const Tablefactory = ({datatable}) => {

    // console.log(datatable);

  return (
    <DataTable
    columns={columns}
    data={datatable}
    // selectableRows
    pagination
    customStyles={customStyles}
    highlightOnHover
/>
  )
}

export default Tablefactory