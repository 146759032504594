import React, { useState, useEffect, useRef } from 'react'

import generatePDF from 'react-to-pdf';
import { Button } from 'react-bootstrap';
import { FaFileDownload } from "react-icons/fa";
import ViewColorlist from '../../components/viewColorlist';

const PdfNewcolorsuccess = ({ journalId }) => {
    const targetRef = useRef();
    return (
        <div>
            <Button onClick={() => generatePDF(targetRef, { filename: `${journalId}_Newcolor_page.pdf` })} size='sm' variant="light">
                <FaFileDownload /> Download PDF</Button>
            <div ref={targetRef}>
                <ViewColorlist journalId={journalId} />
                <img src={require('../../../images/approve_8622624.png')} style={{ width: 50, height: 'auto', borderRadius: 3 }} />
            </div>
        </div>
    )
}

export default PdfNewcolorsuccess