
export const opjectHeader = {
    cansize: "",
    shape: "",
    contact: "",
    createby: "",
    createdate: "",
    createdatetime: "",
    customername: "",
    duedate: "",
    fcode: "",
    filmcode: "",
    refFgcode: "",
    filmtype: [],
    informationnote: "",
    informationtype: [],
    itemcode: "",
    itemname: "",
    itemsample: "",
    journalId: "",
    journaltype: "",
    typeproduct: "",
    printingtype: [],
    printingmachine: [],
    printingtypeother: "",
    productgrouptype: "",
    productname: "",
    revision: "",
    stateflow: "",
    statusflow: "",
    substratetype: "",
    tdscode: "",
    tel: "",
    statusprove_Tds: "",
    noteapproveTds: ""
}


export const objectcoatingbody = {
    journalId: "",
    producttype: "body",
    substratetype: "",
    groupBobyType: [],
    number: "",
    substrate: [],
    shape: "",
    shapedetail: "",
    dia: "",
    ring_eartype: "",
    ring_earcode: "",
    forsaleorder: "",
    intcoating: [],
    intwhite: "",
    intother: "",
    extcoating: [],
    exttypewhite: "",
    extwhite: "",
    extother: "",
    note: "",
    fgcomponent:"",
    statusProof: "",
    tdscode: "",
}

export const objectcoatingtop = {
    journalId: "",
    producttype: "top",
    substratetype: "",
    groupBobyType: [],
    number: "",
    substrate: [],
    shape: [],
    shapedetail: "",
    dia: "",
    ring_eartype: "",
    ring_earcode: "",
    forsaleorder: "",
    intcoating: [],
    intwhite: "",
    intother: "",
    extcoating: [],
    exttypewhite: "",
    extwhite: "",
    extother: "",
    note: "",
    fgcomponent:"",
    statusProof: "",
    tdscode: ""
}

export const objectcoatingbottom = {
    journalId: "",
    producttype: "bottom",
    substratetype: "",
    groupBobyType: [],
    number: "",
    substrate: [],
    shape: [],
    shapedetail: "",
    dia: "",
    ring_eartype: "",
    ring_earcode: "",
    forsaleorder: "",
    intcoating: [],
    intwhite: "",
    intother: "",
    extcoating: [],
    exttypewhite: "",
    extwhite: "",
    extother: "",
    note: "",
    fgcomponent:"",
    statusProof: "",
    tdscode: ""
}


export const objectcoatingring = {
    journalId: "",
    producttype: "ring",
    substratetype: "",
    groupBobyType: [],
    number: "",
    substrate: [],
    shape: [],
    shapedetail: "",
    dia: "",
    ring_eartype: [],
    ring_earcode: "",
    forsaleorder: "",
    intcoating: [],
    intwhite: "",
    intother: "",
    extcoating: [],
    exttypewhite: "",
    extwhite: "",
    extother: "",
    note: "",
    fgcomponent:"",
    statusProof: "",
    tdscode: ""
}

export const objectcoatingear = {
    journalId: "",
    producttype: "ear",
    substratetype: "",
    groupBobyType: [],
    number: "",
    substrate: [],
    shape: [],
    shapedetail: "",
    dia: "",
    ring_eartype: [],
    ring_earcode: "",
    forsaleorder: "",
    intcoating: [],
    intwhite: "",
    intother: "",
    extcoating: [],
    exttypewhite: "",
    extwhite: "",
    extother: "",
    note: "",
    fgcomponent:"",
    statusProof: "",
    tdscode: ""
}


export const objectforming = {
    internalside: [],
    internalother: "",
    seaming: [],
    seamingother: "",
    machineno: "",
    packaging: "",
    note: "",
    journalId: "",
    tdscode: ""
}

export const objectguideline = {
    shelfyears: "",
    shelfdeg: "",
    typegroup: [],
    groupother: [],
    groupother_note: "",
    groupdryfood: [],
    groupaerosol: "",
    groupgeneral: "",
    detailproduct: "",
    ph: [],
    ingredients: [],
    ingredients_chioride: "",
    ingredients_acid: "",
    ingredientother: "",
    seamercondition: [],
    sterilization: [],
    sterilization_retort: "",
    sterilization_cooker: "",
    sterilizationother: "",
    journalId: "",
    tdscode: ""
}

export const objectregulation = {
    regulationtype: "",
    regulationother: "",
    tdscode: ""
}

export const objectrequest = {
    proofplate: "",
    requestcan: "",
    requestend: "",
    requestnote: "",
    journalId: "",
    tdscode: ""
}

export const statusflowall = {
    draftjournal: '1001',
    newjournal: '1002',
    sendjournal: '1003',
    recievejournal: '1004',
    approvejournal: '1005',
    rejectjournal: '2001',
    deligatejournal: '3001',
    recalljouranl: '0000'
}

export const objectcomponentcode = {
    ear: "",
    earwelding: "",
    ring: "",
    innerlid: "",
    outerlid: "",
    toplid: "",
    bottomlid: "",
    plasticlid: "",
    aluminumlid: "",
    journalId: "",
    tdscode: ""
}