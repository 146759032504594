import React, { useState, useEffect } from 'react'
import { Button } from 'react-bootstrap'
import { FaExclamationTriangle } from "react-icons/fa";
import Modal from 'react-bootstrap/Modal';
import FetchApi from '../../../customhooks/functionFetchApi';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Swal from 'sweetalert2'
import { ToastContainer, toast } from 'react-toastify';
import Showdetailjournal from '../../../form01/components/showdetailjournal';
import { statusflowall } from '../../../form01/object-usestate/objectdefaults';

const datasub = {
  spectype: "", sheetsize: "", flatscroll: "", straightscroll: "",
  tin: "", temper: "", bright: "", row: "", filmcode: "", itemno: "", tdscode: ""
}

function LisetDetailJorunal({ handleShow, id }) {

  const Emcode = sessionStorage.getItem('EmCode');
  const FetchApis = new FetchApi(); //fatch data

  const emCode = sessionStorage.getItem('EmCode');

  const [key, setKey] = useState('jouranl');

  const [datafetch, setDatafetch] = useState([]);

  // data update crate task
  const [newTask, setNewTask] = useState([]);

  //data update journal
  const [newjournalUpdate, setNewjournalUpdate] = useState([]);



  // บันทึกข้อมูล
  const submitform = (e) => {
    e.preventDefault();

    Swal.fire({
      title: "ยกเลิกเอกสาร",
      text: "คุณแน่ใจที่ยกเลิกเอกสาร ใช่หรือไม่ ! ",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ใช่",
      cancelButtonText: 'ยกเลิก'
    }).then((result) => {

      if (result.isConfirmed) {
        Swal.fire({
          title: "ยกเลิกเอกสาร!",
          text: "คุณได้ทำยกเลิกเอกสารเรียบแล้ว",
          icon: "success"
        });
        recalljournal();
      }

    });
  }

  // update  recieved 
  function recalljournal() {


    var dataobject = {
      journalid: id,
      emcode: emCode,
      stateflow: "0",
      approvecode: statusflowall.recalljouranl,
      dedicatecode: "",
      tasknote: ""
    }

    setNewTask(dataobject)
    // // create task flow
    FetchApis.FethcPost(`/taskflow/createTaskflow`, dataobject).then((res) => {
      if (res.status == 200) {
        updatejournal();
      }
    })
  }

  // //update flowstatus journal
  function updatejournal() {

    var updatejournal = {
      statusflow: statusflowall.recalljouranl,
      stateflow: "0"
    }

    setNewjournalUpdate(updatejournal);

    FetchApis.FethcUpdate(`/journal/updatestateflow/${id}`, updatejournal).then((res) => {
      if (res.status == 200) {
        updateActive();
      }
    })
  }
  function updateActive() {
    FetchApis.FethcGet(`/runflow/updateActiveClear/${id}`).then((res) => {

      if (res.status == 200) {
        toast.success('บันทึกเรียบร้อยแล้ว', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          // transition: Bounce,
        });
        setTimeout(() => {
          window.location.reload();
        }, 5000);

      }

    })

  }

  return (<div>
    <div className='form-body-page'>
      <form onSubmit={(e) => submitform(e)} >
        <div className='end-flex-row'>
          <Button type='submit' size='sm' variant="danger" style={{ marginRight: 10 }} disabled={datafetch.length > 0 && datafetch[0].statusflow == '0000' ? true : false}><FaExclamationTriangle /> ยกเลิกเอกสาร</Button>
        </div>
        <div>{datafetch.length > 0 &&
          <div>
            {datafetch[0].statusflow == '0000' ? <div><label style={{ color: "red", fontSize: 18 }}>เอกสารฉบับนี้ได้ทำการยกเลิกแล้ว</label></div> : <div></div>}
          </div>
        }</div>
        <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k) => setKey(k)}
          className="mb-3"
        >
          <Tab eventKey="jouranl" title="Form ( F-MK-009A1-1 ) ตรวจสอบข้อมูลก่อนบันทึก" style={{ backgroundColor: 'aliceblue' }}>
            <Showdetailjournal id={id} />
          </Tab>

        </Tabs>
      </form>
    </div>
  </div>)
}


const Actionrecalljournal = ({ id, actionState }) => {
  const [journallist, setJournallist] = useState([])

  const values = [true, 'sm-down', 'md-down', 'lg-down', 'xl-down', 'xxl-down'];
  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);

  function handleShow() {
    // setFullscreen(breakpoint);
    setShow(true);
  }

  return (
    <div>
      <Button size='sm' variant="danger" style={{ fontSize: 12 }} onClick={() => handleShow(true)} disabled={actionState}> <FaExclamationTriangle /> ยกเลิกเอกสาร</Button>

      <Modal
        show={show}
        onHide={() => setShow(false)}
        // size="lg"
        fullscreen={fullscreen}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            รายละเอียดเอกสาร
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <ListStateByJournal id={id} /> */}
          <LisetDetailJorunal handleShow={handleShow} id={id} />
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default Actionrecalljournal