import React, { useState, useEffect } from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import FetchApi from '../../../customhooks/functionFetchApi';

function Viewdetail({ datalist }) {
  return(
    <div>
      <table style={{width:'100%'}}>
        <tr>
          <th style={{width:'10%'}}>ลำดับ</th>
          <th style={{width:'20%'}}>ชื่อ ผู้อนุมัติ</th>
          <th style={{width:'20%'}}>แผนก</th>
          <th style={{width:'15%'}}>ผ่านอนุมัติ</th>
          <th style={{width:'15%'}}>สถานะ</th>
          <th style={{width:'30%'}}>เวลา</th>
        </tr>
      {datalist.map((item,i)=>(
        <tr style={{fontSize:12}}>
          <td>{i+1}</td>
          <td>{item.approveby}</td>
          <td>{item.departments}</td>
          <td>ขั้นตอน {item.stateflow}</td>
          <td>{item.active==0?<label style={{color:'red'}}>รออนุมัติ</label>:<label style={{color:'green'}}>อนุมัติแล้ว</label>}</td>
          <td>{item.timecommit}</td>
        </tr>
      ))}
      </table>
    </div>
  )

}



const Detailstatus = ({ id }) => {
  const FetchApis = new FetchApi();

  const [journallist, setJournallist] = useState([])

  const values = [true, 'sm-down', 'md-down', 'lg-down', 'xl-down', 'xxl-down'];
  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);


  useEffect(() => {
    reloading();

  }, [id])


  function reloading(){
    FetchApis.FethcGet(`/journal/getStatejournalnow/${id}`).then((res) => {
      if (res.status == 200) {
        setJournallist(res.data.data)
      }

    })
  }

  function handleShow(breakpoint) {
    setFullscreen(breakpoint);
    setShow(true);
  }


  return (
    <div>
      <Button variant="success" size='sm' onClick={() => handleShow(true)}>ประวัติอนุมัติ</Button>

      <Modal
        show={show}
        onHide={() => setShow(false)}
        size="lg"
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            แสดงรายชื่อผู้อนุมัติ
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Viewdetail  datalist={journallist}/>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default Detailstatus