import React, { useState, useEffect } from 'react'
import FetchApi from '../../customhooks/functionFetchApi';
import Tablefactory from './componentfg1/tablefactory';
import Spinner from 'react-bootstrap/Spinner';
import { FaFileSignature } from "react-icons/fa";

const Journallistfactory1 = () => {
  const FetchApis = new FetchApi();

  const [loading, setLoading] = useState(false);
  const [datajournal, setDatajournal] = useState([]);

  const Emcode_now = sessionStorage.getItem('EmCode');

  useEffect(() => {
    loadingdata()


  }, [])

  const loadingdata = () => {
    setLoading(true)

    setTimeout(() => {
      FetchApis.FethcGet(`/journal/getjournalSendRunflowByEmcode/${Emcode_now}`).then((res) => {
        if (res) {
          setDatajournal(res.data.data)
          //  console.log(res.data);
        }
        setLoading(false);

      })
    }, 1000);

  }
  return (
    <div>
      <div style={{ backgroundColor: '#E5E7E7' }}>
        <label style={{ fontSize: 20, margin: 5, padding: 5 }}>
          <FaFileSignature /> รายการ รอตรวจสอบ เอกสาร ( part B )
        </label>
      </div>
      {loading == true ? <div className='body-center-page'><Spinner
        as="span"
        animation="grow"
        variant="success"
        size={200}
        role="status"
        aria-hidden="true" /> LOADIN ....</div> : <Tablefactory datatable={datajournal} />}
    </div>
  )
}

export default Journallistfactory1