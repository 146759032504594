import React, { useState, useEffect } from 'react'
import Tablemenulist from './componentmenulist/tablemenulist'
import FetchApi from '../customhooks/functionFetchApi'
import { Button } from 'react-bootstrap';



const Createmenulist = () => {
    const FetchApis = new FetchApi();
    const [menulist, setMenulist] = useState([]);
    const [textInput, setTextInput] = useState("");


    useEffect(() => {

        loadingMenulist();

    }, [])

    function loadingMenulist() {
        FetchApis.FethcGet(`/groupmenu/getgroupmenulistall`).then((res) => {
            setMenulist(res.data.data)

        }).catch(err => alert(err));

    }


    function createMenulistNew() {


        var data = {
            namegroup: textInput
        }

        if (textInput == "") {
            alert('กรุณาตรวจสอบข้อมูลก่อนบันทึก ?')
        }
        else {
            var valuetext = menulist.filter(item => item.namegroup == textInput);

            if (valuetext.length > 0) {

                alert('มีกลุ่มนี้อยู่แล้วกรุณาใช้ชื่ออื่น');

            } else {
                
                FetchApis.FethcPost(`/groupmenu/creategroupmenu`, data).then((res) => {
                    if (res.status == 200) {

                        alert('บันทึกเรียบร้อยแล้ว');
                        loadingMenulist();
                    }
                    else {
                        alert('ไม่สามารถบันทึกได้')
                    }
                }).catch(err => console.log(err));

            }
        }

    }

    return (
        <div>
            <form>
                <div style={{ margin: 10, padding: 10 }} >
                    <div className='row-page'>
                        <label>ชือกลุ่มผุ้ใช้งาน : </label>
                        <input type='text' name='Name_menulist' value={textInput} onChange={(e) => setTextInput(e.target.value)} style={{ width: 300, height: 25 }} />
                        <div style={{ marginLeft: 10 }}>
                            <Button size='sm' variant="success" style={{ marginRight: 10 }} onClick={() => createMenulistNew()}>บันทึก</Button>
                            <Button type='reset' size='sm' variant="warning">ยกเลิก</Button>
                        </div>
                    </div>
                </div>
            </form>
            <div>
            <Tablemenulist values={menulist} loadingMenulist={loadingMenulist}/>
            </div>
        </div>
    )
}

export default Createmenulist