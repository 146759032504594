import { createSlice } from '@reduxjs/toolkit'




const initialState = {
    detiallist:[]
}

export const coatingDetailSlice = createSlice({
    name: 'coatingDetail',
    initialState,
    reducers: {
        adddetiallist: (state, action) => {
            state.detiallist = action.payload;
        },
        removedetiallist: (state, action) => {
            state.detiallist = action.payload;
        },
        removeall: (state) => {
            state.detiallist=[];
        }

    },
})

// Action creators are generated for each case reducer function
export const { adddetiallist, removedetiallist, removeall} = coatingDetailSlice.actions

export default coatingDetailSlice.reducer