import React, { useState, useEffect } from 'react'
import { useForm } from "react-hook-form";
import fromA1 from './json-data/datatypeformA1.json';
import fromA2 from './json-data/datatypeformA2.json';

import FetchApi from '../../pages/customhooks/functionFetchApi';
import PopupItemList from './components/popupItemList';
import PopupCustomerList from './components/popupCustomerList';
import PopupRefFG from './components/popupRefFG';
import FormAtable from './components/formAtable';
import DuplicateByfilmcode from './duplicateByfilmcode';
import { FaFileSignature } from "react-icons/fa";
import Spinner from 'react-bootstrap/Spinner';
import Swal from 'sweetalert2';

import './css/formstyle.css';
import { Button } from 'react-bootstrap';
const dataobject = {
  cansize: "-",
  contact: "-",
  createby: "",
  createdate: "",
  customername: "",
  duedate: "",
  filmcode: "",
  refFgcode: "",
  filmtype: [],
  informationnote: "",
  informationtype: [],
  itemcode: "",
  itemname: "",
  journaltype: "",
  printingtype: "",
  printingmachine:"",
  printingtypeother: "",
  productgrouptype: "",
  productname: "",
  shape: "",
  substratetype: "",
  tel: "",
  typeproduct: "",
  upfile: "",
  uploadfilecolor:""
}

const Formrevision1 = () => {

  const FetchApis = new FetchApi();
  var emCodes = sessionStorage.getItem("EmCode");

  const { register, handleSubmit, formState: { errors }, reset } = useForm();

  const [loading, setLoading] = useState(false);
  const [datajournal, setDatajournal] = useState([]);
  const [formlist, setFormlist] = useState([]);
  const [getDate, setGetDate] = useState();
  const [itemdetail, setItemdetail] = useState({ ...dataobject })

  const [fileLoad, setFileLoad] = useState("")

  const [infortype, setInfortype] = useState([]); // check Information from customer
  const [infortypeError, setInfortypeError] = useState(false);//check  Information length over one item 

  const [printtype, setPrinttype] = useState([]); // check printting system type 
  const [printtypeError, setPrinttypeError] = useState(false); // check printting length over one item

  const [typedocument, setTypedocument] = useState('A1')
  const [dataDocument, setDataDocument] = useState(null);

  const [filmtypeError, setfilmtypeError] = useState(false);

  const emcodes = sessionStorage.getItem("EmCode");


  useEffect(() => {
    if (typedocument == 'A1') {
      setDataDocument(fromA1)
    }

    loadingdata();
    loadingformtype();
    datenow();

  }, [])


  const handleFile = event => {
    var input = document.getElementById('loadfile');
    // console.log(input)

    const file = input.files[0]
    // const size = file.size // it's in bytes

    console.log(file)
    if (file != undefined) {
      setFileLoad(input.files[0])
    } else {
      setFileLoad("")
    }
    

  };

  const onSubmitHandler = async (values) => {

      var Replaceitemdetail = itemdetail.customername.replace("'", "`");
      var Replaceitemname = itemdetail.itemname.replace("'", "`");
      var Replaceproductname = values.productname.replace("'", "`");
      var productnameUpperCase=Replaceproductname.toUpperCase();
      
      const dataresualt = {
        cansize: values.cansize,
        contact: values.contact,
        createby: values.createby,
        createdate: values.createdate,
        customername: Replaceitemdetail,
        duedate: values.duedate,
        filmcode: values.filmcode,
        refFgcode: itemdetail.refFgcode,
        filmtype: values.filmtype,
        informationnote: values.informationnote,
        informationtype: values.informationtype,
        itemcode: itemdetail.itemcode,
        itemname: Replaceitemname,
        journaltype: values.journaltype,
        printingtype: values.printingtype,
        printingmachine:"",
        printingtypeother: values.printingtypeother,
        productgrouptype: values.productgrouptype,
        productname: productnameUpperCase,
        shape: values.shape,
        substratetype: values.substratetype,
        tel: values.tel,
        typeproduct: values.typeproduct,
        uploadfilename: '',
        uploadfilecolor:''
      }
      if (infortype.length > 1) {
        setInfortypeError(true)
      }
      else {
        setInfortypeError(false)
      }

      if (printtype.length > 1) {
        setPrinttypeError(true)
      }
      else {
        setPrinttypeError(false)
      }


      if (values.filmtype.length > 1) {
        setfilmtypeError(true)
      } else {
        setfilmtypeError(false)
      }

      if (infortype.length <= 1 && printtype.length <= 1 || values.filmtype.length <= 1) {

        Swal.fire({
          title: "บันทึกเอกสาร",
          text: "คุณแน่ใจที่บันทึกเอกสาร ใช่หรือไม่ ! ",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "ใช่",
          cancelButtonText: 'ยกเลิก'
        }).then((result) => {

          if (result.isConfirmed) {
            // upload file  
            if (fileLoad !== "") {

              if (fileLoad.size / 1024 > 500) {
                Swal.fire({
                  title: "แจ้งเตือน!",
                  text: "file size must not be greater than to 500k",
                  icon: "warning"
                });
              } else {

                FetchApis.FetchUploadfile('/images/uploadfile', fileLoad).then(res => {

                  if (res.success == true) {
                    dataresualt.uploadfilename = res.filename
                    // create journal 
                  
                    FetchApis.FethcPost('/journal/createjournal', dataresualt).then((result) => {

                      if (result.error == false) {

                        Swal.fire({
                          title: "บันทึกเอกสาร!",
                          text: "คุณได้ทำบันทึกเอกสารเรียบแล้ว",
                          icon: "success"
                        });
                        // loadingdata();
                        window.location.reload()

                      }
                      else {

                        alert(result.message)
                      }
                    })


                  }

                })
              }


            } else {

              // create journal 
              FetchApis.FethcPost('/journal/createjournal', dataresualt).then((result) => {

                if (result.error == false) {

                  Swal.fire({
                    title: "บันทึกเอกสาร!",
                    text: "คุณได้ทำบันทึกเอกสารเรียบแล้ว",
                    icon: "success"
                  });
                  window.location.reload()

                }
                else {

                  alert(result.message)
                }
              })

            }

          }

        });

      }
      else {
        alert('กรุณาตรวจสอบข้อมูลอีกครั้ง')
      }

    
  };

  // load ข้อมูลที่ทำการบันทึกไปแล้ว
  const loadingdata = () => {
    setLoading(true)

    setTimeout(() => {
      FetchApis.FethcGet(`/journal/journalbyNewJournallist/${emcodes}`).then((resultdata) => {
        if (resultdata) {
          setDatajournal(resultdata.data)
        }
        setLoading(false);
      })
    }, 1000);

  }

  function datenow() {
    let ts = Date.now();

    let date_ob = new Date(ts);
    let date = date_ob.getDate();
    let month = date_ob.getMonth() + 1;
    let year = date_ob.getFullYear();
    if (date < 10) {
      date = "0" + date
    }
    if (month < 10) {
      month = "0" + month
    }

    // prints date & time in YYYY-MM-DD format
    let nowdate = year + "-" + month + "-" + date;
    setGetDate(nowdate);
    // return nowdate;
  }

  // load ข้อมูล หัวข้อฟอร์ม
  const loadingformtype = () => {
    FetchApis.FethcGet(`/formtype/formlistByCreateflow`).then((value) => {
      setFormlist(value.data.data)
    })
  }


  // check information from customer 
  function handleChange(e) {

    const ischecked = e.target.checked;
    const istext = e.target.value;


    if (ischecked == true) {

      if (e.target.id == "informationtype") {
        setInfortype([...infortype, e.target.value]);

      }
      if (e.target.name == "printingtype") {

        setPrinttype([...printtype, e.target.value]);
      }


    }

    else if (ischecked == false) {

      if (e.target.name == "informationtype") {

        const index = infortype.indexOf(e.target.value);
        infortype.splice(index, 1);
      }

      if (e.target.name == "printingtype") {
        const index = printtype.indexOf(e.target.value);
        printtype.splice(index, 1);
      }
    }
  };


  function onChangeTypeDocument(e) {
    var values = e.target.value;
    if (values == 'A1') {

      setDataDocument(fromA1)
      setItemdetail({ ...itemdetail, typeproduct: values })

    }
    else if (values == 'A2') {
      setDataDocument(fromA2)
      setItemdetail({ ...itemdetail, typeproduct: values })
    }

  }


  function selectItemId(itemid, itemnames) {

    setItemdetail({
      ...itemdetail, itemcode: itemid,
      itemname: itemnames
    })

  }

  function selectCustomerlist(customernames) {
    setItemdetail({
      ...itemdetail,
      customername: customernames
    })
  }


  function selectRefFf(refFg) {
    setItemdetail({
      ...itemdetail,
      refFgcode: refFg
    })
  }


  return (
    <div>
      {dataDocument == null ? <div className='body-center-page'><Spinner
        as="span"
        animation="grow"
        variant="success"
        size={200}
        role="status"
        aria-hidden="true" /> LOADIN ....</div> :
        <div className='form-body'>

          <form onSubmit={handleSubmit(onSubmitHandler)}>
            <div style={{ backgroundColor: '#E5E7E7' }} className='row-between-page'>
              <center>
                <label style={{ fontSize: 20, margin: 5, padding: 5 }}><FaFileSignature /> สร้างเอกสารใหม่ </label>
              </center>
              <div style={{ width: '80%', justifyContent: 'flex-end', flexDirection: 'row', alignItems: 'flex-end', display: 'flex' }}>
                <div style={{ marginRight: 10 }}>
                  <Button size='sm' type='submit' variant="success" style={{ marginRight: 10 }} >บันทึก สร้างหัวข้อ</Button>
                  <Button size='sm' type='reset' variant="warning">ยกเลิก</Button>
                </div>
              </div>

            </div>

            <div className='row-between-form'>

              <div style={{ width: '100%', padding: 5 }} className='row-between-form'>

                <div style={{ backgroundColor: '#F2EFE7', alignItems: 'center', textAlign: 'center', justifyContent: 'center', padding: 20 }}>
                  <b>Marketing " A "</b>

                  <input type='text' id='createby'{...register('createby')} value={emCodes} hidden />

                </div>
                <div>
                  <label>ประเภทเอกสาร </label>
                  <div>
                    <input type='radio' name='typedocument' value={'A1'}
                      {...register('typeproduct', { required: true })}

                      defaultChecked={true} onChange={(e) => onChangeTypeDocument(e)} /> <label> เอกสารประเภท Food can Product </label>
                  </div>
                  <div>
                    <input type='radio' name='typedocument' value={'A2'}
                      {...register('typeproduct', { required: true })}

                      onChange={(e) => onChangeTypeDocument(e)} /> <label> เอกสารประเภท Dry foods ,Aerosol can and General can product </label>
                  </div>
                </div>
                <div>
                  <select name="journaltype" id="journaltype"
                    {...register('journaltype', { required: true })}
                    onChange={(e) => setItemdetail({ ...itemdetail, journaltype: e.target.value })}
                    style={{ borderColor: errors.journaltype ? "red" : "", width: '100%', height: 50 }}>
                    <option value={""}>เลือกประเภท product ...</option>
                    {formlist.map((item, i) => (
                      <option value={item.formcode}>{item.fname}</option>
                    ))}
                  </select>
                </div>

              </div>

              <div style={{ width: '25%', padding: 5, flexDirection: 'column', alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                <div style={{ marginBottom: 5 }}><b>Due Date:</b></div>
                <div>
                  <input type='date' id='duedate' {...register('duedate', { required: true })}
                    onChange={(e) => setItemdetail({ ...itemdetail, duedate: e.target.value })}
                    style={{ borderColor: errors.duedate ? "red" : "" }} required />
                </div>

              </div>
              <div style={{ width: '25%', padding: 5 }}>

                <div className='row-between-form'>
                  <label>MK NO :</label> <input type='text' value={'MK-####-####'} style={{ borderColor: errors.tdscode ? "red" : "" }} disabled />
                </div>
                <div className='row-between-form'>
                  <label>Date :</label>
                  <input type='date' value={getDate} id='createdate'
                    onChange={(e) => setItemdetail({ ...itemdetail, createdate: e.target.value })}
                    {...register("createdate")} style={{ borderColor: errors.createdate ? "red" : "" }}
                    disabled />
                </div>
              </div>

            </div>
            <hr></hr>
            <div className='row-between-form' style={{ padding: 10 }}>
              <div style={{ width: '50%' }}>
                <div>
                  <label>Customer name </label>
                  <div className='row-page'>
                    <input type='text' id='customername' style={{ width: '50%', margin: 1, borderColor: errors.customername ? "red" : "" }}
                      value={itemdetail.customername}
                      onChange={(e) => setItemdetail({ ...itemdetail, customername: e.target.value })}

                      required />
                    <PopupCustomerList selectCustomerlist={selectCustomerlist} />
                  </div>

                </div>
                <div>
                  <label> Product name </label>
                  <div>
                    <input type='text' id='productname' style={{ width: '50%', margin: 1, borderColor: errors.productname ? "red" : "" }}
                      onChange={(e) => setItemdetail({ ...itemdetail, productname: e.target.value })}
                      {...register("productname", { required: true })} required />

                  </div>
                </div>

                <div>
                  <label>Item name </label>
                  <div className='row-page'>
                    <input type='text' id='itemname' style={{ width: '70%', margin: 1, borderColor: errors.itemname ? 'red' : '' }}
                      onChange={(e) => setItemdetail({ ...itemdetail, itemname: e.target.value })}
                      value={itemdetail.itemname}  />

                    <PopupItemList selectItemId={selectItemId} />
                  </div>
                </div>
              </div>

              <div style={{ width: '50%' }}>
                <div className='row-between-form' style={{ width: '100%' }}>
                  <div style={{ width: '100%' }}>
                    <label>Contact </label>
                    <div>
                      <input type='text' id='contact' style={{ width: '90%', margin: 1, borderColor: errors.contact ? 'red' : '' }}
                        onChange={(e) => setItemdetail({ ...itemdetail, contact: e.target.value })}
                        {...register("contact", { required: false })} />
                    </div>
                  </div>
                  <div style={{ width: '100%' }}>
                    <label>Tel  </label>
                    <div>
                      <input type='text' id='tel' style={{ width: '90%', margin: 1, borderColor: errors.tel ? 'red' : '' }}
                        onChange={(e) => setItemdetail({ ...itemdetail, tel: e.target.value })}
                        {...register("tel", { required: false })} />
                    </div>
                  </div>
                </div>

                <div className='row-between-form' style={{ width: '100%' }}>
                  <div style={{ width: '100%' }}>
                    <label>Can Size / Dia  </label>
                    <div>
                      <input type='text' id='cansize' style={{ width: '90%', margin: 1, borderColor: errors.cansize ? 'red' : '' }}
                        onChange={(e) => setItemdetail({ ...itemdetail, cansize: e.target.value })}
                        {...register("cansize", { required: false })} />
                    </div>
                  </div>
                  <div style={{ width: '100%' }}>
                    <label> Shape </label>
                    <div>
                      <input type='text' id='shape' name='shape' style={{ width: '90%', margin: 1, borderColor: errors.cansize ? 'red' : '' }}
                        onChange={(e) => setItemdetail({ ...itemdetail, shape: e.target.value })}
                        {...register("shape", { required: false })} />
                    </div>
                  </div>
                </div>
                <div>
                  <label>Item no </label>
                  <div>
                    <input type='text' id='itemcode' style={{ width: '50%', margin: 1, borderColor: errors.itemcode ? 'red' : '' }}
                      onChange={(e) => setItemdetail({ ...itemdetail, itemcode: e.target.value })}
                      value={itemdetail.itemcode}
                    />
                  </div>
                </div>
              </div>
            </div>
            <hr></hr>

            <div className='row-between-form'>
              <div>
                <b>substrate</b>
                <div>
                  {dataDocument.substrate.data.map((item, i) => (
                    <div>
                      <input type='radio' id='substratetype'
                        onChange={(e) => setItemdetail({ ...itemdetail, substratetype: e.target.value })}

                        {...register("substratetype", { required: true })} value={item} /> {item}</div>
                  ))}
                </div>
                <div>{errors.substratetype ? <b style={{ color: 'red' }}>substrate required is a field</b> : ""}</div>
              </div>

              <div>
                <b>Product group</b>
                <div>
                  {dataDocument.productgroup.data.map((item, i) => (
                    <div><input type='radio' id='productgrouptype'
                      onChange={(e) => setItemdetail({ ...itemdetail, productgrouptype: e.target.value })}
                      {...register("productgrouptype", { required: true })} value={item} /> {item}</div>
                  ))}
                </div>
                <div>{errors.productgrouptype ? <b style={{ color: 'red' }}>productgroup required is a field</b> : ""}</div>
              </div>

              <div>
                <b>Information from customer</b>
                <div>{dataDocument.information.data.map((item, i) => (
                  <div>
                    <input type='checkbox' id='informationtype' {...register("informationtype")} name='informationtype'
                      value={item} onClick={(e) => handleChange(e)} /> {item}
                  </div>
                ))}</div>

                <input type='text' id='informationnote' {...register("informationnote")} style={{ width: 300 }} />
                <div>
                  {infortypeError == true ? <label style={{ color: 'red' }}>* กรุณาเลือก 1 รายการเท่านั้น *</label> : ""}
                </div>
              </div>

              <div>

                <div hidden>

                  {dataDocument.printing.data.map((item, i) => (
                    <div>
                      <input type='checkbox' id='printingtype' name='printingtype'
                        onChange={(e) => setItemdetail({ ...itemdetail, printingtype: e.target.value })}
                        {...register("printingtype")} value={item}
                        onClick={(e) => handleChange(e)}
                      /> {item}</div>
                  ))}

                  <div>
                    <input type='text' name='printingOther' placeholder='Input other'
                      onChange={(e) => setItemdetail({ ...itemdetail, printingtypeother: e.target.value })}
                      {...register("printingtypeother")} />
                  </div>

                  {printtypeError == true ? <label style={{ color: 'red' }}>* กรุณาเลือก 1 รายการเท่านั้น *</label> : ""}
                </div>

                <div className='row-page'>
                  <b>last film code :</b><input type='text' id='filmcode'
                    onChange={(e) => setItemdetail({ ...itemdetail, filmcode: e.target.value })}
                    {...register("filmcode", { required: false })} style={{ borderColor: errors.filmcode ? "red" : "" }} />
                  <DuplicateByfilmcode />
                </div>

                <div className='row-page'>
                  <div>
                    <label>FG Last Ref. filmcode :</label>
                  </div>
                  <div className='row-page'>
                    <input type='text' name='refFgcode'
                      onChange={(e) => setItemdetail({ ...itemdetail, refFgcode: e.target.value })}
                      style={{ marginRight: 5 }}
                      value={itemdetail.refFgcode}

                    /> <PopupRefFG selectRefFf={selectRefFf} />
                  </div>
                </div>

                <div>
                  <input type='checkbox' id="namefilmtype" name='namefilmtype' value={"Scrap"}
                    {...register("filmtype")} onClick={(e) => handleChange(e)} /> Scrap
                  <input type='checkbox' id="namefilmtype" name='namefilmtype' value={"Maintain"}
                    {...register("filmtype")} onClick={(e) => handleChange(e)} /> Maintain
                  <div>
                    {filmtypeError == true ? <label style={{ color: 'red' }}>* กรุณาเลือก 1 รายการเท่านั้น *</label> : ""}
                  </div>

                </div>

                <div style={{ marginTop: 10 }}>
                  <div>แนบเอกสารอ้างอิง</div>
                  <input type='file' name='loadfile' accept="image/png, image/jpg" onChange={handleFile} id='loadfile' />
                </div>

              </div>

            </div>

            <hr></hr>
          </form>
        </div>
      }
      <dvi>
        {loading == true ? <div className='body-center-page'><Spinner
          as="span"
          animation="grow"
          variant="success"
          size={200}
          role="status"
          aria-hidden="true" /> LOADIN ....</div> : <div><FormAtable able datatable={datajournal.data} /></div>}

      </dvi>
    </div>
  )
}

export default Formrevision1