import React, { useState, useEffect } from 'react'
import FetchApiCalls from '../../../customhooks/functionFetchApi_erp';
import Modal from 'react-bootstrap/Modal';
import { FaSearch } from "react-icons/fa";
import { Button } from 'react-bootstrap';
import DataTable from 'react-data-table-component';


function WhiteCodeListAll({ whitecodeValue, sequence, handleShow }) {

    const FetchApi = new FetchApiCalls();
    const [TextSearch, setTextSearch] = useState("");
    const [whitecode, setWhitecode] = useState([]);
    const[searchwhite,setSearchwhite]=useState([])


    const columns = [
        {
            name: 'white code name',
            sortable: true,
            grow: 2,
            selector: row => row.propertyid,
        },
        {
            name: 'Action',
            //  grow: 1.5,
            cell: row => <div><button onClick={() => selectWhiteCode(row.propertyid)} >เลือกรายการ</button></div>,
        },

    ];


    useEffect(() => {
        FetchApi.FethcGet(`getwhitecode`).then((res) => {
            if (res.data.success == true) {
                setWhitecode(res.data.listall)
                setSearchwhite(res.data.listall);
            }

        })
    }, [])

    function selectWhiteCode(values) {

        whitecodeValue(values, sequence);
        handleShow(false);
    }


    const handleInputChange = (event) => {
        const query = event.target.value;

        const filtered = searchwhite.filter((item) =>
            item.propertyid.toLowerCase().includes(query.toLowerCase())
        );
        setWhitecode(filtered);
    };




    return (
        <div>
            <div>
                <input type='text' name='whitecode' placeholder='ค้นหา whitecoe' style={{ marginRight: 10 }}
                    onChange={(e) => handleInputChange(e)}
                />
                <hr></hr>
                <DataTable
                    columns={columns}
                    data={whitecode}
                    pagination
                    highlightOnHover

                />
                {/* {whitecode.map((item, i) => (
                    <div className='row-between-page box-body-color'>
                        <div className='box-body-color'>
                            <label style={{ fontSize: 12 }}>{item.propertyid}</label>
                        </div>

                        <div>
                            <Button onClick={() => selectWhiteCode(item.propertyid)} size='sm'>เลือกข้อมูล</Button>
                        </div>
                    </div>
                ))} */}
            </div>
            <hr></hr>
        </div>
    )
}

const SearchWhitecode = ({ whitecodeValue, sequence }) => {
    
    const FetchApis = new FetchApiCalls();
    const [fullscreen, setFullscreen] = useState(true);
    const [show, setShow] = useState(false);

    function handleShow(value) {
        setShow(value);
    }

    return (
        <div>
            <div style={{ marginLeft: 5 }}>
                <label onClick={() => handleShow(true)}><FaSearch /></label>
            </div>

            <Modal show={show}
                //   fullscreen={fullscreen}
                size="lg"
                onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title><b>White Code List</b></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* <EditJournal id={id} /> */}
                    <WhiteCodeListAll whitecodeValue={whitecodeValue} sequence={sequence} handleShow={handleShow} />
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default SearchWhitecode