import DataTable from 'react-data-table-component';
import React from 'react'
import Accessuser from './accessuser';


const columns = [
    {
        name: 'username',
        sortable: true,
        selector: row => row.username,
    },
    {
        name: 'employeeId',
        selector: row => row.employeeId,
    },
    {
        name: 'departmentId',
        selector: row => row.departmentId,
    },
    {
        name: 'roles',
        //  grow: 2,
        cell: row =>row.roles,
    },
    {
        name: 'Action',
        //  grow: 2,
        cell: row =><Accessuser emcode={row.employeeId}/>,
    },

];

const customStyles = {
    headRow: {
        style: {
            border: 'none',
            backgroundColor: '#28B463'
        },
    },
    headCells: {
        style: {
            color: '#ffff',
            fontSize: '14px',
        },
    },
    rows: {
        highlightOnHoverStyle: {
            backgroundColor: 'rgb(230, 244, 244)',
            borderBottomColor: '#FFFFFF',
            borderRadius: '25px',
            outline: '1px solid #FFFFFF',
        },
    },
    pagination: {
        style: {
            border: 'none',
        },
    },
};



const Teblelistaccessuser = ({userAll}) => {
    var dataresult = userAll;
  

    return (
    
        <DataTable
            columns={columns}
            data={dataresult}
            // selectableRows
            pagination
            customStyles={customStyles}
            highlightOnHover

        />
    )
}

export default Teblelistaccessuser