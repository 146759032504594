import React, { useState, useEffect } from 'react'
import { Button } from 'react-bootstrap'
import Modal from 'react-bootstrap/Modal';
import FetchApi from '../../../customhooks/functionFetchApi';
import { updateflowsub } from '../../../../reduxSlice/flowsystemsubSlice/flowsystemsubSlice';
import { useDispatch } from 'react-redux';

function Popeditflowsystembus({ Id, handleShow }) {

    const FetchApis = new FetchApi();
    const dispatch = useDispatch();

    const [userlist, setUserlist] = useState([]);
    const [flowsublist, setFlowsublist] = useState({ flowcode: Id, approveby: "", emcode: "", approvecode: "", stateflow: "" });


    useEffect(() => {

        FetchApis.FethcGet(`/account/accountall`).then((result) => {

            if (result.data.status == 200) {
                setUserlist(result.data.data);
            }

            listflowsystemsub()

        })

    }, [])


    function onSubmitflow() {
        if (flowsublist.emcode == "" || flowsublist.approvecode == "" || flowsublist.stateflow == "") {
            alert("กรุณาตรวจสอบข้อมูลก่อนบันทึก")
        }
        else{
            const filtered = userlist.filter(data => data.employeeId == flowsublist.emcode);

            var objectflowsub = {
                flowid:Id,flowcode: flowsublist.flowcode, approveby: filtered[0].username, emcode: flowsublist.emcode, approvecode: flowsublist.approvecode, stateflow: flowsublist.stateflow
            }
            // console.log(objectflowsub);

            if (objectflowsub) {

                FetchApis.FethcUpdate(`/flowsystemsub/flowsystemUpdatesubById/${Id}`, objectflowsub,).then((results) => {
                    if (results) {

                        dispatch(updateflowsub(objectflowsub))
                        listflowsystemsub();
                        handleShow(false);

                    }
                })

            }
         
        }
      

    }

    //ดึงข้อมูลมาแสดง ในการแก้ไขข้อมูล
    function listflowsystemsub() {
        FetchApis.FethcGet(`/flowsystemsub/flowsystemsubByid/${Id}`).then((result) => {
            setFlowsublist(result.data.data[0])
        })
    }


    return (
        <div>
            <div className='row-page' style={{ margin: 10, padding: 5 }}>
                <div>ค้นหาตามแผนก : </div>
                <select name="searhdepart" id="searhdepart">
                    <option value={""} selected>เลือกข้อมูล...</option>
                    <option>2</option>
                    <option >3</option>
                    <option>4</option>
                    <option>5</option>
                </select>
                <label style={{ color: 'red' }}>ยังไม่ได้ทำ</label>
            </div>

            <form style={{ backgroundColor: '#F2EFE7', padding: 10 }}>
                <div className='row-page' style={{ marginLeft: 20 }}>
                    <div>เลือกผู้อนุมัติ : </div>
                    <select name="emcode" id="emcode" onChange={(e) => setFlowsublist({ ...flowsublist, emcode: e.target.value })}>
                        <option value={""} selected>เลือกข้อมูล...</option>
                        {userlist.map((item, i) => (
                            flowsublist.emcode == item.employeeId ? <option value={item.employeeId} selected>{item.name}</option>
                                :
                                <option value={item.employeeId}>{item.name}</option>
                        )
                        )
                        }
                    </select>
                </div>

                <div className='row-page' style={{ marginLeft: 20 }}>
                    <div>
                        <label>ลำดับอนุมัติ : </label>
                    </div>

                    <div>
                        <input type='number' value={flowsublist.stateflow} name='stateflow' placeholder='state flow' onChange={(e) => setFlowsublist({ ...flowsublist, stateflow: e.target.value })} />
                    </div>
                </div>



                <div className='row-page'>

                    <div style={{ margin: 10 }}> <input type='radio' name='approvecode' value={'0'} onChange={(e) => setFlowsublist({ ...flowsublist, approvecode: e.target.value })}
                        checked={flowsublist.approvecode == 0 && true}
                    /> : ตรวจสอบ </div>
                    <div style={{ margin: 10 }}><input type='radio' name='approvecode' value={'1'} onChange={(e) => setFlowsublist({ ...flowsublist, approvecode: e.target.value })}
                        checked={flowsublist.approvecode == 1 && true}
                    /> : อนุมัติ </div>

                </div>

                <div style={{ margin: 10, padding: 10 }} className='end-flex-row'>
                    <Button size='sm' onClick={() => onSubmitflow()} variant="success" style={{ marginRight: 10 }}>แก้ไข</Button>
                    <Button size='sm' type='reset' variant="warning">ยกเลิก</Button>
                </div>
            </form>
        </div>
    )

}

const Editflowsystemsub = ({ Id }) => {

    const values = [true, 'sm-down', 'md-down', 'lg-down', 'xl-down', 'xxl-down'];
    const [fullscreen, setFullscreen] = useState(true);
    const [show, setShow] = useState(false);

    function handleShow(value) {
        // setFullscreen(breakpoint);
        setShow(value);
    }

    return (
        <div>
            <Button size='sm' variant="warning" onClick={() => handleShow(true)}>แก้ไข</Button>

            <Modal show={show}
                // fullscreen={fullscreen}
                size="lg"
                // dialogClassName="modal-90w"
                onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>แก้ไขรายละเอียด flow</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Popeditflowsystembus handleShow={handleShow} Id={Id} />

                </Modal.Body>
            </Modal>

        </div>
    )
}

export default Editflowsystemsub