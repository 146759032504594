import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap'
// import Detailjournalpartb from './detailjournalpartb';
import PrintingDetailpartb from './printingDetailpartb';



const Addprinting = ({ codeId, journalId, stateflow }) => {
   
    const [fullscreen, setFullscreen] = useState(true);
    const [show, setShow] = useState(false);
    const[journaldata,setJournaldata]=useState([])

    function handleShow(value) {
        setShow(value);
    }

    return (
        <div >
            <Button size='sm' variant="warning" onClick={() => handleShow(true)} >
                จัดการเอกสาร
            </Button>

            <Modal show={show} fullscreen={fullscreen} onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>แสดงรายละเอียกเอกสาร  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='form-body-page'>

                        <PrintingDetailpartb codeId={codeId} journalId={journalId} stateflow={stateflow} journaldata={journaldata}/>

                    </div>

                </Modal.Body>
            </Modal>
        </div>
    )
}

export default Addprinting