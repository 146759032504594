import DataTable from 'react-data-table-component';
import React from 'react'
import Statusall from '../componentallstatus/statusall';
import { FaList,FaFileSignature,FaArchive,FaExclamationTriangle,FaCheck,FaCheckCircle } from "react-icons/fa";

import Actionrecall from './actionrecall';


const columns = [
    {
        name: 'TDS code',
        sortable: true,
        selector: row => row.tdscode,
    },
    {
        name: 'Journal Type',
        selector: row => row.journaltype,
    },
    {
        name: 'customer name',
        selector: row => row.customername,
    },
    {
        name: 'revision',
        selector: row => row.revision,
        grow: 0.5
    },
    // {
    //     name: 'item code',
    //     sortable: true,
    //     selector: row => row.itemcode,
    // },
    // {
    //     name: 'cansize',
    //     selector: row => row.cansize,
    // },
    {
        name: 'createdate',
        selector: row => row.createdate,
    },
    {
        name: 'status flow',
        sortable: true,
        selector: row => <Statusall id={row.journalId} statusNow={row.stateflow} stateSuccesfull={row.stateSuccesfull}/>,
        grow: 2,
    },
    {
        name: 'item sample',
        selector: row => row.itemsample=='1'?<div><label style={{color:'orchid'}}><FaFileSignature/> มอบตัวอย่าง</label></div>:"",
    },
    {
        name: 'job status',
        selector: row =><div>{row.statusprove_Tds==1&&<label style={{color:'#0e9a07'}}><FaCheckCircle /> proof job</label>}</div> ,
    },
    { 
        name: 'action',
         grow: 2,
        cell: row =><div>
            <Actionrecall id={row.journalId}/>
        </div>,
    },

];

const customStyles = {
    headRow: {
        style: {
            border: 'none',
            backgroundColor: '#28B463'
        },
    },
    headCells: {
        style: {
            color: '#ffff',
            fontSize: '14px',
        },
    },
    rows: {
        highlightOnHoverStyle: {
            backgroundColor: 'rgb(230, 244, 244)',
            borderBottomColor: '#FFFFFF',
            borderRadius: '25px',
            outline: '1px solid #FFFFFF',
        },
    },
    pagination: {
        style: {
            border: 'none',
        },
    },
};



const Tablerecallstatus = (datatable) => {
    var dataresult = datatable.datatable;
    return (
    
        <DataTable
            columns={columns}
            data={dataresult}
            // selectableRows
            pagination
            customStyles={customStyles}
            highlightOnHover

        />
    )
}

export default Tablerecallstatus