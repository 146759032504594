import React from "react";
import FetchApi from "../../../../customhooks/functionFetchApi"
import { statusflowall } from '../../../../form01/object-usestate/objectdefaults';

const FetchApis = new FetchApi();

export function createRevision_Duplicate(journalId) {

    // header 
    return FetchApis.FethcGet(`/journal/journalbyId/${journalId}`).then((res) => {
        if (res.data.status == 200) {
            // console.log(res.data)
            createjournalHeader(res.data.data[0]);

        }
    })


}

// สร้าง journal
function createjournalHeader(data) {

    const usercode = sessionStorage.getItem('EmCode');


    return FetchApis.FethcGet(`/journal/createjournal-revision/${data.journalId}`).then((res) => {
        if (res.status == 200) {
            // console.log(res.data.id);
            const newjourId = res.data.id;
            const jourId = data.journalId;

            selectjournallist(jourId, newjourId);

            updatestatus(newjourId);
        }
    })

    function selectjournallist(jourid, newJourId) {



        FetchApis.FethcGet(`/journal/journalbyId/${jourid}`).then((res) => {
            if (res.status == 200) {
                getDatailjournal(jourid, newJourId, res.data.data[0].tdscode)

            }
        })
    }

    function updatestatus(journalid) {

        var stateflowall = {
            statusflow: statusflowall.newjournal,
            stateflow: "0"
        }
        // update state flow  new journal 
        FetchApis.FethcUpdate(`/journal/updatestateflow/${journalid}`, stateflowall).then((res) => { console.log(res) })

    }

}


// เรียกข้อมูล ล่าสุดในการบันทึกเพื่อนำไปสร้างรายการ autonomust
async function getDatailjournal(jourid, newJourId, TDScode) {
    // coating 
    await FetchApis.FethcGet(`/coating/coatingByJournalId/${jourid}`).then((res) => {
        if (res.data.status == 200) {

            var datacoating = res.data.data;
            for (let index = 0; index < datacoating.length; index++) {
                const element = datacoating[index];
                createcoating(element, newJourId, TDScode)
            }
        }
    })
    // can & end forming
    await FetchApis.FethcGet(`/forming/formingByJournalId/${jourid}`).then((res) => {
        if (res.data.status == 200) {
            createforming(res.data.data[0], newJourId, TDScode);
        }
    })
    // guideline for product pack
    await FetchApis.FethcGet(`/guideline/guidelineByJournalId/${jourid}`).then((res) => {
        if (res.data.status == 200) {
            createguideline(res.data.data[0], newJourId, TDScode);
        }
    })
    // regulation
    await FetchApis.FethcGet(`/regulation/regulationByJourId/${jourid}`).then((res) => {
        if (res.data.status == 200) {
            create_regulation(res.data.data[0], newJourId, TDScode);
        }
    }).catch(err => { console.log(err) })
    // Request sample
    await FetchApis.FethcGet(`/requestsample/requestsampleByJournalId/${jourid}`).then((res) => {
        if (res.data.status == 200) {
            createrequest(res.data.data[0], newJourId, TDScode);
        }
    }).catch(err => { console.log(err) })

    //conponent code
    await FetchApis.FethcGet(`/componentcode/componentByjournalId/${jourid}`).then((res) => {
        if (res.data.status == 200) {
            // console.log("compoment code=>"+res.data.data[0])
            createComponente(res.data.data[0], newJourId, TDScode);
        }
    }).catch(err => { console.log(err) })


}

// สร้าง coating
function createcoating(data, journalId, newtdscode) {

    const { producttype, number, groupBobyType, substrate, shape, shapedetail, dia, ring_eartype,
        ring_earcode, forsaleorder, intcoating, intwhite, intother, extcoating, extwhite, extother,
        note, tdscode } = data;

    var coatingdata = {
        producttype: producttype,
        number: number,
        groupBobyType: groupBobyType,
        substrate: substrate,
        shape: shape,
        shapedetail: shapedetail,
        dia: dia,
        ring_eartype: ring_eartype,
        ring_earcode: ring_earcode,
        forsaleorder: forsaleorder,
        intcoating: intcoating,
        intwhite: intwhite,
        intother: intother,
        extcoating: extcoating,
        extwhite: extwhite,
        extother: extother,
        note: note,
        journalId: journalId,
        tdscode: newtdscode
    }

    FetchApis.FethcPost(`/coating/createcoating`, coatingdata).then((res) => {
        console.log(res)
    }).catch(err => console.log(err))

}
//สร้าง can & end forming
function createforming(data, journalId, newtdscode) {
    const { internalside, internalother, seaming, seamingother, machineno, packaging, note, tdscode } = data;
    var formingdata = {
        internalside: internalside,
        internalother: internalother,
        seaming: seaming,
        seamingother: seamingother,
        machineno: machineno,
        packaging: packaging,
        note: note,
        journalId: journalId,
        tdscode: newtdscode
    }
    FetchApis.FethcPost(`/forming/createforming`, formingdata).then((res) => {
        console.log(res)
    }).catch(err => { console.log(err) })

}
// สร้าง guideline
function createguideline(data, journalId, newtdscode) {
    const {
        shelfyears,
        shelfdeg,
        typegroup,
        groupother,
        groupother_note,
        groupdryfood,
        groupaerosol,
        groupgeneral,
        detailproduct,
        ph,
        ingredients,
        ingredients_acid,
        ingredients_chioride,
        ingredientother,
        seamercondition,
        sterilization,
        sterilization_retort,
        sterilization_cooker,
        sterilizationother,
        // statusProof, 
        tdscode
    } = data;

    var dataguidelline = {
        shelfyears: shelfyears,
        shelfdeg: shelfdeg,
        typegroup: typegroup,
        groupother: groupother,
        groupother_note: groupother_note,
        groupdryfood: groupdryfood,
        groupaerosol: groupaerosol,
        groupgeneral: groupgeneral,
        detailproduct: detailproduct,
        ph: ph,
        ingredients: ingredients,
        ingredients_acid: ingredients_acid,
        ingredients_chioride: ingredients_chioride,
        ingredientother: ingredientother,
        seamercondition: seamercondition,
        sterilization: sterilization,
        sterilization_retort: sterilization_retort,
        sterilization_cooker: sterilization_cooker,
        sterilizationother: sterilizationother,
        // statusProof: statusProof,
        journalId: journalId,
        tdscode: newtdscode
    }

    FetchApis.FethcPost(`/guideline/createguideline`, dataguidelline).then((res) => {
        console.log(res)
    }).catch(err => { console.log(err) })

}
// สร้าง Request sample
function create_regulation(data, journalId, newtdscode) {

    const { regulationtype, regulationother, tdscode } = data;

    var dataregulation = {
        regulationtype: regulationtype,
        regulationother: regulationother,
        journalId: journalId,
        tdscode: newtdscode
    }

    FetchApis.FethcPost(`/regulation/createregulation`, dataregulation).then((res) => {
        console.log(res)
    }).catch(err => { console.log(err) });
}
//สร้าง Request sample
function createrequest(data, journalId, newtdscode) {
    const { proofplate, requestcan, requestend, requestnote, tdscode } = data;

    var datarequest = {
        proofplate: proofplate,
        requestcan: requestcan,
        requestend: requestend,
        requestnote: requestnote,
        journalId: journalId,
        tdscode: newtdscode
    }
    FetchApis.FethcPost(`/requestsample/createrequestsample`, datarequest).then((res) => {
        console.log(res)
        if (res.status == 200) {
            // navigate('/journallistparta')
            // setTimeout(() => {
            //     window.location.href = "/journallistparta"
            // }, 1700);
        }
    }).catch(err => { console.log(err) })
}


//สร้าง componnet
function createComponente(data, newJournalId, newtdscode) {
    
    const { ear,earwelding,ring,innerlid, outerlid, toplid,bottomlid,plasticlid, aluminumlid, journalId, tdscode } = data;
    // const { proofplate, requestcan, requestend, requestnote, journalId, tdscode } = data;

    var datarequest = {
        ear: ear,
        earwelding:earwelding,
        ring:ring,
        innerlid: innerlid,
        outerlid: outerlid,
        toplid: toplid,
        bottomlid:bottomlid,
        plasticlid: plasticlid,
        aluminumlid: aluminumlid,
        journalId:newJournalId,
        tdscode:newtdscode
    }
    FetchApis.FethcPost(`/componentcode/createcomponentcode`, datarequest).then((res) => {
        console.log(res)
        if (res.status == 200) {
            // navigate('/journallistparta')
            // setTimeout(() => {
            //     window.location.href = "/journallistparta"
            // }, 1700);
        }
    }).catch(err => { console.log(err) })
}

