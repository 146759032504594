import React from 'react'
import Journalapporvededicate from './journalapporvededicate';
// import Journalrejectdedicate from './journalrejectdedicate';

const Actiondedicate = ({idjournal,dedicateid}) => {
  return (
    <div className='row-page'>
      <div style={{marginRight:10}}>
        <Journalapporvededicate journalids={idjournal} dedicateids={dedicateid}/>
      </div>
    </div>
  )
}

export default Actiondedicate