import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    typebody: [],
    typetop: [],
    typebottom: [],
    typetab: []
}

export const newitemSlice = createSlice({
    name: 'newitem',
    initialState,
    reducers: {
        addnewitemBody: (state, action) => {
            state.typebody = action.payload;
        },
        addnewitemTop: (state, action) => {
            state.typetop = action.payload;
        },
        addnewitemBottom: (state, action) => {
            state.typebottom = action.payload;
        },
        addnewitemTab: (state, action) => {
            state.typetab = action.payload;
        },
        removenewitemBody: (state, action) => {
            state.typebody = []
        },
        removenewitemTop: (state, action) => {
            state.typetop = []
        },
        removenewitemBottom: (state, action) => {
            state.typebottom = []
        },
        removenewitemTab: (state, action) => {
            state.typetab = []
        },
    },
})

// Action creators are generated for each case reducer function
export const { addnewitemBody, addnewitemTop, addnewitemBottom, addnewitemTab,
    removenewitemBody, removenewitemTop, removenewitemBottom, removenewitemTab
} = newitemSlice.actions

export default newitemSlice.reducer