import React, { useState, useEffect } from 'react'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import FetchApi from '../../../customhooks/functionFetchApi_erp';
import { FaSearch } from "react-icons/fa";
import DataTable from 'react-data-table-component';

function TableGroupItem({ data, hendleModel,groupItemset }) {

    const SelectGruop=(value)=>{
        hendleModel(false)
        groupItemset(value)
    }

    const columns = [
        {
            name: 'ITEM GROUPID',
            sortable: true,
            selector: row => row.ITEMGROUPID,
        },
        {
            name: 'NAME',
            selector: row => row.NAME,
        },
        {
            name: 'เลือกกุล่ม',
            selector: row => <div>
                <Button variant="primary" size='sm' onClick={() => SelectGruop(row.ITEMGROUPID)}>เลือกกลุ่ม</Button>
                {/* <button onClick={()=>hendleModel(false)}>คลิกเลือก</button> */}
            </div>,
        },
    ];

    const customStyles = {
        headRow: {
            style: {
                border: 'none',
                backgroundColor: '#28B463'
            },
        },
        headCells: {
            style: {
                color: '#ffff',
                fontSize: '14px',
            },
        },
        // rows: {
        //     highlightOnHoverStyle: {
        //         backgroundColor: 'rgb(230, 244, 244)',
        //         borderBottomColor: '#FFFFFF',
        //         borderRadius: '25px',
        //         outline: '1px solid #FFFFFF',
        //     },
        // },
        // pagination: {
        //     style: {
        //         border: 'none',
        //     },
        // },
    };

    return (
        <DataTable
            columns={columns}
            data={data}
            // selectableRows
            pagination
            customStyles={customStyles}
            highlightOnHover

        />
    )
}


function PopUpitemgroup(props) {

    const FetchApis = new FetchApi();
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [groupitems, setGroupitems] = useState([]);
    const[textSearch,setTextSearch]=useState()

    useEffect(() => {

        getapilist()

    }, [])

    const getapilist=()=>{
        FetchApis.FethcGet(`getItemGroup`).then((res) => {
            // console.log(res.status)
            if (res.status == 200) {

                setGroupitems(res.data.listall)
                setTextSearch(res.data.listall);
            }
        })
    }

    const hendleModel = (value) => {
        setShow(false)
    }

    const onSearchText=(datavalue)=>{

        const filteredData = textSearch.filter((item) => {
            return Object.values(item).join('').toLowerCase().includes(datavalue.toLowerCase())
          })

          setGroupitems(filteredData)
    }
    const groupItemset=(v)=>{

        props.getgroupitem(v)
        getapilist()


    }

    return (
        <div>
            <FaSearch variant="primary" onClick={handleShow} size={20}>
                Launch demo modal
            </FaSearch>

            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Item group</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Control type="text" placeholder="ค้นหา ป้อนข้อมูล"  size='sm' onChange={(e)=>onSearchText(e.target.value)}/>
                        </Form.Group>
                    </Form>

                    {
                        groupitems.length > 0 && <TableGroupItem data={groupitems} hendleModel={hendleModel}  groupItemset={groupItemset}/>
                    }

                </Modal.Body>
            </Modal>

        </div>
    )
}

export default PopUpitemgroup