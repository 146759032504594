import React from 'react'
import { Button } from 'react-bootstrap'
import FetchApi from '../../../customhooks/functionFetchApi'
import {useDispatch}from'react-redux';
import {deleteflowsub} from '../../../../reduxSlice/flowsystemsubSlice/flowsystemsubSlice';

const Deleteflowsystemsub = ({Id}) => {
  const FetchApis=new FetchApi();
  const dispatch=useDispatch();

  const deleteflowsubByid=()=>{
    // alert(Id)
    FetchApis.FethcDelete(`/flowsystemsub/flowsystemsubDelete/${Id}`).then((value)=>{
      console.log(value);
      dispatch(deleteflowsub(Id))
    })
   

  }
  return (
    <div><Button size='sm'variant="danger" onClick={()=>deleteflowsubByid()}>ลบ</Button></div>
  )
}

export default Deleteflowsystemsub