import React, { useState, useEffect } from 'react'
import { Button } from 'react-bootstrap'
import Tabletincoatingext from './tabletincoating'
import { useSelector, useDispatch } from 'react-redux'
import { additemExt, additemInt, additemSpray, additemCopper, additemProductitem,additemLacq } from '../../../../reduxSlice/productionsystemSlice/productionSlice';
import PopupPrintingsystem from './componentrd/popUpAddPrintingsystem';


function Addproductionsystem({ journalId }) {
  const dispatch = useDispatch();

  const ojectdata = {
    protype: '',
    sideitemnumber: '',
    sidename: '',
    sidewfw: '',
    com1itemnumber: '',
    com1name: '',
    com1wfw: '',
    com2itemnumber: '',
    com2name: '',
    com2wfw: '',
    journalId: journalId
  }

  const [packtestcheckbox, setPacktestcheckbox] = useState("")
  const [packercheckbox, setPackercheckbox] = useState("")

  const [prodatahead, setProdatahead] = useState({ /// header
    sidetinext: '',
    sidetinint: '',
    com1tinext: '',
    com1tinint: '',
    com2tinext: '',
    com2tinint: '',
    activebom: "",
    auditdep: "",
    packtest: [],
    packer: [],
    refproduct: "",
    journalId: journalId
  })

  function handle_packtest(e) {
    const ischecked = e.target.checked;
    const isvalue = e.target.value;

    if (ischecked) {
      setPacktestcheckbox([...packtestcheckbox, isvalue])
    } else {
      const index = packtestcheckbox.indexOf(e.target.value);
      packtestcheckbox.splice(index, 1);
      setPacktestcheckbox(packtestcheckbox);
    }
  }

  function handle_packer(e) {

    const ischecked = e.target.checked;
    const isvalue = e.target.value;

    if (ischecked) {
      setPackercheckbox([...packercheckbox, isvalue])
    } else {
      const index = packercheckbox.indexOf(e.target.value);
      packercheckbox.splice(index, 1);
      setPackercheckbox(packercheckbox);
    }
  }


  function handle_activebom(e) {
    const ischecked = e.target.checked;
    const isvalue = e.target.value;

    if (ischecked) {
      setProdatahead({ ...prodatahead, activebom: isvalue })
    } else {
      setProdatahead({ ...prodatahead, activebom: "" })

    }
  }

  function handle_auditdep(e) {

    const ischecked = e.target.checked;
    const isvalue = e.target.value;

    if (ischecked) {
      setProdatahead({ ...prodatahead, auditdep: isvalue })
    } else {
      setProdatahead({ ...prodatahead, auditdep: "" })

    }
  }

  function Saveheader() {
    // e.preventDefault();
    // จัดการ object head 
    const opjectdata = {
      sidetinext: prodatahead.sidetinext,
      sidetinint: prodatahead.sidetinint,
      com1tinext: prodatahead.com1tinext,
      com1tinint: prodatahead.com1tinint,
      com2tinext: prodatahead.com2tinext,
      com2tinint: prodatahead.com2tinint,
      activebom: prodatahead.activebom,
      auditdep: prodatahead.auditdep,
      packtest: packtestcheckbox,
      packer: packercheckbox,
      refproduct: prodatahead.refproduct,
      journalId: journalId
    }
    dispatch(additemProductitem(opjectdata))

  }

  

  return (
    <div>

      <label style={{ fontSize: 18, margin: 5 }}><b>Production System</b></label>
      <div className='end-flex-row' style={{ width: '100%' }} >
      </div>
      <div>
        <div className='row-page'>

          <div style={{ width: '35%' }}>

            <div className='row-page'>

              <div style={{ width: 100 }}>
                <label><b>Tin-Conting</b></label>
                <div>Side seams</div>
              </div>
              <div>
                <label>Ext.</label>
                <input type='text' name='sidetinExt'
                  value={prodatahead.sidetinext}
                  onChange={(e) => setProdatahead({ ...prodatahead, sidetinext: e.target.value })} />
              </div>
              <div>
                <label>Int.</label>
                <input type='text' name='sidetinInt'
                  value={prodatahead.sidetinint}
                  onChange={(e) => setProdatahead({ ...prodatahead, sidetinint: e.target.value })} />
              </div>
            </div>
            <hr></hr>

          </div>



          <div style={{ width: '35%' }}>

            <div className='row-page'>
              <div style={{ width: 100 }}>
                <label><b>Tin-Conting</b></label>
                <div>Compound</div>
              </div>
              <div>
                <label>Ext.</label>
                <input type='text' name='com1tinExt'
                  value={prodatahead.com1tinext}
                  onChange={(e) => setProdatahead({ ...prodatahead, com1tinext: e.target.value })} />
              </div>
              <div>
                <label>Int.</label>
                <input type='text' name='com1tinInt'
                  value={prodatahead.com1tinint}
                  onChange={(e) => setProdatahead({ ...prodatahead, com1tinint: e.target.value })}
                />
              </div>
            </div>
           <hr></hr>
      
          </div>

          <div style={{ width: '35%' }}>

            <div className='row-page'>
              <div style={{ width: 100 }}>
                <label><b>Tin-Conting</b></label>
                <div>Compound</div>
              </div>
              <div>
                <label>Ext.</label>
                <input type='text' name='com2tinExt'
                  value={prodatahead.com2tinext}
                  onChange={(e) => setProdatahead({ ...prodatahead, com2tinext: e.target.value })} />
              </div>
              <div>
                <label>Int.</label>
                <input type='text' name='sidetinInt'
                  value={prodatahead.com2tinint}
                  onChange={(e) => setProdatahead({ ...prodatahead, com2tinint: e.target.value })} />
              </div>
            </div>
            <hr></hr>
          </div>

        </div>

        <div className='row-page' style={{ marginTop: 20, background: '#eeeded' }}>

          <div style={{ width: 100 }}>
            <label>Ext.</label>
            <div>
             
            <PopupPrintingsystem typePro={'pro_ext'} journalId={journalId} Saveheader={Saveheader}/>
            </div>

          </div>

          <div style={{ width: 100 }}>
            <label>Int.</label>
            <div>
              
              <PopupPrintingsystem typePro={'pro_int'} journalId={journalId} Saveheader={Saveheader}/>
            </div>

          </div>

          <div style={{ width: 100 }}>
            <label>Spray lacq.</label>
            <div>
              
              <PopupPrintingsystem typePro={'pro_spray'} journalId={journalId} Saveheader={Saveheader}/>
            </div>

          </div>

          <div>
            <label>Copper Wire (kg./1,000 pcs )</label>
            <div>
              
              <PopupPrintingsystem typePro={'pro_copper'} journalId={journalId} Saveheader={Saveheader}/>
            </div>

          </div>
          <div style={{marginLeft:20}}>
            <label> Lacq Stemp rivet (kg./1,000 pcs)</label>
            <div>
              
              <PopupPrintingsystem typePro={'pro_lacq'} journalId={journalId} Saveheader={Saveheader}/>
            </div>
          </div>

        </div>

      </div>

      <hr></hr>

      <div className='row-page'>
        <div style={{ marginRight: 20 }}><b>Prodcut Pack test</b></div>
        <div style={{ width: '50%' }}>
          <div className='row-page' style={{ width: '50%' }}>
            <div style={{ width: 100 }}>
              <label>Pack test :</label>
            </div>
            <div style={{ marginRight: 10, width: 100 }}>
              <input type='checkbox' name='packYes' value={'Yes'} onClick={handle_packtest} />{" "}
              <label>Yes</label>
            </div>
            <div className='row-page' style={{ marginRight: 10, width: 100 }}>
              <input type='checkbox' name='packOnNeed' value={'NoNeed'} onClick={handle_packtest} style={{ marginRight: 5 }} />{" "}
              <label>No need</label>
            </div>
          </div>

          <div className='row-page'>
            <div style={{ width: 100 }}>
              <label>Packer :</label>
            </div>
            <div style={{ marginRight: 10, width: 100 }}>
              <input type='checkbox' name='packerSwan' value={'Swan'} onClick={handle_packer} />{" "}
              <label>Swan</label>
            </div>

            <div style={{ marginRight: 10, width: 100 }}>
              <input type='checkbox' name='packerCustomersite' value={'Customer Site'} onClick={handle_packer} />{" "}
              <label>Customer Site</label>
            </div>
          </div>

        </div>

        <div style={{ backgroundColor: '#eeeded', padding: 10 }}>
          <div style={{ width: 300 }}>
            <input type='checkbox' name='auditdep' value={'1'} onClick={handle_auditdep} /> <label> Audit Dep </label>
          </div>
          <div>
            <input type='checkbox' name='activeBome' value={'1'} onClick={handle_activebom} /> <label> Active Bome </label>
          </div>
        </div>

      </div>
      <div>
        <div> <label> <b>Ref . Product </b></label></div>
        <textarea name='refproduct' style={{ width: 400, height: 50 }} onChange={(e) => setProdatahead({ ...prodatahead, refproduct: e.target.value })} />
      </div>

      <Tabletincoatingext/>

    </div>
  )
}

export default Addproductionsystem