import React, { useState, useEffect } from 'react'
import Tablejournallistrecieved from './components/componentjournallistrecieved/tablejournallistrecieved'
import FetchApi from '../customhooks/functionFetchApi'
import Spinner from 'react-bootstrap/Spinner';
import { FaFileSignature } from "react-icons/fa";

const Journallistrecieved = ({recievedcount}) => {
    const FetchApis = new FetchApi();
    const codes = sessionStorage.getItem('EmCode')

    const [loading, setLoading] = useState(false);
    const [journals, setJournals] = useState([])

    // loading 
    useEffect(() => {
        getlistjournal();
    }, [])

    const getlistjournal = () => {
        setLoading(true)

        setTimeout(() => {
            FetchApis.FethcGet(`/journal/getjournalrecievRunflowByEmcode/${codes}`).then((res) => {
                // console.log(res)
                if (res.status = 200) {
                    setJournals(res.data.data);
                    
                    setLoading(false);
                }
               
            })
        }, 1000);
    }

    return (
        <div>
            <div style={{ backgroundColor: '#E5E7E7' }}>

                <label style={{ fontSize: 20, margin: 5, padding: 5 }}><FaFileSignature /> รายการเอกสารใหม่ </label>

            </div>
            {/* {JSON.stringify(journals)} */}
            <div>
            {loading==true?<div className='body-center-page'><Spinner 
                          as="span"
                          animation="grow"
                          variant="success"
                          size={200}
                          role="status"
                          aria-hidden="true"/> LOADIN ....</div>:<div>
                <Tablejournallistrecieved values={journals} /></div>
            }
            </div>
        </div>
    )
}

export default Journallistrecieved