import React,{useState,useEffect} from 'react'
import { Button } from 'react-bootstrap'
import Modal from 'react-bootstrap/Modal';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Detaildedicatenote from './detaildedicatenote';
import {statusflowall} from'../../object-default/journalobject';
import FetchApi from '../../../customhooks/functionFetchApi';
import { FaFileAlt } from "react-icons/fa";
import Showdetialjournal from'../../../form01/components/showdetailjournal';

import { ToastContainer, toast } from 'react-toastify';
import Swal from 'sweetalert2';

const datasub = {
    spectype: "",sheetsize: "",flatscroll: "",straightscroll: "",tin: "",temper: "",bright: "",row: "",filmcode: "",itemno: "",tdscode: ""
  }
  

function Journaldetail({id,dedicatecode}) {

  
    const FetchApis = new FetchApi();
  
    const emCode = sessionStorage.getItem('EmCode');
  
    const [key, setKey] = useState('jouranl');
  
    const [datafetch, setDatafetch] = useState([]);
    const [coatingbody, setCoatingbody] = useState([]);
    const [coatingtop, setCoatingtop] = useState([]);
    const [coatingbottom, setCoatingbottom] = useState([])
    const [forming, setForming] = useState([])
    const [guideline, setGuideline] = useState([])
  
    const [regulation, setRegulation] = useState([])
    const [regulationGroup, setregulationGroup] = useState([]);
    const [requestsample, setRequestsample] = useState([]);
  
    // add substrate new 
    const [substratebody, setSubstrateBody] = useState([]);
    const [substrateTop, setSubstrateTop] = useState([]);
    const [substrateBotton, setSubstrateBotton] = useState([]);
    const [substrateTab, setSubstrateTab] = useState([]);
  
    const [adddatatype, setAdddatatype] = useState({ ...datasub })
    const [componentCode, setComponentCode] = useState({
      inside: "",
      ring: "",
      ear: "",
      tdscode: ""
    })
  
    // data update crate task
    const [newTask, setNewTask] = useState([]);
  
    //data update journal
    const[newjournalUpdate,setNewjournalUpdate]=useState([]);
  
  
    useEffect(() => {
      var codeid = "";
      var intdataconvert = 0;
      FetchApis.FethcGet(`/journal/journalbyId/${id}`).then((datafetch) => {
        if (datafetch.data.status == 200) {
          setDatafetch(datafetch.data.data)
          codeid = datafetch.data.data[0].tdscode;
  
          intdataconvert=datafetch.data.data[0].stateflow-1;
  
          setAdddatatype({ ...adddatatype, tdscode: codeid })
          setComponentCode({ ...componentCode, tdscode: codeid })

          var dataobject = {
            journalid: id,
            emcode: emCode,
            stateflow: intdataconvert,
            approvecode: statusflowall.sendjournal,
            dedicatecode: ""
          }
      
          setNewTask(dataobject)
      
          var updatejournal = {
            statusflow: statusflowall.sendjournal,
            stateflow: intdataconvert
          }
      
          setNewjournalUpdate(updatejournal);
        
        }
      })
  
  
  
    }, [])
  
  
    // บันทึกข้อมูล
    const submitform = (e) => {
      e.preventDefault();


      Swal.fire({
        title: "บันทึกเอกสาร",
        text: "คุณแน่ใจที่บันทึกเอกสาร ใช่หรือไม่ ! ",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ใช่",
        cancelButtonText:'ยกเลิก'
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: "บันทึกเอกสาร!",
            text: "คุณได้ทำบันทึกเอกสารเรียบแล้ว",
            icon: "success"
          });

          recievedjournal();
        }
        
      });
   
     
  
    }
  
    // update  recieved 
    function recievedjournal() {

        var dataint = parseInt(datafetch[0].stateflow) + 1;
  
        var dataobject = {
          journalid: id,
          emcode: emCode,
          stateflow: dataint-1,
          approvecode: statusflowall.approvejournal,
          dedicatecode: "",
          tasknote:""
        }
         setNewTask(dataobject);
    
        // // create task flow
        FetchApis.FethcPost(`/taskflow/createTaskflow`, dataobject).then((res) => {
          if (res.status == 200) {
            updatejournal(dataint);
          }
        })
  
      }

  
    // //update flowstatus journal
    function updatejournal(stateflows) {
      
      var updatejournal = {
        statusflow: statusflowall.sendjournal,
        stateflow: stateflows
      }
  
      setNewjournalUpdate(updatejournal);
      FetchApis.FethcUpdate(`/journal/updatestateflow/${id}`, updatejournal).then((res) => {
        if (res.status == 200) {
        updaterunflowbycode(stateflows)
        }
      })
  
    }

    function updaterunflowbycode(stateflows){
        
            var dataupdate={
                journalid:id,
                stateflow:stateflows-1
            }
            FetchApis.FethcUpdate(`/taskflow/updaterunflow`,dataupdate).then((res)=>{
                        if(res.status==200){
                          setTimeout(() => {
                            window.location.reload();
                          }, 700);
                         

                        }
            })
    }
  
    return (
      <div className='form-body-page'>
     
        <form onSubmit={(e) => submitform(e)} >
          <div className='end-flex-row'>
         
         <Button type='submit' size='sm' variant="success" style={{ marginRight: 10 }}><FaFileAlt/> อนุมัติแทน</Button>

         <Detaildedicatenote journalid={id} dedicatecode={dedicatecode}/>
          </div>
          <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => setKey(k)}
            className="mb-3"
          >
            <Tab eventKey="jouranl" title="Form ( F-MK-009A1-1 ) ตรวจสอบข้อมูลก่อนบันทึก" style={{ backgroundColor: 'aliceblue' }}>
              <Showdetialjournal id={id} />
            </Tab>
  
          </Tabs>
        </form>
      </div>
    )
}

  

const Journalapporvededicate = ({journalids,dedicateids}) => {

    const values = [true, 'sm-down', 'md-down', 'lg-down', 'xl-down', 'xxl-down'];
    const [fullscreen, setFullscreen] = useState(true);
    const [show, setShow] = useState(false);



    function handleShow(value) {
        // setFullscreen(breakpoint);
        setShow(value);
    }

  return (
    <div>
        <Button size='sm' style={{fontSize:12}} variant="success" onClick={() => handleShow(true)} > <FaFileAlt/> อนุมมัติแทน</Button>

        <Modal show={show}
                 fullscreen={fullscreen} 
                // size="lg"
                onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>กำหนด ผู้อนุมัติแทน </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Journaldetail   id={journalids} dedicatecode={dedicateids}/>
                </Modal.Body> 
            </Modal>

    </div>
    
  )
}

export default Journalapporvededicate