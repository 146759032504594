import React from 'react'
import Editjournals from './editjournals';
import EditformsHeader from './editformsHeader';
import Detailjournal from './detailjournal';
import { Button } from 'react-bootstrap';
import FetchApi from '../../customhooks/functionFetchApi';
import Swal from 'sweetalert2';
import { statusflowall} from '../object-usestate/objectdefaults';

const Actionjourparta = ({ id }) => {

    const FetchApis = new FetchApi();
    const Emcode = sessionStorage.getItem('EmCode');

    async function sendjournal() {

        Swal.fire({
            title: "แจ้งเตือน",
            text: "คุณแน่ใจที่ส่งข้อมูลเอกสาร ใช่หรือไม่ ! ",
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "ใช่",
            cancelButtonText:'ยกเลิก'
          }).then((result) => {
      
            if (result.isConfirmed) {
            var stateflowall = {
                statusflow: statusflowall.sendjournal,
                stateflow: "1"
            }

            var taskeflows = {
                journalid: id,
                emcode: Emcode,
                stateflow: "1",
                approvecode: statusflowall.sendjournal,
                dedicatecode: "",
                tasknote:""
            }

             FetchApis.FethcPost(`/taskflow/createTaskflow`, taskeflows).then((res) => { console.log(res) })
            // update state flow  new journal 
             FetchApis.FethcUpdate(`/journal/updatestateflow/${id}`, stateflowall).then((res) => {
                if (res) {
                    window.location.reload();
                }
            })
    }
            
});


    }


    return (
        <div className='row-between-page'>
            <div style={{ marginRight: 10 }}><Button variant="success" size='sm' style={{ fontSize: 11 }} onClick={() => sendjournal()}>ส่งข้อมูล</Button></div>
            <div style={{ marginRight: 10 }}><Detailjournal id={id} /></div>
            <div style={{ marginRight: 10 }}> <EditformsHeader ids={id}/></div>
            <div style={{ marginRight: 10 }}><Editjournals id={id} /></div>
        </div>
    )
}

export default Actionjourparta