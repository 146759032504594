import React, { useState, useEffect, useRef } from 'react'
import FetchApi from '../../customhooks/functionFetchApi';
import PdfpartAsuccess from './viewSuccessfull/pdfpartAsuccess';
import PdfpartBsuccess from './viewSuccessfull/pdfpartBsuccess';
import PdfprintingColorsuccess from './viewSuccessfull/pdfprintingColorsuccess';
import PdfNewcolorsuccess from './viewSuccessfull/pdfNewcolorsuccess';
import PdfNewItemsuccess from './viewSuccessfull/pdfNewItemsuccess';
import PdfPartAll from './viewdrarftcomponentpdf/pdfPartAll';
import { useParams } from "react-router-dom";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Spinner from 'react-bootstrap/Spinner';


const JournalPDFAll = () => {
    const FetchApis = new FetchApi()
    let { id } = useParams();
    const [key, setKey] = useState('jouranl');
    const [loadingdata, setLoadingdata] = useState(true);
    
    useEffect(()=>{
        setTimeout(() => {
            setLoadingdata(false)
          }, 1300);
    },[])


    return (
        <div className='form-body-page'>

{loadingdata ? <div className='body-center-page'><Spinner
        as="span"
        animation="grow"
        variant="success"
        size={200}
        role="status"
        aria-hidden="true" /> LOADIN ....</div> :
            <form >


                <Tabs
                    id="controlled-tab-example"
                    activeKey={key}
                    onSelect={(k) => setKey(k)}
                    className="mb-3"
                >
                    <Tab eventKey="jouranl" title="TECHNICAL DATA SHEET A" style={{ backgroundColor: 'aliceblue' }}>
                        <PdfpartAsuccess idjour={id}/>
                    </Tab>
                    <Tab eventKey="journaldetailpartb" title="TECHNICAL DATA SHEET B" style={{ backgroundColor: 'aliceblue' }}>
                        <PdfpartBsuccess idjour={id}/>
                    </Tab>
                    <Tab eventKey="printingcolor" title="COLOR PRINTING SYSTEM" style={{ backgroundColor: 'aliceblue' }}>
                        <PdfprintingColorsuccess idjour={id}/>
                    </Tab>
                    <Tab eventKey='colorlist' title='เอกสารหมีกผสม แนบท้าย TDS By printing ' >
                     
                        <PdfNewcolorsuccess  journalId={id}/>
                    </Tab>
                    <Tab eventKey='NewItem' title='Create New Item' >
                        
                        <PdfNewItemsuccess  jourId={id} />
                    </Tab>
                    <Tab eventKey='laodall' title='all part' >
                            <PdfPartAll jourId={id} typejour={'success'}/>
                        {/* <PdfNewItemsuccess  jourId={id} /> */}
                    </Tab>

                </Tabs>
            </form>}
        </div>
    )
}

export default JournalPDFAll