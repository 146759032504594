import React from 'react'
import Actionadd from './actionadd';
import Actiondelete from './actiondelete';
// import Actiondetail from './actiondetail';
import Actionedit from './actionedit';
const Action = ({id,namegroup,loadingMenulist}) => {


  return (
    <div className='row-page'>
        <div style={{marginRight:5}}><Actionadd Id={id} namegroup={namegroup}/></div>
        {/* <di style={{marginRight:5}}><Actiondetail Id={id} /></di> */}
        <di style={{marginRight:5}}><Actionedit Id={id} loadingMenulist={loadingMenulist}/></di>
        <div style={{marginRight:5}}><Actiondelete Id={id} loadingMenulist={loadingMenulist}/></div>
    </div>
  )
}

export default Action