export const color2={
    tecbid:"",
    colorshqde_unit1:"",
    Idcolor_unit1:"",
    fmcode_unit1:"",
    thicknes_unit1:"",
    density_unit1:"",
    temp_unit1:"",
    uvlamp_unit1:"",
    speed_unit1:"",
        colorshqde_unit2:"", 
        Idcolor_unit2:"",
        fmcode_unit2:"",
        thicknes_unit2:"",
        density_unit2:"",
        temp_unit2:"",
        uvlamp_unit2:"", 
        speed_unit2:"",
        pt_type:""
}

export const color6={
    tecbid:"",
    colorshqde_unit1:"",
    Idcolor_unit1:"",
    fmcode_unit1:"",
    thicknes_unit1:"",
    density_unit1:"",
    temp_unit1:"",
    uvlamp_unit1:"",
    speed_unit1:"",
        colorshqde_unit2:"", 
        Idcolor_unit2:"",
        fmcode_unit2:"",
        thicknes_unit2:"",
        density_unit2:"",
        temp_unit2:"",
        uvlamp_unit2:"", 
        speed_unit2:"",
        pt_type:""
}

export const color7={
    tecbid:"",
    colorshqde_unit1:"",
    Idcolor_unit1:"",
    fmcode_unit1:"",
    thicknes_unit1:"",
    density_unit1:"",
    temp_unit1:"",
    uvlamp_unit1:"",
    speed_unit1:"",
        colorshqde_unit2:"", 
        Idcolor_unit2:"",
        fmcode_unit2:"",
        thicknes_unit2:"",
        density_unit2:"",
        temp_unit2:"",
        uvlamp_unit2:"", 
        speed_unit2:"",
        pt_type:""
}

export const color8={
    tecbid:"",
    colorshqde_unit1:"",
    Idcolor_unit1:"",
    fmcode_unit1:"",
    thicknes_unit1:"",
    density_unit1:"",
    temp_unit1:"",
    uvlamp_unit1:"",
    speed_unit1:"",
        pt_type:""
}