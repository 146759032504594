import React, { useCallback, useEffect, useState } from 'react';
import dataform from '../json-data/datatypeform.json';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { set, useForm } from "react-hook-form";
import FetchApi from '../../customhooks/functionFetchApi';
import { opjectHeader } from '../object-usestate/objectdefaults';
import Spinner from 'react-bootstrap/Spinner';
import PopupItemList from './popupItemList';
import PopupCustomerList from './popupCustomerList';
import formA1 from '../json-data/datatypeformA1.json';
import formA2 from '../json-data/datatypeformA2.json';
import { host } from '../../customhooks/functionFetchApi';
import ViewImages from './viewImages';
import PopupRefFG from './popupRefFG';
import Swal from 'sweetalert2'
import { ToastContainer, toast } from 'react-toastify';

function FormEdit({ handleShow, ids }) {

  const FetchApis = new FetchApi();
  const [datafetch, setDatafetch] = useState({ ...opjectHeader });
  const [fileLoad, setFileLoad] = useState("")

  const [infortype, setInfortype] = useState([]); // check Information from customer
  const [infortypeError, setInfortypeError] = useState(false);//check  Information length over one item 

  const [printtype, setPrinttype] = useState([]); // check printting system type 
  const [printtypeError, setPrinttypeError] = useState(false); // check printting length over one item

  const [filmtypeall, setfilmtypeall] = useState([]);
  const [filmtypeError, setfilmtypeError] = useState(false);

  // const [typedocument, setTypedocument] = useState(null);  
  const [dataDocument, setDataDocument] = useState(null);


  const [formlist, setFormlist] = useState([]);

  useEffect(() => {

    FetchApis.FethcGet(`/journal/getupdatejournalbyId/${ids.ids}`).then((res) => {
      if (res.status == 200) {
        setDatafetch(res.data.data[0]);

        if (res.data.data[0].typeproduct == 'A1') {
          setDataDocument(formA1)
        } else if (res.data.data[0].typeproduct == 'A2') {
          setDataDocument(formA2)
        }
        if (res.data.data[0].informationtype != "") {
          setInfortype([...infortype, res.data.data[0].informationtype]);
        }

        if (res.data.data[0].printingtype != "") {
          setPrinttype([...printtype, res.data.data[0].printingtype]);
        }

        if (res.data.data[0].filmtype != "") {
          setfilmtypeall([...filmtypeall, res.data.data[0].filmtype]);
        }

      }
    })
    loadingformtype()
  }, [])

  const handleFile = event => {
    var input = document.getElementById('loadfilename');
    const file = input.files[0]
    // const size = file.size // it's in bytes
    if (file != undefined) {
      setFileLoad(input.files[0])
    } else {
      setFileLoad("")
    }


  };

  const onSubmitHandler = (e) => {
    var id = datafetch.journalId;
    e.preventDefault();



    if (infortype.length > 1) {
      setInfortypeError(true)
    }
    else {
      setInfortypeError(false)
    }


    if (printtype.length > 1) {
      setPrinttypeError(true)
    }
    else {
      setPrinttypeError(false)
    }


    if (filmtypeall.length > 1) {
      setfilmtypeError(true)
    } else {
      setfilmtypeError(false)
    }

    if (infortype.length <= 1 && printtype.length <= 1 && filmtypeall.length <= 1) {
      Swal.fire({
        title: "บันทึกแก้ไข",
        text: "คุณแน่ใจบันทึกเอกสาร ใช่หรือไม่ ! ",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ใช่",
        cancelButtonText: 'ยกเลิก'
      }).then((result) => {

        if (result.isConfirmed) {
          Swal.fire({
            title: "บันทึกแก้ไข!",
            text: "บันทึกแก้ไขเรียบแล้ว",
            icon: "success"
          });

          // console.log(datafetch);
          var Replaceitemdetail = datafetch.customername.replace("'", "`");
          var Replaceitemname = datafetch.itemname.replace("'", "`");
          var Replaceproductname = datafetch.productname.replace("'", "`");
          var productnameUpperCase = Replaceproductname.toUpperCase();


          if (fileLoad != "") {

            if (fileLoad.size / 1024 > 500) {
              // alert("file size must not be greater than to 500k")
              Swal.fire({
                title: "แจ้งเตือน!",
                text: "file size must not be greater than to 500k",
                icon: "warning"
              });
            } else {

              FetchApis.FetchUploadfile('/images/uploadfile', fileLoad).then(res => {

                if (res.success == true) {
                  let dataJournal = {
                    cansize: datafetch.cansize,
                    contact: datafetch.contact,
                    createby: datafetch.createby,
                    createdate: datafetch.createdate,
                    createdatetime: datafetch.createdatetime,
                    customername: Replaceitemdetail,
                    duedate: datafetch.duedate,
                    fcode: datafetch.fcode,
                    filmcode: datafetch.filmcode,
                    filmtype: datafetch.filmtype,
                    informationnote: datafetch.informationnote,
                    informationtype: datafetch.informationtype,
                    itemcode: datafetch.itemcode,
                    itemname: Replaceitemname,
                    itemsample: datafetch.itemsample,
                    journalId: datafetch.journalId,
                    journaltype: datafetch.journaltype,
                    noteapproveTds: datafetch.noteapproveTds,
                    printingtype: datafetch.printingtype,
                    printingmachine:datafetch.printingmachine,
                    printingtypeother: datafetch.printingtypeother,
                    productgrouptype: datafetch.productgrouptype,
                    productname: productnameUpperCase,
                    refFgcode: datafetch.refFgcode,
                    revision: datafetch.revision,
                    shape: datafetch.shape,
                    stateflow: datafetch.stateflow,
                    statusflow: datafetch.statusflow,
                    statusprove_Tds: datafetch.statusprove_Tds,
                    substratetype: datafetch.substratetype,
                    tdscode: datafetch.tdscode,
                    tel: datafetch.tel,
                    typeproduct: datafetch.typeproduct,
                    uploadfilename: res.filename
                  }

                  // console.log(dataJournal)

                  FetchApis.FethcUpdate(`/journal/journalupdate/${id}`, dataJournal).then((result) => {
                    if (result) {
                    }
                  }).catch((err) => {
                    console.log(err)
                  });

                  toast.success('บันทึกเรียบร้อยแล้ว', {
                    position: "top-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    // transition: Bounce,
                  });
                  setTimeout(() => {

                    window.location.reload();
                    handleShow(false);
                  }, 1700);

                }

              })
            }


          } else {


            let dataJournal = {
              cansize: datafetch.cansize,
              contact: datafetch.contact,
              createby: datafetch.createby,
              createdate: datafetch.createdate,
              createdatetime: datafetch.createdatetime,
              customername: Replaceitemdetail,
              duedate: datafetch.duedate,
              fcode: datafetch.fcode,
              filmcode: datafetch.filmcode,
              filmtype: datafetch.filmtype,
              informationnote: datafetch.informationnote,
              informationtype: datafetch.informationtype,
              itemcode: datafetch.itemcode,
              itemname: Replaceitemname,
              itemsample: datafetch.itemsample,
              journalId: datafetch.journalId,
              journaltype: datafetch.journaltype,
              noteapproveTds: datafetch.noteapproveTds,
              printingtype: datafetch.printingtype,
              printingmachine:datafetch.printingmachine,
              printingtypeother: datafetch.printingtypeother,
              productgrouptype: datafetch.productgrouptype,
              productname: productnameUpperCase,
              refFgcode: datafetch.refFgcode,
              revision: datafetch.revision,
              shape: datafetch.shape,
              stateflow: datafetch.stateflow,
              statusflow: datafetch.statusflow,
              statusprove_Tds: datafetch.statusprove_Tds,
              substratetype: datafetch.substratetype,
              tdscode: datafetch.tdscode,
              tel: datafetch.tel,
              typeproduct: datafetch.typeproduct,
              uploadfilename: ""
            }

            FetchApis.FethcUpdate(`/journal/journalupdate/${id}`, dataJournal).then((result) => {
              if (result) {
              }
            }).catch((err) => {
              console.log(err)
            });

            toast.success('บันทึกเรียบร้อยแล้ว', {
              position: "top-right",
              autoClose: 1500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              // transition: Bounce,
            });
            setTimeout(() => {

              window.location.reload();
              handleShow(false);
            }, 1700);

          }

        }



      });

    }


  }

  // load ข้อมูล หัวข้อฟอร์ม
  const loadingformtype = () => {

    FetchApis.FethcGet(`/formtype/formlistByCreateflow`).then((value) => {
      setFormlist(value.data.data)
      var defaulttype = datafetch.fcode;

    })

  }


  function handerOnclick() {
    handleShow(false)
  }

  // check information from customer 
  function handleChange(e) {

    const ischecked = e.target.checked;
    const istext = e.target.value;
    const { printingtype,informationtype } = datafetch

    if (ischecked == true) {

      if (e.target.name == "informationtype") {

          console.log(informationtype);

        if(informationtype=='false'){
        //  setInfortype([...infortype, e.target.value]);
          setDatafetch({ ...datafetch, informationtype: [e.target.value] })
        }
        else{
          setInfortype([...infortype, e.target.value]);
        setDatafetch({ ...datafetch, informationtype:[...informationtype, e.target.value] })
        }
        

      }

      if (e.target.name == "printingtype") {
          setPrinttype([...printtype, e.target.value]);

          setDatafetch({
            ...datafetch,
            printingtype: [...printingtype, e.target.value]
          })

      }

      if (e.target.name == "namefilmtype") {

        setfilmtypeall([...filmtypeall, e.target.value]);
        setDatafetch({ ...datafetch, filmtype: e.target.value });
      }


    }
    else if (ischecked == false) {

      if (e.target.name == "informationtype") {

        const index = infortype.indexOf(e.target.value);
        infortype.splice(index, 1);
        if (infortype[0] == undefined) {
          setDatafetch({ ...datafetch, informationtype: [] })
        }
        else {
          setDatafetch({ ...datafetch, informationtype: infortype[0] })
        }

      }

      if (e.target.name == "printingtype") {
        const index = printtype.indexOf(e.target.value);
        printtype.splice(index, 1);

        if (printtype[0] == undefined) {
          setDatafetch({ ...datafetch, printingtype: [] })
        } else {
          setDatafetch({ ...datafetch, printingtype: printtype[0] })
        }


      }

      if (e.target.name == "namefilmtype") {

        const index = filmtypeall.indexOf(e.target.value);
        filmtypeall.splice(index, 1);

        if (filmtypeall[0] == undefined) {
          setDatafetch({ ...datafetch, filmtype: [] });
        }
        else {
          setDatafetch({ ...datafetch, filmtype: filmtypeall[0] });
        }

      }

    }
  };

  function selectItemId(itemid, itemnames) {

    setDatafetch({
      ...datafetch, itemcode: itemid,
      itemname: itemnames
    })

  }


  function selectCustomerlist(customernames) {
    setDatafetch({
      ...datafetch,
      customername: customernames
    })
  }

  function selectRefFf(refFg) {

    setDatafetch({
      ...datafetch,
      refFgcode: refFg
    })
  }

  return (
    <div className='form-body'>
      {dataDocument == null ? <div className='body-center-page'><Spinner
        as="span"
        animation="grow"
        variant="success"
        size={200}
        role="status"
        aria-hidden="true" /> LOADIN ....</div> :
        <form onSubmit={onSubmitHandler}>
          <div className='row-between-form'>
            <div style={{ width: '70%', padding: 5 }} className='row-between-form'>
              <div style={{ backgroundColor: '#F2EFE7', alignItems: 'center', textAlign: 'center', justifyContent: 'center', padding: 20 }}>
                <b>Marketing " A "</b>
              </div>

              <div>
                {datafetch.typeproduct == 'A1' ?
                  <label style={{ fontSize: 15 }}>เอกสาร Food can Product</label> :
                  <label style={{ fontSize: 15 }}>เอกสาร Dry foods ,Aerosol can and General can product  </label>}
              </div>

              <div>
                <select name="journaltype" id="journaltype"
                  onChange={(e) => setDatafetch({ ...datafetch, journaltype: e.target.value })} style={{ width: 200, height: 20 }} disabled>
                  <option value={""}>เลือกประเภท product ...</option>
                  {formlist.map((item, i) => (
                    datafetch.fcode == item.formcode ? <option value={item.formcode} selected >{item.fname}</option> :
                      <option value={item.formcode}>{item.fname}</option>
                  ))}
                </select>
              </div>

            </div>

            <div style={{ width: '25%', padding: 5, flexDirection: 'column', alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
              <div style={{ marginBottom: 5 }}><b>Due Date:</b></div>
              <div>
                <input type='date' id='duedate' value={datafetch.duedate} onChange={(e) => setDatafetch({ ...datafetch, duedate: e.target.value })} required />
              </div>

            </div>
            <div style={{ width: '25%', padding: 5 }}>

              <div className='row-between-form'>
                <label>MK NO :</label> <input type='text' id='tdscode' value={datafetch.tdscode} disabled />
              </div>
              <div className='row-between-form'>
                <label>Date :</label><input type='date' id='createdate' value={datafetch.createdate} onChange={(e) => setDatafetch({ ...datafetch, createdate: e.target.value })} disabled required />
              </div>
            </div>

          </div>
          <hr></hr>
          <div className='row-between-form' style={{ padding: 10 }}>
            <div style={{ width: '50%' }}>
              <div>
                <label>
                  Customer name </label>
                <div className='row-page'> <input type='text' id='customername' value={datafetch.customername}
                  onChange={(e) => setDatafetch({ ...datafetch, customername: e.target.value })}
                  style={{ width: '50%', margin: 1 }} required />
                  <PopupCustomerList selectCustomerlist={selectCustomerlist} />
                </div>
              </div>
              <div>
                <label>Product name </label>
                <div>
                  <input type='text' id='productname' value={datafetch.productname} onChange={(e) => setDatafetch({ ...datafetch, productname: e.target.value })} style={{ width: '50%', margin: 1 }} required />
                </div>
              </div>
              <div>
                <label>Item name </label>
                <div className='row-page'>
                  <input type='text' id='itemname' value={datafetch.itemname}
                    onChange={(e) => setDatafetch({ ...datafetch, itemname: e.target.value })}
                    style={{ width: '50%', margin: 1 }}  />
                  <PopupItemList selectItemId={selectItemId} />
                </div>
              </div>
            </div>

            <div style={{ width: '50%' }}>

              <div className='row-between-form' style={{ width: '100%' }}>
                <div style={{ width: '100%' }}>
                  <label>Contact </label>
                  <div >
                    <input type='text' id='contact' value={datafetch.contact} onChange={(e) => setDatafetch({ ...datafetch, contact: e.target.value })} style={{ width: '90%', margin: 1 }} />
                  </div>
                </div>

                <div style={{ width: '100%' }}>
                  <label> Tel </label>
                  <div>
                    <input type='text' id='tel' value={datafetch.tel} onChange={(e) => setDatafetch({ ...datafetch, tel: e.target.value })} style={{ width: '90%', margin: 1 }} />
                  </div>
                </div>
              </div>

              <div className='row-between-form'>

                <div style={{ width: '90%' }}>
                  <label> Can Size / Dia  </label>
                  <div>
                    <input type='text' id='cansize' value={datafetch.cansize} onChange={(e) => setDatafetch({ ...datafetch, cansize: e.target.value })} style={{ width: '90%', margin: 1 }} />
                  </div>
                </div>

                <div style={{ width: '90%' }}>
                  <label> Shape </label>
                  <div>
                    <input type='text' id='shape' value={datafetch.shape} name='shape' onChange={(e) => setDatafetch({ ...datafetch, shape: e.target.value })} style={{ width: '90%', margin: 1 }} />
                  </div>
                </div>

              </div>

              <div>
                <label>Item no </label>
                <div>
                  <input type='text' id='itemcode' value={datafetch.itemcode} onChange={(e) => setDatafetch({ ...datafetch, itemcode: e.target.value })} style={{ width: '50%', margin: 1 }} />
                </div>
              </div>
            </div>
          </div>

          <hr></hr>

          <div className='row-between-form'>
            <div>
              <b>substrate</b>
              <div>

                {dataDocument.substrate.data.map((item, i) => (
                  <div>
                    <input type='radio' id='substratetype'
                      onChange={(e) => setDatafetch({ ...datafetch, substratetype: e.target.value })} value={item}
                      checked={datafetch.substratetype == item ? true : false} /> {item}
                  </div>
                ))}
              </div>
            </div>

            <div>
              <b>Product group</b>
              <div>
                {dataDocument.productgroup.data.map((item, i) => (
                  <div>
                    <input type='radio' id='productgrouptype'
                      onChange={(e) => setDatafetch({ ...datafetch, productgrouptype: item })} value={item}
                      checked={datafetch.productgrouptype == item ? true : false} /> {item}
                  </div>
                ))}
              </div>
            </div>
            <div>
              <b>Information from customer</b>
              <div>{dataDocument.information.data.map((item, i) => (
                <div>
                  {datafetch.informationtype == item ? <div>
                    <input type='checkbox' id='informationtype' name='informationtype'
                      onClick={(e) => handleChange(e)} defaultChecked={true} /> {item}
                  </div> : <div>
                    <input type='checkbox' id='informationtype' name='informationtype'
                      onClick={(e) => handleChange(e)} value={item} /> {item}
                  </div>}

                </div>
              ))}
              </div>
              <div>
                <input type='text' id='informationnote' value={datafetch.informationnote}
                  onChange={(e) => setDatafetch({ ...datafetch, datafetch: e.target.value })} style={{ width: 350 }} />
              </div>
              <div>{infortypeError == true ? <label style={{ color: 'red' }}> 'กรุณาเลือก 1 รายการเท่านั้น'</label> : ""}</div>
            </div>

            <div>
              <b>Printing system</b>
              <div hidden>
                {dataDocument.printing.data.map((item, i) => (
                  <div>
                    {
                      datafetch.printingtype == item ?
                        <div>
                          <input type='checkbox' id='printingtype' name='printingtype'
                            onClick={(e) => handleChange(e)} defaultChecked={true} value={item} /> {item}
                        </div> :
                        <div>
                          <input type='checkbox' name='printingtype' onClick={(e) => handleChange(e)} value={item} /> {item}
                        </div>
                    }
                  </div>
                ))}

                <div>
                  <input type='text' name='printingtypeother' placeholder='Input other'
                    value={datafetch.printingtypeother}
                    onChange={(e) => setDatafetch({ ...datafetch, printingtypeother: e.target.value })} />
                </div>

                {printtypeError == true ? <label style={{ color: 'red' }}>'กรุณาเลือก 1 รายการเท่านั้น printingtype'</label> : ""}
                {printtypeError}
              </div>
              <div>
                <b>last film code :</b><input type='text' id='filmcode'
                  value={datafetch.filmcode} onChange={(e) => setDatafetch({ ...datafetch, filmcode: e.target.value })} />
              </div>

              <div className='row-page'>
                <div>
                  <label>FG Last Ref. filmcode :</label>
                </div>
                <div className='row-page'>
                  <input type='text' name='refFgcode' value={datafetch.refFgcode}
                    onChange={(e) => setDatafetch({ ...datafetch, refFgcode: e.target.value })}
                    style={{ marginRight: 5 }}

                  /><PopupRefFG selectRefFf={selectRefFf} />
                </div>
              </div>

              <div>

                {datafetch.filmtype == "Scrap" ? <div>
                  <input type='checkbox' id="namefilmtype" name='namefilmtype' value={"Scrap"}
                    onClick={(e) => handleChange(e)}
                    defaultChecked={true}
                  /> Scrap </div> :
                  <div>
                    <input type='checkbox' id="filmtype" name='namefilmtype' value={"Scrap"}
                      onClick={(e) => handleChange(e)}

                    /> Scrap </div>}

                {
                  datafetch.filmtype == "Maintain" ? <div>
                    <input type='checkbox' id="namefilmtype" name='namefilmtype' value={"Maintain"}
                      onClick={(e) => handleChange(e)}

                      defaultChecked={true}

                    /> Maintain
                  </div> :
                    <div>
                      <input type='checkbox' id="namefilmtype" name='namefilmtype' value={"Maintain"}
                        onClick={(e) => handleChange(e)}

                      /> Maintain
                    </div>
                }
                {filmtypeError == true ? <label style={{ color: 'red' }}>'กรุณาเลือก 1 รายการเท่านั้น '</label> : ""}
              </div>

              <div style={{ marginTop: 10 }}>
                <div>แนบเอกสารอ้างอิง</div>
                <input type='file' name='loadfilename' accept="image/png, image/jpg" onChange={handleFile} id='loadfilename' />
              </div>
            </div>
          </div>
          <hr></hr>

          <div style={{ width: '100%', justifyContent: 'flex-end', flexDirection: 'row', alignItems: 'flex-end', display: 'flex' }}>
            <div style={{ marginRight: 10 }}>
              <Button type='submit' size='sm' style={{ backgroundColor: '#0DB48B', borderColor: '#0DB48B', marginRight: 10 }} >บันทึก แก้ไข</Button>
              <Button type='reset' size='sm' style={{ backgroundColor: '#F7B528', borderColor: '#F7B528', marginRight: 10 }} onClick={() => handerOnclick(false)}>ยกเลิก</Button>
            </div>

          </div>
        </form>
      }
      <div>
        <div className='row-page'>
          <label>ตัวอย่าง Product </label> <ViewImages urlImages={datafetch.uploadfilename} />
        </div>
        <div style={{ margin: 10, padding: 5 }}>
          {datafetch.uploadfilename != "" ? <img src={host + '/images/files/' + datafetch.uploadfilename} style={{ width: 100, hidden: 100 }} /> : "ไม่มีข้อมูล รูป ประกอบ"}
        </div>

        <div>

        </div>
      </div>
    </div>
  )
}

const EditformsHeader = (ids) => {

  const values = [true, 'sm-down', 'md-down', 'lg-down', 'xl-down', 'xxl-down'];
  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);


  function handleShow(value) {
    setShow(value);
  }

  return (
    <div>
      <Button variant="warning" size="sm" style={{ fontSize: 10 }} onClick={() => handleShow(true)} >แก้ไขหัวข้อ</Button>

      <Modal show={show} fullscreen={fullscreen} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Journal</Modal.Title>
        </Modal.Header>
        <Modal.Body><FormEdit handleShow={handleShow} ids={ids} /></Modal.Body>
      </Modal>

    </div>
  );

}

export default EditformsHeader