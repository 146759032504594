import React, { useState } from "react";
import axios from "axios";
// const host = `http://27.254.142.144:3002/api/tds/erpstore/`;
const host = `http://swannet.net/tds/liveerp/api/tds/erpstore/`;
//const host = `http://localhost:3002/api/tds/erpstore/`;

//const hostApi = `http://localhost:3003/api/tds/`;
const hostApi = `http://swannet.net/tds/liveapi/api/tds/`;
  
export default class FetchApi {

    FethcPost(url, values) {

        return axios.post(host + url, values).then((response) => {
            if (response.data) {
                return response.data;
            }
        }).catch((err) => {
            console.log(err)
        })
    }

    FethcPostLineApi(url, values) {
        return axios.post(hostApi + url, values).then((response) => {
            if (response.data) {
                return response.data;
            }
        }).catch((err) => {
            console.log(err)
        })
    }

    FethcGet(url) {

        return axios.get(host + url)
            .then((response) => {
                return response
            })
            .catch((err) => {

            })
            .finally(() => {

            });
    }


    FethcDelete(url) {

        return axios.delete(host + url)
            .then((response) => {
                return response
            })
            .catch((err) => {

            })
            .finally(() => {

            });
    }

    FethcUpdate(url, values) {

        return axios.put(host + url, values).then((response) => {
            if (response.data) {
                return response.data;
            }
        }).catch((err) => {
            console.log(err)
        })
    }


}