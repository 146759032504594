import React, { useEffect, useState } from 'react'
import FetchApi from '../../customhooks/functionFetchApi';
import Journaltableprepass from './prepasscomponent/journaltableprepass';
import Spinner from 'react-bootstrap/Spinner';
import { FaFileSignature } from "react-icons/fa";

const Journallistprepass = () => {
  const FetchApis = new FetchApi();
  const codes = sessionStorage.getItem('EmCode');

  const [jouranllist, setJournallist] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadingdata();
  }, [])

  const loadingdata = () => {
    setLoading(true)

    setTimeout(() => {
      FetchApis.FethcGet(`/journal/getjournalSendRunflowByEmcode/${codes}`).then((resultdata) => {
        if (resultdata) {
          setJournallist(resultdata.data)
        }
        setLoading(false);
      })
    }, 1000);

  }

  return (
    <div>
      <div style={{ backgroundColor: '#E5E7E7' }}>
        <label style={{ fontSize: 20, margin: 5, padding: 5 }}>
          <FaFileSignature /> รายการ รอตรวจสอบ เอกสาร ( part B )
        </label>
      </div>
      {loading ? <div className='body-center-page'><Spinner
        as="span"
        animation="grow"
        variant="success"
        size={200}
        role="status"
        aria-hidden="true" /> LOADIN ....</div> :
        <div><Journaltableprepass datatable={jouranllist.data} /></div>}
    </div>
  )
}

export default Journallistprepass