import React, { useState, useEffect } from 'react'
import { Button } from 'react-bootstrap'
import { FaList } from "react-icons/fa";
import Modal from 'react-bootstrap/Modal';
import FetchApi from '../../../customhooks/functionFetchApi';


function ListStateByJournal({ id }) {
  const FetchApis = new FetchApi();
  const [journaldata, setJournaldata] = useState([]);
  const [tasklist, setTasklist] = useState([]);

  useEffect(() => {

    FetchApis.FethcGet(`/journal/getStatejournalnow/${id}`).then((res) => {
      if (res.status == 200) {
        setJournaldata(res.data.data)
      }
    })

    loadingtask();
  }, [])

  const loadingtask = () => {

    FetchApis.FethcGet(`/taskflow/getTaskflowByid/${id}`).then((res) => {
      // console.log(res)
      if (res.status == 200) {
        setTasklist(res.data.data)
      }
    })
  }

  return (<div>
    <div>
      <table style={{ width: '100%', fontSize: 12 }}>
        <tr>
          <th style={{ width: "10%" }}>#</th>
          <th style={{ width: "20%" }}>ผู้อนุมัติ</th>
          <th style={{ width: "10%" }}>แผนก</th>
          <th style={{ width: "10%" }}>ลำดับ</th>
          <th style={{ width: "10%" }}>ตำแหน่ง</th>
          <th style={{ width: "10%" }}>สถานะ</th>
          <th style={{ width: '10%' }}>เวลา</th>
        </tr>

        {journaldata.map((item, i) => (<tr>
          <td>{i + 1}</td>
          <td>{item.approveby}</td>
          <td>{item.departments}</td>
          <td>ขั้นตอนที่ ( {item.stateflow} )</td>
          <td>{item.positions}</td>
          <td>{item.active == 1 ? <div><label style={{ color: 'green' }}>อนุมัติแล้ว</label></div> : <div><label style={{ color: 'red' }}>รออนุมัติ</label></div>}</td>
          <td>{item.timecommit}</td>
        </tr>))}
      </table>
    </div>
    <hr />
    <div style={{ marginTop: 10,width:'100%' }}>
      <div>ขั้นตอนอนุมัติ</div>
      <table style={{width:'100%'}}>
        <tr style={{backgroundColor:'#f9c688'}}>
        <td style={{width:'10%'}}>ชื่อ</td>
        <td style={{width:'20%'}}>ตำแหน่ง</td>
        <td style={{width:'20%'}}>แผนก</td>
        <th style={{ width: "10%" }}>ลำดับ</th>
        <td style={{width:'20%'}}>สถานะ</td>
        <td style={{width:'20%'}}>เวลาอนุมัติ</td>
        </tr>
      {tasklist.map((item,i)=>(<tr style={{fontSize:12,width:'100%'}}>
        <td>{i+1}.{item.username}</td>
        <td>{item.position}</td>
        <td>{item.departments}</td>
        <td>ขั้นตอนที่ ({item.stateflow})</td>
        <td><label style={{color:item.approvecode=="1005"&&"green"||item.approvecode=="0000"&&"red"||item.approvecode=="2001"&&"red"}}>{item.detailapplove} </label></td>
        <td><label  style={{color:item.approvecode=="1005"&&"green"||item.approvecode=="0000"&&"red"||item.approvecode=="2001"&&"red"}}>{item.datetime}</label></td>
      </tr>))}
      </table>
      <div>
      </div>
    </div>
    <hr></hr>
  </div>)
}


const Actionapprovestatus = ({ id }) => {


  const [journallist, setJournallist] = useState([])

  const values = [true, 'sm-down', 'md-down', 'lg-down', 'xl-down', 'xxl-down'];
  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);

  function handleShow() {
    setShow(true);
  }


  return (
    <div>
      <Button size='sm' variant="success" style={{ fontSize: 12 }}
        onClick={() => handleShow(true)}><FaList /> สถานะเอกสาร</Button>

      <Modal
        show={show}
        onHide={() => setShow(false)}
        // size="lg"
        fullscreen={fullscreen}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            สถานะเอกสาร
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ListStateByJournal id={id} />
        </Modal.Body>
      </Modal>

    </div>
  )
}

export default Actionapprovestatus