import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import FetchApi from '../../../customhooks/functionFetchApi';
import Table from 'react-bootstrap/Table';

function ViewDetailFlow({ Id, handleShow }) {
  const FetchApis = new FetchApi();

  const [flowlist, setFlowlist] = useState([]);
  const [flowsublist, setFlowsublist] = useState([])


  useEffect(() => {
    FetchApis.FethcGet(`/flowsystem/flowsystemById/${Id}`).then((value) => {
      if (value) {
        setFlowlist(value.data.data[0])
        getflowsublist(Id)
      }
    })
  }, [])

  function getflowsublist(id) {

    FetchApis.FethcGet(`/flowsystemsub/flowsystemsubByflowcode/${Id}`).then((value) => {
      if (value) {
        setFlowsublist(value.data.data)
      }
    })
  }

  return (
    <div>
      <div>
        <center>
          <div className='row-between-page' style={{ backgroundColor: '#F5D357', width: '80%', padding: 10, borderRadius: 5 }}>
            <div>
              <b>ชื่อ flow</b>
              <div>{flowlist.nameflow}</div>
            </div>
            <div>
              <b>รหัส flow</b>
              <div>{flowlist.flowcode}</div>
            </div>
            <div>
              <b>กำหนดฟอร์ม</b>
              <div>{flowlist.fname}</div>
            </div>
          </div>
        </center>

      </div>

      <div>
        <div>
          <Table striped bordered hover size="sm">
            <thead>
              <tr>
                <th>#</th>
                <th>Approve By</th>
                <th>Employee Code</th>
                <th>Approve Code</th>
                <th>State Flow</th>
              </tr>
            </thead>
            <tbody>
              
                {flowsublist.map((item,i)=>(
                  <tr>
                  <td>{i+1}</td>
                  <td>{item.approveby}</td>
                  <td>{item.emcode}</td>
                  <td>{item.approvecode=='0'?'ผู้ตรวจสอบ':'ผู้อนุมัติ'}</td>
                  <td>{item.stateflow}</td>
                  </tr>
                ))}
          
              
            </tbody>
          </Table>
        </div>
      </div>

    </div>
  )
}


const Veiwflowsystem = ({ Id }) => {

  const values = [true, 'sm-down', 'md-down', 'lg-down', 'xl-down', 'xxl-down'];
  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);

  function handleShow(value) {
    // setFullscreen(breakpoint);
    setShow(value);
  }

  return (
    <div>
      <div><Button size='sm' variant="primary" onClick={() => handleShow(true)}>รายละเอียด</Button></div>

      <Modal show={show}
        // fullscreen={fullscreen}
        size="lg"
        // dialogClassName="modal-90w"
        onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>แสดงรายละเอียด flow</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <ViewDetailFlow handleShow={handleShow} Id={Id} />

        </Modal.Body>
      </Modal>

    </div>
  )
}

export default Veiwflowsystem