import React,{useState,useEffect} from 'react'
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import AllDetailpartb from '../allDetailpartb';
import Showdetailjournal from'../../../form01/components/showdetailjournal';
import Jorunaldetailpartb from '../journalDetailAll/jorunaldetailpartb';


function Detailrevision({jourId,codeId}) {
    const [key, setKey] = useState('jouranl');
  return (
    <div className='form-body-page'>
    <form >
      <Tabs
        id="controlled-tab-example"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className="mb-3"
      >
        <Tab eventKey="jouranl" title="TECHNICAL DATA SHEET A" style={{ backgroundColor: 'aliceblue' }}>
          
          <Showdetailjournal id={jourId} />
        </Tab>
        <Tab eventKey="journaldetailpartb" title="TECHNICAL DATA SHEET B" style={{ backgroundColor: 'aliceblue' }}>
          {/* <Jorunaldetailpartb codeId={codeId} jourId={jourId} /> */}
          <AllDetailpartb journalId={jourId} />
        </Tab>
        {/* <Tab eventKey="printingcolor" title="COLOR PRINTING SYSTEM" style={{ backgroundColor: 'aliceblue' }}>
          
          {checkdata==true?<div><Detailcolorprinting tecbid={coanting1st.tecbid} typetecb={'1st'}/>
          <Detailcolorprinting tecbid={coanting2nd.tecbid} typetecb={'2nd'}/>
          <Detailcolorprinting tecbid={coanting3rd.tecbid} typetecb={'3rd'}/>
          <Detailcolorprinting tecbid={coanting4th.tecbid} typetecb={'4th'}/>
          <Detailcolorprinting tecbid={coanting5th.tecbid} typetecb={'5th'}/>
          <Detailcolorprinting tecbid={coanting6th.tecbid} typetecb={'6th'}/>
          <Detailcolorprinting tecbid={coanting7th.tecbid} typetecb={'7th'}/></div>
          :<div>loading ...</div>}
        </Tab> */}

      </Tabs>
    </form>
  </div>
  )
}

export default Detailrevision