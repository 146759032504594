import React, { useState, useEffect } from 'react'
import { Button } from 'react-bootstrap'
import Modal from 'react-bootstrap/Modal';
import FetchApi from '../../../customhooks/functionFetchApi';
import Tableflowsystemsub from './tableflowsystemsub';
import {addflowsub} from '../../../../reduxSlice/flowsystemsubSlice/flowsystemsubSlice';
import {useSelector,useDispatch}from'react-redux';


function Addflowsub({ Id}) {

  const FetchApis = new FetchApi();
  const  flowsublistall=useSelector((state)=>state.flowsysub.flowsublist);
  const dispatch=useDispatch()

  const [userlist, setUserlist] = useState([]);
  const[flowsublist,setFlowsublist]=useState([]);

  const [flowsub, setFlowsub] = useState({ flowcode: Id, approveby: "", emcode: "", approvecode: "0", stateflow: "" })


  useEffect(() => {
    listflowsystemsub()
    FetchApis.FethcGet(`/account/accountall`).then((result) => {
      // console.log(result.data.data);
      if (result.data.status == 200) {
        setUserlist(result.data.data);
      }
    })

  }, [])

  function changhendle(e) {
    const { name, value } = e.target
    setFlowsub({ ...flowsub, [name]: value })
  }


  function onSubmitflow() {


    if(flowsub.emcode==""||flowsub.stateflow==""||flowsub.approvecode==""){
      alert('กรุณาตรวจสอบข้อมูลก่อนบันทึก')

  }
  else{
   

    const filtered = userlist.filter(data => data.employeeId==flowsub.emcode);
    var objectflowsub={
      flowcode: Id, approveby: filtered[0].username, emcode:flowsub.emcode, approvecode:flowsub.approvecode, stateflow: flowsub.stateflow
    }

    if(objectflowsub){

      FetchApis.FethcPost(`/flowsystemsub/createflowsystemsub`,objectflowsub,).then((results)=>{
        if(results){
          listflowsystemsub();
        }
      }) 

    }  
  }

    
  }


 async  function listflowsystemsub(){
 await   FetchApis.FethcGet(`/flowsystemsub/flowsystemsubByflowcode/${Id}`).then((result)=>{
      // setFlowsublist(result.data.data)
      dispatch(addflowsub(result.data.data));
    })
  }

  return (
  <div>
    <div className='row-page' style={{ margin: 10, padding: 5 }}>
      <div>ค้นหาตามแผนก : </div>
      <select name="searhdepart" id="searhdepart">
        <option value={""} selected>เลือกข้อมูล...</option>
        <option>2</option>
        <option >3</option>
        <option>4</option>
        <option>5</option>
      </select>
      <label style={{ color: 'red' }}>ยังไม่ได้ทำ</label>
    </div>

    <form  className='row-page' style={{ backgroundColor: '#F2EFE7', padding: 10 }}>

      <div className='border-green-page'>

        <div className='row-page'>

          <div className='row-page' style={{ marginLeft: 20 }}>
            <div>เลือกผู้อนุมัติ : </div>
            <select name="emcode" id="emcode" onChange={(e) => changhendle(e)}>
              <option value={""} selected>เลือกข้อมูล...</option>
              {userlist.map((itme, i) => (
                <option value={itme.employeeId}>{itme.name}</option>
              )
              )
              }
            </select>
          </div>

          <div className='row-page' style={{ marginLeft: 20 }}>
            <div>
              <label>ลำดับอนุมัติ : </label>
            </div>

            <div>
              <input type='number' name='stateflow' placeholder='state flow' onChange={(e) => changhendle(e)} />
            </div>
          </div>
        </div>
      </div>

      <div className='row-page'>

        <div style={{ margin: 10 }}> <input type='radio' name='approvecode' value={'0'} onChange={(e) => changhendle(e)} checked/> : ตรวจสอบ </div>
        <div style={{ margin: 10 }}><input type='radio' name='approvecode' value={'1'} onChange={(e) => changhendle(e)} /> : อนุมัติ </div>

      </div>

      <div style={{ margin: 10 }}>
        <Button size='sm' onClick={()=>onSubmitflow()} variant="success">เพิ่ม</Button>{" "}
        <Button size='sm' type='reset' variant="warning">ยกเลิก</Button>
      </div>
    </form>

    <div>ข้อมูล รายละเอียด flow</div>
    <div>
      <Tableflowsystemsub result={flowsublistall}/>
    </div>
  </div>
  )
}


const Addflowsystemsub = ({ Id }) => {

  const values = [true, 'sm-down', 'md-down', 'lg-down', 'xl-down', 'xxl-down'];
  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);

  function handleShow(value) {
    // setFullscreen(breakpoint);
    setShow(value);
  }

  return (
    <div>

      <div style={{ marginRight: 3 }}><Button size='sm' variant="success" onClick={() => handleShow(true)}>เพิ่มสิทธิ์</Button> </div>

      <Modal show={show}
        fullscreen={fullscreen}
        // size="lg"
        onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>เพิ่มรายละเอียด flow</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <Addflowsub handleShow={handleShow} Id={Id}/>

        </Modal.Body>
      </Modal>
    </div>
  )
}

export default Addflowsystemsub