import React, { useState, useEffect } from 'react'
import { Button } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import Modal from 'react-bootstrap/Modal';
import FetchApi from '../../../customhooks/functionFetchApi';

function EditflowsystemView({ handleShow, Id }) {

  const FetchApis = new FetchApi();

  const [formlist, setFormlist] = useState([])
  const [flowdata, setFlowdata] = useState([])
  // const [dataflow, setDataflow] = useState([])

  useEffect(() => {

    FetchApis.FethcGet('/formtype/formtypeAll').then((result) => {
      // console.log(result.data.data)
      if (result.data.status == 200) {
        setFormlist(result.data.data)
      }
      else {
        setFormlist([])
      }

    });

    listflowsystme();

  }, [])

  const listflowsystme =  async () => {

  await  FetchApis.FethcGet(`/flowsystem/flowsystemById/${Id}`).then((results) => {

      if (results.data.status == 200) {
        setFlowdata(results.data.data[0])
      }

    })

  }


  const onchaenghandle = (e) => {
    const { name, value } = e.target
    setFlowdata({ ...flowdata, [name]: value })
    console.log(value)

  }

  const handleSubmit = (event) => {
    // event.preventDefault()
    if (flowdata.nameflow == "" || flowdata.flowcode == "" || flowdata.formcode == "") {
      alert('กรุณา ป้อนข้อมูลให้ครบถ้วน');
    } else {
      // console.log(flowdata)
      FetchApis.FethcUpdate(`/flowsystem/flowsystemUpdateById/${Id}`, flowdata).then((results) => {
        if (results.status) {
          alert('คุณได้ทำการบันทึกเรียบร้อยแล้ว');
        }
      })
    }
  }


  return (
    <div style={{ backgroundColor: '#F2EFE7', padding: 10 }}>
      <div>เพิ่ม flow เอกสารตามประเทภเอกสาร </div>
      <center>
        <div className='border-green-page' style={{ width: '80%' }}>
          
         {flowdata!=""&&<form onSubmit={(e) => handleSubmit(e)}>
            <div>

              <div className='row-between-page '>
                <div>
                  ชื่อ flow
                </div>
                <div>
                  <input type='text' name='nameflow' value={flowdata.nameflow}  placeholder='input flow name'
                    onChange={(e) => onchaenghandle(e)} style={{ width: 300 }} />
                </div>
              </div>
              <div className='row-between-page '>
                <div>รหัส flow</div>
                <div>
                  <input type='text' name='flowcode' value={flowdata.flowcode} placeholder='input code flow'
                    onChange={(e) => onchaenghandle(e)} style={{ width: 200 }} />
                </div>
              </div>

              <div className='row-between-page '>
                <div>ประเภทเอกสาร </div>
                <div>
                  <select name="formcode" id="formcode" onChange={(e) => onchaenghandle(e)}>
                    {/* <option selected>เลือกข้อมูล...</option> */}
                    {
                      formlist.map((item, i) => (
                        flowdata.formcode ==item.formcode?<option value={flowdata.formcode} selected>{item.fname}</option>:
                        <option  value={item.formcode}>{item.fname}</option>
                      ))
                    }
                  </select>
                </div>
              </div>
              <div>
                <Button type='submit' size='sm' variant="success">แก้ไขข้อมูล</Button> {" "}
                <Button type='reset' size='sm' variant="warning">ยกเลิก</Button>
              </div>

            </div>
          </form>
          }
        </div>
      </center>

    </div>
  )
}

const Editflowsystem = ({ Id }) => {


  const values = [true, 'sm-down', 'md-down', 'lg-down', 'xl-down', 'xxl-down'];
  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);


  function handleShow(value) {
    // setFullscreen(breakpoint);
    setShow(value);
  }

  return (
    <div>
      <div><Button size='sm' variant="warning" onClick={() => handleShow(true)} >แก้ไข</Button></div>

      <Modal show={show}
        // fullscreen={fullscreen}
        size="lg"
        onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Journal</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <EditflowsystemView handleShow={handleShow} Id={Id} />

        </Modal.Body>
      </Modal>
    </div>
  )
}

export default Editflowsystem