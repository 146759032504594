import React, { useEffect } from 'react'
import { Button } from 'react-bootstrap';
import FetchApi from '../../customhooks/functionFetchApi';

const Actiondelete = ({ Id,loadingMenulist }) => {
  const FetchApis = new FetchApi();


  function checkdelete() {

    FetchApis.FethcGet(`/groupmenusub/getgroupdetailByid/${Id}`).then((res) => {
      if (res.data.data.length > 1) {
        alert("ไม่สามรถลบได้เนื่องจากมี ( "+res.data.data.length+" ) รายการ ที่ใช้งานอยู่ ?")
      } else {
        //alert('สามารถลบได้')
        deleteheadermenu(Id)
      }
    }).catch(err => alert(err));
  }

  function deleteheadermenu() {
    FetchApis.FethcDelete(`/groupmenu/deletegroupmenulistByid/${Id}`).then((res)=>{
      if(res.status==200){
        alert('คุณได้ทำการลบข้อมูลเรียบร้อยแล้ว');
        loadingMenulist();
      }
    })
    .catch(err=>alert(alert(err)));
  }

  return (
    <div><Button size='sm' variant="danger" onClick={()=>checkdelete()}>ลบข้อมูล</Button></div>
  )
}

export default Actiondelete