import React, { useEffect, useState } from 'react';
import FetchApi from '../customhooks/functionFetchApi';
import Tablelistallprovebyuser from './components/componentallistallpprovebyuser/tablelistallprovebyuser';
import { FaFileSignature } from "react-icons/fa";
import Spinner from 'react-bootstrap/Spinner';
const Journallistallpprovebyuser = () => {

  const FetchApis = new FetchApi();
  const emcode = sessionStorage.getItem("EmCode")

  const [datalist, setDatalist] = useState([]);
  const [loadingdata, setLoadingdata] = useState(true);
  const [searhcText, setSearchText] = useState([]);

  useEffect(() => {
    laodingList();
  }, [])


  function laodingList() {
    setLoadingdata(true)
    FetchApis.FethcGet(`/runflow/getJournalflowlist/${emcode}`).then((res) => {

      if (res.status == 200) {

        setDatalist(res.data.data);
        setSearchText(res.data.data);

        setTimeout(() => {
          setLoadingdata(false)
        }, 1300);

      }
    })
  }


  //ค้นหาข้อมูลทั้งหมด
  function heandleSearch(e) {
    const { value, name } = e.target;

    // setSearchInput(searchValue)
    const filteredData = datalist.filter((item) => {
      return Object.values(item).join('').toLowerCase().includes(value.toLowerCase())
    })

    setSearchText(filteredData)
  }


  return (
    <div>
      <div className='row-page'>
        <div>
          <label style={{ fontSize: 20, margin: 5, padding: 5 }}><FaFileSignature />เอกสารที่อนุมัติแล้ว</label>
          <label style={{ fontSize: 20, margin: 5, padding: 5 }}>( {datalist.length} ) รายการ</label>
        </div>

        <div style={{ fontSize: 20, margin: 5, padding: 5 }}>
          <input type='text' name='TextSearch' placeholder='ค้นหาข้อมูล' onChange={(e) => heandleSearch(e)} />
        </div>
      </div>
      {loadingdata ? <div className='body-center-page'><Spinner
        as="span"
        animation="grow"
        variant="success"
        size={200}
        role="status"
        aria-hidden="true" /> LOADIN ....</div> : <div><Tablelistallprovebyuser datatable={searhcText} /></div>}
    </div>
  )

}

export default Journallistallpprovebyuser