import React,{useEffect,useState,useRef} from 'react'
import generatePDF from 'react-to-pdf';
import Colordetaillist from '../../components/colordetaillist';
import FetchApi from '../../../customhooks/functionFetchApi';
import { Button } from 'react-bootstrap';
import { FaFileDownload } from "react-icons/fa";
const objectPartB = {
    tecbid: "",sequence: "",bodyside: "",bodycolor: "",bodyfm: "",bodydfw: "",
    bodywfw: "",bodytemp: "",bodyspeed: "",bodyrubber: "",topside: "",topcolor: "",
    topfm: "",topdfw: "",topwfw: "",toptemp: "",topspeed: "",toprubber: "",
    bottomside: "",bottomcolor: "",bottomfm: "",bottomdfw: "",bottomwfw: "",bottomtemp: "",bottomspeed: "",bottomrubber: "",
    otherdetail: "",
    tdscode: ""
}
const PdfPrintingColor = ({idjour}) => {
    const FetchApis = new FetchApi()
    const targetRef = useRef();

    const [coanting1st, setCoanting1st] = useState(objectPartB);
    const [coanting2nd, setCoanting2nd] = useState(objectPartB);
    const [coanting3rd, setCoanting3rd] = useState(objectPartB);
    const [coanting4th, setCoanting4th] = useState(objectPartB);
    const [coanting5th, setCoanting5th] = useState(objectPartB);
    const [coanting6th, setCoanting6th] = useState(objectPartB);
    const [coanting7th, setCoanting7th] = useState(objectPartB);
    const [checkdata, setCheckdata] = useState(false);

    useEffect(() => {

        FetchApis.FethcGet(`/technical/techniaclByJournalId/${idjour}`).then((res) => {
            console.log(res)
            if (res.status == 200) {
                if (res.data.data.length != 0) {

                    Coatinglistbytype(res.data.data)
                    setCheckdata(true)
                }

            }
        })

    }, [])

    function Coatinglistbytype(datalist) {
        const coating1st = datalist.find((item) => item.sequence == '1st');
        setCoanting1st(coating1st);

        const coating2nd = datalist.find((item) => item.sequence == '2nd');
        setCoanting2nd(coating2nd);

        const coating3rd = datalist.find((item) => item.sequence == '3rd');
        setCoanting3rd(coating3rd);

        const coating4th = datalist.find((item) => item.sequence == '4th');
        setCoanting4th(coating4th);

        const coating5th = datalist.find((item) => item.sequence == '5th');
        setCoanting5th(coating5th);

        const coating6th = datalist.find((item) => item.sequence == '6th');
        setCoanting6th(coating6th);

        const coating7th = datalist.find((item) => item.sequence == '7th');
        setCoanting7th(coating7th);
    }

    function getjournalbyid() {
        FetchApis.FethcGet(`/journal/journalbyId/${idjour}`).then(res=>{
            if(res){
              let tdscodeid  =res.data.data[0].tdscode;

                generatePDF(targetRef, { filename: `${tdscodeid}_printingcolor_page.pdf` })
            }
            
        })
    }


  return (
    <div> 
        <Button onClick={() => getjournalbyid()} size='sm' variant="light">
            <FaFileDownload /> Download PDF</Button>
        {checkdata == true ? <div ref={targetRef}>
        <Colordetaillist tecbid={coanting1st.tecbid} typetecb={'1st'} />
        <Colordetaillist tecbid={coanting2nd.tecbid} typetecb={'2nd'} />
        <Colordetaillist tecbid={coanting3rd.tecbid} typetecb={'3rd'} />
        <Colordetaillist tecbid={coanting4th.tecbid} typetecb={'4th'} />
        <Colordetaillist tecbid={coanting5th.tecbid} typetecb={'5th'} />
        <Colordetaillist tecbid={coanting6th.tecbid} typetecb={'6th'} />
        <Colordetaillist tecbid={coanting7th.tecbid} typetecb={'7th'} />
        <img src={require('../../../images/draft-icon-27.jpg')} style={{ width: 50, height: 'auto', borderRadius: 3 }} />
    </div>
        : <div>ไม่มข้อมูล</div>}

        </div>
  )
}

export default PdfPrintingColor