import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import FetchApi from '../../customhooks/functionFetchApi';
import Showdetailjournal from'../components/showdetailjournal';
const datasub = {
  spectype: "",
  sheetsize: "",
  flatscroll: "",
  straightscroll: "",
  tin: "",
  temper: "",
  bright: "",
  row: "",
  filmcode: "",
  itemno: "",
  tdscode: ""
}


function FormViewJournal({ jourId, handleShow }) {

  const FetchApis = new FetchApi()
  const [key, setKey] = useState('jouranl');

 
  return (
    <div className='form-body-page'>
      <form >
        <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k) => setKey(k)}
          className="mb-3"
        >
          <Tab eventKey="jouranl" title="TECHNICAL DATA SHEET A" style={{ backgroundColor: 'aliceblue' }}>
            <Showdetailjournal id={jourId}/>
          </Tab>
        </Tabs>
      </form>
    </div>
  )
}


const Viewduplicatedetails = ({ jourId,JournalTDS}) => {
  console.log(jourId)
  const values = [true, 'sm-down', 'md-down', 'lg-down', 'xl-down', 'xxl-down'];
  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);

  function handleShow(value) {
    setShow(value);
  }

  return (
    <div >
      <Button variant="primary" size="sm" onClick={() => handleShow(true)} >รายละเอียด</Button>

      <Modal show={show} fullscreen={fullscreen} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>รายละเอียด : {JournalTDS}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormViewJournal jourId={jourId} handleShow={handleShow} />
          </Modal.Body>
      </Modal>
    </div>
  )
}

export default Viewduplicatedetails