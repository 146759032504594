import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import FetchApi from '../../../customhooks/functionFetchApi';
import Modal from 'react-bootstrap/Modal';
import { statusflowall } from '../../object-default/journalobject';
import { ToastContainer, toast } from 'react-toastify';
import Swal from 'sweetalert2';


function Formdedicated({ newTask, newjournalUpdate }) {
    const FetchApis = new FetchApi();
    const [userList, setUserList] = useState([]);
    const [userdedicate, setUserdedicate] = useState({ userdedicates: "", usernote: "" });
    const emcodes=sessionStorage.getItem("EmCode");

    useEffect(() => {

        FetchApis.FethcGet(`/account/accounByDedicate/${emcodes}`).then((res) => {
            if (res.status == 200) {
                setUserList(res.data.data)
            }
            else {
                console.log(res.error);
            }
        })
    }, [])


    const saveDedicate = () => {
        if (userdedicate.userdedicates == "") {
            Swal.fire({
                title: "แจ้งเตือน",
                text: "กรุณาเลือกผู้อนุมัติแทน",
                icon: "question"
              });
        } else {
            Swal.fire({
                title: "บันทึกเอกสาร",
                text: "คุณแน่ใจที่บันทึกเอกสาร ใช่หรือไม่ ! ",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "ใช่",
                cancelButtonText: 'ยกเลิก'
            }).then((result) => {
                if (result.isConfirmed) {
                    Swal.fire({
                        title: "บันทึกเอกสาร!",
                        text: "คุณได้ทำบันทึกเอกสารเรียบแล้ว",
                        icon: "success"
                    });
                    dedicatejournal();
                }

            });
        }
    }


    // update  recieved 
    function dedicatejournal() {

        var dataobject = {
            journalid: newTask.journalid,
            emcode: newTask.emcode,
            stateflow: newTask.stateflow + 1,
            approvecode: statusflowall.deligatejournal,
            dedicatecode: userdedicate.userdedicates,
            tasknote: userdedicate.usernote
        }
        var dataint = parseInt(newTask.stateflow) + 1;

        // // create task flow
        FetchApis.FethcPost(`/taskflow/createTaskflow`, dataobject).then((res) => {
            if (res.status == 200) {
                updatejournal(dataint);
            }
        })


    }


    // //update flowstatus journal
    function updatejournal(stateflows) {

        var journaldata = {
            statusflow: statusflowall.deligatejournal,
            stateflow: stateflows
        }

        FetchApis.FethcUpdate(`/journal/updatestateflow/${newTask.journalid}`, journaldata).then((res) => {
            if (res.status == 200) {

                setTimeout(() => {
                    window.location.href = '/journallistbyapprove'
                }, 1500);

            }
        })

    }



    return (
        <div>
            <form>
                <div className='row-page'>
                    <div style={{ marginRight: 20 }}>กำหนดชื่อผู้อนุมัติแทน : </div>
                    <div>
                        {userList.length > 0 && <select name='username' id='username'
                            onChange={(e) => setUserdedicate({ ...userdedicate, userdedicates: e.target.value })}>
                            <option value={""}>เลือกผุ้อนุมัติ... </option>
                            {
                                userList.map((item, i) => (
                                    <option value={item.employeeId}>{item.username}</option>
                                ))
                            }
                        </select>}
                    </div>
                </div>

                <div className='row-page' style={{ marginTop: 20 }}>
                    <div>รายละเอียด : </div>
                    <div>
                        <textarea name='dedicateNote' id='dedicateNote' maxLength={200} style={{ width: 350 }}
                            onChange={(e) => setUserdedicate({ ...userdedicate, usernote: e.target.value })}>
                        </textarea>
                    </div>
                </div>

                <div className='end-flex-row'>
                    <div style={{ marginRight: 10 }}>
                        <Button onClick={() => saveDedicate()} size='sm' variant="success" >บันทึก</Button>
                    </div>
                    <div>
                        <Button type='reset' size='sm' variant="primary">ยกเลิก</Button>
                    </div>
                </div>
            </form>
        </div>
    )
}



const Journaldedicated = ({ newTask, newjournalUpdate }) => {


    const values = [true, 'sm-down', 'md-down', 'lg-down', 'xl-down', 'xxl-down'];
    const [fullscreen, setFullscreen] = useState(true);
    const [show, setShow] = useState(false);



    function handleShow(value) {
        // setFullscreen(breakpoint);
        setShow(value);
    }

    return (
        <div>
            <Button onClick={() => handleShow(true)} size='sm' variant="primary" style={{ marginRight: 10 }}>มอบหมาย</Button>

            <Modal show={show}
                // fullscreen={fullscreen} 
                size="lg"
                onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>กำหนด ผู้อนุมัติแทน </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Formdedicated newTask={newTask} newjournalUpdate={newjournalUpdate} />
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default Journaldedicated