import React, { useState, useEffect } from 'react'
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import FetchApi from '../../../customhooks/functionFetchApi';
// import Swal from 'sweetalert2'
import { MdAddCircle } from "react-icons/md";
import FormAddcolor from './formAddcolor';
import { color2, color6, color7, color8 } from './objectcolor';

const Addcolorprinting = ({ tecbid, typetecb }) => {
    const FetchApis = new FetchApi()
    const [fullscreen, setFullscreen] = useState(true);
    const [show, setShow] = useState(false);
    // const [datacolorlist, setDatacolorlist] = useState([])
    // const [loadingdata, setLoadingdata] = useState(false);
    const [datacolor2, setDatacolor2] = useState({ ...color2 })
    const [datacolor6, setDatacolor6] = useState({ ...color6 })
    const [datacolor7, setDatacolor7] = useState({ ...color7 })
    const [datacolor8, setDatacolor8] = useState({ ...color8 })


    useEffect(() => {
        selectall(tecbid)

    }, [])


    function handleShow(value) {
        setShow(value);
    }

    function onChangeSave(dtcolor2,dtcolor6,dtcolor7,dtcolor8) {
        // setDatacolorlist(result);
        setDatacolor2(dtcolor2);
        setDatacolor6(dtcolor6);
        setDatacolor7(dtcolor7);
        setDatacolor8(dtcolor8);
    }


    const selectall = (tecbid) => {

        FetchApis.FethcGet(`/color/getInk2colorbytecbid/${tecbid}`).then((res) => {
            if (res.data.data.length > 0) {
                // console.log(res.data.data)
                setDatacolor2(res.data.data[0])
            } 
        })

        FetchApis.FethcGet(`/color/getInk6colorbytecbid/${tecbid}`).then((res) => {
            if (res.data.data.length > 0) {
                // console.log(res.data.data)
                setDatacolor6(res.data.data[0])
            }
        })

        FetchApis.FethcGet(`/color/getInk7colorbytecbid/${tecbid}`).then((res) => {
            if (res.data.data.length > 0) {
                // console.log(res.data.data)
                setDatacolor7(res.data.data[0])
            }
        })

        FetchApis.FethcGet(`/color/getInk8colorbytecbid/${tecbid}`).then((res) => {
            if (res.data.data.length > 0) {
                // console.log(res.data.data)
                setDatacolor8(res.data.data[0])
            } 
        })

    }

    return (
        <div>

            {tecbid != undefined && <div>
                <table style={{ width: '99%' ,marginBottom:10}}>
                    <tr>
                    {/* colspan="2" */}
                        <th style={{ width: '10%' }}><Button size='sm' style={{ fontSize: 12 }} onClick={() => handleShow(true)} ><MdAddCircle size={20}/> เพิ่มข้อมูล</Button></th>
                        <th style={{ width: '20%'}} colspan="2"><center>Convent Ink 2 Color (PT1-5)</center></th>
                        <th style={{ width: '20%' }} colspan="2" ><center>UV Ink 6 Color (PT6)</center></th>
                        <th style={{ width: '20%' }} colspan="2"><center>Convent Ink 6 Color (PT7) </center></th>
                        <th style={{ width: '10%' }}><center>Digital print (PT8)</center></th>

                    </tr>
                    <tr style={{backgroundColor:"#53A2F1"}}>
                        <td><center><b>System Printing</b></center></td>
                        <td><center><b>Unit1</b></center></td>
                        <td><center><b>Unit2</b></center></td>
                        <td><center><b>#Pass1</b></center></td>
                        <td><center><b>#Pass2</b></center></td>

                        <td><center><b>#Pass1</b></center></td>
                        <td><center><b>#Pass2</b></center></td>

                        <td><center><b>#Pass1</b></center></td>
                        {/* <td><center><b>#Pass2</b></center></td> */}
                    </tr>
                    <tr>
                        <td>Color shade</td>
                        <td>{datacolor2.colorshqde_unit1}</td>
                        <td>{datacolor2.colorshqde_unit2}</td>
                        <td>{datacolor6.colorshqde_unit1}</td>
                        <td>{datacolor6.colorshqde_unit2}</td>

                        <td>{datacolor7.colorshqde_unit1}</td>
                        <td>{datacolor7.colorshqde_unit2}</td>

                        <td>{datacolor8.colorshqde_unit1}</td>
                        {/* <td>{datacolor6.colorshqde_unit2}</td> */}
                    </tr>
                    <tr>
                        <td><b>Item Id</b></td>
                        <td>{datacolor2.Idcolor_unit1}</td>
                        <td>{datacolor2.Idcolor_unit2}</td>
                        <td>{datacolor6.Idcolor_unit1}</td>
                        <td>{datacolor6.Idcolor_unit2}</td>

                        <td>{datacolor7.Idcolor_unit1}</td>
                        <td>{datacolor7.Idcolor_unit2}</td>

                        <td>{datacolor8.Idcolor_unit1}</td>
                        {/* <td>{datacolor6.colorshqde_unit2}</td> */}
                    </tr>
                    <tr>
                        <td>FM.code</td>
                        <td>{datacolor2.fmcode_unit1}</td>
                        <td>{datacolor2.fmcode_unit2}</td>
                        <td>{datacolor6.fmcode_unit1}</td>
                        <td>{datacolor6.fmcode_unit2}</td>

                        <td>{datacolor7.fmcode_unit1}</td>
                        <td>{datacolor7.fmcode_unit2}</td>

                        <td>{datacolor8.fmcode_unit1}</td>
                        {/* <td>{datacolor6.fmcode_unit2}</td> */}
                    </tr>
                    <tr>
                        <td>Thickness / Density </td>
                        <td>{datacolor2.thicknes_unit1 ? <div>{datacolor2.thicknes_unit1} / {datacolor2.density_unit1}</div> : <div></div>}</td>
                        <td>{datacolor2.thicknes_unit2 ? <div>{datacolor2.thicknes_unit2} / {datacolor2.density_unit2}</div> : <div></div>}</td>
                        
                        <td>{datacolor6.thicknes_unit1 ? <div>{datacolor6.thicknes_unit1} / {datacolor6.thicknes_unit1}</div> : <div></div>}</td>
                        <td>{datacolor6.thicknes_unit2 ? <div>{datacolor6.thicknes_unit2} / {datacolor6.density_unit2}</div> : <div></div>}</td>
                        
                        <td>{datacolor7.thicknes_unit1 ? <div>{datacolor7.thicknes_unit1} / {datacolor7.thicknes_unit1}</div> : <div></div>}</td>
                        <td>{datacolor7.thicknes_unit2 ? <div>{datacolor7.thicknes_unit2} / {datacolor7.density_unit2}</div> : <div></div>}</td>

                        <td>{datacolor8.thicknes_unit1 ? <div>{datacolor8.thicknes_unit1} / {datacolor8.thicknes_unit1}</div> : <div></div>}</td>
                        {/* <td>{datacolor6.thicknes_unit2 ? <div>{datacolor6.thicknes_unit2} / {datacolor6.density_unit2}</div> : <div></div>}</td> */}
                    </tr>
                    <tr>
                        <td>Temp. / UV lamp. </td>
                        <td>{datacolor2.temp_unit1 ? <div>{datacolor2.temp_unit1} / {datacolor2.uvlamp_unit1}</div> : <div></div>}</td>
                        <td>{datacolor2.temp_unit2 ? <div>{datacolor2.temp_unit2} / {datacolor2.uvlamp_unit2}</div> : <div></div>}</td>
                        <td>{datacolor6.temp_unit1 ? <div>{datacolor6.temp_unit1} / {datacolor6.uvlamp_unit1}</div> : <div></div>}</td>
                        <td>{datacolor6.temp_unit2 ? <div>{datacolor6.temp_unit2} / {datacolor6.uvlamp_unit2}</div> : <div></div>}</td>

                        <td>{datacolor7.temp_unit1 ? <div>{datacolor7.temp_unit1} / {datacolor7.uvlamp_unit1}</div> : <div></div>}</td>
                        <td>{datacolor7.temp_unit2 ? <div>{datacolor7.temp_unit2} / {datacolor7.uvlamp_unit2}</div> : <div></div>}</td>

                        <td>{datacolor8.temp_unit1 ? <div>{datacolor8.temp_unit1} / {datacolor8.uvlamp_unit1}</div> : <div></div>}</td>
                        {/* <td>{datacolor6.temp_unit2 ? <div>{datacolor6.temp_unit2} / {datacolor6.uvlamp_unit2}</div> : <div></div>}</td> */}
                    </tr>
                    <tr>
                        <td>Speed (sph)</td>
                        <td>{datacolor2.speed_unit1}</td>
                        <td>{datacolor2.speed_unit2}</td>
                        <td>{datacolor6.speed_unit1}</td>
                        <td>{datacolor6.speed_unit2}</td>

                        <td>{datacolor7.speed_unit1}</td>
                        <td>{datacolor7.speed_unit2}</td>

                        <td>{datacolor8.speed_unit1}</td>
                        {/* <td>{datacolor6.speed_unit2}</td> */}
                    </tr>
                    <tr>
                        <td><b># {typetecb}</b></td>
                        <td></td>
                        <td></td>
                        <td></td>

                        <td></td>
                        <td></td>

                        <td></td>
                        {/* <td></td> */}
                    </tr>
                </table>
            </div>
            }

            <div>
                <Modal show={show} fullscreen={fullscreen} onHide={() => setShow(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>เพิ่มข้อมูล Color detial   </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='form-body-page'>

                            {/* <PrintingDetailpartb codeId={codeId} /> */}
                            <FormAddcolor tecbid={tecbid} handleShow={handleShow} onChangeSave={onChangeSave} typetecb={typetecb} />
                        </div>

                    </Modal.Body>
                </Modal>
            </div>
        </div>
    )
}

export default Addcolorprinting