import React, { useState, useEffect } from 'react'
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import FetchApi from '../../../customhooks/functionFetchApi';
import Swal from 'sweetalert2'
import { MdAddCircle } from "react-icons/md";
import { color2, color6, color7, color8 } from './objectcolor';
import Popupitemgroupcolor from './popupitemgroupcolor';

function FormAddcolor({ tecbid, handleShow, onChangeSave, typetecb }) {
    const FetchApis = new FetchApi();
    const [checkdata, setCheckdata] = useState(false);

    const [datacolor2, setDatacolor2] = useState({ ...color2 })
    const [datacolor6, setDatacolor6] = useState({ ...color6 })
    const [datacolor7, setDatacolor7] = useState({ ...color7 })
    const [datacolor8, setDatacolor8] = useState({ ...color8 })


    useEffect(() => {

        setDatacolor2({
            ...datacolor2,
            pt_type: typetecb,
            tecbid: tecbid
        })
        setDatacolor6({
            ...datacolor6, pt_type: typetecb,
            tecbid: tecbid
        })
        setDatacolor7({
            ...datacolor7, pt_type: typetecb,
            tecbid: tecbid
        })
        setDatacolor8({
            ...datacolor8, pt_type: typetecb,
            tecbid: tecbid
        })


        FetchApis.FethcGet(`/color/getInk2colorbytecbid/${tecbid}`).then((res) => {
            if (res.data.data.length > 0) {
                // setDataColorlist(res.data.data[0]);

                // onChangeSave(res.data.data[0])

                setCheckdata(true)

                selectall()

            } else {

                // onChangeSave(datacolorlist);

                setCheckdata(false)
            }
        })



    }, [])


    const SaveColor = () => {

        if (checkdata == true) {
            updateAll();
        }
        else {
            insertall()
        }

    }


    const Alert_Save = () => {
        Swal.fire({
            title: "บันทึกเอกสาร",
            text: "คุณแน่ใจที่บันทึกเอกสาร ใช่หรือไม่ ! ",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "ใช่",
            cancelButtonText: 'ยกเลิก'
        }).then((result) => {

            if (result.isConfirmed) {
                Swal.fire({
                    title: "บันทึกเอกสาร!",
                    text: "คุณได้ทำบันทึกเอกสารเรียบแล้ว",
                    icon: "success"
                });
                // loadingdata();
                onChangeSave(datacolor2, datacolor6, datacolor7, datacolor8);

                handleShow(false);
            }

        });
    }


    const updateAll = () => {

        FetchApis.FethcUpdate(`/color/updateInk2color/${datacolor2.InkId}`, datacolor2).then((res) => {
            if (res.status == 200) {

                FetchApis.FethcUpdate(`/color/updateInk6color/${datacolor6.InkId}`, datacolor6).then((res) => {
                    if (res.status == 200) {

                        FetchApis.FethcUpdate(`/color/updateInk7color/${datacolor7.InkId}`, datacolor7).then((res) => {
                            if (res.status == 200) {

                                FetchApis.FethcUpdate(`/color/updateInk8color/${datacolor8.InkId}`, datacolor8).then((res) => {
                                    if (res.status == 200) {
                                        Alert_Save();
                                    }
                                })
                            }
                        })
                    }
                })
            }
        })

    }

    const insertall = () => {

        FetchApis.FethcPost(`/color/createInk2color`, datacolor2).then((res) => {
            if (res.status == 200) {
                // Alert_Save();
                FetchApis.FethcPost(`/color/createInk6color`, datacolor6).then((res) => {
                    if (res.status == 200) {
                        // Alert_Save();
                        FetchApis.FethcPost(`/color/createInk7color`, datacolor7).then((res) => {
                            if (res.status == 200) {
                                // Alert_Save();
                                FetchApis.FethcPost(`/color/createInk8color`, datacolor8).then((res) => {
                                    if (res.status == 200) {
                                        Alert_Save();
                                    }
                                })
                            }
                        })
                    }
                })
            }
        })
    }


    const selectall = () => {

        FetchApis.FethcGet(`/color/getInk2colorbytecbid/${tecbid}`).then((res) => {
            if (res.data.data.length > 0) {
                // console.log(res.data.data)
                setDatacolor2(res.data.data[0])
            }
        })

        FetchApis.FethcGet(`/color/getInk6colorbytecbid/${tecbid}`).then((res) => {
            if (res.data.data.length > 0) {
                // console.log(res.data.data)
                setDatacolor6(res.data.data[0])
            }
        })

        FetchApis.FethcGet(`/color/getInk7colorbytecbid/${tecbid}`).then((res) => {
            if (res.data.data.length > 0) {
                // console.log(res.data.data)
                setDatacolor7(res.data.data[0])
            }
        })

        FetchApis.FethcGet(`/color/getInk8colorbytecbid/${tecbid}`).then((res) => {
            if (res.data.data.length > 0) {
                // console.log(res.data.data)
                setDatacolor8(res.data.data[0])
            }
        })

    }

    // color 2
    const OnChangDatacolor2 = (IdItem, Itemname) => {

        setDatacolor2({
            ...datacolor2,
            Idcolor_unit1: IdItem,
            colorshqde_unit1: Itemname
        })
    }

    const OnChangDatacolor2_ = (IdItem, Itemname) => {

        setDatacolor2({
            ...datacolor2,
            Idcolor_unit2: IdItem,
            colorshqde_unit2: Itemname
        })
    }


    // color 6
    const OnChangDatacolor6 = (IdItem, Itemname) => {

        setDatacolor6({
            ...datacolor6,
            Idcolor_unit1: IdItem,
            colorshqde_unit1: Itemname
        })
    }

    const OnChangDatacolor6_ = (IdItem, Itemname) => {

        setDatacolor6({
            ...datacolor6,
            Idcolor_unit2: IdItem,
            colorshqde_unit2: Itemname
        })
    }

    // color 6
    const OnChangDatacolor7 = (IdItem, Itemname) => {

        setDatacolor7({
            ...datacolor7,
            Idcolor_unit1: IdItem,
            colorshqde_unit1: Itemname
        })
    }

    const OnChangDatacolor7_ = (IdItem, Itemname) => {

        setDatacolor7({
            ...datacolor7,
            Idcolor_unit2: IdItem,
            colorshqde_unit2: Itemname
        })
    }

    const OnChangDatacolor8 = (IdItem, Itemname) => {

        setDatacolor8({
            ...datacolor8,
            Idcolor_unit1: IdItem,
            colorshqde_unit1: Itemname
        })
    }


    return (
        <div>
            <form>
                <b>[ {typetecb} # ]</b>
                <div className='end-flex-row'>
                    <div style={{ marginRight: 10 }}>
                        <Button variant="success" size='sm' onClick={() => SaveColor()}>บันทึกข้อมูล</Button>
                    </div>
                    <div style={{ marginRight: 10 }}>
                        <Button variant="warning" size='sm' type='reset'>ยกเลิก</Button>
                    </div>
                </div>
                {/* color 2 */}
                <hr></hr>
                <div><b style={{ fontSize: 15 }}>Convent Ink 2 Color (PT1-5)</b></div>
                <div className='row-between-page' style={{ marginTop: 0 }}>
                    {/*unit1*/}

                    <div style={{ width: '60%', padding: 10 }} >
                        <div><b style={{ fontSize: 16 }}>Color Unit 1 . </b></div>
                        <div className='row-between-page'>
                            <label style={{ fontSize: 14 }}>Color shade</label>
                            <div className='row-between-page'>
                                <Popupitemgroupcolor OnChangDatacolor2={OnChangDatacolor2} typeink={'ink21'} />
                                <input type='text' name='speed_unit1' value={datacolor2.colorshqde_unit1}
                                    onChange={(e) => setDatacolor2({ ...datacolor2, colorshqde_unit1: e.target.value })} style={{ width: 300 }} />
                                <label style={{ marginLeft: 3 }}><b>Item ID :</b></label><input type='text' name='ItemId_ink2_code1' value={datacolor2.Idcolor_unit1} disabled />
                            </div>
                        </div>
                        <div className='row-between-page'>
                            <label style={{ fontSize: 14 }}>FM .code </label>
                            <input type='text' name='fmcode_unit1' style={{ width: 300 }} value={datacolor2.fmcode_unit1}
                                onChange={(e) => setDatacolor2({ ...datacolor2, fmcode_unit1: e.target.value })} />
                        </div>
                        <div className='row-between-page'>
                            <label style={{ fontSize: 14 }}>Thickness /Density</label>
                            <div>Thickness : <input type='text' name='thicknes_unit1' style={{ width: 150 }}
                                value={datacolor2.thicknes_unit1}
                                onChange={(e) => setDatacolor2({ ...datacolor2, thicknes_unit1: e.target.value })} />
                            </div>
                            <div>Density :<input type='text' name='density_unit1' value={datacolor2.density_unit1}
                                onChange={(e) => setDatacolor2({ ...datacolor2, density_unit1: e.target.value })} style={{ width: 150 }} /></div>
                        </div>
                        <div className='row-between-page' >
                            <label style={{ fontSize: 14 }}>Temp. / UV lamp.</label>
                            <div>Temp : <input type='text' name='temp_unit1' style={{ width: 150 }} value={datacolor2.temp_unit1}
                                onChange={(e) => setDatacolor2({ ...datacolor2, temp_unit1: e.target.value })} />
                            </div>
                            <div>UV lamp. <input type='text' name='uvlamp_unit1' style={{ width: 150 }} value={datacolor2.uvlamp_unit1}
                                onChange={(e) => setDatacolor2({ ...datacolor2, uvlamp_unit1: e.target.value })} /></div>
                        </div>
                        <div className='row-between-page'>
                            <label style={{ fontSize: 14 }}>Speed (sph)</label>
                            <input type='text' name='speed_unit1' style={{ width: 300 }} value={datacolor2.speed_unit1}
                                onChange={(e) => setDatacolor2({ ...datacolor2, speed_unit1: e.target.value })} />
                        </div>
                    </div>

                    {/*unit 2*/}
                    <div style={{ width: '60%', padding: 10 }} >
                        <div><b style={{ fontSize: 16 }}>Color Unit 2 . </b></div>
                        <div className='row-between-page'>
                            <label style={{ fontSize: 14 }}>Color shade</label>
                            <div className='row-between-page'>
                                <Popupitemgroupcolor OnChangDatacolor2_={OnChangDatacolor2_} typeink={'ink22'} />
                                <input type='text' name='colorshqde_unit2' style={{ width: 300 }} value={datacolor2.colorshqde_unit2}
                                    onChange={(e) => setDatacolor2({ ...datacolor2, colorshqde_unit2: e.target.value })} />
                                <label style={{ marginLeft: 3 }}><b>Item ID :</b></label><input type='text' name='ItemId_ink2_code2' value={datacolor2.Idcolor_unit2} disabled />
                            </div>
                        </div>
                        <div className='row-between-page'>
                            <label style={{ fontSize: 14 }}>FM .code </label>  <input type='text' name='fmcode_unit2' style={{ width: 300 }}
                                value={datacolor2.fmcode_unit2}
                                onChange={(e) => setDatacolor2({ ...datacolor2, fmcode_unit2: e.target.value })} />
                        </div>
                        <div className='row-between-page'>
                            <label style={{ fontSize: 14 }}>Thickness /Density</label>
                            <div>Thickness : <input type='text' name='thicknes_unit2' style={{ width: 150 }} value={datacolor2.thicknes_unit2}
                                onChange={(e) => setDatacolor2({ ...datacolor2, thicknes_unit2: e.target.value })} />
                            </div>
                            <div>Density :<input type='text' name='density_unit2' style={{ width: 150 }} value={datacolor2.density_unit2}
                                onChange={(e) => setDatacolor2({ ...datacolor2, density_unit2: e.target.value })} /></div>
                        </div>
                        <div className='row-between-page' >
                            <label style={{ fontSize: 14 }}>Temp. / UV lamp.</label>
                            <div>Temp : <input type='text' name='temp_unit2' style={{ width: 150 }}
                                value={datacolor2.temp_unit2}
                                onChange={(e) => setDatacolor2({ ...datacolor2, temp_unit2: e.target.value })} />
                            </div>
                            <div>UV lamp. <input type='text' name='uvlamp_unit2' style={{ width: 150 }} value={datacolor2.uvlamp_unit2}
                                onChange={(e) => setDatacolor2({ ...datacolor2, uvlamp_unit2: e.target.value })}
                            /></div>
                        </div>
                        <div className='row-between-page'>
                            <label style={{ fontSize: 14 }}>Speed (sph)</label>
                            <input type='text' name='speed_unit2' style={{ width: 300 }} value={datacolor2.speed_unit2}
                                onChange={(e) => setDatacolor2({ ...datacolor2, speed_unit2: e.target.value })} />
                        </div>
                    </div>
                </div>
                <hr></hr>

                {/* color 6 */}
                <div><b style={{ fontSize: 15 }}>UV Ink 6 COlor (PT6)</b></div>
                <div className='row-between-page' style={{ marginTop: 0 }}>
                    {/*pass 1*/}
                    <div style={{ width: '60%', padding: 10 }} >
                        <div><b style={{ fontSize: 16 }}>#Pass 1. </b></div>
                        <div className='row-between-page'>
                            <label style={{ fontSize: 14 }}>Color shade</label>
                            <div className='row-between-page'>
                                <Popupitemgroupcolor OnChangDatacolor6={OnChangDatacolor6} typeink={'ink61'} />
                                <input type='text' name='colorshqde6_unit1' style={{ width: 300 }} value={datacolor6.colorshqde_unit1}
                                    onChange={(e) => setDatacolor6({ ...datacolor6, colorshqde_unit1: e.target.value })} />
                                <label style={{ marginLeft: 3 }}><b>Item ID :</b></label><input type='text' name='ItemId_ink6_code1' value={datacolor6.Idcolor_unit1} disabled />
                            </div>
                        </div>
                        <div className='row-between-page'>
                            <label style={{ fontSize: 14 }}>FM .code </label>
                            <input type='text' name='fmcode6_unit1' style={{ width: 300 }}
                                value={datacolor6.fmcode_unit1}
                                onChange={(e) => setDatacolor6({ ...datacolor6, fmcode_unit1: e.target.value })} />
                        </div>
                        <div className='row-between-page'>
                            <label style={{ fontSize: 14 }}>Thickness /Density</label>
                            <div>Thickness :
                                <input type='text' name='thicknes6_unit1' style={{ width: 150 }}
                                    value={datacolor6.thicknes_unit1}
                                    onChange={(e) => setDatacolor6({ ...datacolor6, thicknes_unit1: e.target.value })} /></div>
                            <div>Density :
                                <input type='text' name='density6_unit1' style={{ width: 150 }}
                                    value={datacolor6.density_unit1}
                                    onChange={(e) => setDatacolor6({ ...datacolor6, density_unit1: e.target.value })} />
                            </div>
                        </div>
                        <div className='row-between-page' >
                            <label style={{ fontSize: 14 }}>Temp. / UV lamp.</label>
                            <div>Temp :
                                <input type='text' name='temp6_unit1' style={{ width: 150 }}
                                    value={datacolor6.temp_unit1}
                                    onChange={(e) => setDatacolor6({ ...datacolor6, temp_unit1: e.target.value })} /></div>
                            <div>UV lamp.
                                <input type='text' name='uvlamp6_unit1' style={{ width: 150 }}
                                    value={datacolor6.uvlamp_unit1}
                                    onChange={(e) => setDatacolor6({ ...datacolor6, uvlamp_unit1: e.target.value })} /></div>
                        </div>
                        <div className='row-between-page'>
                            <label style={{ fontSize: 14 }}>Speed (sph)</label>
                            <input type='text' name='speed6_unit1'
                                value={datacolor6.speed_unit1}
                                onChange={(e) => setDatacolor6({ ...datacolor6, speed_unit1: e.target.value })}
                                style={{ width: 300 }} />
                        </div>
                    </div>
                    {/*pass 2*/}
                    <div style={{ width: '60%', padding: 10 }} >
                        <div><b style={{ fontSize: 16 }}>#Pass 2. </b></div>
                        <div className='row-between-page'>
                            <label style={{ fontSize: 14 }}>Color shade</label>
                            <div className='row-between-page'>
                                <Popupitemgroupcolor OnChangDatacolor6={OnChangDatacolor6_} typeink={'ink62'} />
                                <input type='text' name='colorshqde6_unit2' style={{ width: 300 }} value={datacolor6.colorshqde_unit2}
                                    onChange={(e) => setDatacolor6({ ...datacolor6, colorshqde_unit2: e.target.value })} />
                                <label style={{ marginLeft: 3 }}><b>Item ID :</b></label> <input type='text' name='ItemId_ink6_code2' value={datacolor6.Idcolor_unit2} disabled />
                            </div>
                        </div>
                        <div className='row-between-page'>
                            <label style={{ fontSize: 14 }}>FM .code </label>
                            <input type='text' name='fmcode6_unit2' style={{ width: 300 }}
                                value={datacolor6.fmcode_unit2}
                                onChange={(e) => setDatacolor6({ ...datacolor6, fmcode_unit2: e.target.value })} />
                        </div>
                        <div className='row-between-page'>
                            <label style={{ fontSize: 14 }}>Thickness /Density</label>
                            <div>Thickness :
                                <input type='text' name='thicknes6_unit2' style={{ width: 150 }}
                                    value={datacolor6.thicknes_unit2}
                                    onChange={(e) => setDatacolor6({ ...datacolor6, thicknes_unit2: e.target.value })} /></div>
                            <div>Density :
                                <input type='text' name='density6_unit2' style={{ width: 150 }}
                                    value={datacolor6.density_unit2}
                                    onChange={(e) => setDatacolor6({ ...datacolor6, density_unit2: e.target.value })} /></div>
                        </div>
                        <div className='row-between-page' >
                            <label style={{ fontSize: 14 }}>Temp. / UV lamp.</label>
                            <div>Temp :
                                <input type='text' name='temp6_unit2' style={{ width: 150 }}
                                    value={datacolor6.temp_unit2}
                                    onChange={(e) => setDatacolor6({ ...datacolor6, temp_unit2: e.target.value })} /></div>
                            <div>UV lamp.
                                <input type='text' name='uvlamp6_unit2' style={{ width: 150 }}
                                    value={datacolor6.uvlamp_unit2}
                                    onChange={(e) => setDatacolor6({ ...datacolor6, uvlamp_unit2: e.target.value })} /></div>
                        </div>
                        <div className='row-between-page'>
                            <label style={{ fontSize: 14 }}>Speed (sph)</label>
                            <input type='text' name='speed6_unit2'
                                value={datacolor6.speed_unit2}
                                onChange={(e) => setDatacolor6({ ...datacolor6, speed_unit2: e.target.value })}
                                style={{ width: 300 }} />
                        </div>
                    </div>
                </div>

                <hr></hr>
                {/* color 7 */}
                <div><b style={{ fontSize: 15 }}>Convent Ink 6 COlor (PT7)</b></div>
                <div className='row-between-page'>
                    {/*pass 1*/}
                    <div style={{ width: '60%', padding: 10 }} >
                        <div><b style={{ fontSize: 16 }}>#Pass 1. </b></div>
                        <div className='row-between-page'>
                            <label style={{ fontSize: 14 }}>Color shade</label>
                            <div className='row-between-page'>
                                <Popupitemgroupcolor OnChangDatacolor7={OnChangDatacolor7} typeink={'ink71'} />
                                <input type='text' name='colorshqde7_unit1' style={{ width: 300 }} value={datacolor7.colorshqde_unit1}
                                    onChange={(e) => setDatacolor7({ ...datacolor7, colorshqde_unit1: e.target.value })} />
                                <label style={{ marginLeft: 3 }}><b>Item ID :</b></label><input type='text' name='Idcolor7_unit1' value={datacolor7.Idcolor_unit1} disabled />
                            </div>
                        </div>
                        <div className='row-between-page'>
                            <label style={{ fontSize: 14 }}>FM .code </label>
                            <input type='text' name='fmcode7_unit1' style={{ width: 300 }}
                                value={datacolor7.fmcode_unit1}
                                onChange={(e) => setDatacolor7({ ...datacolor7, fmcode_unit1: e.target.value })} />
                        </div>
                        <div className='row-between-page'>
                            <label style={{ fontSize: 14 }}>Thickness /Density</label>
                            <div>Thickness :
                                <input type='text' name='thickness7_unit1' style={{ width: 150 }}
                                    value={datacolor7.thicknes_unit1}
                                    onChange={(e) => setDatacolor7({ ...datacolor7, thicknes_unit1: e.target.value })} /></div>
                            <div>Density :
                                <input type='text' name='density7_unit1' style={{ width: 150 }}
                                    value={datacolor7.density_unit1}
                                    onChange={(e) => setDatacolor7({ ...datacolor7, density_unit1: e.target.value })} /></div>
                        </div>
                        <div className='row-between-page' >
                            <label style={{ fontSize: 14 }}>Temp. / UV lamp.</label>
                            <div>Temp :
                                <input type='text' name='temp7_unit1' style={{ width: 150 }}
                                    value={datacolor7.temp_unit1}
                                    onChange={(e) => setDatacolor7({ ...datacolor7, temp_unit1: e.target.value })} /></div>
                            <div>UV lamp.
                                <input type='text' name='uvlamp7_unit1' style={{ width: 150 }}
                                    value={datacolor7.uvlamp_unit1}
                                    onChange={(e) => setDatacolor7({ ...datacolor7, uvlamp_unit1: e.target.value })} /></div>
                        </div>
                        <div className='row-between-page'>
                            <label style={{ fontSize: 14 }}>Speed (sph)</label>
                            <input type='text' name='speed7_unit1'
                                value={datacolor7.speed_unit1}
                                onChange={(e) => setDatacolor7({ ...datacolor7, speed_unit1: e.target.value })}
                                style={{ width: 300 }} />
                        </div>
                    </div>
                    {/*pass 2*/}
                    <div style={{ width: '60%', padding: 10 }} >
                        <div><b style={{ fontSize: 16 }}>#Pass 2. </b></div>
                        <div className='row-between-page'>
                            <label style={{ fontSize: 14 }}>Color shade</label>
                            <div className='row-between-page'>
                                <Popupitemgroupcolor OnChangDatacolor7_={OnChangDatacolor7_} typeink={'ink72'} />
                                <input type='text' name='colorshqde7_unit2' style={{ width: 300 }} value={datacolor7.colorshqde_unit2}
                                    onChange={(e) => setDatacolor7({ ...datacolor7, colorshqde_unit2: e.target.value })} />
                                <label style={{ marginLeft: 3 }}><b>Item ID :</b></label><input type='text' name='Idcolor7_unit2' value={datacolor7.Idcolor_unit2} disabled />
                            </div>
                        </div>
                        <div className='row-between-page'>
                            <label style={{ fontSize: 14 }}>FM .code </label>
                            <input type='text' name='fmcode7_unit2' style={{ width: 300 }}
                                value={datacolor7.fmcode_unit2}
                                onChange={(e) => setDatacolor7({ ...datacolor7, fmcode_unit2: e.target.value })} />
                        </div>
                        <div className='row-between-page'>
                            <label style={{ fontSize: 14 }}>Thickness /Density</label>
                            <div>Thickness :
                                <input type='text' name='thicknes7_unit2' style={{ width: 150 }}
                                    value={datacolor7.thicknes_unit2}
                                    onChange={(e) => setDatacolor7({ ...datacolor7, thicknes_unit2: e.target.value })} /></div>
                            <div>Density :
                                <input type='text' name='density7_unit2' style={{ width: 150 }}
                                    value={datacolor7.density_unit2}
                                    onChange={(e) => setDatacolor7({ ...datacolor7, density_unit2: e.target.value })} /></div>
                        </div>
                        <div className='row-between-page' >
                            <label style={{ fontSize: 14 }}>Temp. / UV lamp.</label>
                            <div>Temp :
                                <input type='text' name='temp7_unit2' style={{ width: 150 }}
                                    value={datacolor7.temp_unit2}
                                    onChange={(e) => setDatacolor7({ ...datacolor7, temp_unit2: e.target.value })} /></div>
                            <div>UV lamp.
                                <input type='text' name='uvlamp_unit2' style={{ width: 150 }}
                                    value={datacolor7.uvlamp_unit2}
                                    onChange={(e) => setDatacolor7({ ...datacolor7, uvlamp_unit2: e.target.value })} /></div>
                        </div>
                        <div className='row-between-page'>
                            <label style={{ fontSize: 14 }}>Speed (sph)</label>
                            <input type='text' name='speed7_unit2'
                                value={datacolor7.speed_unit2}
                                onChange={(e) => setDatacolor7({ ...datacolor7, speed_unit2: e.target.value })}
                                style={{ width: 300 }} />
                        </div>
                    </div>
                </div>
                <hr></hr>

                {/* color 8 */}
                <div><b style={{ fontSize: 16 }}>Digital print (PT8)# . </b></div>
                <div>
                    <div style={{ width: '60%', padding: 10 }} >
                        <div><b style={{ fontSize: 16 }}>#Pass 1. </b></div>
                        <div className='row-between-page'>
                            <label style={{ fontSize: 14 }}>Color shade</label>
                            <div className='row-between-page'>
                                <Popupitemgroupcolor OnChangDatacolor8={OnChangDatacolor8} typeink={'ink81'} />
                                <input type='text' name='colorshqde8_unit1' style={{ width: 300 }} value={datacolor8.colorshqde_unit1}
                                    onChange={(e) => setDatacolor8({ ...datacolor8, colorshqde_unit1: e.target.value })} />
                                <label style={{ marginLeft: 3 }}><b>Item ID :</b></label><input type='text' name='Idcolor8_unit1' value={datacolor8.Idcolor_unit1} disabled />
                            </div>
                        </div>
                        <div className='row-between-page'>
                            <label style={{ fontSize: 14 }}>FM .code </label>
                            <input type='text' name='fmcode8_unit1' style={{ width: 300 }}
                                value={datacolor8.fmcode_unit1}
                                onChange={(e) => setDatacolor8({ ...datacolor8, fmcode_unit1: e.target.value })} />
                        </div>
                        <div className='row-between-page'>
                            <label style={{ fontSize: 14 }}>Thickness /Density</label>
                            <div>Thickness :
                                <input type='text' name='thicknes8_unit1' style={{ width: 150 }}
                                    value={datacolor8.thicknes_unit1}
                                    onChange={(e) => setDatacolor8({ ...datacolor8, thicknes_unit1: e.target.value })} /></div>
                            <div>Density :
                                <input type='text' name='density8_unit1' style={{ width: 150 }}
                                    value={datacolor8.density_unit1}
                                    onChange={(e) => setDatacolor8({ ...datacolor8, density_unit1: e.target.value })} /></div>
                        </div>
                        <div className='row-between-page' >
                            <label style={{ fontSize: 14 }}>Temp. / UV lamp.</label>
                            <div>Temp :
                                <input type='text' name='temp8_unit1' style={{ width: 150 }}
                                    value={datacolor8.temp_unit1}
                                    onChange={(e) => setDatacolor8({ ...datacolor8, temp_unit1: e.target.value })} /></div>
                            <div>UV lamp.
                                <input type='text' name='uvlamp8_unit1' style={{ width: 150 }}
                                    value={datacolor8.uvlamp_unit1}
                                    onChange={(e) => setDatacolor8({ ...datacolor8, uvlamp_unit1: e.target.value })} /></div>
                        </div>
                        <div className='row-between-page'>
                            <label style={{ fontSize: 14 }}>Speed (sph)</label>
                            <input type='text' name='speed8_unit1'
                                value={datacolor8.speed_unit1}
                                onChange={(e) => setDatacolor8({ ...datacolor8, speed_unit1: e.target.value })}
                                style={{ width: 300 }} />
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default FormAddcolor;