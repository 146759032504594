import React, { useState, useEffect } from 'react'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import FetchApi from '../../../../customhooks/functionFetchApi_erp';
import { FaSearch } from "react-icons/fa";
import DataTable from 'react-data-table-component';


function TablePropertySolid({ data, hendleModel, SeelctPropertyList }) {


  const selectItem = (propertyName, solidnumber) => {
    SeelctPropertyList(propertyName, solidnumber)
    hendleModel(false)
  }

  const columns = [
    {
      name: 'Name Property ',
      sortable: true,
      selector: row => row.NAME,
    },
    {
      name: 'Solid ',
      grow: 2,
      selector: row => row.SWAN_SOLID,
    },
    {
      name: 'เลือกกุล่ม',
      selector: row => <div>
        <Button variant="primary" size='sm' onClick={() => selectItem(row.NAME, row.SWAN_SOLID)}>เลือก</Button>

      </div>,
    },
  ];

  const customStyles = {
    headRow: {
      style: {
        border: 'none',
        backgroundColor: '#28B463'
      },
    },
    headCells: {
      style: {
        color: '#ffff',
        fontSize: '14px',
      },
    },
    // rows: {
    //     highlightOnHoverStyle: {
    //         backgroundColor: 'rgb(230, 244, 244)',
    //         borderBottomColor: '#FFFFFF',
    //         borderRadius: '25px',
    //         outline: '1px solid #FFFFFF',
    //     },
    // },
    // pagination: {
    //     style: {
    //         border: 'none',
    //     },
    // },
  };

  return (
    <DataTable
      columns={columns}
      data={data}
      // selectableRows
      pagination
      customStyles={customStyles}
      highlightOnHover

    />
  )

}






function Popupsolidlist({ SeelctPropertyList }) {
  const FetchApis = new FetchApi();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [solidList, setSolidList] = useState([]);
  const [textSearch, setTextSearch] = useState([]);

  useEffect(() => {

    getapilist()

  }, [])

  const hendleModel = (value) => {
    setShow(false)
  }

  const getapilist = () => {

    FetchApis.FethcGet(`gotetPropertySolid`).then((res) => {
      if (res.status == 200) {

        setSolidList(res.data.listall)
        setTextSearch(res.data.listall);
      }
    })
  }

  const onSearchText = (datavalue) => {

    const filteredData = textSearch.filter((item) => {
      return Object.values(item).join('').toLowerCase().includes(datavalue.toLowerCase())
    })

    setSolidList(filteredData)
  }

  return (
    <div>
      <FaSearch variant="primary" onClick={handleShow} size={20}>
        Launch demo modal
      </FaSearch>

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Item list</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Control type="text" placeholder="ค้นหา ป้อนข้อมูล" size='sm' onChange={(e) => onSearchText(e.target.value)} />
            </Form.Group>
          </Form>

          {
            solidList.length > 0 && <TablePropertySolid data={solidList} hendleModel={hendleModel} SeelctPropertyList={SeelctPropertyList} />
          }

        </Modal.Body>
      </Modal>
    </div>
  )
}

export default Popupsolidlist