import FetchApi from "../../../../customhooks/functionFetchApi";
import FetchApiErp from "../../../../customhooks/functionFetchApi_erp";
const FetchApis = new FetchApi();
const FetchApiErps = new FetchApiErp();
// ตรวจสอบว่ามีข้อมูลซ้ำหรือป่าวก่อน ทำการบันทึก

export function CreateNewItemTo(datanewItem) {

    if (datanewItem.proBody.length == 1) {

        if (datanewItem.proBody[0].DraftFG) {
            CreateItemToTable(datanewItem.proBody[0].DraftFG)
        }

        if (datanewItem.proBody[0].data_objectW != "") {
            CreateItemToTable(datanewItem.proBody[0].data_objectW)
        }


        if (datanewItem.proBody[0].data_objectCE) {
            CreateItemToTable(datanewItem.proBody[0].data_objectCE)
        }
    }

    if (datanewItem.proTop.length == 1) {

        if (datanewItem.proTop[0].DraftFG) {
            CreateItemToTable(datanewItem.proTop[0].DraftFG)
        }

        if (datanewItem.proTop[0].data_objectW != "") {
            CreateItemToTable(datanewItem.proTop[0].data_objectW)

        }

        if (datanewItem.proTop[0].data_objectCE) {
            CreateItemToTable(datanewItem.proTop[0].data_objectCE)
        }
    }

    if (datanewItem.proBottom.length == 1) {

        if(datanewItem.proBottom[0].DraftFG){
            CreateItemToTable(datanewItem.proBottom[0].DraftFG)
        }

        if(datanewItem.proBottom[0].data_objectW!=""){
            CreateItemToTable(datanewItem.proBottom[0].data_objectW)
        }
        
        if (datanewItem.proBottom[0].data_objectCE) {
            CreateItemToTable(datanewItem.proBottom[0].data_objectCE)
          
        }
    }

    if (datanewItem.proTab.length == 1) {
        
        if(datanewItem.proTab[0].DraftFG){
            CreateItemToTable(datanewItem.proTab[0].DraftFG)
        }

        if(datanewItem.proTab[0].data_objectW !=""){
            CreateItemToTable(datanewItem.proTab[0].data_objectW)
        }

        if (datanewItem.proTab[0].data_objectCE) {
            CreateItemToTable(datanewItem.proTab[0].data_objectCE)
        }
    }


}

// update mysql
export function UpdateItem(itemall) {

    FetchApis.FethcUpdate(`/newitem/updateNewitem/${itemall.newItemId}`, itemall).then((res) => {
        console.log(res.status);
    })


}

// insert to mysql 
function CreateItemToTable(data) {
    FetchApis.FethcPost(`/newitem/createItem`, data).then((res) => { console.log(res.status) }).catch(err => console.log(err))

}

// รับค่าเพื่อตรวจสอบข้อมูล ก่อนบันทึกเข้า sql server erp
export function CreateNewItemTOerp(idJour) {

    FetchApis.FethcGet(`/newitem/getnewitemByJournalId/${idJour}`).then((res) => {
        if (res.status == 200) {

            if (res.status == 200) {
                var jourlist = res.data.data

                if (jourlist.length >= 1) {
                    InsertToERP(jourlist[0])
                    InsertToERP(jourlist[1])
                }

            }
        }
    })
}
// insert to qsl server erp
function InsertToERP(data) {

    let dataobjec = {
        ItemGroupID: data.itemegroup,
        ItemID: data.itemId,
        ItemName: data.itemname,
        DimGroupId: data.dimensiongroup,
        PackagingGroupId: data.packinggroup,
        InventUnit: data.inventtoryUnit,
        PurchaseUnit: data.purchaseUnit,
        SalesUnit: data.saleUnit,
        VBS_RAFJournalNameId: data.raf_journalname,
        VBS_YYMMGen: data.yymm,
        VBS_SwanJobGen: data.swanjob,
        VBS_QtyPerTag: data.qtypertag,
        TDSnumber: data.tdscode
    }

    FetchApiErps.FethcPostLineApi(`TdsNewItemERP/createNewitem`, dataobjec).then((res) => console.log(res)).catch(err => console.log(err))
}
