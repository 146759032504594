import React, { useState, useEffect } from 'react'
import FetchApi from '../../customhooks/functionFetchApi'
import Table from 'react-bootstrap/Table';


const ViewNewItem = ({ jourId }) => {

  const FetchApis = new FetchApi();

  const [itemproductall, setItemproductall] = useState([])

  useEffect(() => {
    CheckItemCreate()
  }, [])

  function CheckItemCreate() {

    FetchApis.FethcGet(`/newitem/getnewitemByJournalId/${jourId}`).then((res) => {
    
      if (res.status == 200) {
        if (res.data.data.length > 0) {
          var itemlist = res.data.data;
        

          setItemproductall(itemlist);
        }
      }
    })
  }

  return (
    <div>

      <label style={{fontSize:18}}><b>รายการ New Item  {itemproductall.length} รายการ</b></label>
      <div style={{ marginTop: 5 }}>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>bodytype</th>
              <th>typeItem</th>
              <th>Item Group</th>
              <th>Item</th>
              <th>Item Name</th>
              <th>Dimension Group</th>
              <th>Packing Group</th>
              <th>RAF Jouranl</th>
              <th>Serail Number Qty / Tag</th>
              <th>Inventory Unit</th>
              <th>Purchase Unit</th>
              <th>Sale Unit</th>

            </tr>
          </thead>
          <tbody>
            {itemproductall.map((item, i) => (
              <tr>
                <td>{i + 1}</td>
                <td>{item.bodytype}</td>
                <td>{item.typeItem}</td>
                <td>{item.itemegroup}</td>
                <td>{item.itemId}</td>
                <td>{item.itemname}</td>
                <td>{item.dimensiongroup}</td>
                <td>{item.packinggroup}</td>
                <td>{item.raf_journalname}</td>
                <td>{item.qtypertag}</td>
                <td>{item.inventtoryUnit}</td>
                <td>{item.purchaseUnit}</td>
                <td>{item.saleUnit}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  )
}

export default ViewNewItem