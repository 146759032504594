import React, { useState, useEffect } from 'react'
import FetchApi from '../../../customhooks/functionFetchApi'
import { FaExclamationTriangle, FaCheck } from "react-icons/fa";

const Statusall = ({ id, statusflownow, stateflow, stateSuccesfull }) => {
    const FetchApis = new FetchApi();
    const [journallist, setJournallist] = useState([])

    useEffect(() => {
        loadingdata();
    }, []);

    async function loadingdata() {
        await FetchApis.FethcGet(`/journal/getStatejournalById/${id}`).then((res) => {
            if (res) {
                setJournallist(res.data.data)
                // console.log(res.data.data)
            }
        })
    }
    if (journallist.length == 0) {
        <div></div>
    } else {
        return (
            <div className='row-page'>
                {stateflow > stateSuccesfull ? <div><label style={{ color: 'green' }}><FaCheck /> เสร็จเรียบร้อย</label></div> : <div>
                    {statusflownow == '0000' ? <div><FaExclamationTriangle color='red' /> <label style={{ color: 'red' }}> ยกเลิก</label></div> : <div className='row-page'>
                        {journallist.map((item, i) => (
                            <div>
                                {item.active == 1 ?
                                    <div style={{
                                        color: 'green', textTransform: 'uppercase', borderRadius: 100,
                                        backgroundColor: 'green', width: 10 + i, height: 10 + i, marginRight: 3
                                    }}>
                                    </div>
                                    : <div style={{
                                        color: 'red', borderRadius: 100, textTransform: 'uppercase',
                                        backgroundColor: 'red', width: 10 + i, height: 10 + i, marginRight: 3
                                    }}>
                                    </div>}


                            </div>
                        )
                        )}
                    </div>
                    }
                </div>}
            </div>
        )
    }
}

export default Statusall