import React, { useState, useEffect } from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useSelector, useDispatch } from 'react-redux'
import { additemExt, additemInt, additemSpray, additemCopper, additemLacq } from '../../../../../reduxSlice/productionsystemSlice/productionSlice';


function FormAddPrinting(props) {
  const dispatch = useDispatch();

  const productExt = useSelector((state) => state.product.dataext);
  const productInt = useSelector((state) => state.product.dataint);
  const productSpray = useSelector((state) => state.product.dataspray);
  const productCopper = useSelector((state) => state.product.datacopper);
  const productLacq = useSelector((state) => state.product.datallacq);

  const ojectdata = {
    protype: props.Nametype, sideitemnumber: '', sidename: '',
    sidewfw: '', com1itemnumber: '', com1name: '',
    com1wfw: '', com2itemnumber: '', com2name: '',
    com2wfw: '', journalId: props.jourId
  }

  const [prodataall, setProdataall] = useState({ ...ojectdata }) // sub detial

  useEffect(() => {

    setProdataall({ ...prodataall, journalId: props.jourId });

    if (productExt != '' && props.typePro == 'pro_ext') {

      setProdataall(productExt)
    }

    else if (productInt != '' && props.typePro === 'pro_int') {
      setProdataall(productInt)
    }

    else if (productSpray != '' && props.typePro === 'pro_spray') {

      setProdataall(productSpray)
    }
    else if (productCopper != '' && props.typePro === 'pro_copper') {
      setProdataall(productCopper)
    }

    else if (productLacq != "" && props.typePro == 'pro_lacq') {
      setProdataall(productLacq)
    }

  }, [props.jourId])



  function Saveform() {
    props.Saveheader();

    if (props.typePro == 'pro_ext') {

      dispatch(additemExt(prodataall))
      props.setShow(false)
    }
    else if (props.typePro === 'pro_int') {

      dispatch(additemInt(prodataall))
      props.setShow(false)
    }
    else if (props.typePro === 'pro_spray') {

      dispatch(additemSpray(prodataall))
      props.setShow(false)
    }
    else if (props.typePro === 'pro_copper') {

      dispatch(additemCopper(prodataall))
      props.setShow(false)

    } else if (props.typePro == 'pro_lacq') {
      dispatch(additemLacq(prodataall))
      props.setShow(false)
    }
    else if (props.typePro == '') {
      alert('คุณยังไม่ได้เลือก ประเภทในการบันทึก')
    }

  }


  function removeitem() {
    if (props.typePro == 'pro_ext') {
      setProdataall(ojectdata)
      dispatch(additemExt(""))
    }
    else if (props.typePro === 'pro_int') {
      setProdataall(ojectdata)
      dispatch(additemInt(""))
    }
    else if (props.typePro === 'pro_spray') {
      setProdataall(ojectdata)
      dispatch(additemSpray(""))
    }
    else if (props.typePro === 'pro_copper') {
      setProdataall(ojectdata)
      dispatch(additemCopper(""))
    }
    else if (props.typePro === 'pro_lacq') {
      setProdataall(ojectdata)
      dispatch(additemLacq(""))
    }

  }

  return <div>
    {prodataall.journalId != '' ?
      <form>
        
        <div className='row-page'>
          <div style={{ width: '35%' }}>
            <input type='text' value={props.jourId} name='journid' hidden />
            <div>
              <label style={{ fontSize: 14 }}><b>Side Seams</b></label>
            </div>
            <div className='row-page'>
              <div style={{ width: 120 }}><label>Item Number</label></div>
              <input type='text' name='sideitemnumber'
                onChange={(e) => setProdataall({ ...prodataall, sideitemnumber: e.target.value })}
                value={prodataall.sideitemnumber}
                style={{ width: 250 }} />
            </div>
            <div className='row-page'>
              <div style={{ width: 120 }}><label>Name</label></div>
              <input type='text' name='sidename'
                onChange={(e) => setProdataall({ ...prodataall, sidename: e.target.value })}
                value={prodataall.sidename}
                style={{ width: 200 }} />
            </div>
            <div className='row-page'>
              <div style={{ width: 120 }}><label>WFW (kg /1,000 pcs)</label></div>
              <input type='number' name='sidewfw' onChange={(e) => setProdataall({ ...prodataall, sidewfw: e.target.value })}
                value={prodataall.sidewfw}
              />
            </div>
          </div>



          <div style={{ width: '35%' }}>

            <div>
              <label style={{ fontSize: 14 }}><b>Compound</b></label>
            </div>

            <div className='row-page'>
              <div style={{ width: 120 }}><label>Item Number</label></div>
              <input type='text' name='com1itemnumber'
                onChange={(e) => setProdataall({ ...prodataall, com1itemnumber: e.target.value })}
                value={prodataall.com1itemnumber}
                style={{ width: 250 }} />
            </div>
            <div className='row-page'>
              <div style={{ width: 120 }}><label>Name</label></div>
              <input type='text' name='com1name'
                onChange={(e) => setProdataall({ ...prodataall, com1name: e.target.value })}
                value={prodataall.com1name}
                style={{ width: 200 }} />
            </div>
            <div className='row-page'>
              <div style={{ width: 120 }}><label>WFW (kg /1,000 pcs)</label></div>
              <input type='number' name='com1wfw' onChange={(e) => setProdataall({ ...prodataall, com1wfw: e.target.value })}
                value={prodataall.com1wfw}
              />
            </div>
          </div>


          <div style={{ width: '35%' }}>
            <div>
              <label style={{ fontSize: 14 }}><b>Compound</b></label>
            </div>

            <div className='row-page'>
              <div style={{ width: 120 }}><label>Item Number</label></div>
              <input type='text' name='com2itemnumber'
                onChange={(e) => setProdataall({ ...prodataall, com2itemnumber: e.target.value })}
                value={prodataall.com2itemnumber}
                style={{ width: 250 }} />
            </div>
            <div className='row-page'>
              <div style={{ width: 120 }}><label>Name</label></div>
              <input type='text' name='com2name'
                onChange={(e) => setProdataall({ ...prodataall, com2name: e.target.value })}
                value={prodataall.com2name}
                style={{ width: 200 }} />
            </div>
            <div className='row-page'>
              <div style={{ width: 120 }}><label>WFW (kg /1,000 pcs)</label></div>
              <input type='number' name='com2ewfw' onChange={(e) => setProdataall({ ...prodataall, com2wfw: e.target.value })}
                value={prodataall.com2wfw}
              />
            </div>
          </div>
        </div>

        <hr></hr>
        <div>
          <center>
            <Button onClick={() => Saveform()}>บันทึกข้อมูล {props.Nametype}</Button>{" "}
            <Button variant="warning" type='reset' onClick={() => removeitem()}>ลบข้อมูล</Button>
          </center>
        </div>
      </form> : <div></div>
    }

  </div>
}


const PopupAddPrintingsystem = (props) => {

  const [show, setShow] = useState(false);
  const [Nametype, setNametype] = useState("")
  const [LableName, setLableName] = useState("")

  useEffect(() => {

    if (props.typePro == 'pro_ext') {
      setNametype("ext")
      setLableName("Ext.")
    }
    else if (props.typePro === 'pro_int') {
      setNametype("int")
      setLableName("Int.")
    }
    else if (props.typePro === 'pro_spray') {
      setNametype("spray")
      setLableName("Spray lacq.")
    }
    else if (props.typePro === 'pro_copper') {
      setNametype("copper")
      setLableName("Copper Wire (kg./1,000 pcs )")
    } else if (props.typePro == 'pro_lacq') {
      setNametype("lacq")
      setLableName("Lacq Stemp rivet (kg./1,000 pcs)")
    }
    else if (props.typePro == '') {

    }
  }, [])


  return (
    <div><>
      <Button variant="primary" size='sm' onClick={() => setShow(true)}>
        เพิ่ม {Nametype}
      </Button>

      <Modal show={show} fullscreen={true} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>เพิ่มข้อมูล {LableName} </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormAddPrinting
            typePro={props.typePro}
            jourId={props.journalId}
            Saveheader={props.Saveheader}
            Nametype={Nametype}
            setShow={setShow} />
        </Modal.Body>
      </Modal>
    </></div>
  )
}

export default PopupAddPrintingsystem