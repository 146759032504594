export const jouranllist = {
    journalId: null,
    journaltype: "",
    duedate: "",
    tdscode: "",
    createdate: "",
    customername: "",
    contact: "",
    tel: "",
    productname: "",
    itemname: "",
    itemcode: "",
    cansize: "",
    substratetype: "",
    productgrouptype: "",
    informationtype: "",
    informationnote: "",
    printingtype: "",
    filmcode: "",
    filmtype: "",
    createby: "",
    createdatetime: ""
}

export const statusflowall={
    draftjournal:'1001',
    newjournal:'1002',
    sendjournal:'1003',
    recievejournal:'1004',
    approvejournal:'1005',
    rejectjournal:'2001',
    deligatejournal:'3001',
    recalljouranl:'0000',
    recallsendjouranl:'4001'    
}