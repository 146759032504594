import React, { useEffect, useState } from 'react'
import Tablejournallistrecieved from './components/componentjournallistrecieved/tablejournallistrecieved'
import FetchApi from '../customhooks/functionFetchApi'
import { FaFileSignature } from "react-icons/fa";
import Spinner from 'react-bootstrap/Spinner';

const Journallistrejectstatus = () => {
  const FetchApis = new FetchApi();
  const emcdoes = sessionStorage.getItem('EmCode');
  const [loading, setLoading] = useState(false);
  const [journallist, setJournallist] = useState([]);

  useEffect(() => {
    getlistreject();
  }, [])

  const getlistreject = () => {
    setLoading(true);

    setTimeout(() => {
      FetchApis.FethcGet(`/journal/getjournalrejectRunflowByEmcode/${emcdoes}`).then((res) => {
        if (res.status == 200) {
          setJournallist(res.data.data);
        }
        setLoading(false);
      })
    }, 1000);

  }

  return (
    <div>
      <div style={{ backgroundColor: '#E5E7E7' }}>

        <label style={{ fontSize: 20, margin: 5, padding: 5 }}>
          <FaFileSignature /> รายการตีกลับ
        </label>

      </div>
      <div>
        {loading == true ? <div className='body-center-page'><Spinner
          as="span"
          animation="grow"
          variant="success"
          size={200}
          role="status"
          aria-hidden="true" /> LOADIN ....</div> : <div>
          <Tablejournallistrecieved values={journallist} /></div>}
      </div>

    </div>
  )
}

export default Journallistrejectstatus