import React from 'react'
import Deleteflowsystemsub from './deleteflowsystemsub';
import Editflowsystemsub from './editflowsystemsub';

const Actionflowsystemsub = ({Id}) => {
  return (
    <div className='row-between-page'>
        <div style={{marginRight:10}}><Editflowsystemsub Id={Id}/></div>
        <div><Deleteflowsystemsub Id={Id}/></div>
    </div>
  )
}

export default Actionflowsystemsub