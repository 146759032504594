import React, { useState, useEffect } from 'react'
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import FetchApi from '../../../customhooks/functionFetchApi';
import { ToastContainer, toast } from 'react-toastify';
import Swal from 'sweetalert2';
import PlanApprove from './planApprove';
import EditNewitemToerp from './editNewitemToerp';
import PopupItemList from './popupItemList';
import PopupitemWcList from './popupitemWcList';
import Showdetailjournal from '../../../form01/components/showdetailjournal';

const datasub = {
  spectype: "",
  sheetsize: "",
  flatscroll: "",
  straightscroll: "",
  tin: "",
  temper: "",
  bright: "",
  row: "",
  filmcode: "",
  itemno: "",
  refitemwc: "",
  journalId: "",
  tdscode: "",
  specId: ""

}


function FormEditJournal({ jourId, stateflow, handleShow }) {


  const FetchApis = new FetchApi()
  const [key, setKey] = useState('jouranl');

  // add substrate new 
  const [substratebody, setSubstrateBody] = useState([]);
  const [substrateTop, setSubstrateTop] = useState([]);
  const [substrateBottom, setSubstrateBottom] = useState([]);
  const [substrateTab, setSubstrateTab] = useState([]);

  const [adddatatype, setAdddatatype] = useState({ ...datasub })
  const [componentCode, setComponentCode] = useState({
    ear: "",
    earwelding: "",
    ring: "",
    innerlid: "",
    outerlid: "",
    toplid: "",
    bottomlid: "",
    plasticlid: "",
    aluminumlid: "",
    journalId: "",
    tdscode: ""
  })

  useEffect(() => {
    var codeid = "";
    FetchApis.FethcGet(`/journal/journalbyId/${jourId}`).then((datafetch) => {
      if (datafetch.data.status == 200) {

        codeid = datafetch.data.data[0].tdscode;

        getcomponentcode(jourId, codeid);

        setAdddatatype({
          ...adddatatype, tdscode: codeid,
          journalId: jourId
        })

        setComponentCode({
          ...componentCode, tdscode: codeid,
          journalId: jourId
        })

        getSubstrateAll();


      }
    })
  }, [])


  async function getSubstrateAll() {

    await FetchApis.FethcGet(`/specplanning/specplanningByJournalId/${jourId}`).then((datacoating) => {
      if (datacoating.data.status == 200) {

        for (let index = 0; index < datacoating.data.data.length; index++) {
          // console.log(datacoating.data.data)
          if (datacoating.data.data[index].spectype == 'body') {
            setSubstrateBody(datacoating.data.data[index]);

          }
          else if (datacoating.data.data[index].spectype == 'top') {
            setSubstrateTop(datacoating.data.data[index])

          }
          else if (datacoating.data.data[index].spectype == 'bottom') {
            setSubstrateBottom(datacoating.data.data[index])

          }
          else if (datacoating.data.data[index].spectype == 'tab') {
            setSubstrateTab(datacoating.data.data[index])

          }
        }
      }
    })

  }

  async function getcomponentcode(Id, codeid) {

    await FetchApis.FethcGet(`/componentcode/componentByjournalId/${Id}`).then((res) => {
      if (res.status == 200) {
        if (res.data.data.length > 0) {
          setComponentCode(res.data.data[0]);
          // setcheckcomponentcode(true)
        }
        else {
          // setcheckcomponentcode(false);
          console.log(res.data.data[0])
          setComponentCode({
            ...componentCode,
            tdscode: codeid,
            journalId: jourId
          })
        }
      }
    })
  }




  // บันทึกข้อมูล
  const submitform = (e) => {
    e.preventDefault();

    Swal.fire({
      title: "แก้ไขเอกสาร",
      text: "คุณแน่ใจที่บันทึกแก้ไขเอกสาร ใช่หรือไม่ ! ",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ใช่",
      cancelButtonText: 'ยกเลิก'
    }).then((result) => {
      if (result.isConfirmed) {

        toast.success('บันทึกเรียบร้อยแล้ว', {
          position: "top-right",
          autoClose: 1300,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          // transition: Bounce,
        });

        if (substratebody != "") {
          if (substratebody.sheetsize != "" || substratebody.itemno != "") {
            FetchApis.FethcUpdate(`/specplanning/updatespecplanning/${substratebody.specId}`, substratebody)
          }

        }

        if (substrateTop != "") {
          if (substrateTop.sheetsize != "" || substrateTop.itemno != "") {
            FetchApis.FethcUpdate(`/specplanning/updatespecplanning/${substrateTop.specId}`, substrateTop)
          }

        }

        if (substrateBottom != "") {
          if (substrateBottom.sheetsize != "" || substrateBottom.itemno != "") {
            FetchApis.FethcUpdate(`/specplanning/updatespecplanning/${substrateBottom.specId}`, substrateBottom)
          }

        }

        if (substrateTab != "") {
          if (substrateTab.sheetsize != "" || substrateTab.itemno != "") {
            FetchApis.FethcUpdate(`/specplanning/updatespecplanning/${substrateTab.specId}`, substrateTab)
          }
        }

        // update component code 
        FetchApis.FethcUpdate(`/componentcode/updatecomponentcode/${componentCode.comId}`, componentCode).then((res) => {

          if (res.status != 200) {
            alert(res.message)
          }
        }).catch((err) => { console.log(err) })

        setTimeout(() => {
          handleShow(false)
        }, 1500);

      }
    });


  }


  //เลือกข้อมูลประเภท ในการแก้ไข แล้วนำมาแสดงให้ text input
  const onchangeTraget = (e) => {
    var typesubstrate = e.target.value;
    setAdddatatype({ ...adddatatype, spectype: typesubstrate })

    if (typesubstrate == 'body') {
      setAdddatatype({ ...substratebody })
      //   setSubstrateBody({ ...adddatatype })
    }
    else if (typesubstrate == 'top') {
      setAdddatatype({ ...substrateTop })
    }
    else if (typesubstrate == 'bottom') {
      setAdddatatype({ ...substrateBottom })
    }
    else if (typesubstrate == 'tab') {
      setAdddatatype({ ...substrateTab })
    }


  }

  // เพิ่มข้อมูลในตารางเพื่อนำมาแสดงก่อนทำการ บันทึกเข้าดาต้่าเบส
  const addItemsubStrate = () => {

    if (adddatatype.spectype == 'body') {
      setSubstrateBody({ ...adddatatype })
    }
    else if (adddatatype.spectype == 'top') {
      setSubstrateTop({ ...adddatatype })
    }
    else if (adddatatype.spectype == 'bottom') {
      setSubstrateBottom({ ...adddatatype })
    }
    else if (adddatatype.spectype == 'tab') {
      setSubstrateTab({ ...adddatatype })
    }
    else {
      alert('ไม่มีข้อมูลนี้ในการแก้ไข')
    }

  }

  function selectItemId(itemnames) {
    console.log(itemnames)
    setAdddatatype({
      ...adddatatype,
      itemno: itemnames
    })

  }

  function selectItemWC(itemWc) {

    setAdddatatype({
      ...adddatatype,
      refitemwc: itemWc
    })
  }


  function removeDataList() {

    if (adddatatype.spectype == 'body') {
      delatespecplanning(substratebody.specId)
    }
    else if (adddatatype.spectype == 'top') {
      delatespecplanning(substrateTop.specId)
    }
    else if (adddatatype.spectype == 'bottom') {
      delatespecplanning(substrateBottom.specId)
    }
    else if (adddatatype.spectype == 'tab') {
      delatespecplanning(substrateTab.specId)
    }
    else {
      alert('ไม่มีข้อมูลนี้ในการแก้ไข')
    }
  }

  function delatespecplanning(Id) {
    Swal.fire({
      title: "แก้ไขเอกสาร",
      text: "คุณแน่ใจที่จะลบข้อมูล ใช่หรือไม่ ! ",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ใช่",
      cancelButtonText: 'ยกเลิก'
    }).then((result) => {
      if (result.isConfirmed) {

        FetchApis.FethcDelete(`/specplanning/deleteById/${Id}`).then((res) => {
          if (res.status != 200) {
            console.log(res.message)
          } else {
            if (adddatatype.spectype == 'body') {
              setSubstrateBody(datasub)
            }
            else if (adddatatype.spectype == 'top') {
              setSubstrateTop(datasub)
            }
            else if (adddatatype.spectype == 'bottom') {
              setSubstrateBottom(datasub)
            }
            else if (adddatatype.spectype == 'tab') {
              setSubstrateTab(datasub)
            }

          }
        })

      }

    })



  }


  return (
    <div className='form-body-page'>
      <form onSubmit={(e) => submitform(e)} >
        <div className='end-flex-row'>
          <Button type='submit' variant="primary" style={{ marginRight: 10, fontSize: 12 }}>บันทึกแก้ไขข้อมูล</Button>
          {/* <Button type='reset' variant="warning" style={{ marginRight: 10, fontSize: 12 }}>ลบข้อมูล</Button> */}
          <PlanApprove idjournal={jourId} stateflow={stateflow} />
        </div>

        <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k) => setKey(k)}
          className="mb-3"

        >
          <Tab eventKey="jouranl" title="Form ( F-MK-009A1-1 ) ตรวจสอบข้อมูลก่อนบันทึก" style={{ backgroundColor: 'aliceblue' }}>
            <Showdetailjournal id={jourId} />
          </Tab>
          <Tab eventKey="body" title="แก้ไขข้อมูล Spec" style={{ backgroundColor: 'aliceblue' }}>

            <div className='row-between-page' style={{ width: 500, backgroundColor: '#EEEDEB', padding: 5, borderRadius: 5 }}>

              <div>
                <input type='radio' name='typesub' value={'body'} onClick={(e) => onchangeTraget(e)} /> Body
              </div>
              <div>
                <input type='radio' name='typesub' value={'top'} onClick={(e) => onchangeTraget(e)} /> Top end
              </div>
              <div>
                <input type='radio' name='typesub' value={'bottom'} onClick={(e) => onchangeTraget(e)} /> Botton end
              </div>
              <div>
                <input type='radio' name='typesub' value={'tab'} onClick={(e) => onchangeTraget(e)} /> Tab
              </div>
              <div>
                <Button variant="primary" size='sm' onClick={addItemsubStrate} style={{ marginRight: 20 }}>อัพเดทข้อมูล</Button>
                <Button variant="warning" size='sm'
                  onClick={() => removeDataList()} style={{ marginRight: 20 }}>ลบข้อมูล</Button>
              </div>
            </div>
            <div>
            </div>

            <div className='row-between-page' style={{ width: '100%' }}>
              <div style={{ width: '60%', padding: 5 }} className='row-between-page border_green'>

                <div style={{ width: '50%' }}>
                  <div style={{ width: '100%' }} className='row-between-page'>
                    <div>Sheet size (T x W x L)</div>
                    <div><input type='text' name='Sheetsize' value={adddatatype.sheetsize} onChange={(e) => setAdddatatype({ ...adddatatype, sheetsize: e.target.value })} style={{ width: 250 }} /></div>
                  </div>
                  <div style={{ width: '100%' }} className='row-between-page'>
                    <div>Straight / Scoll </div>
                    <div style={{ width: '30%' }} >
                      <div>
                        <input type='radio' name='StraightScoll' value={'Straight'}
                          onChange={(e) => setAdddatatype({ ...adddatatype, straightscroll: e.target.value })} /> <label>Straight</label>
                      </div>
                      {" "}
                      <div>
                        <input type='radio' name='StraightScoll' value={'Scoll'}
                          onChange={(e) => setAdddatatype({ ...adddatatype, straightscroll: e.target.value })} /> <label>Scoll </label>
                      </div>
                      {" "}
                      <div>
                        <input type='radio' name='StraightScoll' value={'Coil Feed'}
                          onChange={(e) => setAdddatatype({ ...adddatatype, straightscroll: e.target.value })} /> <label> Coil Feed</label>
                      </div>
                      {" "}
                      <div>
                        <input type='radio' name='StraightScoll' value={'Coil Tab'}
                          onChange={(e) => setAdddatatype({ ...adddatatype, straightscroll: e.target.value })} /> <label> Coil Tab</label>
                      </div>
                      {" "}
                      {/* <div>
                        <input type='radio' name='StraightScoll' value={'Flat'}
                          onChange={(e) => setAdddatatype({ ...adddatatype, straightscroll: e.target.value })} /> <label> Flat</label>
                      </div> */}

                    </div>

                  </div>
                  <div style={{ width: '100%' }} className='row-between-page'>
                    <div>Tin coating /Alloy </div>
                    <div><input type='text' name='Tincoating' value={adddatatype.tin}
                      onChange={(e) => setAdddatatype({ ...adddatatype, tin: e.target.value })} style={{ width: 150 }} /></div>
                  </div>
                  <div style={{ width: '100%' }} className='row-between-page'>
                    <div>Temper / Hardness</div>
                    <div><input type='text' name='TemperHardness' value={adddatatype.temper}
                      onChange={(e) => setAdddatatype({ ...adddatatype, temper: e.target.value })} style={{ width: 150 }} /></div>
                  </div>
                </div>

                <div style={{ width: '50%', marginLeft: 5 }}>
                  <div style={{ width: '100%' }} className='row-between-page'>
                    <div>Bright / Stone / Matte</div>
                    <div><input type='text' name='BrightStone' value={adddatatype.bright}
                      onChange={(e) => setAdddatatype({ ...adddatatype, bright: e.target.value })} style={{ width: 150 }} /></div>
                  </div>
                  <div style={{ width: '100%' }} className='row-between-page'>
                    <div>Row x Layer</div>
                    <div><input type='text' name='RowLayer' value={adddatatype.row}
                      onChange={(e) => setAdddatatype({ ...adddatatype, row: e.target.value })} style={{ width: 150 }} /></div>
                  </div>
                  <div style={{ width: '100%' }} className='row-between-page'>
                    <div>Film code</div>
                    <div>
                      <input type='text' name='FilmCode' value={adddatatype.filmcode}
                        onChange={(e) => setAdddatatype({ ...adddatatype, filmcode: e.target.value })}
                        style={{ width: 150 }} /></div>
                  </div>

                  <div style={{ width: '100%' }} className='row-between-page'>
                    <div>Item No.</div>
                    <div className='row-between-page'>
                      <PopupItemList selectItemId={selectItemId} />
                      <input type='text' name='ItemNo' value={adddatatype.itemno}
                        onChange={(e) => setAdddatatype({ ...adddatatype, itemno: e.target.value })} style={{ width: 250 }} /></div>
                  </div>

                  <div style={{ width: '100%' }} className='row-between-page'>
                    <div>Ref. Item WC</div>

                    <div className='row-between-page'>
                      <PopupitemWcList selectItemWC={selectItemWC} />
                      <input type='text' name='refitemwc'
                        value={adddatatype.refitemwc}
                        onChange={(e) => setAdddatatype({ ...adddatatype, refitemwc: e.target.value })}
                        style={{ width: 250, marginLeft: 5 }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div style={{ marginLeft: '10%', backgroundColor: '#F0F0F0', padding: 10, borderRadius: 5 }}>
                <center><div style={{ backgroundColor: '#989898' }}>เพิ่ม Component Code</div></center>
                <div className='row-page' style={{ marginTop: 10 }}>
                  <div style={{ width: 100 }}>หูอ๊อก/ถ้วยอ๊อก</div>
                  <div>
                    <input type='text' name='Earwelding'
                      defaultValue={componentCode.earwelding}
                      onChange={(e) => setComponentCode({ ...componentCode, earwelding: e.target.value })}
                      style={{ width: 300 }} />
                  </div>
                </div>
                <div className='row-page' style={{ marginTop: 10 }}>
                  <div style={{ width: 100 }}>หู</div>
                  <div>
                    <input type='text' name='Ear'
                      defaultValue={componentCode.ear}
                      onChange={(e) => setComponentCode({ ...componentCode, ear: e.target.value })}
                      style={{ width: 300 }} />
                  </div>
                </div>
                <div className='row-page' style={{ marginTop: 10 }}>
                  <div style={{ width: 100 }}>ห่วง</div>
                  <div>
                    <input type='text' name='Ring'
                      defaultValue={componentCode.ring}
                      onChange={(e) => setComponentCode({ ...componentCode, ring: e.target.value })}
                      style={{ width: 300 }} />
                  </div>
                </div>
                <div className='row-page' style={{ marginTop: 10 }}>
                  <div style={{ width: 100 }}>ฝาใน</div>
                  <div><input type='text' name='Innerlid'
                    defaultValue={componentCode.innerlid}
                    onChange={(e) => setComponentCode({ ...componentCode, innerlid: e.target.value })}
                    style={{ width: 300 }} /></div>
                </div>
                {/* <div className='row-page' style={{ marginTop: 10 }}>
                  <div style={{ width: 100 }}>ฝานอก</div>
                  <div><input type='text' name='Outerlid'
                    defaultValue={componentCode.outerlid}
                    onChange={(e) => setComponentCode({ ...componentCode, outerlid: e.target.value })}
                    style={{ width: 300 }} /></div>
                </div> */}
                <div className='row-page' style={{ marginTop: 10 }}>
                  <div style={{ width: 100 }}>ฝาบน</div>
                  <div><input type='text' name='Toplid'
                    defaultValue={componentCode.toplid}
                    onChange={(e) => setComponentCode({ ...componentCode, toplid: e.target.value })}
                    style={{ width: 300 }} /></div>
                </div>
                {/* <div className='row-page' style={{ marginTop: 10 }}>
                  <div style={{ width: 100 }}>ฝาล่าง</div>
                  <div><input type='text' name='Bottomlid'
                    defaultValue={componentCode.bottomlid}
                    onChange={(e) => setComponentCode({ ...componentCode, bottomlid: e.target.value })}
                    style={{ width: 300 }} /></div>
                </div> */}
                <div className='row-page' style={{ marginTop: 10 }}>
                  <div style={{ width: 100 }}>ฝาพลาสติก</div>
                  <div><input type='text' name='Plasticlid'
                    defaultValue={componentCode.plasticlid}
                    onChange={(e) => setComponentCode({ ...componentCode, plasticlid: e.target.value })}
                    style={{ width: 300 }} /></div>
                </div>
                <div className='row-page' style={{ marginTop: 10 }}>
                  <div style={{ width: 100 }}>ฝาอลูมิเนียม</div>
                  <div>
                    <input type='text' name='Aluminumlid'
                      defaultValue={componentCode.aluminumlid}
                      onChange={(e) => setComponentCode({ ...componentCode, aluminumlid: e.target.value })}
                      style={{ width: 300 }} />
                  </div>
                </div>
              </div>

            </div>
            <hr></hr>
            <div className='row-between-page'>
              <hr></hr>
              <table style={{ width: '100%' }}>
                <tr>
                  <th style={{ width: '10%' }}><center>Substrate</center></th>
                  <th style={{ width: '10%' }}><center>Body</center></th>
                  <th style={{ width: '10%' }}><center>Top end</center></th>
                  <th style={{ width: '10%' }}><center>Botton end</center></th>
                  <th style={{ width: '10%' }}><center>Tab</center></th>
                </tr>
                <tr>
                  <td><b>Sheet size(T x W x L)</b></td>
                  <td>{substratebody.sheetsize}</td>
                  <td>{substrateTop.sheetsize}</td>
                  <td>{substrateBottom.sheetsize}</td>
                  <td>{substrateTab.sheetsize}</td>
                </tr>
                <tr>
                  <td><b>Straight / Scoll</b></td>
                  <td>{substratebody.straightscroll}</td>
                  <td>{substrateTop.straightscroll}</td>
                  <td>{substrateBottom.straightscroll}</td>
                  <td>{substrateTab.straightscroll}</td>
                </tr>
                <tr>
                  <td><b>Tin coating / Alloy</b></td>
                  <td>{substratebody.tin}</td>
                  <td>{substrateTop.tin}</td>
                  <td>{substrateBottom.tin}</td>
                  <td>{substrateTab.tin}</td>
                </tr>
                <tr>
                  <td><b>Temper / Hardness</b></td>
                  <td>{substratebody.temper}</td>
                  <td>{substrateTop.temper}</td>
                  <td>{substrateBottom.temper}</td>
                  <td>{substrateTab.temper}</td>
                </tr>
                <tr>
                  <td><b>Bright / Stone / Matte</b></td>
                  <td>{substratebody.bright}</td>
                  <td>{substrateTop.bright}</td>
                  <td>{substrateBottom.bright}</td>
                  <td>{substrateTab.bright}</td>
                </tr>
                <tr>
                  <td><b>Row x Layer</b></td>
                  <td>{substratebody.row}</td>
                  <td>{substrateTop.row}</td>
                  <td>{substrateBottom.row}</td>
                  <td>{substrateTab.row}</td>
                </tr>
                <tr>
                  <td><b>Film code</b></td>
                  <td>{substratebody.filmcode}</td>
                  <td>{substrateTop.filmcode}</td>
                  <td>{substrateBottom.filmcode}</td>
                  <td>{substrateTab.filmcode}</td>
                </tr>
                <tr>
                  <td><b>Item No.</b></td>
                  <td>{substratebody.itemno}</td>
                  <td>{substrateTop.itemno}</td>
                  <td>{substrateBottom.itemno}</td>
                  <td>{substrateTab.itemno}</td>
                </tr>
                <tr>
                  <td><b>Ref.Item WC.</b></td>
                  <td>{substratebody.refitemwc}</td>
                  <td>{substrateTop.refitemwc}</td>
                  <td>{substrateBottom.refitemwc}</td>
                  <td>{substrateTab.refitemwc}</td>
                </tr>
              </table>
              <div className='border_green' style={{ width: '30%', height: 'auto', padding: 10 }}>
                <div style={{ backgroundColor: '#989898' }}>
                  <center>Component Code </center>
                </div>
                <div className='row-between-page '>
                  <div><b>หูอ๊อก/ถ้วยอ๊อก :</b></div>
                  <div>{componentCode.earwelding}</div>

                </div>
                <div className='row-between-page '>
                  <div><b>หู :</b></div>
                  <div>{componentCode.ear}</div>

                </div>
                <div className='row-between-page '>
                  <div><b>ห่วง :</b></div>
                  <div>{componentCode.ring}</div>

                </div>
                <div className='row-between-page '>
                  <div><b>ฝาใน :</b></div>
                  <div>{componentCode.innerlid}</div>
                </div>
                {/* <div className='row-between-page '>
                  <div><b>ฝานอก :</b></div>
                  <div>{componentCode.outerlid}</div>
                </div> */}
                <div className='row-between-page '>
                  <div><b>ฝาบน :</b></div>
                  <div>{componentCode.toplid}</div>
                </div>
                {/* <div className='row-between-page '>
                  <div><b>ฝาล่าง:</b></div>
                  <div>{componentCode.bottomlid}</div>
                </div> */}
                <div className='row-between-page '>
                  <div><b>ฝาพลาสติก :</b></div>
                  <div>{componentCode.plasticlid}</div>
                </div>
                <div className='row-between-page '>
                  <div><b>ฝาอลูมิเนียม :</b></div>
                  <div>{componentCode.aluminumlid}</div>
                </div>
              </div>
            </div>
          </Tab>
          <Tab eventKey="NewITEM" title="แก้ไขข้อมูล New Item to ERP" style={{ backgroundColor: 'aliceblue' }}>
            <EditNewitemToerp idjournal={jourId} />
          </Tab>
        </Tabs>
      </form>
    </div>
  )
}


const PreprassEditspec = ({ journalId, stateflow }) => {

  const values = [true, 'sm-down', 'md-down', 'lg-down', 'xl-down', 'xxl-down'];
  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);

  function handleShow(value) {
    setShow(value);
  }

  function refunction(value) {
    console.log(value)
  }



  return (
    <div >
      <Button variant="warning" style={{ fontSize: 12 }} onClick={() => handleShow(true)} >แก้ไข spec</Button>

      <Modal show={show} fullscreen={fullscreen} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Journal</Modal.Title>
        </Modal.Header>
        <Modal.Body><FormEditJournal jourId={journalId} stateflow={stateflow} handleShow={handleShow} /></Modal.Body>
      </Modal>
    </div>
  )
}

export default PreprassEditspec